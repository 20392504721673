import { deleteRoadmapTempo } from 'domain/roadmap/API';
import { useRoadmapTempo } from 'domain/roadmap/hooks';
import { GetRoadmapTempoDTO } from 'domain/roadmap/types';
import { isAdminSelector, userSelector } from 'features/auth/reducer/auth';
import { editableRoadMapSelector } from 'features/roadmap/reducers/roadmap';
import { convertMinutesToTimeFormat } from 'features/timeAllocation/utils';
import { Button } from 'primereact/button';
import { Column } from 'primereact/column';
import { confirmPopup } from 'primereact/confirmpopup';
import { DataTable } from 'primereact/datatable';
import { MouseEvent, useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useAppSelector } from 'store';
import { toDateString } from 'utils/datetime';
import { capitalizeFirstLetter } from 'utils/string';

import RoadmapTempoDialog from '../RoadmapGrid/RoadmapTempoDialog';

type TempoTableProps = {
  roadmapId: number;
  idRoadmapStatus?: number | null;
};

const dateTemplate = (rowData: GetRoadmapTempoDTO) => toDateString(rowData.date);

const timeTemplate = (rowData: GetRoadmapTempoDTO) => convertMinutesToTimeFormat(rowData.minutes);

const TempoTable = ({ roadmapId, idRoadmapStatus = null }: TempoTableProps) => {
  const { t } = useTranslation();
  const [showTempo, setShowTempo] = useState(false);
  const editableRoadMap = useAppSelector(editableRoadMapSelector);
  const { data: tempoArray, isLoading, refetch, isFetching } = useRoadmapTempo(roadmapId, idRoadmapStatus);
  const isAdmin = useAppSelector(isAdminSelector);
  const user = useAppSelector(userSelector);
  const [editableTempo, setEditableTempo] = useState<GetRoadmapTempoDTO | null>(null);

  useEffect(() => {
    refetch();
  }, [refetch]);

  const handleDeleteTempo = useCallback(
    (e: MouseEvent<HTMLButtonElement>, idRoadmapTempo: number) => {
      confirmPopup({
        target: e.currentTarget,
        message: t('general.remove-item'),
        icon: 'pi pi-info-circle',
        acceptClassName: 'p-button-danger',
        accept: async () => {
          await deleteRoadmapTempo(idRoadmapTempo);
          refetch();
        },
      });
    },
    [refetch, t],
  );

  const handleEditTempo = useCallback((rowData: GetRoadmapTempoDTO) => {
    setEditableTempo(rowData);
    setShowTempo(true);
  }, []);

  const handleButtonClick = useCallback(() => {
    setShowTempo(true);
  }, []);

  const onCloseTempo = useCallback(() => {
    setShowTempo(false);
    setEditableTempo(null);
    refetch();
  }, [refetch]);

  const header = useMemo(
    () => (
      <div>
        <Button icon="pi pi-clock" label="Log Time" onClick={handleButtonClick} />
      </div>
    ),
    [handleButtonClick],
  );

  const editButtonTemplate = useCallback(
    (rowData: GetRoadmapTempoDTO) => {
      return (
        <div className="actions">
          <Button
            disabled={!isAdmin && rowData.idUser !== user?.idUser}
            icon="pi pi-pencil"
            tooltip={capitalizeFirstLetter(t('general.edit'))}
            className="p-button-rounded mr-2"
            onClick={() => {
              handleEditTempo(rowData);
            }}
          />
          <Button
            disabled={!isAdmin && rowData.idUser !== user?.idUser}
            icon="pi pi-trash"
            tooltip="Delete"
            className="p-button-rounded p-button-warning"
            onClick={(e) => {
              handleDeleteTempo(e, rowData.idRoadmapTempo);
            }}
          />
        </div>
      );
    },
    [handleDeleteTempo, handleEditTempo, isAdmin, t, user?.idUser],
  );

  return (
    <>
      <DataTable
        value={tempoArray}
        header={header}
        loading={isLoading || isFetching}
        dataKey="idRoadmapTempo"
        paginator
        rows={10}
        rowsPerPageOptions={[5, 10, 25]}
        paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
        currentPageReportTemplate="Showing {first} to {last} of {totalRecords} items"
        emptyMessage="No items found."
        footer={`Total time: ${convertMinutesToTimeFormat(tempoArray?.reduce((a, b) => a + b.minutes, 0) ?? 0)}`}
      >
        <Column body={editButtonTemplate} />
        <Column field="user" header="User" sortable />
        <Column field="status" header="Stage" />
        <Column field="date" header="Date" sortable body={dateTemplate} />
        <Column field="time" header="Time" body={timeTemplate} />
        <Column field="comment" header="Comment" />
      </DataTable>
      {showTempo && (
        <RoadmapTempoDialog
          visible={showTempo}
          onHide={onCloseTempo}
          roadmap={editableRoadMap}
          idRoadmapStatus={idRoadmapStatus ?? undefined}
          tempo={editableTempo ?? undefined}
        />
      )}
    </>
  );
};

export default TempoTable;

TempoTable.defaultProps = {
  idRoadmapStatus: null,
};
