import editIcon from 'assets/images/icons/Edit.png';
import { RoadmapStatusItem, RoadmapStatusWithResourceDTO } from 'domain/roadmap/types';
import { isLoggedInSelector, userSelector } from 'features/auth/reducer/auth';
import { editableRoadMapSelector, setEditableRoadmap } from 'features/roadmap/reducers/roadmap';
import { Accordion, AccordionTab } from 'primereact/accordion';
import { Column, ColumnEditorOptions } from 'primereact/column';
import { DataTable, DataTableExpandedRows, DataTableValueArray } from 'primereact/datatable';
import { InputNumber } from 'primereact/inputnumber';
import { TabPanel, TabView } from 'primereact/tabview';
import { forwardRef, useCallback, useImperativeHandle, useState } from 'react';
import { useAppDispatch, useAppSelector } from 'store';
import { toDateString } from 'utils/datetime';
import { currency, roundDecimal } from 'utils/number';
import { canEditRoadmapDetails, isRoadmapCreator, isRoadmapOwner } from 'utils/permissions';

import TempoTable from '../TempoTable/TempoTable';
import CostTable from './CostTable';
import { PlanningTable } from './PlanningTable';
import SummaryTable from './SummaryTable';
import { getUpdateWorkingDays } from './utils';

interface PlanningTabProps {
  statuses: RoadmapStatusWithResourceDTO[];
}

export interface PlanningTabRef {
  expandFirstRow: () => void;
  focusOnCostTab: () => void;
}

const getPercentage = (items: RoadmapStatusItem[]) => {
  const total = items.length;
  const completed = items.filter((item) => item.status === 'Completed' || item.status === 'Not Applicable').length;
  return Math.round((completed / total) * 100);
};

const headerTemplate = (data: RoadmapStatusWithResourceDTO) => {
  const percentage = getPercentage(data.roadmapStatusItems);
  return (
    <strong>
      {data.name} ({percentage}%)
    </strong>
  );
};

const endDateTemplate = (rowData: RoadmapStatusWithResourceDTO) => rowData.endDate && toDateString(rowData.endDate);

const startDateTemplate = (rowData: RoadmapStatusWithResourceDTO) =>
  rowData.startDate && (
    <div
      style={{
        display: 'flex',
        alignItems: 'center',
        ...(rowData.isRelease ? { fontWeight: 'bold', fontFamily: 'Gilroy-SemiBold' } : {}),
      }}
    >
      {toDateString(rowData.startDate)}{' '}
      {rowData.isRelease && <i title="Release Date" className="pi pi-info-circle" style={{ marginLeft: '0.5rem' }} />}
    </div>
  );

const totalCostTemplate = (rowData: RoadmapStatusWithResourceDTO) =>
  currency.format(
    roundDecimal(rowData.resources?.reduce((a, b) => a + (b.roadmapResourceYear?.hourlyRate ?? 0) * b.hours, 0) ?? 0),
  );

const PlanningTab = forwardRef<PlanningTabRef, PlanningTabProps>(({ statuses }: PlanningTabProps, ref) => {
  const [expandedRows, setExpandedRows] = useState<DataTableExpandedRows | DataTableValueArray | undefined>([]);
  const [activeIndex, setActiveIndex] = useState(0);
  const [checkInconsistency, setCheckInconsistency] = useState(true);
  const editableRoadMap = useAppSelector(editableRoadMapSelector);
  const isLoggedIn = useAppSelector(isLoggedInSelector);
  const dispatch = useAppDispatch();

  const expandFirstRow = useCallback(() => {
    if (statuses.length) {
      setExpandedRows({ [statuses[0].idRoadmapStatus]: true });
    }
  }, [statuses]);

  const focusOnCostTab = useCallback(() => {
    setActiveIndex(1);
  }, []);

  useImperativeHandle(ref, () => ({
    expandFirstRow,
    focusOnCostTab,
  }));

  const user = useAppSelector(userSelector);

  const canEditRoadmap =
    !editableRoadMap.idRoadmap ||
    canEditRoadmapDetails(user, editableRoadMap) ||
    isRoadmapOwner(user, editableRoadMap) ||
    isRoadmapCreator(user, editableRoadMap);

  const editWorkingDays = useCallback(
    (idRoadmapStatus: number, value: number | null) => {
      const updatedRoadmap = getUpdateWorkingDays(editableRoadMap, idRoadmapStatus, value);
      if (updatedRoadmap) {
        dispatch(setEditableRoadmap(updatedRoadmap));
      }
    },
    [dispatch, editableRoadMap],
  );

  const workingDaysEditor = useCallback(
    (options: ColumnEditorOptions) => (
      <InputNumber
        value={options.rowData.workingDays}
        onValueChange={(e) => editWorkingDays(options.rowData.idRoadmapStatus, e.value ?? null)}
        mode="decimal"
        showButtons
        min={1}
        style={{ width: '3rem' }}
        inputStyle={{ width: '3rem' }}
      />
    ),
    [editWorkingDays],
  );

  const rowExpansionTemplate = useCallback(
    (rowData: RoadmapStatusWithResourceDTO) => (
      <div className="planning-tab-content">
        <TabView activeIndex={activeIndex} onTabChange={(e) => setActiveIndex(e.index)}>
          <TabPanel header="Planning" leftIcon="pi pi-calendar mr-2">
            <PlanningTable roadmapStatus={rowData} />
          </TabPanel>
          <TabPanel className="new-cost-tab" header="Cost" leftIcon="pi pi-dollar mr-2">
            <CostTable
              row={rowData}
              year={
                editableRoadMap.version
                  ? new Date(editableRoadMap.version?.releaseDate).getFullYear()
                  : new Date().getFullYear()
              }
              checkInconsistency={checkInconsistency}
              setCheckInconsistency={setCheckInconsistency}
            />
          </TabPanel>
          {isLoggedIn && (
            <TabPanel header="Time" leftIcon="pi pi-clock mr-2">
              <TempoTable roadmapId={editableRoadMap.idRoadmap} idRoadmapStatus={rowData.idRoadmapStatus} />
            </TabPanel>
          )}
        </TabView>
      </div>
    ),
    [activeIndex, checkInconsistency, editableRoadMap.idRoadmap, editableRoadMap.version, isLoggedIn],
  );

  return (
    <>
      <Accordion>
        <AccordionTab header="Cost Summary">
          <SummaryTable statuses={statuses} />
        </AccordionTab>
      </Accordion>
      <br />
      <DataTable
        value={statuses}
        dataKey="idRoadmapStatus"
        responsiveLayout="scroll"
        editMode={canEditRoadmap ? 'cell' : undefined}
        sortOrder={1}
        expandedRows={expandedRows}
        onRowToggle={(e) => setExpandedRows(e.data)}
        rowExpansionTemplate={rowExpansionTemplate}
      >
        <Column expander style={{ width: '3em' }} />
        <Column field="name" header="Name" body={headerTemplate} />
        <Column
          style={{ width: '7em' }}
          field="workingDays"
          bodyStyle={{ cursor: canEditRoadmap ? `url(${editIcon}),cell` : 'default' }}
          editor={canEditRoadmap ? workingDaysEditor : undefined}
          header="Days"
        />
        <Column style={{ width: '9em' }} field="startDate" header="Start Date" body={startDateTemplate} />
        <Column style={{ width: '8em' }} field="endDate" header="End Date" body={endDateTemplate} />
        <Column style={{ width: '12rem' }} header="Total Cost" body={totalCostTemplate} />
      </DataTable>
    </>
  );
});

export default PlanningTab;
