import { ProductMaturityHistory } from 'domain/product/types';
import { getHeader } from 'features/admin/utils/maturityModel';
import { Column } from 'primereact/column';
import { DataTable } from 'primereact/datatable';
import { toDateString } from 'utils/datetime';

interface MaturityHistoryItemProps {
  items: ProductMaturityHistory[];
}

const editedTimeStampTemplate = (rowData: ProductMaturityHistory) => toDateString(rowData.editedTimestamp);
const valueTemplate = (rowData: ProductMaturityHistory) => getHeader(rowData.optionValue);

const MaturityHistoryItem = ({ items }: MaturityHistoryItemProps) => {
  return (
    <DataTable value={items} rowGroupMode="rowspan" groupRowsBy="item" sortMode="single" sortField="item" sortOrder={1}>
      <Column field="item" header="Item" sortable />
      <Column field="option" header="Option" sortable />
      <Column field="optionValue" header="Value" body={valueTemplate} sortable />
      <Column field="user" header="User" sortable />
      <Column field="editedTimestamp" header="Edited Timestamp" body={editedTimeStampTemplate} sortable />
    </DataTable>
  );
};

export default MaturityHistoryItem;
