import classNames from 'classnames';
import { RoadMapUpdate, RoadMapVersion } from 'domain/roadmap/types';
import { ProductBrief } from 'features/products/types';
import { editableRoadMapSelector } from 'features/roadmap/reducers/roadmap';
import { MultiSelect } from 'primereact/multiselect';
import { useMemo } from 'react';
import { Control, Controller } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useAppSelector } from 'store';

import styles from '../RoadMapDetailForm.module.scss';
import { versionTemplate } from './templates';

interface AffectedVersionsFieldProps {
  viewOnly: boolean;
  control: Control<RoadMapUpdate, unknown>;
  product: ProductBrief | null;
  roadmapVersions: RoadMapVersion[];
  required: boolean;
}

const renderAffectedVersion = (affectedVersions: RoadMapVersion[]) =>
  affectedVersions.length ? (
    <p className={styles.readonlyField}>
      {affectedVersions.map((version) => (
        <div key={version.idPlatformVersion}>{versionTemplate(version)}</div>
      ))}
    </p>
  ) : (
    <MultiSelect disabled />
  );

const AffectedVersionsField = ({
  viewOnly,
  control,
  product,
  roadmapVersions,
  required,
}: AffectedVersionsFieldProps) => {
  const { t } = useTranslation();
  const editableRoadMap = useAppSelector(editableRoadMapSelector);

  const renderTip = useMemo(() => {
    if (viewOnly || (product != null && roadmapVersions.length > 0)) return undefined;
    if (!product)
      return (
        <div>
          <small className="p-warning">Select a product first</small>
        </div>
      );
    return (
      <div>
        <small className="p-warning">{`No versions saved for the product ${product?.product}`}</small>
      </div>
    );
  }, [product, roadmapVersions.length, viewOnly]);

  return (
    <Controller
      name="affectedVersions"
      control={control}
      rules={{ required }}
      render={({ field: { value, onChange }, fieldState: { error } }) => (
        <div className={styles.field}>
          <span className={classNames({ 'p-error': error && required, required })}>Affected Versions{renderTip}</span>
          {viewOnly || !roadmapVersions.length ? (
            renderAffectedVersion(editableRoadMap.affectedVersions ?? [])
          ) : (
            <MultiSelect
              className={classNames({ 'p-invalid': error && required })}
              disabled={product === null || !roadmapVersions.length}
              dataKey="idPlatformVersion"
              value={value}
              options={[...roadmapVersions]}
              onChange={(e) => onChange(e.value ?? [])}
              optionLabel="name"
              filter
              showClear
              filterBy="name"
              display="chip"
              itemTemplate={versionTemplate}
            />
          )}
          {required && error && <small className="p-error">{t('form.required')}</small>}
        </div>
      )}
    />
  );
};

export default AffectedVersionsField;
