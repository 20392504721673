import { editableProductSelector, setEditableProduct } from 'features/products/reducers/solutionSlice';
import { ProductVideo } from 'features/products/types';
import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';
import { Toolbar } from 'primereact/toolbar';
import { useState } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { toast } from 'react-toastify';
import { useAppDispatch, useAppSelector } from 'store';

import { AddVideosForm } from '../AddVideosForm';
import PublicVideoList from '../PublicVideoList/PublicVideoList';
import styles from './RelatedProductVideos.module.scss';

type RelatedProductVideosProps = {
  videos: ProductVideo[];
};

const initialValue = {
  title: '',
  hyperlink: '',
};

const getVideoId = (url: string) => {
  const regExp = /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|&v=)([^#&?]*).*/;
  const match = url.match(regExp);

  return match && match[2].length === 11 ? match[2] : null;
};

const formatVideoWithEmbedUrl = (value: ProductVideo) => {
  let url = value.hyperlink.trim();
  const videoId = getVideoId(url);
  if (videoId) {
    url = `https://www.youtube.com/embed/${videoId}`;
  }
  const video = { ...value, hyperlink: url };
  return video;
};

const RelatedProductVideos = ({ videos }: RelatedProductVideosProps) => {
  const editableProduct = useAppSelector(editableProductSelector);
  const dispatch = useAppDispatch();
  const [addDialogVisible, setAddDialogVisible] = useState(false);
  const [isNew, setIsNew] = useState(false);

  const renderHeader = () => (
    <div className={styles.headerContainer}>
      <span>{isNew ? 'Add' : 'Edit'} Video</span>
    </div>
  );
  const { control, handleSubmit, formState, reset } = useForm<ProductVideo>({
    defaultValues: initialValue,
  });

  const addItem = (value: ProductVideo) => {
    if (editableProduct) {
      const video = formatVideoWithEmbedUrl(value);
      dispatch(setEditableProduct({ ...editableProduct, videos: [...(editableProduct.videos ?? []), video] }));
    }
  };

  const updateItem = (value: ProductVideo) => {
    if (editableProduct) {
      const video = formatVideoWithEmbedUrl(value);
      dispatch(
        setEditableProduct({
          ...editableProduct,
          videos: editableProduct.videos.map((v) => (v.idProductVideo === value.idProductVideo ? video : v)),
        }),
      );
    }
  };

  const handleAddNewVideo = () => {
    reset({
      ...initialValue,
    });
    setIsNew(true);
    setAddDialogVisible(true);
  };

  const handleEditVideo = (value: ProductVideo) => {
    reset(value);
    setIsNew(false);
    setAddDialogVisible(true);
  };

  const removeItem = (value: ProductVideo) => {
    if (editableProduct) {
      dispatch(
        setEditableProduct({
          ...editableProduct,
          videos: editableProduct.videos.filter((item) => item.idProductVideo !== value.idProductVideo),
        }),
      );
    }
  };
  const leftToolbarTemplate = () => (
    <Button
      label="Add"
      icon="pi pi-plus"
      className="p-button-success mr-2 p-mb-2"
      aria-haspopup
      aria-controls="overlay_panel"
      onClick={handleAddNewVideo}
    />
  );

  const onSubmit: SubmitHandler<ProductVideo> = async (payload) => {
    if (videos.some((video) => video.title === payload.title && video.idProductVideo !== payload.idProductVideo)) {
      toast.error('A video with the same title already exists');
      return;
    }
    if (payload.idProductVideo > 0) {
      updateItem(payload);
    } else {
      addItem(payload);
    }
    reset(initialValue);
    setAddDialogVisible(false);
  };

  const itemDialogFooter = (
    <div className={styles.bottomButton}>
      <Button
        label="Close"
        icon="pi pi-times"
        className="p-button-outlined"
        onClick={() => setAddDialogVisible(false)}
      />
      <Button
        label={isNew ? 'Add' : 'Save'}
        icon={`pi ${isNew ? 'pi-plus' : 'pi-check'}`}
        onClick={handleSubmit(onSubmit)}
      />
    </div>
  );

  return (
    <>
      <Toolbar className="p-mb-4 p-toolbar" left={leftToolbarTemplate} />
      <PublicVideoList videos={videos} readonly={false} onRemove={removeItem} onEdit={handleEditVideo} />
      {addDialogVisible && (
        <Dialog
          visible={addDialogVisible}
          className={styles.dialog}
          header={renderHeader}
          modal
          footer={itemDialogFooter}
          onHide={() => setAddDialogVisible(false)}
        >
          <AddVideosForm control={control} errors={formState.errors} />
        </Dialog>
      )}
    </>
  );
};

export default RelatedProductVideos;
