import { PayloadAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { postOrder } from 'domain/orders/api/orders';
import type { RootState } from 'store/rootReducer';

import { CartForm, CartProduct } from '../types';

interface PostCartProps extends CartForm {
  country: string;
}

type CartState = {
  cartProducts: CartProduct[];
  isLoading: boolean;
  error: string | null;
};

const initialState: CartState = { cartProducts: [], isLoading: false, error: null };

// const showSuccess = () => {
//   showSuccess({
//     title: capitalizeFirstLetter(t('general.success')),
//     detail: `${product.product} ${t('products.added-to-cart')}`,
//     content: (
//       <>
//         <span className="p-toast-message-icon pi pi-check" />
//         <div className="p-toast-message-text">
//           <span className="p-toast-summary capitalize">{t('general.success')}</span>
//           <div className="p-toast-detail">{`${product.product} ${t('products.added-to-cart')}`}</div>
//           <Link to="/solutions/cart" className="p-toast-link">
//             {t('cart.go-to-cart')}
//           </Link>
//         </div>
//       </>
//     ),
//     lifeTime: 5000,
//   });
// };

export const postCart = createAsyncThunk('cart/post', async (data: PostCartProps, thunkAPI) => {
  try {
    const { cart } = thunkAPI.getState() as RootState;
    await postOrder({ ...data, ...{ products: cart.cartProducts } });
    return null;
  } catch (error) {
    return thunkAPI.rejectWithValue(`${error}`);
  }
});

const cartSlice = createSlice({
  name: 'cart',
  initialState,
  reducers: {
    addToCart: (state, action: PayloadAction<CartProduct>) => {
      const { idProduct, product, image2, group } = action.payload;
      state.cartProducts = [...state.cartProducts, { idProduct, product, image2, group }];
    },
    removeFromCart: (state, action: PayloadAction<number>) => {
      const id = action.payload;
      state.cartProducts = state.cartProducts.filter((p) => p.idProduct !== id);
    },
    resetCart: (state) => {
      state.cartProducts = [];
      state.isLoading = false;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(postCart.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(postCart.fulfilled, (state) => {
      state.isLoading = false;
      state.cartProducts = [];
    });
    builder.addCase(postCart.rejected, (state, action) => {
      state.isLoading = false;
      state.error = action.payload as string;
    });
  },
});

export const { addToCart, removeFromCart, resetCart } = cartSlice.actions;

export const cartProductsSelector = (state: RootState) => state.cart.cartProducts;
export const isLoadingCartSelector = (state: RootState) => state.cart.isLoading;

export default cartSlice.reducer;
