import classNames from 'classnames';
import { useRoadmapsVersions } from 'domain/roadmap/hooks';
import { RoadMapUpdate, RoadMapVersion } from 'domain/roadmap/types';
import { useProductsBrief } from 'features/admin/hooks';
import { isAdminSelector, userSelector } from 'features/auth/reducer/auth';
import { ProductBrief } from 'features/products/types';
import { editableRoadMapSelector } from 'features/roadmap/reducers/roadmap';
import { Dropdown } from 'primereact/dropdown';
import { Tooltip } from 'primereact/tooltip';
import { useCallback, useMemo } from 'react';
import { Control, Controller, UseFormSetValue } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useAppSelector } from 'store';
import { isProductManager, isProductOwner, isTechnologyManager } from 'utils/permissions';

import styles from '../RoadMapDetailForm.module.scss';

interface ProductFieldProps {
  viewOnly: boolean;
  isCustomerCare: boolean;
  control: Control<RoadMapUpdate, unknown>;
  setValue: UseFormSetValue<RoadMapUpdate>;
  setRoadmapVersions: (versions: RoadMapVersion[]) => void;
}

const ProductField = ({ viewOnly, isCustomerCare, control, setValue, setRoadmapVersions }: ProductFieldProps) => {
  const { t } = useTranslation();
  const { data: products } = useProductsBrief();
  const editableRoadMap = useAppSelector(editableRoadMapSelector);
  const user = useAppSelector(userSelector);
  const { data: versions } = useRoadmapsVersions(!!user);
  const isAdmin = useAppSelector(isAdminSelector);

  const canViewProduct = useCallback(
    (p: ProductBrief): boolean =>
      isAdmin || isCustomerCare || isProductOwner(user, p) || isTechnologyManager(user, p) || isProductManager(user, p),
    [isAdmin, isCustomerCare, user],
  );

  const visibleProducts = useMemo(
    () =>
      products
        ?.filter((p) => canViewProduct(p))
        .map((p) => ({ idProduct: p.idProduct, product: p.product, nickName: p.nickName, idPlatform: p.idPlatform })) ??
      [],
    [canViewProduct, products],
  );

  const handleChangeProduct = useCallback(
    (product: ProductBrief) => {
      setValue('affectedVersions', []);
      setValue('fixVersions', []);

      const filteredVersions = versions?.filter((v) => v.release?.idPlatform === product.idPlatform) ?? [];
      setRoadmapVersions(filteredVersions);
    },
    [setRoadmapVersions, setValue, versions],
  );

  return (
    <>
      <Tooltip disabled={!viewOnly} mouseTrack target="[id='product']">
        <div>
          <p>Editing product requires the following permissions</p>
          <ul>
            <li>Admin</li>
            <li>Head of Technology</li>
            <li>Technical Product Owner</li>
            <li>Global Product Manager</li>
          </ul>
        </div>
      </Tooltip>
      <Controller
        name="product"
        control={control}
        rules={{ required: true }}
        render={({ field: { value, onChange }, fieldState: { error } }) => (
          <div id="product" className={styles.field}>
            <span className={classNames({ 'p-error': error, required: !viewOnly })}>Product</span>

            {viewOnly ? (
              <p className={styles.readonlyField}>{editableRoadMap.product?.product}</p>
            ) : (
              <>
                <Dropdown
                  value={value}
                  onChange={(e) => {
                    handleChangeProduct(e.value as ProductBrief);
                    onChange(e.value);
                  }}
                  optionLabel="product"
                  dataKey="idProduct"
                  filter
                  filterBy="product,nickName"
                  options={visibleProducts}
                  className={classNames({ 'p-invalid': error })}
                />
                {error && <small className="p-error">{t('form.required')}</small>}
              </>
            )}
          </div>
        )}
      />
    </>
  );
};

export default ProductField;
