import { isAdminSelector } from 'features/auth/reducer/auth';
import { FC } from 'react';
import { Link } from 'react-router-dom';
import { useAppSelector } from 'store';

import styles from './HomePage.module.scss';

const Home: FC = () => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const isAdmin = useAppSelector(isAdminSelector);
  return (
    <div className={styles.mainContainer}>
      {/* <div className="surface-card shadow-2 border-round w-full lg:w-auto select-none" style="min-width: 16rem;">
        <ul className="list-none p-2 m-0">
          <li>
            <div className="p-3 text-500 font-medium">HOME</div>
          </li>
          <li>
            <a className="p-ripple flex align-items-center cursor-pointer p-3 hover:surface-100 border-round transition-colors transition-duration-150 w-full">
              <i className="pi pi-home mr-2 text-700"></i>
              <span className="font-medium text-700">Dashboard</span>
              <span role="presentation" className="p-ink"></span>
            </a>
          </li>
          <li>
            <a className="p-ripple flex align-items-center cursor-pointer p-3 hover:surface-100 border-round transition-colors transition-duration-150 w-full">
              <i className="pi pi-bookmark mr-2 text-700"></i>
              <span className="font-medium text-700">Bookmarks</span>
              <span
                className="inline-flex align-items-center justify-content-center ml-auto bg-blue-100 text-blue-900 border-circle"
                style="min-width: 1.5rem; height: 1.5rem;"
              >
                3
              </span>
              <span role="presentation" className="p-ink"></span>
            </a>
          </li>
          <li>
            <a className="p-ripple flex align-items-center cursor-pointer p-3 hover:surface-100 border-round transition-colors transition-duration-150 w-full">
              <i className="pi pi-users mr-2 text-700"></i>
              <span className="font-medium text-700">Team</span>
              <span role="presentation" className="p-ink"></span>
            </a>
          </li>
          <li>
            <a className="p-ripple flex align-items-center cursor-pointer p-3 hover:surface-100 border-round transition-colors transition-duration-150 w-full">
              <i className="pi pi-comments mr-2 text-700"></i>
              <span className="font-medium text-700">Messages</span>
              <span role="presentation" className="p-ink"></span>
            </a>
          </li>
          <li>
            <a className="p-ripple flex align-items-center cursor-pointer p-3 hover:surface-100 border-round transition-colors transition-duration-150 w-full">
              <i className="pi pi-calendar mr-2 text-700"></i>
              <span className="font-medium text-700">Calendar</span>
              <span
                className="inline-flex align-items-center justify-content-center ml-auto bg-purple-100 text-purple-900 border-circle"
                style="min-width: 1.5rem; height: 1.5rem;"
              >
                2
              </span>
              <span role="presentation" className="p-ink"></span>
            </a>
          </li>
        </ul>
        <ul className="list-none p-2 m-0 border-top-1 surface-border">
          <li>
            <div className="p-3 text-500 font-medium">ORGANIZATION</div>
          </li>
          <li>
            <a className="p-ripple flex align-items-center cursor-pointer p-3 hover:surface-100 border-round transition-colors transition-duration-150 w-full">
              <i className="pi pi-home mr-2 text-700"></i>
              <span className="font-medium text-700">Overview</span>
              <span role="presentation" className="p-ink"></span>
            </a>
          </li>
          <li>
            <a className="p-ripple flex align-items-center cursor-pointer p-3 hover:surface-100 border-round transition-colors transition-duration-150 w-full">
              <i className="pi pi-bookmark mr-2 text-700"></i>
              <span className="font-medium text-700">Security</span>
              <span
                className="inline-flex align-items-center justify-content-center ml-auto bg-cyan-100 text-cyan-900 border-circle"
                style="min-width: 1.5rem; height: 1.5rem;"
              >
                3
              </span>
              <span role="presentation" className="p-ink"></span>
            </a>
          </li>
          <li>
            <a className="p-ripple flex align-items-center cursor-pointer p-3 hover:surface-100 border-round transition-colors transition-duration-150 w-full">
              <i className="pi pi-users mr-2 text-700"></i>
              <span className="font-medium text-700">Domains</span>
              <span role="presentation" className="p-ink"></span>
            </a>
          </li>
          <li>
            <a className="p-ripple flex align-items-center cursor-pointer p-3 hover:surface-100 border-round transition-colors transition-duration-150 w-full">
              <i className="pi pi-calendar mr-2 text-700"></i>
              <span className="font-medium text-700">Reports</span>
              <span
                className="inline-flex align-items-center justify-content-center ml-auto bg-orange-100 text-orange-900 border-circle"
                style="min-width: 1.5rem; height: 1.5rem;"
              >
                2
              </span>
              <span role="presentation" className="p-ink"></span>
            </a>
          </li>
        </ul>
      </div> */}
      <div className="card">
        <div className="table-header">
          <h4 className="p-m-0 capitalize">Home</h4>
        </div>
        <hr />
        <div className={styles.item}>
          <Link className={styles.link} to="./products">
            Admin/Products
          </Link>
        </div>
        <div className={styles.item}>
          <Link className={styles.link} to="./roadmap">
            Admin/RoadMap
          </Link>
        </div>
        <div className={styles.item}>
          <Link className={styles.link} to="./benefits">
            Admin/Benefits
          </Link>
        </div>
        <div className={styles.item}>
          <Link className={styles.link} to="./okrs">
            Admin/Okrs
          </Link>
        </div>
        <div className={styles.item}>
          <Link className={styles.link} to="./product-lifecycle">
            Admin/Products LifeCycles
          </Link>
        </div>
        <div className={styles.item}>
          <Link className={styles.link} to="./plants">
            Admin/Plants
          </Link>
        </div>
        <div className={styles.item}>
          <Link className={styles.link} to="./countries">
            Admin/Countries
          </Link>
        </div>
        <div className={styles.item}>
          <Link className={styles.link} to="./quality-assurance/templates">
            Admin/Quality Assurance Templates
          </Link>
        </div>
        <div className={styles.item}>
          <Link className={styles.link} to="./quality-assurance/certificates">
            Admin/Quality Assurance Certificates
          </Link>
        </div>
        {/* <div className={styles.item}>
          <Link className={styles.link} to="./maturity-model/options">
            Admin/Maturity Model Options
          </Link>
        </div> */}
        <div className={styles.item}>
          <Link className={styles.link} to="./maturity-model/models">
            Admin/Maturity Model Models
          </Link>
        </div>
        <div className={styles.item}>
          <Link className={styles.link} to="./product-portfolio">
            Admin/Product Portfolio
          </Link>
        </div>
        <div className={styles.item}>
          <Link className={styles.link} to="./product-groups">
            Admin/Product Groups
          </Link>
        </div>
        <div className={styles.item}>
          <Link className={styles.link} to="./user-personas">
            Admin/User Personas
          </Link>
        </div>
        <div className={styles.item}>
          <Link className={styles.link} to="./product-type">
            Admin/Product Type
          </Link>
        </div>
        <div className={styles.item}>
          <Link className={styles.link} to="./industries">
            Admin/Industries
          </Link>
        </div>
        <div className={styles.item}>
          <Link className={styles.link} to="./users">
            Admin/Users
          </Link>
        </div>
        <div className={styles.item}>
          <Link className={styles.link} to="./funding-sources">
            Admin/Funding Sources
          </Link>
        </div>
        <div className={styles.item}>
          <Link className={styles.link} to="./country/maps">
            Country Map
          </Link>
        </div>
      </div>
    </div>
  );
};

export default Home;
