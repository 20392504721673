import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

type SuccessToastProps = {
  title: string;
};

const SuccessToast = ({ title }: SuccessToastProps) => {
  const { t } = useTranslation();
  return (
    <>
      <span className="p-toast-message-icon pi pi-check" />
      <div className="p-toast-message-text">
        <span className="p-toast-summary capitalize">{t('general.success')}</span>
        <div className="p-toast-detail">{`${title} ${t('products.added-to-cart')}`}</div>
        <Link to="/solutions/cart" className="p-toast-link">
          {t('cart.go-to-cart')}
        </Link>
      </div>
    </>
  );
};

export default SuccessToast;
