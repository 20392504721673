import { DropdownProps, Dropdown as PrimeDropdown } from 'primereact/dropdown';
import { ReactNode } from 'react';

const Dropdown = (
  props: JSX.IntrinsicAttributes &
    JSX.IntrinsicClassAttributes<PrimeDropdown> &
    Readonly<DropdownProps> &
    Readonly<{ children?: ReactNode }>,
) => <PrimeDropdown {...props} />;

export default Dropdown;
