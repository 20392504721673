import { PlantBasicDTO } from 'domain/country/types';
import { toast } from 'react-toastify';
import api from 'services/api';

import { Plant, PlantDTO, PlantStatusDTO } from '../types';

const BASE_URL = 'plants';

export const getPlants = async () => {
  const { data } = await api.get(BASE_URL);
  return data as PlantDTO[];
};

export const getPlantsBasic = async () => {
  const { data } = await api.get(`${BASE_URL}/basic`);
  return data as PlantBasicDTO[];
};

export const getPlantsWithProducts = async () => {
  const { data } = await api.get(`${BASE_URL}/with-products`);
  return data as Plant[];
};

export const getPlant = async (id: number) => {
  const { data } = await api.get(`${BASE_URL}/${id}`);
  return data as Plant;
};

export const updatePlant = async (data: PlantDTO) => {
  try {
    await api.put(BASE_URL, data);
    toast.success('Plant updated successfully');
    return data;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  } catch (e: any) {
    toast.error(`Failed to update plant: ${e.message} `);
    throw e;
  }
};

export const createPlant = async (data: PlantDTO) => {
  try {
    await api.post(BASE_URL, data);
    toast.success('Plant created successfully');
    return data;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  } catch (e: any) {
    toast.error(`Failed to create plant: ${e.message} `);
    throw e;
  }
};

export const deletePlant = async (id: number) => {
  try {
    await api.delete(`${BASE_URL}/${id}`);
    toast.success('Plant deleted successfully');
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  } catch (e: any) {
    toast.error(`Failed to delete plant: ${e.message} `);
    throw e;
  }
};

export const getPlantStatuses = async () => {
  const { data } = await api.get(`${BASE_URL}/statuses`);
  return data as PlantStatusDTO[];
};
