import { CheckboxProps, Checkbox as PrimeCheckbox } from 'primereact/checkbox';
import { ReactNode } from 'react';

const Checkbox = (
  props: JSX.IntrinsicAttributes &
    JSX.IntrinsicClassAttributes<PrimeCheckbox> &
    Readonly<CheckboxProps> &
    Readonly<{ children?: ReactNode }>,
) => <PrimeCheckbox {...props} />;

export default Checkbox;
