import api from 'services/api';

import { AuthUser, LoginResponse } from '../types';

export const adLogin = (accessToken: string): Promise<LoginResponse> => api.post(`/auth/validate/${accessToken}`);

export const getUserInfo = async (): Promise<AuthUser> => {
  const { data } = await api.get('/auth/user');
  return data as AuthUser;
};
