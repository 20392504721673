import { toast } from 'react-toastify';
import api from 'services/api';

import { Industry } from '../types';

const BASE_URL = 'industries';

export const getIndustries = async () => {
  const { data } = await api.get(BASE_URL);
  return data as Industry[];
};

export const getIndustry = async (id: number) => {
  const { data } = await api.get(`${BASE_URL}/${id}`);
  return data as Industry;
};

export const updateIndustry = async (data: Industry) => {
  try {
    await api.put(BASE_URL, data);
    toast.success('Industry updated successfully');
    return data;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  } catch (e: any) {
    toast.error(`Failed to update Industry: ${e.message} `);
    throw e;
  }
};

export const createIndustry = async (data: Industry) => {
  try {
    await api.post(BASE_URL, data);
    toast.success('Industry created successfully');
    return data;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  } catch (e: any) {
    toast.error(`Failed to create Industry: ${e.message} `);
    throw e;
  }
};

export const deleteIndustry = async (id: number) => {
  try {
    await api.delete(`${BASE_URL}/${id}`);
    toast.success('Industry deleted successfully');
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  } catch (e: any) {
    throw Error(`Failed to delete Industry: ${e.response?.data?.Message ?? e.message} `);
  }
};
