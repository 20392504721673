import { adminRoutes } from 'features/admin/routes';
import { LogoutPage } from 'features/auth/pages';
import { MainLayout } from 'features/main/components';
import type { RouteObject } from 'react-router-dom';
import { lazyImport } from 'utils/lazyImport';

const { OkrRoutes } = lazyImport(() => import('features/okrs/routes'), 'OkrRoutes');
const { QualityAssuranceRoutes } = lazyImport(
  () => import('features/qualityAssurance/routes'),
  'QualityAssuranceRoutes',
);
const { MaturityModelRoutes } = lazyImport(() => import('features/maturityModel/routes'), 'MaturityModelRoutes');
const { RoadmapTempoRoutes } = lazyImport(() => import('features/timeAllocation/routes'), 'RoadmapTempoRoutes');
const { ProductPortfolioRoutes } = lazyImport(
  () => import('features/productPortfolio/routes'),
  'ProductPortfolioRoutes',
);
const { PlantRoutes } = lazyImport(() => import('features/plant/routes'), 'PlantRoutes');
const { ProductGroupRoutes } = lazyImport(() => import('features/productGroup/routes'), 'ProductGroupRoutes');
const { UserPersonaRoutes } = lazyImport(() => import('features/userPersona/routes'), 'UserPersonaRoutes');
const { ProductTypeRoutes } = lazyImport(() => import('features/productType/routes'), 'ProductTypeRoutes');
const { IndustryRoutes } = lazyImport(() => import('features/industry/routes'), 'IndustryRoutes');
const { UserRoutes } = lazyImport(() => import('features/user/routes'), 'UserRoutes');
const { AccessTokenRoutes } = lazyImport(() => import('features/accessTokens/routes'), 'AccessTokenRoutes');
const { FundingSourceRoutes } = lazyImport(() => import('features/fundingSource/routes'), 'FundingSourceRoutes');
const { CountryRoutes } = lazyImport(() => import('features/country/routes'), 'CountryRoutes');

export const protectedRoutes: RouteObject[] = [
  {
    path: '/auth/logout',
    element: <LogoutPage />,
  },
  {
    path: '/manage/*',
    element: <MainLayout />,
    children: [{ path: 'time-allocation/*', element: <RoadmapTempoRoutes /> }],
  },
  {
    path: '/admin/*',
    element: <MainLayout />,
    children: [
      { path: '', children: adminRoutes },
      { path: 'okrs/*', element: <OkrRoutes /> },
      { path: 'quality-assurance/*', element: <QualityAssuranceRoutes /> },
      { path: 'maturity-model/*', element: <MaturityModelRoutes /> },
      { path: 'product-portfolio/*', element: <ProductPortfolioRoutes /> },
      { path: 'plants/*', element: <PlantRoutes /> },
      { path: 'product-groups/*', element: <ProductGroupRoutes /> },
      { path: 'user-personas/*', element: <UserPersonaRoutes /> },
      { path: 'product-type/*', element: <ProductTypeRoutes /> },
      { path: 'industries/*', element: <IndustryRoutes /> },
      { path: 'users/*', element: <UserRoutes /> },
      { path: 'access-tokens/*', element: <AccessTokenRoutes /> },
      { path: 'funding-sources/*', element: <FundingSourceRoutes /> },
      { path: 'country/*', element: <CountryRoutes /> },
    ],
  },
];
