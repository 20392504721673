import { RoadMapUpdate } from 'domain/roadmap/types';
import { useUsers } from 'domain/user/hooks';
import { UserBrief } from 'domain/user/types';
import { userSelector } from 'features/auth/reducer/auth';
import { editableRoadMapSelector } from 'features/roadmap/reducers/roadmap';
import { MultiSelect } from 'primereact/multiselect';
import { Tag } from 'primereact/tag';
import { Control, Controller } from 'react-hook-form';
import { useAppSelector } from 'store';

import styles from '../RoadMapDetailForm.module.scss';

const renderParticipants = (participants: UserBrief[], idUser: number | undefined) =>
  participants.map((participant) => (
    <div className="mb-2">
      <Tag severity={participant.idUser === idUser ? 'success' : 'info'} icon="pi pi-user" key={participant.idUser}>
        {participant.name}
      </Tag>
    </div>
  ));

interface ParticipantsFieldProps {
  viewOnly: boolean;
  control: Control<RoadMapUpdate, unknown>;
}

const ParticipantsField = ({ viewOnly, control }: ParticipantsFieldProps) => {
  const editableRoadMap = useAppSelector(editableRoadMapSelector);
  const user = useAppSelector(userSelector);
  const { data: users } = useUsers({ enabled: !!user });
  return (
    <Controller
      name="participants"
      control={control}
      render={({ field: { value, onChange } }) => (
        <div className={styles.field}>
          <span>Participants</span>
          {viewOnly ? (
            <div className={styles.readonlyField}>
              {renderParticipants(editableRoadMap.participants ?? [], user?.idUser)}
            </div>
          ) : (
            <MultiSelect
              value={value ?? []}
              onChange={(e) => onChange(e.value ?? [])}
              optionLabel="name"
              showClear
              dataKey="idUser"
              filter
              filterBy="name"
              display="chip"
              options={users}
            />
          )}
        </div>
      )}
    />
  );
};

export default ParticipantsField;
