import api from 'services/api';

import { AutonomyFunctionDTO, AutonomyLevelDTO } from '../types/autonomylevels.interface';

export const getAutonomyLevel = async () => {
  const { data } = await api.get('autonomylevels');
  return data as AutonomyLevelDTO[];
};

export const getAutonomyLevelFunctions = async () => {
  const { data } = await api.get('autonomylevels/functions');
  return data as AutonomyFunctionDTO[];
};
