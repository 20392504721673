import React, { memo } from 'react';

import styles from './Footer.module.scss';

const Footer = () => (
  <footer className={styles.footer}>
    <div className={styles.copyright}>© ANDRITZ {new Date().getFullYear()}</div>
    <ul>
      <li>
        <a href="https://www.andritz.com/group-en/privacy-policy/imprint" target="_blank" rel="noreferrer">
          Imprint
        </a>
      </li>
      <li>
        <a href="https://www.andritz.com/group-en/privacy-policy" target="_blank" rel="noreferrer">
          Data privacy declaration
        </a>
      </li>
      <li>
        <a href="https://www.andritz.com/group-en/cookies" target="_blank" rel="noreferrer">
          Cookies policy
        </a>
      </li>
    </ul>
    <div className={styles.socialMedia}>
      <ul>
        <li>
          <a href="http://www.youtube.com/c/AndritzGroup" target="_blank" rel="noreferrer">
            <i className="pi pi-youtube" />
          </a>
        </li>
        <li>
          <a href="https://www.linkedin.com/company/andritz" target="_blank" rel="noreferrer">
            <i className="pi pi-linkedin" />
          </a>
        </li>
      </ul>
    </div>
    <script src="https://analytics.andritz.com/csp/?siteid=437" />
  </footer>
);

export default memo(Footer);
