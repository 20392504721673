import editIcon from 'assets/images/icons/Edit.png';
import { AddItemDialog } from 'components/common';
import { useIndustries } from 'domain/industry/hooks';
import { useUsers } from 'domain/user/hooks';
import { UserBrief } from 'domain/user/types';
import { useIndustryAreas } from 'features/admin/hooks';
import { editableProductSelector, setEditableProduct } from 'features/products/reducers/solutionSlice';
import { RelatedIndustry } from 'features/products/types';
import { Button } from 'primereact/button';
import { Column, ColumnEditorOptions } from 'primereact/column';
import { confirmPopup } from 'primereact/confirmpopup';
import { DataTable } from 'primereact/datatable';
import { Dropdown } from 'primereact/dropdown';
import { Toolbar } from 'primereact/toolbar';
import { MouseEvent, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useAppDispatch, useAppSelector } from 'store';

type RelatedIndustriesTableProps = {
  industries: RelatedIndustry[];
  onEdit: (id: number, isSpecific: string, manager?: UserBrief) => void;
};

const isSpecificOptions = ['Yes', 'No'];

interface AddRelatedIndustries extends RelatedIndustry {
  rowName: string;
}

const isSpecificBodyTemplate = (rowData: RelatedIndustry) => (rowData.isSpecific ? 'Yes' : 'No');

const RelatedIndustriesTable = ({ industries, onEdit }: RelatedIndustriesTableProps) => {
  const editableProduct = useAppSelector(editableProductSelector);
  const dispatch = useAppDispatch();
  const [relatedIndustries, setRelatedIndustries] = useState<AddRelatedIndustries[]>([]);
  const { data: users, isLoading: isLoadingUsers } = useUsers();
  const [addDialogVisible, setAddDialogVisible] = useState(false);
  const { t } = useTranslation();

  const { data: industriesDB, isLoading: isLoadingIndustries } = useIndustries();
  const { data: industryAreas, isLoading: isLoadingAreas } = useIndustryAreas();

  const isLoading = isLoadingIndustries || isLoadingAreas;

  useEffect(() => {
    if (!isLoading && industriesDB && industryAreas) {
      const industryArray: AddRelatedIndustries[] = industriesDB.map((industry, i) => ({
        idProductIndustry: (i + 1) * -1,
        rowName: industry.industry,
        industry: industry.industry,
        idIndustry: industry.idIndustry,
        isSpecific: false,
        idIndustryArea: null,
        archived: false,
      }));
      industryAreas.forEach((area) => {
        const industry = industryArray.find((i) => i.idIndustry === area.idIndustry);
        if (industry) {
          industryArray.push({
            idProductIndustry: (industryArray.length + 1) * -1,
            rowName: `${industry.industry} - ${area.industryArea}`,
            industry: industry.industry,
            idIndustry: industry.idIndustry,
            isSpecific: false,
            archived: false,
            industryArea: area,
            idIndustryArea: area.idIndustryArea,
          });
        }
      });
      setRelatedIndustries(industryArray);
    }
  }, [industriesDB, industryAreas, isLoading]);

  const isSpecificEditor = (options: ColumnEditorOptions) => {
    const { rowData }: { rowData: RelatedIndustry } = options;
    return (
      <Dropdown
        value={rowData.isSpecific ? 'Yes' : 'No'}
        options={isSpecificOptions}
        onChange={(e) => onEdit(rowData.idProductIndustry, e.value, rowData.manager ?? undefined)}
        style={{ width: '100%' }}
        placeholder="Is Specific"
      />
    );
  };

  const managerEditor = (options: ColumnEditorOptions) => {
    const { rowData }: { rowData: RelatedIndustry } = options;
    return (
      <Dropdown
        value={rowData.manager}
        options={users}
        onChange={(e) => onEdit(rowData.idProductIndustry, rowData.isSpecific ? 'Yes' : 'No', e.value)}
        style={{ width: '100%' }}
        optionLabel="name"
        dataKey="idUser"
        filter
        showClear
        filterBy="name"
      />
    );
  };

  const addItems = async (values: RelatedIndustry[]) => {
    if (editableProduct) {
      dispatch(
        setEditableProduct({ ...editableProduct, industries: [...(editableProduct.industries ?? []), ...values] }),
      );
    }
  };

  const removeItems = async (id: number) => {
    if (editableProduct) {
      dispatch(
        setEditableProduct({
          ...editableProduct,
          industries: editableProduct.industries.filter((x) => x.idProductIndustry !== id),
        }),
      );
    }
  };

  const removeProduct = (e: MouseEvent<HTMLButtonElement>, id: number) => {
    confirmPopup({
      target: e.currentTarget,
      message: t('general.remove-item'),
      icon: 'pi pi-info-circle',
      acceptClassName: 'p-button-danger',
      accept: () => removeItems(id),
    });
  };

  const leftToolbarTemplate = () => (
    <Button
      label="Add"
      icon="pi pi-plus"
      className="p-button-success mr-2 p-mb-2"
      aria-haspopup
      aria-controls="overlay_panel"
      onClick={() => setAddDialogVisible(true)}
    />
  );

  const actionBodyTemplate = (industry: RelatedIndustry) => (
    <div className="actions">
      <Button
        icon="pi pi-trash"
        tooltip="Delete"
        className="p-button-rounded p-button-danger"
        onClick={(e: MouseEvent<HTMLButtonElement>) => removeProduct(e, industry.idProductIndustry)}
      />
    </div>
  );

  return (
    <>
      <Toolbar className="p-mb-4 p-toolbar" left={leftToolbarTemplate} />
      <DataTable
        loading={isLoadingUsers}
        value={industries}
        dataKey="idProductIndustry"
        paginator
        editMode="cell"
        rows={10}
        rowsPerPageOptions={[5, 10, 25]}
        className="datatable-responsive"
        paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport 
RowsPerPageDropdown"
        currentPageReportTemplate="Showing {first} to {last} of {totalRecords} items"
        emptyMessage="No items found."
      >
        <Column field="industry" header="Industry" sortable />
        <Column field="industryArea.industryArea" header="Industry Area" sortable />
        <Column
          field="isSpecific"
          header="Is Specific"
          sortable
          bodyStyle={{ cursor: `url(${editIcon}),cell` }}
          editor={isSpecificEditor}
          body={isSpecificBodyTemplate}
        />
        <Column bodyStyle={{ cursor: 'cell' }} field="manager.name" header="Manager" sortable editor={managerEditor} />
        <Column headerStyle={{ width: '10%', minWidth: '8rem' }} body={actionBodyTemplate} />
      </DataTable>
      <AddItemDialog
        values={relatedIndustries
          ?.filter(
            (item) =>
              !industries.some((b) => b.idIndustry === item.idIndustry && b.idIndustryArea === item.idIndustryArea),
          )
          .sort((a, b) => (a.industry < b.industry ? -1 : 1))}
        selectionMode="multiple"
        isLoading={isLoading}
        visible={addDialogVisible}
        setVisible={setAddDialogVisible}
        dataKey="idProductIndustry"
        onAddAsync={addItems}
      >
        <Column field="rowName" header="Industry" sortable />
      </AddItemDialog>
    </>
  );
};

export default RelatedIndustriesTable;
