import { Toast } from 'primereact/toast';
import { ReactNode, createContext, useMemo, useRef } from 'react';

type ToastContextType = {
  showSuccess(props: ToastOptions): void;
  showInfo(props: ToastOptions): void;
  showWarn(props: ToastOptions): void;
  showError(props: ToastOptions): void;
};

type ToastOptions = {
  title: string;
  content?: JSX.Element | string;
  detail?: string;
  lifeTime?: number;
  sticky?: boolean;
};

type ToastContextProviderProps = {
  children: ReactNode;
};

export const ToastContext = createContext({} as ToastContextType);

export const ToastContextProvider = ({ children }: ToastContextProviderProps) => {
  const toastRef = useRef<Toast>(null);

  const showSuccess = ({ title, detail, content, lifeTime = 3000, sticky = false }: ToastOptions) => {
    toastRef?.current?.show({ severity: 'success', summary: title, detail, content, life: lifeTime, sticky });
  };

  const showInfo = ({ title, detail, content, lifeTime = 3000, sticky = false }: ToastOptions) => {
    toastRef?.current?.show({ severity: 'info', summary: title, detail, content, life: lifeTime, sticky });
  };

  const showWarn = ({ title, detail, content, lifeTime = 3000, sticky = false }: ToastOptions) => {
    toastRef?.current?.show({ severity: 'warn', summary: title, detail, content, life: lifeTime, sticky });
  };

  const showError = ({ title, detail, content, lifeTime = 3000, sticky = false }: ToastOptions) => {
    toastRef?.current?.show({ severity: 'error', summary: title, detail, content, life: lifeTime, sticky });
  };

  const values = useMemo(() => ({ showSuccess, showInfo, showWarn, showError }), []);

  return (
    <ToastContext.Provider value={values}>
      <>
        <Toast ref={toastRef} />
        {children}
      </>
    </ToastContext.Provider>
  );
};
