import { ColumnEditorOptions } from 'primereact/column';
import { Dropdown, DropdownProps } from 'primereact/dropdown';
import { InputNumber, InputNumberProps } from 'primereact/inputnumber';
import { InputSwitch } from 'primereact/inputswitch';
import { InputTextarea } from 'primereact/inputtextarea';

import styles from './utils.module.scss';

export const inputTextEditor = (options: ColumnEditorOptions, maxLength?: number, rows = 1) => (
  <InputTextarea
    className={styles.inputTextEditor}
    value={options.value}
    maxLength={maxLength}
    rows={rows}
    autoResize
    onChange={(e) => options.editorCallback?.(e.target.value)}
  />
);

interface InputNumberEditorProps extends InputNumberProps {
  minFractionDigits?: number;
  options: ColumnEditorOptions;
}

export const inputNumberEditor = ({ options, minFractionDigits, ...rest }: InputNumberEditorProps) => (
  <InputNumber
    {...rest}
    className={styles.inputNumberEditor}
    value={options.value}
    onChange={(e) => options.editorCallback?.(e.value)}
    mode="decimal"
    minFractionDigits={minFractionDigits}
  />
);

export const inputBooleanEditor = (options: ColumnEditorOptions) => (
  <InputSwitch checked={options.value} onChange={(e) => options.editorCallback?.(e.target.value)} />
);

interface InputDropdownEditorProps<T> extends DropdownProps {
  editorOptions: ColumnEditorOptions;
  optionLabel: keyof T & string;
  optionValue?: keyof T & string;
  dataKey: keyof T & string;
  options: T[];
}

export const inputDropdownEditor = <T,>({
  editorOptions,
  optionLabel,
  options,
  optionValue,
  dataKey,
  ...rest
}: InputDropdownEditorProps<T>) => (
  <Dropdown
    value={editorOptions.value}
    dataKey={dataKey}
    options={options}
    onChange={(e) => editorOptions.editorCallback?.(e.value)}
    optionLabel={optionLabel}
    optionValue={optionValue}
    {...rest}
  />
);
