export function getImpact(impact: number): string {
  switch (impact) {
    case 0:
      return 'Low';
    case 1:
      return 'Medium';
    case 2:
      return 'High';
    default:
      return 'Low';
  }
}

export function getImpactColor(impact: number) {
  switch (impact) {
    case 0:
      return 'green';
    case 1:
      return 'yellow';
    case 2:
      return 'red';
    default:
      return 'green';
  }
}
