import { AxiosError } from 'axios';
import { Button } from 'components/common';
import { createCountry, updateCountry } from 'features/admin/api';
import { CountryWithProducts } from 'features/admin/types';
import { Country, ProductBasic } from 'features/products/types';
import { useToast } from 'hooks';
import { Dialog } from 'primereact/dialog';
import { TabPanel, TabView } from 'primereact/tabview';
import { Dispatch, SetStateAction, useState } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useMutation, useQueryClient } from 'react-query';
import { capitalizeFirstLetter } from 'utils/string';

import CountryDetailForm from '../CountryDetailForm/CountryDetailForm';
import RelatedProductTable from '../RelatedProductTable/RelatedProductTable';
import styles from './CountryEditDialog.module.scss';

type CountryEditDialogProps = {
  visible: boolean;
  setVisible: Dispatch<SetStateAction<boolean>>;
  country: CountryWithProducts;
};

const sortProducts = (a: ProductBasic, b: ProductBasic): 1 | -1 => (a.product < b.product ? -1 : 1);

const CountryEditDialog = ({ visible, setVisible, country }: CountryEditDialogProps) => {
  const { control, handleSubmit, formState, getValues } = useForm<Country>({
    defaultValues: country,
  });
  const [editableCountry, setEditableCountry] = useState<CountryWithProducts>(country);
  const queryClient = useQueryClient();
  const { showSuccess, showError } = useToast();
  const { t } = useTranslation();

  const onMutate = {
    onSuccess: () => {
      setVisible(false);
      showSuccess({
        title: capitalizeFirstLetter(t('general.success')),
        detail: 'Data saved successfully',
      });
    },
    onError: (error: AxiosError) => {
      showError({
        title: capitalizeFirstLetter(t('general.error')),
        detail: `An error occurred while saving data! ${error?.message}`,
      });
    },
    onSettled: () => {
      queryClient.invalidateQueries('countries');
    },
  };

  const { mutate: mutateUpdate, isLoading: isLoadingUpdate } = useMutation(updateCountry, onMutate);

  const { mutate: mutateCreate, isLoading: isLoadingCreate } = useMutation(createCountry, onMutate);

  const isSubmitting = isLoadingUpdate || isLoadingCreate;

  const onSubmit: SubmitHandler<Country> = (data) => {
    const item: Country = {
      ...data,
      productIds: editableCountry.availableProducts.map((product) => product.idProduct),
      idCountryRegion: data.region?.idCountryRegion ?? editableCountry.idCountryRegion,
    };
    if (item.idCountry) {
      mutateUpdate(item);
    } else {
      mutateCreate(item);
    }
  };

  const itemDialogFooter = (
    <div className={styles.bottomButton}>
      <Button label="Cancel" icon="pi pi-times" className="p-button-outlined" onClick={() => setVisible(false)} />
      <Button label="Save" loading={isSubmitting} icon="pi pi-check" onClick={handleSubmit(onSubmit)} />
    </div>
  );

  const renderHeader = () => (
    <div className={styles.headerContainer}>
      <span>{getValues('name')}</span>
    </div>
  );

  const addAvailableProducts = (products: ProductBasic[]) => {
    const plant: CountryWithProducts = {
      ...editableCountry,
      availableProducts: [...(editableCountry?.availableProducts ?? []), ...products].sort(sortProducts),
      unavailableProducts: editableCountry.unavailableProducts.filter(
        (product) => !products.some((p) => p.idProduct === product.idProduct),
      ),
    };
    setEditableCountry(plant);
  };

  const deleteAvailableProduct = (product: ProductBasic) => {
    const plant: CountryWithProducts = {
      ...editableCountry,
      availableProducts: editableCountry.availableProducts.filter((p) => p.idProduct !== product.idProduct),
      unavailableProducts: [...(editableCountry?.unavailableProducts ?? []), product].sort(sortProducts),
    };
    setEditableCountry(plant);
  };

  const addUnavailableProducts = (products: ProductBasic[]) => {
    const plant: CountryWithProducts = {
      ...editableCountry,
      unavailableProducts: [...(editableCountry?.unavailableProducts ?? []), ...products].sort(sortProducts),
      availableProducts: editableCountry.availableProducts.filter(
        (product) => !products.some((p) => p.idProduct === product.idProduct),
      ),
    };
    setEditableCountry(plant);
  };

  const deleteUnavailableProduct = (product: ProductBasic) => {
    const plant: CountryWithProducts = {
      ...editableCountry,
      unavailableProducts: editableCountry.unavailableProducts.filter((p) => p.idProduct !== product.idProduct),
      availableProducts: [...(editableCountry?.availableProducts ?? []), product].sort(sortProducts),
    };
    setEditableCountry(plant);
  };

  return (
    <Dialog
      visible={visible}
      className={styles.dialog}
      header={renderHeader}
      modal
      footer={itemDialogFooter}
      onHide={() => setVisible(false)}
    >
      <TabView>
        <TabPanel header="Overview">
          <CountryDetailForm control={control} errors={formState.errors} />
        </TabPanel>
        <TabPanel header="Available Products">
          <RelatedProductTable
            products={editableCountry.availableProducts}
            onAdd={addAvailableProducts}
            onDelete={deleteAvailableProduct}
          />
        </TabPanel>
        <TabPanel header="Unavailable Products">
          <RelatedProductTable
            products={editableCountry.unavailableProducts}
            onAdd={addUnavailableProducts}
            onDelete={deleteUnavailableProduct}
          />
        </TabPanel>
      </TabView>
    </Dialog>
  );
};

export default CountryEditDialog;
