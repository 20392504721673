import { logout, validateInternal } from 'features/auth/reducer/auth';
import { useEffect } from 'react';
import { useQueryClient } from 'react-query';
import { useNavigate } from 'react-router-dom';
import { useAppDispatch } from 'store';

const LogoutPage = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const queryClient = useQueryClient();

  useEffect(() => {
    dispatch(logout());
    queryClient.invalidateQueries();
    dispatch(validateInternal());
    navigate('/');
  }, [dispatch, navigate, queryClient]);

  return null;
};

export default LogoutPage;
