import { MaturityModelGroup, MaturityModelGroupItemsDTO } from 'domain/maturityModel/types';
import { getHeader } from 'features/admin/utils';
import { Column } from 'primereact/column';
import { DataTable } from 'primereact/datatable';

import styles from './MaturityGroupItemTable.module.scss';

interface MaturityGroupItemTableProps {
  row: MaturityModelGroup;
}

const getColor = (data: number) => {
  switch (data) {
    case 0:
      return styles.red;
    case 1:
      return styles.lightRed;
    case 2:
      return styles.yellow;
    case 3:
      return styles.lightGreen;
    case 4:
      return styles.green;
    default:
      return styles.gray;
  }
};

const MaturityGroupItemTable = ({ row }: MaturityGroupItemTableProps) => (
  <DataTable
    value={[...row.items]
      .sort((a, b) => a.order - b.order)
      .map((i) => ({
        ...i,
        field0: !i.selectedOption || i.selectedOption?.maturityLevel === 0 ? 0 : null,
        field1: i.selectedOption?.maturityLevel === 1 ? 1 : null,
        field2: i.selectedOption?.maturityLevel === 2 ? 2 : null,
        field3: i.selectedOption?.maturityLevel === 3 ? 3 : null,
        field4: i.selectedOption?.maturityLevel === 4 ? 4 : null,
      }))}
    size="small"
    cellClassName={(data: number) => ({
      [styles.active]: data != null && data >= 0,
      [getColor(data)]: data != null && data >= 0,
    })}
  >
    <Column field="name" header="Name" />
    <Column
      field="field0"
      style={{ width: '12em' }}
      header={<div style={{ textAlign: 'center' }}>{getHeader(0)}</div>}
      body={(rowData: MaturityModelGroupItemsDTO) => rowData.options.find((o) => o.maturityLevel === 0)?.name}
    />
    <Column
      field="field1"
      style={{ width: '12em' }}
      header={<div style={{ textAlign: 'center' }}>{getHeader(1)}</div>}
      body={(rowData: MaturityModelGroupItemsDTO) => rowData.options.find((o) => o.maturityLevel === 1)?.name}
    />
    <Column
      field="field2"
      style={{ width: '12em' }}
      header={<div style={{ textAlign: 'center' }}>{getHeader(2)}</div>}
      body={(rowData: MaturityModelGroupItemsDTO) => rowData.options.find((o) => o.maturityLevel === 2)?.name}
    />
    <Column
      field="field3"
      style={{ width: '12em' }}
      header={<div style={{ textAlign: 'center' }}>{getHeader(3)}</div>}
      body={(rowData: MaturityModelGroupItemsDTO) => rowData.options.find((o) => o.maturityLevel === 3)?.name}
    />
    <Column
      field="field4"
      style={{ width: '12em' }}
      header={<div style={{ textAlign: 'center' }}>{getHeader(4)}</div>}
      body={(rowData: MaturityModelGroupItemsDTO) => rowData.options.find((o) => o.maturityLevel === 4)?.name}
    />
  </DataTable>
);

export default MaturityGroupItemTable;
