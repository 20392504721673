export const getPercentageColor = (percentage: number): 'red' | 'yellow' | 'green' | 'grey' | 'blue' => {
  if (percentage >= 0 && percentage < 50) return 'red';
  if (percentage < 75) return 'yellow';
  if (percentage <= 100) return 'green';
  return 'grey';
};

export const getHeader = (level: number): string => {
  if (level === 0) return '0 - Poor';
  if (level === 1) return '1 - Fair';
  if (level === 2) return '2 - Good';
  if (level === 3) return '3 - Very Good';
  if (level === 4) return '4 - Excellent';
  return '';
};

export const getStatusColor = (
  level: number,
): 'red' | 'lightRed' | 'yellow' | 'lightGreen' | 'green' | 'grey' | 'blue' => {
  if (level === 0) return 'red';
  if (level === 1) return 'lightRed';
  if (level === 2) return 'yellow';
  if (level === 3) return 'lightGreen';
  if (level === 4) return 'green';
  return 'grey';
};
