import classNames from 'classnames';
import { DefaultScreen, DesktopScreen, MobileScreen } from 'components';
import { Badge, Button, InputText, ProgressSpinner, ScrollTop, SelectButton, Sidebar } from 'components/common';
import { useProductsSimple } from 'features/admin/hooks';
import { cartProductsSelector } from 'features/cart/reducers/cart';
import { CardsList, HelpDialog, ProductDetailDialog, ProductFilter } from 'features/products/components';
import { InitialWizard } from 'features/products/components/InitialWizard';
import {
  filtersSelector,
  searchValueSelector,
  setFilters,
  setSearchValue,
  showTypeSelector,
  solutionIndustrySelector,
} from 'features/products/reducers/solutionSlice';
import { Filter, FilterGroup } from 'features/products/types';
import { filterProduct } from 'features/products/utils';
import { Chip } from 'primereact/chip';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from 'store';
import { debounce } from 'utils/debounce';
import { capitalizeFirstLetter } from 'utils/string';

import styles from './ProductsPage.module.scss';

const layoutOptions = [
  { icon: 'pi pi-th-large', value: 'card' },
  { icon: 'pi pi-table', value: 'grid' },
];

const renderSelectedFilters = (filters: Filter[], removeChip: (name: string) => void) => (
  <div className={styles.selectedFiltersContainer}>
    {filters.map((filter) => (
      <Chip
        className={styles.chip}
        key={filter.name}
        label={filter.name}
        onRemove={() => removeChip(filter.name)}
        removable
      />
    ))}
  </div>
);

const ProductsPage = () => {
  const showType = useAppSelector(showTypeSelector);
  const { isLoading, data: products } = useProductsSimple(showType);
  const [selectedProductId, setSelectedProductId] = useState<number | null>(null);
  const industry = useAppSelector(solutionIndustrySelector);
  const searchValue = useAppSelector(searchValueSelector);
  const [searchText, setSearchText] = useState('');
  const [layout, setLayout] = useState('card');
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [sidebarVisible, setSidebarVisible] = useState(false);
  const [isDialogCLosed, setIsDialogCLosed] = useState(false);
  const [helpDialogVisible, setHelpDialogVisible] = useState(false);
  const cartProducts = useAppSelector(cartProductsSelector);
  const filters = useAppSelector(filtersSelector);
  const dispatch = useAppDispatch();
  const [searchParams] = useSearchParams();

  const productId = searchParams.get('id');

  useEffect(() => {
    if (productId) {
      setSelectedProductId(parseInt(productId, 10));
    }
  }, [navigate, productId]);

  const filteredProducts = useMemo(
    () =>
      products?.filter((product) => {
        const personas = filterProduct(product, filters, FilterGroup.USER_PERSONA);
        const industries = filterProduct(product, filters, FilterGroup.INDUSTRY, industry);
        const industryAreas = filterProduct(product, filters, FilterGroup.INDUSTRY_AREA, industry);
        const productLine = filterProduct(product, filters, FilterGroup.PRODUCT_LINE);
        const group = filterProduct(product, filters, FilterGroup.PRODUCT_GROUP);
        const benefitGroup = filterProduct(product, filters, FilterGroup.BENEFIT_GROUP);
        const benefit = filterProduct(product, filters, FilterGroup.BENEFIT);
        const lifeCycleGroup = filterProduct(product, filters, FilterGroup.PLANT_LIFECYCLE_GROUP);
        const lifeCycle = filterProduct(product, filters, FilterGroup.PLANT_LIFECYCLE);
        const businessModel = filterProduct(product, filters, FilterGroup.BUSINESS_MODEL);
        const platform = filterProduct(product, filters, FilterGroup.PLATFORM);
        const countries = filterProduct(product, filters, FilterGroup.COUNTRY);
        const regions = filterProduct(product, filters, FilterGroup.REGION);
        const portfolios = filterProduct(product, filters, FilterGroup.PORTFOLIO);
        const search =
          !searchValue ||
          product.product.toUpperCase().includes(searchValue.toUpperCase()) ||
          product.nickName?.toUpperCase().includes(searchValue.toUpperCase());

        return (
          personas &&
          industries &&
          industryAreas &&
          productLine &&
          group &&
          benefitGroup &&
          benefit &&
          lifeCycleGroup &&
          lifeCycle &&
          businessModel &&
          platform &&
          countries &&
          regions &&
          portfolios &&
          search
        );
      }) ?? [],
    [filters, industry, products, searchValue],
  );

  const renderProducts = useMemo(
    () =>
      isLoading ? (
        <ProgressSpinner className={styles.fullScreenSpinner} />
      ) : (
        <CardsList layout={layout} filteredProducts={filteredProducts} />
      ),
    [filteredProducts, isLoading, layout],
  );

  const itemTemplate = (option: { icon: string }) => <i className={option.icon} />;

  const handleOpenHelpDialog = useCallback(() => setHelpDialogVisible(true), []);
  const handleCloseHelpDialog = useCallback(() => setHelpDialogVisible(false), []);

  const handleSearchText = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      setSearchText(e.target.value);
      debounce(() => dispatch(setSearchValue(e.target.value)), 300)();
    },
    [dispatch],
  );

  const renderHeader = useMemo(
    () => (
      <div className={styles.topFilterContainer}>
        <MobileScreen>
          <>
            <Button
              className={styles.helpButton}
              label={t('wizard.need-help')}
              icon="pi pi-question-circle"
              iconPos="right"
              onClick={handleOpenHelpDialog}
            />
            <div className={styles.searchButton}>
              <div className="p-inputgroup">
                <span className="p-input-icon-left">
                  <i className="pi pi-search" />
                  <InputText
                    type="search"
                    value={searchText}
                    onChange={handleSearchText}
                    placeholder={capitalizeFirstLetter(t('general.search'))}
                  />
                </span>
              </div>
            </div>
          </>
        </MobileScreen>
        <div className={styles.mobileContainer}>
          <div className={styles.right}>
            {isDialogCLosed && (
              <DesktopScreen>
                <Button
                  className={`p-button-outlined ${styles.filterButton}`}
                  label={capitalizeFirstLetter(t('general.filters'))}
                  onClick={() => setIsDialogCLosed(false)}
                  icon="pi pi-filter"
                />
              </DesktopScreen>
            )}
            <MobileScreen>
              <Button
                className={`p-button-outlined ${styles.filterButton}`}
                label={capitalizeFirstLetter(t('general.filters'))}
                onClick={() => setSidebarVisible(true)}
                icon="pi pi-arrow-right"
                iconPos="right"
              />
            </MobileScreen>

            <DefaultScreen>
              <div className={styles.searchButton}>
                <div className="p-inputgroup">
                  <span className="p-input-icon-left">
                    <i className="pi pi-search" />
                    <InputText
                      type="search"
                      value={searchText}
                      onChange={handleSearchText}
                      className={styles.searchButton}
                      placeholder={capitalizeFirstLetter(t('general.search'))}
                    />
                  </span>
                </div>
              </div>
            </DefaultScreen>
          </div>
          <SelectButton
            className={styles.layoutOptions}
            unselectable={false}
            value={layout}
            options={layoutOptions}
            onChange={(e) => setLayout(e.value)}
            itemTemplate={itemTemplate}
          />
        </div>
        <div className={`${styles.cartButton} ${classNames({ 'mr-2': !cartProducts.length })}`}>
          {cartProducts.length ? (
            <button
              type="button"
              className="p-link layout-topbar-icon"
              onClick={() => {
                navigate('/solutions/cart');
              }}
            >
              <i className="topbar-icon pi pi-fw pi-shopping-cart">
                <Badge value={cartProducts.length} severity="success" />
              </i>
            </button>
          ) : (
            <Button
              className={`p-button-outlined ${styles.contactButton}`}
              tooltip={t('products.could-not-find')}
              tooltipOptions={{ position: 'top' }}
              label={t('products.contact-us')}
              onClick={() => navigate('/solutions/cart')}
            />
          )}
        </div>
      </div>
    ),
    [t, handleOpenHelpDialog, searchText, handleSearchText, isDialogCLosed, layout, cartProducts.length, navigate],
  );

  const renderSideFilter = useMemo(
    () => (
      <div className={`${styles.sideFilterContainer} ${isDialogCLosed ? styles.closed : ''}`}>
        <DefaultScreen>
          <div className={styles.sideFilterWrapper}>
            <div className={styles.topContainer}>
              <div className={styles.buttonWrapper}>
                <Button
                  className={styles.helpButton}
                  label={t('wizard.need-help')}
                  icon="pi pi-question-circle"
                  iconPos="right"
                  onClick={handleOpenHelpDialog}
                />
                <Button
                  className={styles.closeSidebarButton}
                  icon="pi pi-angle-double-left"
                  iconPos="right"
                  onClick={() => setIsDialogCLosed(true)}
                />
              </div>
            </div>
            <ProductFilter filteredProducts={filteredProducts} />
          </div>
        </DefaultScreen>
        <MobileScreen>
          <Sidebar visible={sidebarVisible} onHide={() => setSidebarVisible(false)}>
            <ProductFilter filteredProducts={filteredProducts} />
          </Sidebar>
        </MobileScreen>
      </div>
    ),
    [filteredProducts, handleOpenHelpDialog, isDialogCLosed, sidebarVisible, t],
  );

  const removeChip = (name: string) => {
    const newFilters = filters.filter((filter) => filter.name !== name);
    dispatch(setFilters(newFilters));
  };

  return (
    <div className={styles.productsContainer}>
      {renderSideFilter}
      <div className={styles.cardContainer} style={{ width: '100%' }}>
        {renderHeader}
        <div className={styles.mainContainer}>
          {renderSelectedFilters(filters, removeChip)}
          {renderProducts}
          <ScrollTop threshold={200} />
        </div>
      </div>
      {!isLoading && <InitialWizard />}
      {helpDialogVisible && <HelpDialog visible={helpDialogVisible} handleClose={handleCloseHelpDialog} />}
      {selectedProductId && (
        <ProductDetailDialog
          idProduct={selectedProductId}
          visible={!!selectedProductId}
          onClose={() => {
            setSelectedProductId(null);
            navigate('/');
          }}
        />
      )}
    </div>
  );
};
export default ProductsPage;
