import editIcon from 'assets/images/icons/Edit.png';
import { AddItemDialog, CustomDataTable } from 'components/common';
import { usePlantStatuses, usePlants } from 'domain/plant/hooks';
import { PlantDTO, PlantStatusDTO, UpdateRelatedPlantDTO } from 'domain/plant/types';
import { getStatus } from 'features/plant/utils';
import { editableProductSelector, setEditableProduct } from 'features/products/reducers/solutionSlice';
import { Button } from 'primereact/button';
import { Calendar } from 'primereact/calendar';
import { Column, ColumnEditorOptions } from 'primereact/column';
import { confirmPopup } from 'primereact/confirmpopup';
import { Dropdown } from 'primereact/dropdown';
import { InputNumber } from 'primereact/inputnumber';
import { InputTextarea } from 'primereact/inputtextarea';
import { Toolbar } from 'primereact/toolbar';
import { MouseEvent, useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useAppDispatch, useAppSelector } from 'store';
import { isoDateWithoutTimeZone, toMonthString } from 'utils/datetime';

import styles from './RelatedPlantTable.module.scss';

type RelatedPlantTableProps = {
  plants: UpdateRelatedPlantDTO[];
};

const RelatedPlantTable = ({ plants }: RelatedPlantTableProps) => {
  const [addDialogVisible, setAddDialogVisible] = useState(false);
  const { t } = useTranslation();
  const editableProduct = useAppSelector(editableProductSelector);
  const dispatch = useAppDispatch();
  const { data: statuses } = usePlantStatuses();
  const { data, isLoading } = usePlants();
  // redux uses immet and makes the object read-only which is conflicting with editor callback
  const editablePlants: UpdateRelatedPlantDTO[] = useMemo(() => plants.map((plant) => ({ ...plant })), [plants]);

  const addItems = async (values: PlantDTO[]) => {
    if (editableProduct) {
      dispatch(
        setEditableProduct({
          ...editableProduct,
          relatedPlants: [
            ...(editableProduct.relatedPlants ?? []),
            ...values.map(
              (v) =>
                ({
                  quantity: 1,
                  details: '',
                  startDate: null,
                  endDate: null,
                  country: v.country,
                  industry: v.industry,
                  idPlant: v.idPlant,
                  plant: v.plant,
                  status: null,
                  idProductCustomer: -(editableProduct.relatedPlants.length
                    ? Math.max(...editableProduct.relatedPlants.map((r) => r.idProductCustomer)) + 1
                    : 1),
                  edited: true,
                } as UpdateRelatedPlantDTO),
            ),
          ],
        }),
      );
    }
  };

  const onEdit = useCallback(
    async (values: UpdateRelatedPlantDTO[]) => {
      if (editableProduct) {
        dispatch(
          setEditableProduct({
            ...editableProduct,
            relatedPlants: values,
          }),
        );
      }
    },
    [dispatch, editableProduct],
  );

  const removeItems = async (value: UpdateRelatedPlantDTO) => {
    if (editableProduct) {
      dispatch(
        setEditableProduct({
          ...editableProduct,
          relatedPlants: editableProduct.relatedPlants.filter((x) => x.idProductCustomer !== value.idProductCustomer),
        }),
      );
    }
  };

  const onStatusEditorValueChange = (props: ColumnEditorOptions, value: PlantStatusDTO) => {
    if (!editableProduct) return;
    const updatedPlants = [...editableProduct.relatedPlants];
    const { rowIndex } = props;
    updatedPlants[rowIndex] = { ...updatedPlants[rowIndex], status: value?.idStatus ?? null, edited: true };
    onEdit(updatedPlants);
  };

  const updateRelatedPlantValue = useCallback(
    (props: ColumnEditorOptions, value: string | number | null, field: keyof UpdateRelatedPlantDTO) => {
      if (!editableProduct) return;
      const updatedPlants = [...editableProduct.relatedPlants];
      const { rowIndex } = props;
      updatedPlants[rowIndex] = { ...updatedPlants[rowIndex], [field]: value, edited: true };
      onEdit(updatedPlants);
    },
    [editableProduct, onEdit],
  );

  const endDateEditor = useCallback(
    (options: ColumnEditorOptions) => (
      <Calendar
        value={options.value ? new Date(options.value) : null}
        view="month"
        dateFormat="mm/yy"
        minDate={options.rowData.startDate ? new Date(options.rowData.startDate) : undefined}
        onChange={(e) => {
          const adjustedDate = isoDateWithoutTimeZone(e.value as Date);
          options.editorCallback?.(adjustedDate);
          updateRelatedPlantValue(options, adjustedDate, 'endDate');
        }}
        placeholder="status"
        style={{ width: '100%' }}
      />
    ),
    [updateRelatedPlantValue],
  );

  const startDateEditor = useCallback(
    (options: ColumnEditorOptions) => (
      <Calendar
        value={options.value ? new Date(options.value) : null}
        view="month"
        dateFormat="mm/yy"
        maxDate={options.rowData.endDate ? new Date(options.rowData.endDate) : undefined}
        onChange={(e) => {
          const adjustedDate = isoDateWithoutTimeZone(e.value as Date);
          options.editorCallback?.(adjustedDate);
          updateRelatedPlantValue(options, adjustedDate, 'startDate');
        }}
        placeholder="status"
        style={{ width: '100%' }}
      />
    ),
    [updateRelatedPlantValue],
  );

  const statusEditor = (options: ColumnEditorOptions) => (
    <Dropdown
      value={options.rowData.status != null ? { idStatus: options.rowData.status } : null}
      dataKey="idStatus"
      options={statuses ?? []}
      onChange={(e) => onStatusEditorValueChange(options, e.value)}
      optionLabel="name"
      showClear
      placeholder="status"
      style={{ width: '100%' }}
    />
  );

  const detailsEditor = useCallback(
    (options: ColumnEditorOptions) => (
      <InputTextarea
        className={styles.inputTextEditor}
        value={options.value}
        maxLength={200}
        rows={1}
        autoResize
        onChange={(e) => {
          updateRelatedPlantValue(options, e.target.value, 'details');
          options.editorCallback?.(e.target.value);
        }}
      />
    ),
    [updateRelatedPlantValue],
  );

  const quantityEditor = useCallback(
    (options: ColumnEditorOptions) => (
      <InputNumber
        min={1}
        showButtons
        className={styles.inputNumberEditor}
        value={options.value}
        onChange={(e) => {
          updateRelatedPlantValue(options, e.value, 'quantity');
          options.editorCallback?.(e.value);
        }}
        mode="decimal"
        minFractionDigits={0}
      />
    ),
    [updateRelatedPlantValue],
  );

  const leftToolbarTemplate = () => (
    <Button
      label="Add"
      icon="pi pi-plus"
      className="p-button-success mr-2 p-mb-2"
      aria-haspopup
      aria-controls="overlay_panel"
      onClick={() => setAddDialogVisible(true)}
    />
  );

  const removeElement = (e: MouseEvent<HTMLButtonElement>, element: UpdateRelatedPlantDTO) => {
    confirmPopup({
      target: e.currentTarget,
      message: t('general.remove-item'),
      icon: 'pi pi-info-circle',
      acceptClassName: 'p-button-danger',
      accept: () => removeItems(element),
    });
  };

  const actionBodyTemplate = (rowData: UpdateRelatedPlantDTO) => (
    <div className="actions">
      <Button
        icon="pi pi-trash"
        tooltip="Delete"
        className="p-button-rounded p-button-danger"
        onClick={(e: MouseEvent<HTMLButtonElement>) => removeElement(e, rowData)}
      />
    </div>
  );

  return (
    <>
      <Toolbar className="p-mb-4 p-toolbar" start={leftToolbarTemplate} />
      <CustomDataTable value={editablePlants} loading={isLoading} dataKey="idProductCustomer">
        <Column headerStyle={{ width: '10%', minWidth: '8rem' }} body={actionBodyTemplate} />
        <Column field="plant" header="Plant" sortable />
        <Column field="industry.industry" header="Industry" sortable />
        <Column field="country.name" header="Country" sortable />
        <Column
          bodyStyle={{ cursor: `url(${editIcon}),cell` }}
          field="details"
          header="Details"
          editor={detailsEditor}
        />
        <Column
          bodyStyle={{ cursor: `url(${editIcon}),cell` }}
          header="Status"
          editor={statusEditor}
          body={(rowData: UpdateRelatedPlantDTO) => getStatus(rowData.status)}
        />
        <Column
          bodyStyle={{ cursor: `url(${editIcon}),cell` }}
          header="Start Date"
          field="startDate"
          editor={startDateEditor}
          body={(rowData: UpdateRelatedPlantDTO) => (rowData.startDate ? toMonthString(rowData.startDate) : '')}
        />
        <Column
          bodyStyle={{ cursor: `url(${editIcon}),cell` }}
          header="End Date"
          field="endDate"
          editor={endDateEditor}
          body={(rowData: UpdateRelatedPlantDTO) => (rowData.endDate ? toMonthString(rowData.endDate) : '')}
        />
        <Column
          bodyStyle={{ cursor: `url(${editIcon}),cell` }}
          headerStyle={{ width: '10%', minWidth: '8rem' }}
          field="quantity"
          header="Quantity"
          editor={quantityEditor}
        />
      </CustomDataTable>
      <AddItemDialog
        values={data ?? []}
        selectionMode="multiple"
        isLoading={isLoading}
        visible={addDialogVisible}
        setVisible={setAddDialogVisible}
        dataKey="idPlant"
        onAddAsync={addItems}
      >
        <Column field="plant" header="Plant" sortable />
        <Column field="industry.industry" header="Industry" sortable />
        <Column field="country.name" header="Country" sortable />
      </AddItemDialog>
    </>
  );
};

export default RelatedPlantTable;
