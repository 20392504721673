import classNames from 'classnames';
import { useProductDocumentType } from 'domain/product/hook';
import { editableProductSelector, setEditableProduct } from 'features/products/reducers/solutionSlice';
import { ProductDocumentLinkDTO } from 'features/products/types';
import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';
import { Dropdown } from 'primereact/dropdown';
import { InputText } from 'primereact/inputtext';
import { InputTextarea } from 'primereact/inputtextarea';
import { useEffect, useMemo } from 'react';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useAppDispatch, useAppSelector } from 'store';

import styles from './AddDocumentDialog.module.scss';

interface AddDocumentDialogProps {
  isVisible: boolean;
  onClose: () => void;
  document: ProductDocumentLinkDTO;
}

const AddDocumentDialog = ({ isVisible, onClose, document }: AddDocumentDialogProps) => {
  const { t } = useTranslation();
  const { data: documentTypes } = useProductDocumentType();
  const editableProduct = useAppSelector(editableProductSelector);
  const dispatch = useAppDispatch();
  const {
    control,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm<ProductDocumentLinkDTO>();

  useEffect(() => {
    reset(document);
  }, [document, reset]);

  const isNew = useMemo(() => document.idProductDocumentLink === 0, [document.idProductDocumentLink]);

  const updateItem = (value: ProductDocumentLinkDTO) => {
    if (editableProduct) {
      dispatch(
        setEditableProduct({
          ...editableProduct,
          documents: editableProduct.documents.map((v) =>
            v.idProductDocumentLink === value.idProductDocumentLink ? value : v,
          ),
        }),
      );
    }
  };

  const addItem = (value: ProductDocumentLinkDTO) => {
    if (editableProduct) {
      dispatch(
        setEditableProduct({
          ...editableProduct,
          documents: [
            ...(editableProduct.documents ?? []),
            { ...value, idProductDocumentLink: -((editableProduct.documents?.length ?? 0) + 1) },
          ],
        }),
      );
    }
  };

  const onSubmit: SubmitHandler<ProductDocumentLinkDTO> = async (payload) => {
    if (payload.idProductDocumentLink === 0) {
      addItem(payload);
    } else {
      updateItem(payload);
    }
    onClose();
  };

  const itemDialogFooter = (
    <div className={styles.bottomButton}>
      <Button label="Close" icon="pi pi-times" className="p-button-outlined" onClick={onClose} />
      <Button
        label={isNew ? 'Add' : 'Save'}
        icon={`pi ${isNew ? 'pi-plus' : 'pi-check'}`}
        onClick={handleSubmit(onSubmit)}
      />
    </div>
  );

  const renderHeader = () => (
    <div className={styles.headerContainer}>
      <span>{isNew ? 'Add' : 'Edit'} Document</span>
    </div>
  );

  return (
    <Dialog
      visible={isVisible}
      className={styles.dialog}
      header={renderHeader}
      modal
      footer={itemDialogFooter}
      onHide={onClose}
    >
      <div className={`${styles.form} p-jc-center`}>
        <form className="p-fluid">
          <div className={styles.field}>
            <span className={classNames({ 'p-error': errors.documentName })}>
              <span className="red">*</span> Name
            </span>
            <Controller
              name="documentName"
              control={control}
              rules={{ required: true }}
              render={({ field }) => (
                <InputText {...field} className={classNames({ 'p-invalid': errors.documentName })} maxLength={200} />
              )}
            />
            {errors.documentName && <small className="p-error">{t('form.required')}</small>}
          </div>

          <div className={styles.field}>
            <span className={classNames({ 'p-error': errors.documentLink })}>
              <span className="red">*</span> Link
            </span>
            <Controller
              name="documentLink"
              control={control}
              rules={{ required: true }}
              render={({ field }) => (
                <InputTextarea
                  {...field}
                  rows={2}
                  className={classNames({ 'p-invalid': errors.documentLink })}
                  autoResize
                  maxLength={500}
                />
              )}
            />
            {errors.documentLink && <small className="p-error">{t('form.required')}</small>}
          </div>

          <div className={styles.field}>
            <span>
              <span className="red">*</span> Document Type
            </span>
            <Controller
              name="documentType"
              control={control}
              rules={{ required: true }}
              render={({ field }) => (
                <Dropdown
                  {...field}
                  optionLabel="name"
                  className={classNames({ 'p-invalid': errors.documentType })}
                  dataKey="idProductDocumentType"
                  filter
                  filterBy="name"
                  options={documentTypes}
                />
              )}
            />
            {errors.documentType && <small className="p-error">{t('form.required')}</small>}
          </div>
        </form>
      </div>
    </Dialog>
  );
};

export default AddDocumentDialog;
