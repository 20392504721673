import { OKRsBodyTemplate } from 'components/templates/datatable';
import { useProductRoadmap } from 'domain/product/hook';
import { RoadmapTable } from 'features/admin/components';
import { Product, ProductRoadmap } from 'features/products/types';
import { Column } from 'primereact/column';
import { toDateString } from 'utils/datetime';

const releaseDateTemplate = (rowData: ProductRoadmap) => {
  return rowData.version?.releaseDate ? toDateString(rowData.version.releaseDate) : '';
};

const statusBodyTemplate = (rowData: ProductRoadmap) =>
  rowData.status ? `${rowData.status?.name} (${Math.round(rowData.percentComplete)}%)` : '';

interface RoadmapTabProps {
  product: Product;
}

const RoadmapTab = ({ product }: RoadmapTabProps) => {
  const { data, isLoading, refetch } = useProductRoadmap(product.idProduct);

  const onSaveDeleteRoadmap = () => {
    refetch();
  };

  return (
    <RoadmapTable
      roadMaps={(data ?? []).map((r) => ({ ...r, product }))}
      onDeleteCallback={onSaveDeleteRoadmap}
      onSaveCallback={onSaveDeleteRoadmap}
      isLoading={isLoading}
    >
      <Column field="type.name" header="Type" sortable />
      <Column field="item" header="Item" sortable />
      <Column field="version.name" header="Version" sortable />
      <Column field="okrs.name" header="Business Impact" sortable body={OKRsBodyTemplate} />
      <Column field="version.releaseDate" header="Date" body={releaseDateTemplate} sortable />
      <Column field="percentComplete" header="Status" sortable body={statusBodyTemplate} />
    </RoadmapTable>
  );
};

export default RoadmapTab;
