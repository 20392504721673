import { RoadmapStatusWithResourceDTO, StatusResource } from 'domain/roadmap/types';
import { Column } from 'primereact/column';
import { DataTable } from 'primereact/datatable';
import { useMemo } from 'react';
import { currency } from 'utils/number';

interface SummaryItem {
  resource: string;
  hourlyRate: number;
  hours: number;
  totalCost: number;
}

interface SummaryTableProps {
  statuses: RoadmapStatusWithResourceDTO[];
}

const SummaryTable = ({ statuses }: SummaryTableProps) => {
  const resourcesUsers = useMemo(
    () =>
      statuses
        .flatMap((r) => r.resources ?? [])
        .reduce(
          (res, item) => ({
            ...res,
            [item.resource]: [...(res[item.resource] || []), item],
          }),
          {} as { [key: string]: StatusResource[] },
        ),
    [statuses],
  );

  const summaryItems = useMemo(
    () =>
      Object.entries(resourcesUsers).map(
        (item): SummaryItem => ({
          resource: item[0],
          hourlyRate: item[1][0].roadmapResourceYear?.hourlyRate ?? 0,
          hours: item[1].reduce((a, b) => a + b.hours, 0),
          totalCost: item[1].reduce((a, b) => a + b.hours, 0) * (item[1][0].roadmapResourceYear?.hourlyRate ?? 0),
        }),
      ),
    [resourcesUsers],
  );

  return (
    <DataTable
      value={summaryItems}
      sortField="totalCost"
      footer={`Total Cost: ${currency.format(summaryItems?.reduce((acc, i) => acc + i.totalCost, 0))}`}
    >
      <Column header="Resource" field="resource" sortable />
      <Column header="Hours" field="hours" sortable />
      <Column
        header="Total Cost"
        field="totalCost"
        body={(data: SummaryItem) => `${currency.format(data.hours * data.hourlyRate)}`}
        sortable
      />
    </DataTable>
  );
};

export default SummaryTable;
