import { Button, InputText } from 'components/common';
import { useProductImages } from 'domain/productImages/hooks';
import { ProductImage } from 'domain/productImages/types';
import { DataView } from 'primereact/dataview';
import { Dialog } from 'primereact/dialog';
import { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { capitalizeFirstLetter } from 'utils/string';

import styles from './ImageSelectorDialog.module.scss';

interface ImageSelectorDialogProps {
  visible: boolean;
  setVisible: Dispatch<SetStateAction<boolean>>;
  selectImage: (image: ProductImage) => void;
}

const ImageSelectorDialog = ({ visible, setVisible, selectImage }: ImageSelectorDialogProps) => {
  const { t } = useTranslation();
  const [images, setImages] = useState<ProductImage[]>([]);
  const [searchValue, setSearchValue] = useState('');
  const { data } = useProductImages();

  useEffect(() => {
    if (data?.length) {
      if (searchValue) {
        setImages(data.filter((image) => image.image1.toUpperCase().includes(searchValue.toUpperCase())));
      } else {
        setImages(data);
      }
    }
  }, [data, searchValue, setSearchValue]);

  const itemTemplate = (row: ProductImage) => (
    <div className="p-col-12 p-md-4">
      <div className={styles.iconCard}>
        <div className={styles.icon}>
          <div className={styles.imageContainer}>
            <img
              src={`${process.env.PUBLIC_URL}${row.image2 || row.image1}`}
              alt={row.image1}
              className={`${!row.image2 && styles.color}`}
            />
          </div>
          <div className={styles.productName}>{row.image1.replace('.png', '')}</div>
        </div>
        <div className="iconCardBottom">
          <Button
            label="Select icon"
            className={styles.selectIconButton}
            onClick={() =>
              selectImage({
                ...row,
                image1: `${row.image1}`,
                image2: `${row.image2}`,
              })
            }
          />
        </div>
      </div>
    </div>
  );

  const renderHeader = () => (
    <div className={styles.searchButton}>
      <div className="p-inputgroup">
        <span className="p-input-icon-left">
          <i className="pi pi-search" />
          <InputText
            type="search"
            value={searchValue}
            className={styles.searchButton}
            onChange={(e) => setSearchValue(e.target.value)}
            placeholder={capitalizeFirstLetter(t('general.search'))}
          />
        </span>
      </div>
    </div>
  );

  return (
    <Dialog visible={visible} className={styles.dialog} header={renderHeader} modal onHide={() => setVisible(false)}>
      <DataView value={images} layout="grid" itemTemplate={itemTemplate} paginator rows={9} />
    </Dialog>
  );
};

export default ImageSelectorDialog;
