declare global {
  interface Window {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    env: any;
  }
}

type EnvType = {
  REACT_APP_BASE_URL: string;
  REACT_APP_REPO_URL: string;
  REACT_APP_IS_TEST: boolean;
};
export const env: EnvType = { ...process.env, ...window.env };
