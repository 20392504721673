import { ButtonProps, Button as PrimeButton } from 'primereact/button';
import { Tooltip } from 'primereact/tooltip';
import { ReactNode } from 'react';
import { v4 as uuidv4 } from 'uuid';

interface GlobalStyle {
  disabledTooltip?: string | ReactNode;
}

const Button = ({
  disabled,
  disabledTooltip,
  ...props
}: JSX.IntrinsicAttributes &
  JSX.IntrinsicClassAttributes<PrimeButton> &
  Readonly<ButtonProps> &
  Readonly<{ children?: ReactNode }> &
  GlobalStyle) => {
  const id = uuidv4().split('-')[0];
  return (
    <>
      <Tooltip disabled={!disabled} mouseTrack target={`[id='${id}']`}>
        {disabledTooltip}
      </Tooltip>
      <PrimeButton disabled={disabled} type="button" {...props} />
    </>
  );
};

export default Button;

Button.defaultProps = {
  disabledTooltip: '',
};
