import { ProductVideo } from 'features/products/types';
import { Button } from 'primereact/button';
import { DataView } from 'primereact/dataview';
import { toDateString } from 'utils/datetime';

import styles from './PublicVideoList.module.scss';

interface PublicVideoListProps {
  videos: ProductVideo[];
  readonly?: boolean;
  onRemove?: (value: ProductVideo) => void;
  onEdit?: (value: ProductVideo) => void;
}

const sortVideos = (a: ProductVideo, b: ProductVideo) => {
  if (!a.timestamp && !b.timestamp) {
    if (a.title < b.title) return -1;
    if (a.title > b.title) return 1;
  }
  if (!a.timestamp) return 1;
  if (!b.timestamp) return -1;
  if (a.timestamp > b.timestamp) return -1;
  if (a.timestamp < b.timestamp) return 1;
  if (a.title < b.title) return -1;
  if (a.title > b.title) return 1;
  return 0;
};

const videoTemplate = (
  video: ProductVideo,
  readonly: boolean,
  onRemove?: (value: ProductVideo) => void,
  onEdit?: (value: ProductVideo) => void,
) => (
  <div className={styles.videoCard}>
    <div className={styles.videoCardContent}>
      <div key={video.idProductVideo} className={styles.videoContainer}>
        <iframe src={video.hyperlink.replace('watch?v=', 'embed/')} allowFullScreen title={video.title} />
      </div>
      <div className={styles.videoContent}>
        <p className={styles.videoTitle}>{video.title}</p>
        <p className={styles.videoDate}>{video.timestamp && toDateString(video.timestamp, '-')}</p>
      </div>
    </div>
    {!readonly && onRemove && onEdit && (
      <div className={styles.videoActions}>
        <Button
          onClick={() => onEdit(video)}
          icon="pi pi-pencil"
          tooltip="Edit"
          className="p-button-rounded p-button-success mr-2"
        />
        <Button onClick={() => onRemove(video)} icon="pi pi-trash" className="p-button-rounded p-button-danger" />
      </div>
    )}
  </div>
);

const PublicVideoList = ({ videos, readonly = true, onRemove, onEdit }: PublicVideoListProps) => {
  return (
    <div className={styles.videoListContainer}>
      <DataView
        value={videos.sort(sortVideos)}
        layout="list"
        itemTemplate={(rowData) => videoTemplate(rowData, readonly, onRemove, onEdit)}
        paginator
        rows={4}
      />
    </div>
  );
};

export default PublicVideoList;

PublicVideoList.defaultProps = {
  readonly: true,
  onRemove: undefined,
  onEdit: undefined,
};
