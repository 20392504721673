import api from 'services/api';

import { MaturityModel } from '../types/maturityModel.interface';

const BASE_URL = 'maturityModels';

export const getMaturityModels = async (): Promise<MaturityModel[]> => {
  const { data } = await api.get(BASE_URL);
  return data as MaturityModel[];
};

export const getMaturityModel = async (id: number): Promise<MaturityModel> => {
  const { data } = await api.get(`${BASE_URL}/${id}`);
  return data as MaturityModel;
};

export const updateMaturityModel = async (model: MaturityModel): Promise<MaturityModel> => {
  await api.put(`${BASE_URL}/${model.idMaturityModel}`, model);
  return model;
};

export const createMaturityModel = async (model: MaturityModel): Promise<MaturityModel> => {
  const { data } = await api.post(BASE_URL, model);
  return data as MaturityModel;
};

export const deleteMaturityModel = async (id: number): Promise<void> => api.delete(`${BASE_URL}/${id}`);
