import { ReactNode, createContext, useMemo } from 'react';
import { useMediaQuery } from 'react-responsive';

type MediaQueryContextType = {
  isDesktop: boolean;
  isTablet: boolean;
  isMobile: boolean;
  isNotMobile: boolean;
  isPortrait: boolean;
};

type MediaQueryContextProviderProps = {
  children: ReactNode;
};

export const MediaQueryContext = createContext({} as MediaQueryContextType);

export const MediaQueryContextProvider = ({ children }: MediaQueryContextProviderProps) => {
  const isDesktop = useMediaQuery({ minWidth: 992 });
  const isTablet = useMediaQuery({ minWidth: 768, maxWidth: 991 });
  const isMobile = useMediaQuery({ maxWidth: 767 });
  const isNotMobile = useMediaQuery({ minWidth: 768 });
  const isPortrait = useMediaQuery({ orientation: 'portrait' });

  const values = useMemo(
    () => ({
      isDesktop,
      isTablet,
      isMobile,
      isNotMobile,
      isPortrait,
    }),
    [isDesktop, isTablet, isMobile, isNotMobile, isPortrait],
  );

  return <MediaQueryContext.Provider value={values}>{children}</MediaQueryContext.Provider>;
};
