import api from 'services/api';

import { GetRoadmapTempoDTO, PostRoadmapTempoDTO } from '../types';

const BASE_URL = 'roadmaps';

export const saveTempo = async (tempo: PostRoadmapTempoDTO) => {
  await api.post(`${BASE_URL}/${tempo.idRoadmap}/tempo`, tempo);
};

export const updateTempo = async (tempo: PostRoadmapTempoDTO) => {
  await api.put(`${BASE_URL}/${tempo.idRoadmap}/tempo`, tempo);
};

export const getRoadmapTempo = async (roadmapId: number, roadmapStatusId: number | null = null) => {
  const { data } = await api.get<GetRoadmapTempoDTO[]>(`${BASE_URL}/${roadmapId}/tempo`, {
    params: { roadmapStatusId },
  });
  return data;
};

export const deleteRoadmapTempo = async (idRoadmapTempo: number) => {
  await api.delete<GetRoadmapTempoDTO[]>(`${BASE_URL}/${idRoadmapTempo}/tempo`);
};
