import { StreamVideo } from 'features/products/types';
import { Button } from 'primereact/button';
import { DataView } from 'primereact/dataview';
import { toDateString } from 'utils/datetime';

import styles from './StreamVideoList.module.scss';

interface StreamVideoListProps {
  streamVideos: StreamVideo[];
  readonly?: boolean;
  onRemove?: (value: StreamVideo) => void;
  onEdit?: (value: StreamVideo) => void;
}

const videoTemplate = (
  video: StreamVideo,
  readonly: boolean,
  onRemove?: (value: StreamVideo) => void,
  onEdit?: (value: StreamVideo) => void,
) => (
  <div className={styles.videoCard}>
    <div className={styles.videoCardContent}>
      <div key={video.idProductStream} className={styles.videoContainer}>
        <iframe src={video.hyperlink} allowFullScreen title={video.title} />
      </div>
      <div className={styles.videoContent}>
        <p className={styles.videoTitle}>{video.title}</p>
        <p className={styles.videoDate}>{video.timestamp && toDateString(video.timestamp, '-')}</p>
        <p className={styles.videoDescription}>{video.description}</p>
      </div>
    </div>
    {!readonly && onRemove && onEdit && (
      <div className={styles.videoActions}>
        <Button
          onClick={() => onEdit(video)}
          icon="pi pi-pencil"
          tooltip="Edit"
          className="p-button-rounded p-button-success mr-2"
        />
        <Button onClick={() => onRemove(video)} icon="pi pi-trash" className="p-button-rounded p-button-danger" />
      </div>
    )}
  </div>
);

const sortVideos = (a: StreamVideo, b: StreamVideo) => {
  if (!a.timestamp && !b.timestamp) {
    if (a.title < b.title) return -1;
    if (a.title > b.title) return 1;
  }
  if (!a.timestamp) return 1;
  if (!b.timestamp) return -1;
  if (a.timestamp > b.timestamp) return -1;
  if (a.timestamp < b.timestamp) return 1;
  if (a.title < b.title) return -1;
  if (a.title > b.title) return 1;
  return 0;
};

const StreamVideoList = ({ streamVideos, readonly = true, onRemove, onEdit }: StreamVideoListProps) => {
  return (
    <div className={styles.videoListContainer}>
      <DataView
        value={[...streamVideos].sort(sortVideos)}
        layout="list"
        itemTemplate={(rowData) => videoTemplate(rowData, readonly, onRemove, onEdit)}
        paginator
        rows={4}
      />
    </div>
  );
};

export default StreamVideoList;

StreamVideoList.defaultProps = {
  readonly: true,
  onRemove: undefined,
  onEdit: undefined,
};
