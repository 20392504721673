import { RoadMap, RoadMapUpdate } from 'domain/roadmap/types';
import { BasicUserDTO } from 'domain/user/types';
import { Role, User } from 'features/auth/types';
import { Benefit } from 'features/products/types';

interface GenericProduct {
  idTechnologyManager?: number;
  technologyManager?: BasicUserDTO | null;
  idProductOwner?: number;
  productOwner?: BasicUserDTO | null;
  idProductManager?: number;
  productManager?: BasicUserDTO | null;
  idOwner?: number;
  owner?: BasicUserDTO | null;
  qaSpecialistsIds?: number[];
  qaSpecialists?: BasicUserDTO[];
}

interface GenericRoadmap {
  idOwner?: number;
  idReporter?: number;
  createdById?: number;
  isParticipant?: boolean;
}

interface GenericRoadmapWithProduct extends GenericRoadmap {
  product: GenericProduct;
}

export const isUserAdmin = (user: User): boolean =>
  user.role === Role.Admin || (user.roles?.some((role) => role === Role.Admin) ?? false);

export const isRoadmapReporter = (user: User | null, roadmap: GenericRoadmap): boolean =>
  roadmap.idReporter === user?.idUser;

export const isRoadmapCreator = (user: User | null, roadmap: GenericRoadmap): boolean =>
  roadmap.createdById === user?.idUser;

export const isRoadmapOwner = (user: User | null, roadmap: GenericRoadmap): boolean => roadmap.idOwner === user?.idUser;

export const isTechnologyManager = (user: User | null, product: GenericProduct | null): boolean => {
  if (!user || !product) {
    return false;
  }

  if ('idTechnologyManager' in product) {
    return product.idTechnologyManager === user.idUser;
  }
  if ('technologyManager' in product) {
    return product.technologyManager?.idUser === user.idUser;
  }

  return false;
};

export const isProductOwner = (user: User | null, product: GenericProduct | null): boolean => {
  if (!user || !product) {
    return false;
  }

  if ('idProductOwner' in product) {
    return product.idProductOwner === user.idUser;
  }
  if ('productOwner' in product) {
    return product.productOwner?.idUser === user.idUser;
  }

  return false;
};

export const isProductManager = (user: User | null, product: GenericProduct | null): boolean => {
  if (!user || !product) {
    return false;
  }
  if ('idProductManager' in product) {
    return product.idProductManager === user.idUser;
  }
  if ('productManager' in product) {
    return product.productManager?.idUser === user.idUser;
  }
  return false;
};

export const isQaSpecialist = (user: User | null, product: GenericProduct | null): boolean => {
  if (!user || !product) {
    return false;
  }
  if ('qaSpecialists' in product) {
    return product.qaSpecialists?.some((qa) => qa.idUser === user.idUser) ?? false;
  }
  if ('qaSpecialistsIds' in product) {
    return product.qaSpecialistsIds?.some((id) => id === user.idUser) ?? false;
  }
  return false;
};

export const canEditRoadmap = (user: User | null, roadmap: GenericRoadmapWithProduct): boolean => {
  if (!user || !roadmap) {
    return false;
  }
  return (
    isUserAdmin(user) ||
    isRoadmapOwner(user, roadmap) ||
    isProductOwner(user, roadmap.product) ||
    isTechnologyManager(user, roadmap.product) ||
    isQaSpecialist(user, roadmap.product) ||
    isRoadmapCreator(user, roadmap) ||
    isProductManager(user, roadmap.product)
  );
};

export const canEditBenefits = (user: User | null, benefit: Benefit): boolean => {
  if (!user || !benefit) {
    return false;
  }
  return isUserAdmin(user);
};

export const canEditRoadmapDetails = (user: User | null, roadmap: RoadMap | RoadMapUpdate): boolean => {
  if (!user || !roadmap) {
    return false;
  }
  return (
    isUserAdmin(user) ||
    isProductOwner(user, roadmap.product) ||
    isTechnologyManager(user, roadmap.product) ||
    isProductManager(user, roadmap.product)
  );
};

export const canDeleteRoadmap = (user: User | null, roadmap: GenericRoadmapWithProduct): boolean => {
  if (!user || !roadmap) {
    return false;
  }
  return (
    isUserAdmin(user) ||
    isProductOwner(user, roadmap.product) ||
    isTechnologyManager(user, roadmap.product) ||
    isRoadmapCreator(user, roadmap) ||
    isProductManager(user, roadmap.product)
  );
};
