import classNames from 'classnames';
import { checkUserIsParticipant, saveTempo, updateTempo } from 'domain/roadmap/API';
import { useBasicRoadmaps, useRoadmapStatuses } from 'domain/roadmap/hooks';
import { ROADMAP_TEMPO_REPORT_BY_USER_KEY } from 'domain/roadmap/hooks/useReport';
import { BasicRoadmap, GetRoadmapTempoDTO, PostRoadmapTempoDTO } from 'domain/roadmap/types';
import { userSelector } from 'features/auth/reducer/auth';
import { editableRoadMapSelector, setEditableRoadmap } from 'features/roadmap/reducers/roadmap';
import { convertMinutesToTimeFormat, convertTimeFormat, getMinutesFromTimeFormat } from 'features/timeAllocation/utils';
import { useMediaQuery } from 'hooks';
import { Button } from 'primereact/button';
import { Calendar } from 'primereact/calendar';
import { Dialog } from 'primereact/dialog';
import { Dropdown } from 'primereact/dropdown';
import { InputText } from 'primereact/inputtext';
import { InputTextarea } from 'primereact/inputtextarea';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useQueryClient } from 'react-query';
import { toast } from 'react-toastify';
import { useAppDispatch, useAppSelector } from 'store';
import { getLastWeekday, getWeekends, isoDateWithoutTimeZone } from 'utils/datetime';

import styles from './RoadmapTempoDialog.module.scss';

interface RoadMap {
  idRoadmap: number;
  item: string;
}

interface RoadmapTempoDialogProps {
  roadmap?: RoadMap;
  visible: boolean;
  onHide: () => void;
  idRoadmapStatus?: number;
  tempo?: GetRoadmapTempoDTO;
}

interface FormRoadmapTempoDTO extends Omit<PostRoadmapTempoDTO, 'minutes'> {
  minutes: string;
}

const defaultValues: FormRoadmapTempoDTO = {
  date: '',
  minutes: '1h',
  idRoadmapTempo: 0,
  idRoadmap: 0,
  idRoadmapStatus: 0,
  comment: '',
};

interface GroupedItems {
  label: string;
  items: RoadMap[];
}

const START_DATE = new Date(2023, 5, 1); // Date we started logging time
const currentDate = new Date();
const nextYearDate = new Date(currentDate);
nextYearDate.setFullYear(currentDate.getFullYear() + 1);
const weekends = getWeekends(START_DATE, nextYearDate);

// should be used for grouping items, but greatly decreases performance
// eslint-disable-next-line @typescript-eslint/no-unused-vars
function groupItemsByOwnership(items: BasicRoadmap[]): GroupedItems[] {
  const groupedItems: GroupedItems[] = [];
  const lookupTable: Record<string, GroupedItems> = {
    true: { label: 'My Items', items: [] },
    false: { label: 'Not My Items', items: [] },
  };

  items.forEach((item) => {
    const isMyItem = item.isParticipant || item.isOwner || item.isReporter || item.isCreator;
    const group = lookupTable[isMyItem.toString()];
    group.items.push({
      idRoadmap: item.idRoadmap,
      item: item.item,
    });
  });

  groupedItems.push(lookupTable.true);
  groupedItems.push(lookupTable.false);

  return groupedItems;
}

const sortItems = (a: BasicRoadmap, b: BasicRoadmap) => {
  const aHasOwnership = a.isParticipant || a.isOwner || a.isReporter || a.isCreator;
  const bHasOwnership = b.isParticipant || b.isOwner || b.isReporter || b.isCreator;

  if (aHasOwnership && !bHasOwnership) {
    return -1;
  }
  if (!aHasOwnership && bHasOwnership) {
    return 1;
  }

  return a.item.localeCompare(b.item);
};

const roadmapOptionTemplate = (option: BasicRoadmap) => {
  const hasOwnership = option.isParticipant || option.isOwner || option.isReporter || option.isCreator;
  return (
    <div className="flex align-items-center">
      <div style={{ fontFamily: hasOwnership ? '"Gilroy-ExtraBold", sans-serif' : '"Gilroy-Regular", sans-serif' }}>
        {option.item}
      </div>
    </div>
  );
};

const RoadmapTempoDialog = ({ visible, onHide, roadmap, idRoadmapStatus, tempo }: RoadmapTempoDialogProps) => {
  const [roadmapId, setRoadmapId] = useState<number | undefined>(roadmap?.idRoadmap);
  const { data: statuses } = useRoadmapStatuses(roadmapId ?? 0, !!roadmapId);
  const { data: roadmaps } = useBasicRoadmaps(!roadmap);
  const [isParticipant, setIsParticipant] = useState(true);
  const editableRoadMap = useAppSelector(editableRoadMapSelector);
  const user = useAppSelector(userSelector);
  const dispatch = useAppDispatch();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const { t } = useTranslation();
  const { control, handleSubmit } = useForm<FormRoadmapTempoDTO>({
    defaultValues: tempo
      ? { ...tempo, minutes: convertMinutesToTimeFormat(tempo.minutes) }
      : { ...defaultValues, date: getLastWeekday().toISOString(), idRoadmap: roadmap?.idRoadmap, idRoadmapStatus },
  });
  const { isMobile } = useMediaQuery();
  const queryClient = useQueryClient();
  const sortedRoadmaps = useMemo(() => roadmaps?.sort(sortItems) ?? [], [roadmaps]);

  useEffect(() => {
    const checkParticipantStatus = async () => {
      if (roadmapId) {
        const status = await checkUserIsParticipant(roadmapId);
        setIsParticipant(status);
      }
    };

    checkParticipantStatus();
  }, [roadmapId]);

  const onSubmit: SubmitHandler<FormRoadmapTempoDTO> = useCallback(
    async (data) => {
      const date = new Date(data.date);
      const adjustedDate = isoDateWithoutTimeZone(date);
      try {
        setIsSubmitting(true);
        if (data.idRoadmapTempo) {
          await updateTempo({ ...data, date: adjustedDate, minutes: getMinutesFromTimeFormat(data.minutes) });
          toast.success('Time updated successfully');
        } else {
          await saveTempo({ ...data, date: adjustedDate, minutes: getMinutesFromTimeFormat(data.minutes) });
          toast.success('Time added successfully');
        }
        if (user && !isParticipant) {
          dispatch(
            setEditableRoadmap({ ...editableRoadMap, participants: [...(editableRoadMap.participants ?? []), user] }),
          );
        }
        queryClient.invalidateQueries(['roadmap-tempo']);
        queryClient.invalidateQueries([ROADMAP_TEMPO_REPORT_BY_USER_KEY]);
        onHide();
      } catch {
        toast.error('Error adding Time');
      } finally {
        setIsSubmitting(false);
      }
    },
    [dispatch, editableRoadMap, isParticipant, onHide, queryClient, user],
  );

  const itemDialogFooter = useMemo(
    () => (
      <div className={styles.bottomButton}>
        {!isParticipant && (
          <div>
            <small className="red">You will be added as a participant of this item</small>
          </div>
        )}
        <Button label="Cancel" icon="pi pi-times" className="p-button-outlined" onClick={onHide} />
        <Button
          label="Save"
          icon="pi pi-plus"
          onClick={handleSubmit(onSubmit)}
          loading={isSubmitting}
          disabled={isSubmitting}
        />
      </div>
    ),
    [handleSubmit, isParticipant, isSubmitting, onHide, onSubmit],
  );

  const renderHeader = useMemo(
    () => (
      <>
        <div className={styles.headerTitle}>
          <i className="pi pi-clock mr-2" style={{ fontSize: '2.5rem', color: 'green' }} />
          <span>Log Time</span>
        </div>
        <hr />
        <small>{roadmap ? roadmap.item : 'Log Time'}</small>
      </>
    ),
    [roadmap],
  );

  return (
    <Dialog
      visible={visible}
      onHide={onHide}
      className={styles.dialog}
      header={renderHeader}
      modal
      footer={itemDialogFooter}
    >
      <form className={`${styles.form} p-jc-center p-fluid`}>
        {!roadmap && (
          <Controller
            name="idRoadmap"
            control={control}
            rules={{ required: true }}
            render={({ field: { value, onChange }, fieldState: { error } }) => (
              <div className={styles.field}>
                <span className={`required ${classNames({ 'p-error': error })}`}>Roadmap Item</span>
                <Dropdown
                  value={value}
                  onChange={(e) => {
                    onChange(e.target.value);
                    setRoadmapId(e.target.value);
                  }}
                  virtualScrollerOptions={{ itemSize: 35 }}
                  filter
                  dataKey="idRoadmap"
                  itemTemplate={roadmapOptionTemplate}
                  options={sortedRoadmaps}
                  optionLabel="item"
                  optionValue="idRoadmap"
                  autoComplete="false"
                />
                {error && <small className="p-error">{t('form.required')}</small>}
              </div>
            )}
          />
        )}
        <Controller
          name="idRoadmapStatus"
          control={control}
          rules={{ required: true }}
          render={({ field, fieldState: { error } }) => (
            <div className={styles.field}>
              <span className={`required ${classNames({ 'p-error': error })}`}>Stage</span>
              <Dropdown
                {...field}
                dataKey="idRoadmapStatus"
                options={statuses}
                optionLabel="name"
                optionValue="idRoadmapStatus"
              />
              {error && <small className="p-error">{t('form.required')}</small>}
            </div>
          )}
        />

        <Controller
          name="minutes"
          control={control}
          rules={{ required: true }}
          render={({ field: { value, onChange }, formState: { errors } }) => (
            <div className={styles.field}>
              <span className={`required ${classNames({ 'p-error': errors.minutes })}`}>Time</span>
              <InputText
                className={classNames({ 'p-invalid': errors.minutes })}
                value={`${value}`}
                onChange={(e) => onChange(e.target.value)}
                onBlur={(e) => onChange(convertTimeFormat(e.target.value))}
              />
              {errors.minutes && <small className="p-error">{t('form.required')}</small>}
            </div>
          )}
        />

        <Controller
          name="date"
          control={control}
          rules={{ required: true }}
          render={({ field: { value, onChange }, formState: { errors } }) => (
            <div className={styles.field}>
              <span className={`required ${classNames({ 'p-error': errors.date })}`}>Date</span>
              <Calendar
                id="mask"
                value={value ? new Date(value) : undefined}
                onChange={(e) => (e.value ? onChange((e.value as Date).toISOString()) : onChange(null))}
                mask="9999-99-99"
                dateFormat="yy-mm-dd"
                showIcon
                maxDate={new Date()}
                minDate={START_DATE}
                touchUI={isMobile}
                disabledDates={weekends}
              />
            </div>
          )}
        />

        <Controller
          name="comment"
          control={control}
          render={({ field }) => (
            <div className={styles.field}>
              <label htmlFor="knowledgePrerequisites">Comments</label>
              <InputTextarea style={{ minHeight: '4rem' }} rows={2} {...field} maxLength={300} />
            </div>
          )}
        />
      </form>
    </Dialog>
  );
};

export default RoadmapTempoDialog;

RoadmapTempoDialog.defaultProps = {
  idRoadmapStatus: undefined,
  tempo: undefined,
  roadmap: undefined,
};
