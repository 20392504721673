import { isAdminSelector, userSelector } from 'features/auth/reducer/auth';
import { useOutsideComponent } from 'hooks';
import { useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { useAppSelector } from 'store';

import styles from './UserMenuPopup.module.scss';

interface UserMenuPopupProps {
  handleClose: () => void;
}

const UserMenuPopup = ({ handleClose }: UserMenuPopupProps) => {
  const { t } = useTranslation();
  const wrapperRef = useRef(null);
  const user = useAppSelector(userSelector);
  const isAdmin = useAppSelector(isAdminSelector);
  useOutsideComponent(wrapperRef, () => setTimeout(handleClose, 200));

  return (
    <div ref={wrapperRef} className={styles.menu}>
      <div className={styles.item}>
        <div className={styles.content}>
          <div className={styles.avatar}>
            <img alt="user" src="/images/icons/user.png" />
          </div>
          <div className={styles.column}>
            <h5>{user?.name}</h5>
            <span>{user?.email}</span>
          </div>
        </div>
      </div>
      <div className={styles.item}>
        <Link onClick={handleClose} to="/admin" className={styles.link}>
          Manage
        </Link>
      </div>
      {isAdmin && (
        <div className={styles.item}>
          <Link onClick={handleClose} to="/admin/access-tokens" className={styles.link}>
            Access Tokens
          </Link>
        </div>
      )}
      <div className={styles.item}>
        <div className={styles.showSolutionStatus}>
          <span>Version: {process.env.REACT_APP_VERSION}</span>
        </div>
      </div>
      <div className={styles.separator} />
      <div className={styles.item}>
        <Link to="/auth/logout" className={styles.link}>
          {t('auth.logout')}
        </Link>
      </div>
    </div>
  );
};

export default UserMenuPopup;
