/* eslint-disable max-lines */
import { Industry } from 'domain/industry/types';
import {
  Filter,
  FilterGroup,
  FilterGroupItem,
  FilterItem,
  FilterList,
  Filters,
  Func,
  Industries,
  IndustryFilterGroups,
  IndustryFilterValue,
  ProductSimple,
} from 'features/products/types';
import i18next from 'i18next';

function checkIndustry(value: Industry, industry: Industries): boolean {
  switch (industry) {
    case 'Specific':
      return value.isSpecific;
    case 'Generic':
      return !value.isSpecific;
    default:
      return true;
  }
}

function getGroups(data: ProductSimple[]): FilterGroupItem[] {
  const groups = [] as FilterGroupItem[];
  data.forEach(({ group }) => {
    const { productLine } = group;

    const index = groups.findIndex((g) => g.id === productLine.idProductLine);

    if (index >= 0) {
      const valueIndex = groups[index].values.findIndex((g) => g.id === group.idProductGroup);
      if (valueIndex === -1) {
        groups[index].values.push({ id: group.idProductGroup, value: group.productGroup });
      }
    } else {
      groups.push({
        id: productLine.idProductLine,
        group: productLine.productLine,
        values: [{ id: group.idProductGroup, value: group.productGroup }],
      });
    }
  });
  return groups;
}

function getCountries(data: ProductSimple[]): FilterGroupItem[] {
  const groups = [] as FilterGroupItem[];
  data.forEach(({ countries }) => {
    countries?.forEach((country) => {
      if (country.region) {
        const { region } = country;

        const index = groups.findIndex((g) => g.id === region.idCountryRegion);

        if (index >= 0) {
          const valueIndex = groups[index].values.findIndex((g) => g.id === country.idCountry);
          if (valueIndex === -1) {
            groups[index].values.push({ id: country.idCountry, value: country.name });
          }
        } else {
          groups.push({
            id: region.idCountryRegion,
            group: region.name,
            values: [{ id: country.idCountry, value: country.name }],
          });
        }
      }
    });
  });
  return groups;
}

function getBenefits(data: ProductSimple[]): FilterGroupItem[] {
  const groups = [] as FilterGroupItem[];
  data.forEach(({ benefits }) => {
    benefits.forEach((benefit) => {
      const index = groups.findIndex((g) => g.id === benefit.idBenefitGroup);

      if (index >= 0) {
        const valueIndex = groups[index].values.findIndex((g) => g.id === benefit.idBenefit);
        if (valueIndex === -1) {
          groups[index].values.push({ id: benefit.idBenefit, value: benefit.benefit, tooltip: benefit.tooltip });
        }
      } else {
        groups.push({
          id: benefit.idBenefitGroup,
          group: benefit.group,
          values: [{ id: benefit.idBenefit, value: benefit.benefit, tooltip: benefit.tooltip }],
        });
      }
    });
  });

  return groups;
}

function getIndustries(data: ProductSimple[]): IndustryFilterGroups[] {
  const groups = [] as FilterGroupItem<IndustryFilterValue>[];
  data.forEach(({ industries }) => {
    industries.forEach((industry) => {
      const index = groups.findIndex((g) => g.id === industry.idIndustry);

      if (index >= 0) {
        if (industry.industryArea) {
          const valueIndex = groups[index].values.findIndex((g) => g.id === industry.industryArea?.idIndustryArea);
          if (valueIndex === -1) {
            groups[index].values.push({
              id: industry.industryArea.idIndustryArea,
              value: industry.industryArea.industryArea,
              order: industry.industryArea.areaOrder ?? 0,
            });
          }
        }
      } else {
        groups.push({
          id: industry.idIndustry,
          group: industry.industry,
          values: industry.industryArea
            ? [
                {
                  id: industry.industryArea.idIndustryArea,
                  value: industry.industryArea.industryArea,
                  order: industry.industryArea.areaOrder ?? 0,
                },
              ]
            : [],
        });
      }
    });
  });

  return groups;
}

function getLifecycle(data: ProductSimple[]): FilterGroupItem[] {
  const groups = [] as FilterGroupItem[];
  data.forEach(({ lifeCycles }) => {
    lifeCycles.forEach((lifeCycle) => {
      if (lifeCycle.lifeCycleGroup) {
        const index = groups.findIndex((g) => g.id === lifeCycle.lifeCycleGroup?.idLifeCycleGroup);

        if (index >= 0) {
          const valueIndex = groups[index].values.findIndex((g) => g.id === lifeCycle.idLifeCycle);
          if (valueIndex === -1) {
            groups[index].values.push({
              value: lifeCycle.lifeCycle,
              id: lifeCycle.idLifeCycle,
              tooltip: lifeCycle.tooltip,
            });
          }
        } else {
          groups.push({
            id: lifeCycle.lifeCycleGroup.idLifeCycleGroup,
            group: lifeCycle.lifeCycleGroup.lifeCycleGroup,
            values: [{ value: lifeCycle.lifeCycle, id: lifeCycle.idLifeCycle, tooltip: lifeCycle.tooltip }],
          });
        }
      }
    });
  });

  return groups;
}

export const filterFunctions: {
  [key in FilterGroup]: Func;
} = {
  [FilterGroup.USER_PERSONA]: (p: ProductSimple, id: number) => p.userPersonas.some((u) => u.idUserPersona === id),

  [FilterGroup.INDUSTRY]: (p: ProductSimple, id: number, industry: Industries) =>
    p.industries.some((i) => i.idIndustry === id && checkIndustry(i, industry)),

  [FilterGroup.INDUSTRY_AREA]: (p: ProductSimple, id: number, industry: Industries) =>
    p.industries.some((i) => i.industryArea?.idIndustryArea === id && checkIndustry(i, industry)),

  [FilterGroup.PRODUCT_LINE]: (p: ProductSimple, id: number) => p.group.productLine.idProductLine === id,

  [FilterGroup.PRODUCT_GROUP]: (p: ProductSimple, id: number) => p.group.idProductGroup === id,

  [FilterGroup.BENEFIT_GROUP]: (p: ProductSimple, id: number) => p.benefits.some((i) => i.idBenefitGroup === id),

  [FilterGroup.BENEFIT]: (p: ProductSimple, id: number) => p.benefits.some((i) => i.idBenefit === id),

  [FilterGroup.PLANT_LIFECYCLE_GROUP]: (p: ProductSimple, id: number) =>
    p.lifeCycles.some((l) => l.lifeCycleGroup?.idLifeCycleGroup === id),

  [FilterGroup.PLANT_LIFECYCLE]: (p: ProductSimple, id: number) => p.lifeCycles.some((i) => i.idLifeCycle === id),

  [FilterGroup.BUSINESS_MODEL]: (p: ProductSimple, id: number) =>
    p.group?.productLine?.businessModel?.idBusinessModel === id,

  // [FilterGroup.SOLUTION_TYPE]: (p: ProductSimple, id: number) => p.type?.idProductType === id,

  // [FilterGroup.STATUS]: (p: ProductSimple, id: number) => p.status?.idProductStatus === id,

  [FilterGroup.PLATFORM]: (p: ProductSimple, id: number) => p.platform?.idPlatform === id,

  [FilterGroup.REGION]: (p: ProductSimple, id: number) => p.countries.some((i) => i.region?.idCountryRegion === id),

  [FilterGroup.COUNTRY]: (p: ProductSimple, id: number) => p.countries.some((i) => i.idCountry === id),

  [FilterGroup.PORTFOLIO]: (p: ProductSimple, id: number) => p.portfolios.some((i) => i.idProductPortfolio === id),
};

const sortValue = (a: FilterItem, b: FilterItem): 1 | -1 => (a.value < b.value ? -1 : 1);

const sortLabel = (a: Filters, b: Filters): 1 | -1 => (a.label < b.label ? -1 : 1);

export /**
 * @param {FilterList} filters - object containing available filters
 * @returns {Filters[]} array of filters to be rendered
 */
const createFilters = (filters: FilterList, isInternal: boolean): Filters[] => [
  {
    label: i18next.t('products.end-user'),
    group: FilterGroup.USER_PERSONA,
    values: [...filters.userPersonas].sort(sortValue),
  },
  {
    label: i18next.t('products.industries'),
    group: FilterGroup.INDUSTRY,
    // options: [{ value: Industries.ALL_SOLUTIONS }, { value: Industries.GENERIC }, { value: Industries.SPECIFIC }],
    children: filters.industries
      .map((g) => ({
        id: g.id,
        label: g.group,
        group: g.values.length ? FilterGroup.INDUSTRY_AREA : FilterGroup.INDUSTRY,
        values: [...g.values].sort((a, b) => (a.order < b.order ? -1 : 1)),
      }))
      .sort(sortLabel),
    values: [],
  },
  {
    label: i18next.t('products.solution-categories'),
    group: FilterGroup.PRODUCT_LINE,
    children: filters.groups
      .map((g) => ({
        id: g.id,
        label: g.group,
        group: FilterGroup.PRODUCT_GROUP,
        values: [...g.values].sort(sortValue),
      }))
      .sort(sortLabel),
    values: [],
  },
  {
    label: i18next.t('products.customer-benefits'),
    group: FilterGroup.BENEFIT_GROUP,
    children: filters.benefits
      .map((g) => ({
        id: g.id,
        label: g.group,
        group: FilterGroup.BENEFIT,
        values: [...g.values].sort(sortValue),
      }))
      .sort(sortLabel),
    values: [],
  },
  {
    label: i18next.t('products.plant-lifecycle-management'),
    group: FilterGroup.PLANT_LIFECYCLE_GROUP,
    children: filters.plantLifecycleManagement.map((g) => ({
      id: g.id,
      label: g.group,
      group: FilterGroup.PLANT_LIFECYCLE,
      values: [...g.values].sort((a, b) => a.id - b.id),
    })),
    values: [],
  },
  ...(isInternal
    ? [
        {
          label: i18next.t('products.business-model'),
          group: FilterGroup.BUSINESS_MODEL,
          values: [...filters.businessModel].sort(sortValue),
        },
      ]
    : []),
  ...(isInternal
    ? [
        // {
        //   label: i18next.t('products.solution-type'),
        //   group: FilterGroup.SOLUTION_TYPE,
        //   values: [...filters.solutionType].sort(sortValue),
        // },
        {
          label: i18next.t('products.portfolios'),
          group: FilterGroup.PORTFOLIO,
          values: [...filters.portfolios].sort(sortValue),
          singleSelect: true,
        },
        // {
        //   label: i18next.t('products.status'),
        //   group: FilterGroup.STATUS,
        //   values: [...filters.status].sort(sortValue),
        // },
        {
          label: i18next.t('general.countries'),
          group: FilterGroup.REGION,
          children: filters.countries
            .map((g) => ({
              id: g.id,
              label: g.group,
              group: FilterGroup.COUNTRY,
              values: [...g.values].sort(sortValue),
            }))
            .sort(sortLabel),
          values: [],
        },
      ]
    : []),
  {
    label: i18next.t('roadmap.platform'),
    group: FilterGroup.PLATFORM,
    values: [...filters.platform].sort(sortValue),
  },
];

function getFilterItem<T extends keyof ProductSimple>(
  data: ProductSimple[],
  element: T,
  id: keyof Exclude<ProductSimple[T], undefined>,
  name: keyof Exclude<ProductSimple[T], undefined>,
): FilterItem[] {
  const items = [] as FilterItem[];
  data.forEach((product) => {
    const value = product[element];
    // @ts-expect-error The compiler has a hard time understanding that `name` is guaranteed to be a valid key of `value`.
    if (value && id in value && name in value) {
      // @ts-expect-error The compiler has a hard time understanding that `id` is guaranteed to be a valid key of `value`.
      const valueId: number = value[id];
      // @ts-expect-error The compiler has a hard time understanding that `name` is guaranteed to be a valid key of `value`.
      const valueName: string = value[name];
      const index = items.findIndex((g) => g.id === valueId);

      if (index < 0) {
        items.push({
          id: valueId,
          value: valueName,
        });
      }
    }
  });

  return items;
}

// const getStatus = (data: ProductSimple[]): FilterItem[] =>
//   getFilterItem(data, 'status', 'idProductStatus', 'productStatus');

const getPlatforms = (data: ProductSimple[]): FilterItem[] => getFilterItem(data, 'platform', 'idPlatform', 'name');

function getPortfolios(data: ProductSimple[]): FilterItem[] {
  const items = [] as FilterItem[];
  data.forEach(({ portfolios }) => {
    portfolios?.forEach((portfolio) => {
      const index = items.findIndex((g) => g.id === portfolio.idProductPortfolio);

      if (index < 0) {
        items.push({
          id: portfolio.idProductPortfolio,
          value: portfolio.name,
        });
      }
    });
  });

  return items;
}

function getBusinessModel(data: ProductSimple[]): FilterItem[] {
  const items = [] as FilterItem[];
  data.forEach((product) => {
    const businessModel = product?.group?.productLine?.businessModel;
    if (businessModel) {
      const index = items.findIndex((g) => g.id === businessModel.idBusinessModel);

      if (index < 0) {
        items.push({
          id: businessModel.idBusinessModel,
          value: businessModel.businessModel,
        });
      }
    }
  });

  return items;
}

function getUserPersonas(data: ProductSimple[]): FilterItem[] {
  const items = [] as FilterItem[];
  data.forEach(({ userPersonas }) => {
    userPersonas.forEach((userPersona) => {
      const index = items.findIndex((g) => g.id === userPersona.idUserPersona);

      if (index < 0) {
        items.push({
          id: userPersona.idUserPersona,
          value: userPersona.userPersona,
        });
      }
    });
  });

  return items;
}

/**
 * @param {Product[]} data - array of products
 * @returns {FilterList} unique values used for filters
 */
export const extractFilters = (data: ProductSimple[]): FilterList => ({
  // status: getStatus(data),
  industries: getIndustries(data),
  businessModel: getBusinessModel(data),
  plantLifecycleManagement: getLifecycle(data),
  benefits: getBenefits(data),
  groups: getGroups(data),
  countries: getCountries(data),
  userPersonas: getUserPersonas(data),
  platform: getPlatforms(data),
  portfolios: getPortfolios(data),
});

export /**
 * @param {Product[]} product - array of products
 * @param {Filter[]} filters - array of filters
 * @param {Group} group - filter group
 * @returns {boolean} whether product matches provided filters
 */
const filterProduct = (product: ProductSimple, filters: Filter[], group: FilterGroup, industry?: Industries) => {
  const filtered = filters.filter((filter) => filter.group === group);
  if (!filtered.length) return true;
  return filtered.some((filter) => filterFunctions[group](product, filter.id, industry));
};
