/* eslint-disable max-lines */
import type { AutonomyFunctionDTO } from 'domain/autonomylevels/types';
import type { Industry } from 'domain/industry/types';
import type { MaturityModel } from 'domain/maturityModel/types';
import type { UpdateRelatedPlantDTO } from 'domain/plant/types';
import type { ProductPortfolio } from 'domain/productPortfolio/types';
import type { QualityAssuranceProcedure } from 'domain/qualityAssurance/types';
import type { RoadMap, RoadMapType, RoadMapVersion, RoadmapPlatform, RoadmapStatus } from 'domain/roadmap/types';
import { UserBrief } from 'domain/user/types';

export interface ImpactOption {
  id: number;
  impact: string;
}

export const impactOptions = [
  {
    id: 0,
    impact: 'Low',
  },
  {
    id: 1,
    impact: 'Medium',
  },
  {
    id: 2,
    impact: 'High',
  },
];

export enum Industries {
  ALL_SOLUTIONS = 'All Solutions',
  GENERIC = 'Generic',
  SPECIFIC = 'Specific',
}

export interface IndustryArea {
  idIndustryArea: number;
  idIndustry: number;
  industryArea: string;
  areaOrder?: number;
}

export interface ProductStatus {
  idProductStatus: number;
  productStatus: string;
}

export interface LifeCycleGroup {
  idLifeCycleGroup: number;
  lifeCycleGroup: string;
}

export interface LifeCycle {
  idLifeCycle: number;
  lifeCycle: string;
  lifeCycleGroup?: LifeCycleGroup;
  tooltip: string;
}

export interface Benefit {
  idBenefit: number;
  idBenefitGroup: number;
  benefit: string;
  tooltip: string;
  group: string;
  impact: number;
}

export interface BasicOkr {
  id: number;
  name: string;
}

export interface OKR extends BasicOkr {
  idPlatform: number;
  description: string;
  alias: string;
  impact: number;
}

export interface BusinessModel {
  idBusinessModel: number;
  businessModel: string;
}

export interface ProductLine {
  idProductLine: number;
  productLine: string;
  businessModel?: BusinessModel;
}

export interface Group {
  idProductGroup: number;
  productGroup: string;
  productLine: ProductLine;
}

export interface Training {
  idTraining: number;
  idProduct: number;
  courseName: string;
  description: string;
  courseOrder: number;
  courseLevel: string;
  courseType: string;
  availability: string;
  contentUpdate: string;
  link: string;
  knowledgePrerequisites: string;
  duration: number;
}

export type Status = 'Released' | 'Preview' | 'Development' | 'Productize';

export type UserPersona = {
  idUserPersona: number;
  userPersona: string;
};

export interface RelatedIndustry extends Industry {
  idProductIndustry: number;
  idIndustryArea?: number | null;
}

export type ProductVideo = {
  idProductVideo: number;
  idProduct: number;
  title: string;
  hyperlink: string;
  timestamp: string | null;
};

export interface ProductDocumentTypeDTO {
  idProductDocumentType: number;
  name: string;
}

export interface ProductDocumentLinkDTO {
  idProductDocumentLink: number;
  idProductDocumentType: number;
  documentName: string;
  documentLink: string;
  documentType?: ProductDocumentTypeDTO;
}

export type News = {
  idNews: number;
  title: string;
  description: string;
  contentUrl: string;
  imageUrl: string;
  timestamp: string | null;
};

export type StreamVideo = {
  idProductStream: number;
  idProduct: number;
  title: string;
  description: string;
  hyperlink: string;
  timestamp: string | null;
};

export interface ProductList {
  idProduct: number;
  product: string;
  nickName: string;
  description: string;
  externalAccess: true;
  enabled: true;
  group: Group;
  platform?: RoadmapPlatform;
  productOwner: UserBrief | null;
  technologyManager: UserBrief | null;
  productManager: UserBrief | null;
  idProductOwner: number;
  idTechnologyManager: number;
  idProductManager: number;
  idType: number;
  saleable: boolean;
  isRelease: boolean;
  poApproved: boolean;
  pmApproved: boolean;
}

export interface ProductSimple extends ProductList {
  image: string;
  image2: string;
  status?: ProductStatus;
  industries: Industry[];
  lifeCycles: LifeCycle[];
  benefits: Benefit[];
  userPersonas: UserPersona[];
  countries: Country[];
  portfolios: Omit<ProductPortfolio, 'sections, description'>[];
  productOwner: UserBrief | null;
}

export interface Product {
  idProduct: number;
  product: string;
  nickName: string;
  description: string;
  descriptionForProposals: string;
  businessModels: string;
  image: string;
  image2: string;
  documentation: string;
  enabled: boolean;
  externalAccess: boolean;
  linkYammer: string;
  status: ProductStatus;
  group: Group;
  platform: RoadmapPlatform | null;
  productOwner: UserBrief | null;
  productManager: UserBrief | null;
  technologyManager: UserBrief | null;
  countries: Country[];
  videos: ProductVideo[];
  streamVideos: StreamVideo[];
  news: News[];
  industries: RelatedIndustry[];
  benefits: Benefit[];
  lifeCycles: LifeCycle[];
  includedProducts: ProductBrief[];
  relatedProducts: ProductBrief[];
  relatedAddons: ProductBrief[];
  trainings: Training[];
  userPersonas: UserPersona[];
  roadmaps: RoadMap[] | null;
  relatedPlants: UpdateRelatedPlantDTO[];
  qaSpecialists: UserBrief[];
  procedures: QualityAssuranceProcedure[];
  documents: ProductDocumentLinkDTO[];
  autonomyFunctions: EditableProductAutonomyFunction[];
  idEam?: number;
  idType: number;
  saleable: boolean;
  idMaturityModel: number | null;
  poApproved: boolean;
  pmApproved: boolean;
}

export interface ExportableSolution {
  productId: number;
  productName: string;
  productFamily: string;
  productFamilyId: number;
  technicalProductOwner: string;
  technicalProductOwnerEmail: string;
}

export interface EditableProductAutonomyFunction extends ProductAutonomyFunction {
  edited: boolean;
}

export type BenefitAdd = {
  idBenefit: number;
  impact: {
    impact: string;
    id: number;
  };
};

export type OKRAdd = {
  id: number;
  impact: {
    impact: string;
    id: number;
  };
};

export interface NewsUpdate extends News {
  edited: boolean;
}

export type SolutionTypes = 'Product' | 'Component';

export interface SolutionType {
  value: number;
  name: SolutionTypes;
}

export interface EditableProduct
  extends Omit<
    Product,
    'benefits' | 'industries' | 'news' | 'autonomyLevel' | 'status' | 'type' | 'group' | 'procedures'
  > {
  maturityModel?: MaturityModel | null;
  status?: ProductStatus;
  group?: Group;
  benefits: BenefitAdd[];
  industries: RelatedIndustry[];
  news: NewsUpdate[];
}

export type ProductUpdate = {
  idProduct: number;
  product: string;
  nickName: string;
  description: string;
  businessModels: string;
  image: string;
  image2: string;
  documentation: string;
  linkYammer: string;
  idProductStatus: number;
  idProductGroup: number;
  idProductOwner?: number;
  idMaturityModel?: number;
  idTechnologyManager?: number;
  idProductManager?: number;
  idPlatform?: number;
  idEam?: number;
  enabled: boolean;
  externalAccess: boolean;
  industries: RelatedIndustry[];
  lifeCycles: number[];
  benefits: BenefitAdd[];
  relatedProducts: number[];
  relatedAddons: number[];
  relatedPlants: UpdateRelatedPlantDTO[];
  trainings: Training[];
  userPersonas: number[];
  includedProducts: number[];
  news: NewsUpdate[];
  qaSpecialists: number[];
  videos: ProductVideo[];
  streamVideos: StreamVideo[];
};

export type ProductBrief = {
  idProduct: number;
  product: string;
  nickName: string;
  description: string;
  image: string;
  image2: string;
  productGroup?: string;
  productOwner?: UserBrief | null;
  platform: RoadmapPlatform | null;
  idPlatform: number | null;
  idProductOwner: number;
  idTechnologyManager: number;
  idProductManager: number;
  qaSpecialistsIds?: number[];
  industries?: Industry[];
  idType: number;
  status?: ProductStatus;
};

export type ProductBasic = {
  idProduct: number;
  product: string;
};

export interface PlantRelatedProduct {
  idProductCustomer: number;
  idProduct: number;
  product: string;
  details: string;
  status: number | null;
  endDate: string | null;
  startDate: string | null;
  confidential: boolean;
  quantity: number;
}

export type RelatedProduct = {
  idProduct: number;
  keyName: string;
  product: string;
  nickName: string;
  description: string;
  image: string;
  image2: string;
};

export type Country = {
  idCountry: number;
  name: string;
  idCountryRegion: number;
  productIds: number[];
  region: CountryRegion | null;
};

export type CountryRegion = {
  idCountryRegion: number;
  name: string;
};

export interface ProductAutonomyFunction extends AutonomyFunctionDTO {
  idProductAutonomyFunction: number;
  impact: number;
}

export interface ProductRoadmap {
  idRoadmap: number;
  item: string;
  type: RoadMapType | null;
  idOwner?: number;
  version?: RoadMapVersion;
  status: RoadmapStatus;
  percentComplete: number;
  okrs: OKR[];
}

export interface ProductRoadmapWithProduct extends ProductRoadmap {
  product: Product | ProductBrief;
}
