import { useQuery } from 'react-query';

import { getFundingSources, getFundingSourcesByYear, getFundingSourcesGroupByYear } from '../api';
import { FundingSourceDTO, FundingSourceGroupDTO } from '../types';

export const FUNDING_SOURCE_BY_YEAR_ID = 'funding-source-by-year';
export const FUNDING_SOURCE_GROUP_BY_YEAR_ID = 'funding-source-group-by-year';
export const FUNDING_SOURCE = 'funding-source';

export function useFundingSourceByYear(year: number, platform?: number) {
  return useQuery<FundingSourceDTO[], Error>(
    [FUNDING_SOURCE_BY_YEAR_ID, year, platform],
    () => getFundingSourcesByYear(year, platform),
    {
      staleTime: 1000 * 60 * 5,
    },
  );
}

export function useFundingSourceGroupByYear(year: number, platform?: number) {
  return useQuery<FundingSourceGroupDTO[], Error>(
    [FUNDING_SOURCE_GROUP_BY_YEAR_ID, year, platform],
    () => getFundingSourcesGroupByYear(year, platform),
    {
      staleTime: 1000 * 60 * 5,
    },
  );
}

export function useFundingSource() {
  return useQuery<FundingSourceDTO[], Error>(FUNDING_SOURCE, getFundingSources, {
    staleTime: 1000 * 60 * 5,
  });
}
