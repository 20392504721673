import { useUser } from 'features/admin/hooks';
import { isAdminSelector, isInternalSelector, isLoggedInSelector, setUser } from 'features/auth/reducer/auth';
import { Role } from 'features/auth/types';
import { useEffect } from 'react';
import { useNavigate, useRoutes } from 'react-router-dom';
import { toast } from 'react-toastify';
import api from 'services/api';
import { useAppDispatch, useAppSelector } from 'store';

import { protectedRoutes } from './protected.routes';
import { publicRoutes } from './public.routes';

export const AppRoutes = () => {
  const isLoggedIn = useAppSelector(isLoggedInSelector);
  const isInternal = useAppSelector(isInternalSelector);
  const isAdmin = useAppSelector(isAdminSelector);
  const { data: user } = useUser(isLoggedIn && isInternal);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    const { referrer } = document;
    const currentUrl = window.location.href;
    const recordVisit = async () => {
      api.post('/visits', {
        referrer,
        userAgent: navigator.userAgent,
      });
    };

    if (referrer !== currentUrl) {
      recordVisit();
    }
  }, []);

  useEffect(() => {
    if (isLoggedIn && user) {
      dispatch(setUser({ ...user, token: '' }));
      if (isAdmin !== (user.role === Role.Admin || user.roles?.some((r) => r === Role.Admin))) {
        toast.warn(`Your user role has changed to ${user.role}! You must log in again`, {
          autoClose: false,
        });
        navigate('/auth/logout');
      }
    }
  }, [user, dispatch, isAdmin, navigate, isLoggedIn]);

  return useRoutes(isInternal && isLoggedIn ? [...protectedRoutes, ...publicRoutes] : publicRoutes);
};
