import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { QualityAssuranceProcedure, QualityAssuranceSection } from 'domain/qualityAssurance/types';
import type { RootState } from 'store/rootReducer';

interface ProcedureState {
  editableProcedure: QualityAssuranceProcedure | null;
}

const initialState: ProcedureState = {
  editableProcedure: null,
};

export const procedureSlice = createSlice({
  name: 'quality-assurance/procedure',
  initialState,
  reducers: {
    setProcedure: (state, action: PayloadAction<QualityAssuranceProcedure | null>) => {
      state.editableProcedure = action.payload;
    },
    updateProcedureSections: (state, action: PayloadAction<QualityAssuranceSection[]>) => {
      if (state.editableProcedure) {
        state.editableProcedure.sections = action.payload;
      }
    },
  },
});

export const { setProcedure, updateProcedureSections } = procedureSlice.actions;

export const procedureSelector = (state: RootState) => state.procedure.editableProcedure;

export default procedureSlice.reducer;
