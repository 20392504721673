import { useQuery } from 'react-query';

import { getRoadmapTempoReportByUser } from '../API';
import { RoadmapTempoReportByUser, RoadmapTempoReportRequest } from '../types';

export const ROADMAP_TEMPO_REPORT_BY_USER_KEY = 'roadmap-tempo-report-by-user';

export function useRoadmapTempoReportByUser(request: RoadmapTempoReportRequest, enabled = true) {
  return useQuery<RoadmapTempoReportByUser[], Error>(
    [ROADMAP_TEMPO_REPORT_BY_USER_KEY, request],
    () => getRoadmapTempoReportByUser(request),
    {
      staleTime: 1000 * 60 * 15,
      enabled,
    },
  );
}
