/**
 * @param {number} min - lower bound of range, eg: 0
 * @param {number} max - higher bound of range, eg: 100
 * @returns {number} random number between min and max
 */

export const randomIntFromInterval = (min: number, max: number): number =>
  Math.floor(Math.random() * (max - min + 1) + min);

/**
 * @param {number} value - value being rounded eg: 3.3384456899999
 * @param {number} [decimals = 2] - number of decimal places
 * @returns {number} rounded number 3.84
 */
export const roundDecimal = (value: number, decimals = 2): number => {
  const multiplier = 10 ** decimals;
  return Math.round(value * multiplier) / multiplier;
};

export const currency = new Intl.NumberFormat('de-DE', {
  style: 'currency',
  currency: 'EUR',
  minimumFractionDigits: 0,
});
