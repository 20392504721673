import { useQuery } from 'react-query';

import { getProductImages } from '../api';
import { ProductImage } from '../types';

export function useProductImages() {
  return useQuery<ProductImage[], Error>('product-images', getProductImages, {
    staleTime: 1000 * 60 * 5,
  });
}
