import { Configuration, PublicClientApplication } from '@azure/msal-browser';

const configuration: Configuration = {
  auth: {
    clientId: process.env.REACT_APP_CLIENT_ID ?? '',
    authority: process.env.REACT_APP_AUTHORITY ?? '',
  },
  cache: {
    cacheLocation: 'localStorage',
  },
};

export const pca = new PublicClientApplication(configuration);
