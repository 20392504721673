import { RoadMapUpdate } from 'domain/roadmap/types';
import { EditableProduct } from 'features/products/types';

export const initialProductValue: EditableProduct = {
  benefits: [],
  businessModels: '',
  description: '',
  descriptionForProposals: '',
  documentation: '',
  linkYammer: '',
  enabled: true,
  externalAccess: true,
  industries: [],
  qaSpecialists: [],
  includedProducts: [],
  lifeCycles: [],
  nickName: '',
  product: '',
  relatedProducts: [],
  relatedAddons: [],
  relatedPlants: [],
  trainings: [],
  userPersonas: [],
  idProduct: 0,
  image: '',
  image2: '',
  news: [],
  streamVideos: [],
  videos: [],
  platform: null,
  countries: [],
  roadmaps: [],
  productOwner: null,
  productManager: null,
  technologyManager: null,
  idType: 0,
  saleable: false,
  idMaturityModel: null,
  documents: [],
  autonomyFunctions: [],
  pmApproved: false,
  poApproved: false,
};

export const initialValue: RoadMapUpdate = {
  description: '',
  detailedDescription: '',
  item: '',
  justification: '',
  qualityCertificate: '',
  specification: '',
  developmentTasks: '',
  versionChangesCount: 0,
  idProduct: 0,
  product: null,
  idRoadmap: 0,
  milestones: [],
  plants: [],
  okrs: [],
  status: null,
  type: null,
  version: null,
  owner: null,
  fundingSources: [],
  priority: 0,
  category: 0,
  bugType: 0,
};
