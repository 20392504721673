import { AnyAction, combineReducers } from '@reduxjs/toolkit';
import authReducer, { logout } from 'features/auth/reducer/auth';
import settingsReducer from 'features/auth/reducer/settings';
import cartReducer from 'features/cart/reducers/cart';
import countryMapFilterReducer from 'features/country/reducers/countryMapFilter';
import fundingSourceReducer from 'features/fundingSource/reducers/fundingSource';
import maturityModelFiltersReducer from 'features/maturityModel/reducers/maturityFilter';
import maturityModelReducer from 'features/maturityModel/reducers/maturityModel';
import maturityModelOptionReducer from 'features/maturityModel/reducers/maturityModelOption';
import productPortfolioReducer from 'features/productPortfolio/reducers/productPortfolio';
import solutionSlice from 'features/products/reducers/solutionSlice';
import certificateReducer from 'features/qualityAssurance/reducers/certificate';
import procedureReducer from 'features/qualityAssurance/reducers/procedure';
import roadmapFilterReducer from 'features/roadmap/reducers/filter';
import roadmapReducer from 'features/roadmap/reducers/roadmap';
import roadmapTempoReducer from 'features/timeAllocation/hooks/tempo';

const appReducer = combineReducers({
  auth: authReducer,
  settings: settingsReducer,
  cart: cartReducer,
  procedure: procedureReducer,
  certificate: certificateReducer,
  maturityModelOption: maturityModelOptionReducer,
  maturityModel: maturityModelReducer,
  productPortfolio: productPortfolioReducer,
  roadmap: roadmapReducer,
  fundingSource: fundingSourceReducer,
  maturityModelFilters: maturityModelFiltersReducer,
  roadmapFilter: roadmapFilterReducer,
  countryMapFilter: countryMapFilterReducer,
  solutions: solutionSlice,
  roadmapTempo: roadmapTempoReducer,
});

const rootReducer = (state: RootState | undefined, action: AnyAction) => {
  if (action.type === logout.toString()) {
    return appReducer(undefined, action);
  }
  return appReducer(state, action);
};

export type RootState = ReturnType<typeof appReducer>;

export default rootReducer;
