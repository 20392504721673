import { getUserInfo } from 'features/auth/api';
import { AuthUser } from 'features/auth/types';
import { useQuery } from 'react-query';

export function useUser(enabled = true) {
  return useQuery<AuthUser, Error>('user', getUserInfo, {
    staleTime: 1000 * 60 * 15,
    enabled,
  });
}
