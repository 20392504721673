import axios from 'axios';
import { toast } from 'react-toastify';

import api from './api';

const pdf = {
  downloadPDFAsync: async <T>(url: string, name: string, body: T | null = null) => {
    api.defaults.responseType = 'blob';
    try {
      const res = body ? await api.post(url, body) : await api.get(url);
      const blob = res.data as Blob;
      const href = window.URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.style.display = 'none';
      a.href = href;

      a.download = `${name}.pdf`;
      document.body.appendChild(a);
      a.click();
      window.URL.revokeObjectURL(url);
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (err: any) {
      let errorMessage = '';
      if (axios.isAxiosError(err)) {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const error = JSON.parse(await (err as any).response?.data.text());
        errorMessage = error?.Message;
      }
      toast.error(`Error downloading PDF: ${errorMessage ?? err.response.statusText}`, {
        position: 'top-right',
        toastId: 'PDFerror',
        autoClose: false,
      });
      throw err;
    } finally {
      api.defaults.responseType = 'json';
    }
  },
  previewHTMLAsync: async (
    url: string,
    callback = () => {
      // empty
    },
  ) => {
    api.defaults.responseType = 'blob';
    try {
      const res = await api.get(url);
      const blob = res.data as Blob;
      const href = window.URL.createObjectURL(blob);

      const iframe = document.createElement('iframe');
      document.body.appendChild(iframe);
      iframe.src = href;
      iframe.onload = () => {
        setTimeout(() => {
          iframe.focus();
          iframe.contentWindow?.print();
          callback();
        }, 1);
      };

      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (err: any) {
      toast.error(`Error previewing PDF: ${err.response.statusText}`, {
        position: 'top-right',
        toastId: 'PDFerror',
        autoClose: false,
      });
    } finally {
      api.defaults.responseType = 'json';
    }
  },
};

export default pdf;
