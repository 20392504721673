import classNames from 'classnames';
import { ProductVideo } from 'features/products/types';
import { Calendar } from 'primereact/calendar';
import { InputText } from 'primereact/inputtext';
import { InputTextarea } from 'primereact/inputtextarea';
import { Control, Controller } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import styles from './AddVideosForm.module.scss';

interface AddVideosFormProps {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  control: Control<ProductVideo, any>;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  errors: any;
}

const AddVideosForm = ({ control, errors }: AddVideosFormProps) => {
  const { t } = useTranslation();

  return (
    <div className={`${styles.form} p-jc-center`}>
      <form className="p-fluid">
        <div className={styles.field}>
          <span className={classNames({ 'p-error': errors.title })}>
            <span className="red">*</span> Title
          </span>
          <Controller
            name="title"
            control={control}
            rules={{ required: true }}
            render={({ field }) => (
              <InputTextarea
                {...field}
                rows={2}
                className={classNames({ 'p-invalid': errors.title })}
                autoResize
                maxLength={700}
              />
            )}
          />
          {errors.title && <small className="p-error">{t('form.required')}</small>}
        </div>

        <div className={styles.field}>
          <div className="p-field">
            <span className={classNames({ 'p-error': errors.hyperlink })}>
              <span className="red">*</span> URL
            </span>
            <Controller
              name="hyperlink"
              control={control}
              rules={{ required: true }}
              render={({ field }) => (
                <InputText {...field} className={classNames({ 'p-invalid': errors.hyperlink })} maxLength={250} />
              )}
            />
          </div>
          {errors.hyperlink && <small className="p-error">{t('form.required')}</small>}
        </div>

        <div className={styles.field}>
          <div className="p-field">
            <label htmlFor="timestamp">Timestamp</label>
            <Controller
              name="timestamp"
              control={control}
              render={({ field: { value, onChange } }) => (
                <Calendar
                  id="mask"
                  value={value ? new Date(value) : undefined}
                  onChange={(e) => onChange((e.value as Date).toISOString())}
                  mask="9999-99-99"
                  dateFormat="yy-mm-dd"
                  showIcon
                />
              )}
            />
          </div>
        </div>
      </form>
    </div>
  );
};

export default AddVideosForm;
