import { StatusBadge } from 'components/common';
import { useRoadmapCategorization } from 'domain/roadmap/hooks';
import { RoadMapUpdate, RoadmapCategory } from 'domain/roadmap/types';
import { editableRoadMapSelector } from 'features/roadmap/reducers/roadmap';
import { Dropdown } from 'primereact/dropdown';
import { Control, Controller } from 'react-hook-form';
import { useAppSelector } from 'store';

import styles from '../RoadMapDetailForm.module.scss';

interface CategoryFieldProps {
  viewOnly: boolean;
  control: Control<RoadMapUpdate, unknown>;
}

const getColor = (value: number | undefined) => {
  switch (value) {
    case 0:
      return 'lightRed';
    case 1:
      return 'yellow';
    case 2:
      return 'lightGreen';
    case 3:
      return 'green';
    case 4:
      return 'red';
    default:
      return 'grey';
  }
};

const renderCategory = (value: number | undefined, categories: RoadmapCategory[]) => {
  const category = categories.find((c) => c.value === value);
  return category && <StatusBadge color={getColor(value)}>{category.label}</StatusBadge>;
};

const CategoryField = ({ viewOnly, control }: CategoryFieldProps) => {
  const editableRoadMap = useAppSelector(editableRoadMapSelector);
  const { data } = useRoadmapCategorization();
  return (
    <Controller
      name="category"
      control={control}
      render={({ field: { value, onChange } }) => (
        <div className={styles.field}>
          <span>Category</span>
          {viewOnly ? (
            <p className={styles.readonlyField}>{renderCategory(editableRoadMap.category, data ?? [])}</p>
          ) : (
            <Dropdown value={value} onChange={onChange} options={data} />
          )}
        </div>
      )}
    />
  );
};

export default CategoryField;
