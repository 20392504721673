import { Skeleton } from 'primereact/skeleton';

import styles from './CardSkeleton.module.scss';

const CardSkeleton = () => (
  <div className={`${styles.skeleton} p-p-4`}>
    <div className="p-d-flex p-mb-2">
      <Skeleton width="100%" height="4rem" />
    </div>
    <div className="p-mb-1">
      <Skeleton width="100%" height="1rem" className="p-mb-1" />
      <Skeleton width="100%" height="1rem" className="p-mb-1" />
    </div>
    <Skeleton width="100%" height="200px" />
    <div className="p-d-flex p-jc-end p-mt-3">
      <Skeleton width="5rem" height="3rem" />
    </div>
  </div>
);

export default CardSkeleton;
