/* eslint-disable max-lines */
import { Button, Checkbox, RadioButton, Tooltip } from 'components/common';
import { useProductsSimple } from 'features/admin/hooks';
import { isInternalSelector } from 'features/auth/reducer/auth';
import {
  addFilter,
  clearFilters,
  filterListSelector,
  filtersSelector,
  filtersValueSelector,
  removeFilter,
  setFilter,
  setFilterList,
  setFilterValue,
  setFilters,
  setShowType,
  setSolutionIndustry,
  showTypeSelector,
  solutionIndustrySelector,
} from 'features/products/reducers/solutionSlice';
import {
  Filter,
  FilterChild,
  FilterGroup,
  FilterItem,
  FilterList,
  Filters,
  Option,
  ProductSimple,
} from 'features/products/types';
import { createFilters, extractFilters } from 'features/products/utils';
import { Accordion, AccordionTab } from 'primereact/accordion';
import { Button as PrimeButton } from 'primereact/button';
import { Divider } from 'primereact/divider';
import { InputTextarea } from 'primereact/inputtextarea';
import { OverlayPanel } from 'primereact/overlaypanel';
import { ScrollPanel } from 'primereact/scrollpanel';
import { SelectButton } from 'primereact/selectbutton';
import { MouseEvent, memo, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { BASE_API_URL } from 'services/api';
import pdf from 'services/pdf';
import { useAppDispatch, useAppSelector } from 'store';
import { capitalizeFirstLetter } from 'utils/string';
import { v4 as uuidv4 } from 'uuid';

import styles from './ProductFilter.module.scss';

function getFilterGroupValues(filterList: FilterList, filterGroup: FilterGroup): FilterItem[] {
  switch (filterGroup) {
    case FilterGroup.BENEFIT:
      return filterList.benefits.reduce((a, b) => a.concat(b.values), [] as FilterItem[]);
    case FilterGroup.BENEFIT_GROUP:
      return filterList.benefits.map(({ id, group }) => ({ id, value: group }));
    case FilterGroup.COUNTRY:
      return filterList.countries.reduce((a, b) => a.concat(b.values), [] as FilterItem[]);
    case FilterGroup.INDUSTRY:
      return filterList.industries.map(({ id, group }) => ({ id, value: group }));
    case FilterGroup.INDUSTRY_AREA:
      return filterList.industries.reduce((a, b) => a.concat(b.values), [] as FilterItem[]);
    case FilterGroup.PLANT_LIFECYCLE:
      return filterList.plantLifecycleManagement.reduce((a, b) => a.concat(b.values), [] as FilterItem[]);
    case FilterGroup.PLANT_LIFECYCLE_GROUP:
      return filterList.plantLifecycleManagement.map(({ id, group }) => ({ id, value: group }));
    case FilterGroup.PLATFORM:
      return filterList.platform;
    case FilterGroup.PRODUCT_LINE:
      return filterList.groups.map(({ id, group }) => ({ id, value: group }));
    case FilterGroup.PRODUCT_GROUP:
      return filterList.groups.reduce((a, b) => a.concat(b.values), [] as FilterItem[]);
    case FilterGroup.REGION:
      return filterList.countries.map(({ id, group }) => ({ id, value: group }));
    // case FilterGroup.SOLUTION_TYPE:
    //   return filterList.solutionType;
    // case FilterGroup.STATUS:
    //   return filterList.status;
    case FilterGroup.USER_PERSONA:
      return filterList.userPersonas;
    case FilterGroup.PORTFOLIO:
      return filterList.portfolios;
    default:
      return [];
  }
}
interface ProductFilterProps {
  filteredProducts: ProductSimple[];
}

const ProductFilter = ({ filteredProducts }: ProductFilterProps) => {
  const showType = useAppSelector(showTypeSelector);
  const { data: products } = useProductsSimple(showType);
  const industry = useAppSelector(solutionIndustrySelector);
  const { t } = useTranslation();
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const op = useRef<OverlayPanel>(null);
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const textAreaRef = useRef<any>(null);
  const [sharedQuery, setSharedQuery] = useState('');
  const [isLoadingExport, setIsLoadingExport] = useState(false);
  const isInternal = useAppSelector(isInternalSelector);
  const dispatch = useAppDispatch();
  const filterList = useAppSelector(filterListSelector);
  const filters = useAppSelector(filtersSelector);
  const filtersValue = useAppSelector(filtersValueSelector);

  useEffect(() => {
    if (products) {
      dispatch(setFilterList(extractFilters(products)));
    }
  }, [dispatch, products]);

  useEffect(() => {
    if (filterList) {
      dispatch(setFilterValue(createFilters(filterList, isInternal)));
    }
  }, [dispatch, filterList, isInternal]);

  const filterExists = useCallback(
    (id: number, group: FilterGroup) => {
      return filters.some((f) => f.id === id && f.group === group);
    },
    [filters],
  );

  const toggleFilter = useCallback(
    (
      id: number,
      name: string,
      group: FilterGroup,
      parentId: number | null = null,
      parentGroup: FilterGroup | null = null,
    ) => {
      if (filterExists(id, group)) {
        dispatch(removeFilter({ id, group }));
        if (parentId && parentGroup) {
          dispatch(removeFilter({ id: parentId, group: parentGroup }));
        }
      } else {
        dispatch(addFilter({ id, name, group }));
      }
    },
    [dispatch, filterExists],
  );

  const toggleParentFilter = useCallback(
    (node: FilterChild, group: FilterGroup) => {
      if (filterExists(node.id, group)) {
        dispatch(removeFilter({ id: node.id, group }));
        node.values.forEach(({ id }) => dispatch(removeFilter({ id, group: node.group })));
      } else {
        dispatch(addFilter({ id: node.id, name: node.label, group }));
        node.values.forEach(({ id, value }) => {
          if (!filterExists(id, node.group)) {
            dispatch(addFilter({ id, name: value, group: node.group }));
          }
        });
      }
    },
    [dispatch, filterExists],
  );

  useEffect(() => {
    const queryFilters = searchParams.getAll('q');
    if (queryFilters.length && !filters.length && filterList) {
      const queryObject = [] as Filter[];
      queryFilters.forEach((filter) => {
        const [idString, groupString] = filter.split(';');
        const id = parseInt(idString, 10);
        const group = parseInt(groupString, 10) as FilterGroup;

        const values = getFilterGroupValues(filterList, group);

        const item = values.find((value) => value.id === id);
        if (item) {
          queryObject.push({ id, group, name: item.value } as Filter);
        }
      });
      if (queryObject.length) {
        navigate('/');
        dispatch(setFilters(queryObject));
      }
    }
  }, [dispatch, filterList, filters.length, navigate, searchParams]);

  const shareFilters = useCallback(
    (e: MouseEvent<HTMLButtonElement, globalThis.MouseEvent>) => {
      const query = filters.map((f) => `q=${f.id};${f.group}`).join('&');
      const url = `${window.location.origin}?${query}`;
      if (url.length > 2000) {
        toast.error(`${t('general.too-many-filters')}.${t('general.max-size-has-been-exceeded')}`);
        return;
      }
      setSharedQuery(url);
      op.current?.toggle(e, this);
    },
    [filters, t],
  );

  const downloadSaleableProducts = useCallback(async () => {
    setIsLoadingExport(true);
    const ids = filteredProducts.filter((p) => p.saleable).map(({ idProduct }) => idProduct);

    if (!ids) {
      toast.warning('No saleable products available for given portfolio');
      return;
    }
    try {
      const portf = Object.values(filters).find((f) => f.group === FilterGroup.PORTFOLIO);
      const title = portf?.name ?? 'Saleable Products';
      await pdf.downloadPDFAsync(`${BASE_API_URL}/pdf/saleable-solutions`, title, { title, ids });
      toast.success('PDF downloaded successfully!');
    } catch {
      toast.error('Error downloading PDF!');
    } finally {
      setIsLoadingExport(false);
    }
  }, [filteredProducts, filters]);

  const renderCheckboxes = (
    { values, group }: Filters,
    parentGroup: FilterGroup | null = null,
    parentId: number | null = null,
  ) =>
    values.map(({ id, value, tooltip }) => {
      const tooltipId = uuidv4().split('-')[0];
      return (
        value && (
          <div key={value} className="p-col-12 p-md-12">
            <Tooltip
              target={`[id='${tooltipId}']`}
              mouseTrack
              mouseTrackLeft={10}
              content={tooltip}
              className={styles.tooltip}
            />
            <div id={tooltipId} className={`field-checkbox ${styles.checkbox}`}>
              <Checkbox
                inputId={value}
                name={value}
                value={value}
                checked={filterExists(id, group)}
                onChange={() => toggleFilter(id, value, group, parentId, parentGroup)}
              />
              <label htmlFor={value} className="p-checkbox-label">
                {capitalizeFirstLetter(value)}
              </label>
            </div>
          </div>
        )
      );
    });

  const renderCheckboxGroup = ({ group, children }: Filters) => (
    <div className="p-col-12 p-md-12">
      {children?.map((node, index) => {
        const hasChildren = !!node.values.length;
        return (
          <div key={node.label} className="p-col-12 p-md-12">
            <Tooltip
              target={`#g${group}-p-${index}`}
              mouseTrack
              mouseTrackLeft={10}
              content={node.label}
              className="tooltip"
            />
            <div id={`g${group}-p-${index}`} className={`field-checkbox ${styles.checkbox}`}>
              <Checkbox
                inputId={node.label}
                name={node.label}
                value={node.label}
                checked={filterExists(node.id, group)}
                onChange={() =>
                  hasChildren ? toggleParentFilter(node, group) : toggleFilter(node.id, node.label, node.group)
                }
              />
              <label htmlFor={node.label}>{node.label}</label>
            </div>
            <div style={{ marginLeft: 20 }}>{renderCheckboxes(node, group, node.id)}</div>
          </div>
        );
      })}
    </div>
  );

  const renderRadioButtonGroup = ({ group, values }: Filters) => (
    <div className="p-col-12 p-md-12">
      {values.map(({ id, value }) => (
        <div key={value} className="field-radiobutton p-col-12 p-md-12">
          <RadioButton
            value={value}
            name={value}
            onChange={() => dispatch(setFilter({ id, name: value, group }))}
            checked={filterExists(id, group)}
          />
          <label>{value}</label>
        </div>
      ))}
    </div>
  );

  const renderOptions = (options?: Option[]) =>
    options?.length ? (
      <div className={styles.radiobuttonContainer}>
        {options.map((option) => (
          <div key={option.value} className={`field-radiobutton ${styles.radiobutton}`}>
            <RadioButton
              value={option.value}
              name={option.value}
              onChange={() => dispatch(setSolutionIndustry(option.value))}
              checked={industry === option.value}
            />
            <label htmlFor={option.value}>{capitalizeFirstLetter(option.value)}</label>
          </div>
        ))}
      </div>
    ) : null;

  const handleClickCopy = useCallback(() => {
    navigator.clipboard.writeText(sharedQuery);
    textAreaRef.current?.focus();
    textAreaRef.current?.select();
    toast.success(t('general.copied-to-clipboard'));
  }, [sharedQuery, t]);

  const renderFilterTitle = useMemo(
    () => (
      <>
        <div className={styles.container}>
          {filters.length ? (
            <div className={styles.filterButtons}>
              <PrimeButton
                className={`p-button-outlined p-button-danger ${styles.clearButton}`}
                label={capitalizeFirstLetter(t('general.clear-filters'))}
                onClick={() => dispatch(clearFilters())}
                icon="pi pi-times"
              />
              <PrimeButton
                className="p-button-outlined"
                label={capitalizeFirstLetter(t('general.share-filters'))}
                onClick={shareFilters}
                icon="pi pi-send"
              />
            </div>
          ) : null}
          <div className={styles.filterCount}>
            <p>{t('products.showing-count-applications', { count: filteredProducts.length })}</p>
          </div>
        </div>
        <Divider align="center">
          <div className="p-d-inline-flex p-ai-center">
            <i className="pi pi-filter mr-2" />
            <span className="ml-1">
              <b>{capitalizeFirstLetter(t('general.filters'))}</b>
            </span>
          </div>
        </Divider>
        <OverlayPanel ref={op} showCloseIcon id="overlay_panel" style={{ width: '450px' }}>
          <>
            <ScrollPanel className={styles.queryScrollPanel}>
              <InputTextarea autoCorrect="false" ref={textAreaRef} rows={3} cols={100} value={sharedQuery} autoResize />
            </ScrollPanel>
            <Button
              className="p-mr-1 p-button-link"
              label={capitalizeFirstLetter(t('general.copy-url'))}
              onClick={handleClickCopy}
              icon="pi pi-copy"
            />
          </>
        </OverlayPanel>
      </>
    ),
    [dispatch, filteredProducts.length, filters.length, handleClickCopy, shareFilters, sharedQuery, t],
  );

  const renderChildren = (filterValue: Filters) =>
    filterValue.children ? renderCheckboxGroup(filterValue) : renderCheckboxes(filterValue);

  return (
    <div>
      {renderFilterTitle}
      <ScrollPanel className={`${styles.customBar} ${filters.length ? styles.hasFilter : null}`}>
        {isInternal && (
          <div className={`${styles.showTypeContainer}`}>
            <SelectButton
              value={showType}
              options={['Show Add-ons', 'Show Solutions']}
              onChange={(e) => dispatch(setShowType(e.value.length ? e.value : ['Show Solutions']))}
              multiple
              unselectable
            />
          </div>
        )}
        {filtersValue.map((filterValue) =>
          filterValue.values.length || filterValue.children?.length ? (
            <Accordion key={filterValue.label} multiple>
              <AccordionTab
                headerClassName={styles.accordion}
                header={<h5>{capitalizeFirstLetter(filterValue.label)}</h5>}
              >
                {renderOptions(filterValue.options)}
                {filterValue.singleSelect ? renderRadioButtonGroup(filterValue) : renderChildren(filterValue)}
                {filterValue.group === FilterGroup.PORTFOLIO && (
                  <Button
                    className="mt-2"
                    disabled={!Object.values(filters).some((f) => f.group === FilterGroup.PORTFOLIO)}
                    tooltip={
                      Object.values(filters).some((f) => f.group === FilterGroup.PORTFOLIO)
                        ? 'Export Saleable Products to PDF'
                        : 'Select at least one portfolio to enable export'
                    }
                    tooltipOptions={{ position: 'top', showOnDisabled: true }}
                    label="Export Saleable Products"
                    onClick={downloadSaleableProducts}
                    loading={isLoadingExport}
                    type="button"
                    icon="pi pi-file-pdf"
                  />
                )}
              </AccordionTab>
            </Accordion>
          ) : null,
        )}
      </ScrollPanel>
    </div>
  );
};

export default memo(ProductFilter);
