import { Industries, ProductSimple } from './product.interfaces';

export enum FilterGroup {
  // STATUS = 1,
  INDUSTRY = 2,
  INDUSTRY_AREA = 3,
  BENEFIT_GROUP = 4,
  BENEFIT = 5,
  PRODUCT_GROUP = 6,
  PRODUCT_LINE = 7,
  // SOLUTION_TYPE = 8,
  BUSINESS_MODEL = 9,
  PLANT_LIFECYCLE = 11,
  PLANT_LIFECYCLE_GROUP = 12,
  USER_PERSONA = 13,
  PLATFORM = 14,
  COUNTRY = 15,
  REGION = 16,
  PORTFOLIO = 17,
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export type Func = (product: ProductSimple, value: number, arg2?: any) => boolean;

export type DropDownProps<T> = {
  name: string;
  code: T;
};

export type Filter = {
  id: number;
  name: string;
  group: FilterGroup;
};

export type FilterItem = {
  id: number;
  value: string;
  tooltip?: string;
};

export type FilterGroupItem<T = FilterItem> = {
  id: number;
  group: string;
  values: T[];
};

export type IndustryFilterGroups = FilterGroupItem<IndustryFilterValue>;

export interface FilterList {
  // status: FilterItem[];
  platform: FilterItem[];
  industries: IndustryFilterGroups[];
  businessModel: FilterItem[];
  plantLifecycleManagement: FilterGroupItem[];
  benefits: FilterGroupItem[];
  groups: FilterGroupItem[];
  countries: FilterGroupItem[];
  userPersonas: FilterItem[];
  portfolios: FilterItem[];
}

export interface IndustryFilterValue extends FilterItem {
  order: number;
}

export type Option = {
  value: Industries;
};

export interface FilterChild extends Filters {
  id: number;
}

export interface Filters {
  label: string;
  values: FilterItem[];
  group: FilterGroup;
  children?: FilterChild[];
  options?: Option[];
  singleSelect?: boolean;
}

export type ShowType = 'Show Add-ons' | 'Show Solutions';
