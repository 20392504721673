import { Button, MessageCard } from 'components/common';
import { RoadmapStatusItem } from 'domain/roadmap/types';
import { userSelector } from 'features/auth/reducer/auth';
import { editableRoadMapSelector, setEditableRoadmap } from 'features/roadmap/reducers/roadmap';
import { InputTextarea } from 'primereact/inputtextarea';
import { OverlayPanel } from 'primereact/overlaypanel';
import { useState } from 'react';
import { useAppDispatch, useAppSelector } from 'store';
import { canEditRoadmap, isQaSpecialist } from 'utils/permissions';

import styles from './CommentSection.module.scss';

interface CommentSectionProps {
  op: React.RefObject<OverlayPanel>;
  statusItemId: number | undefined;
}

const CommentSection = ({ op, statusItemId }: CommentSectionProps) => {
  const [newComment, setNewComment] = useState('');
  const editableRoadMap = useAppSelector(editableRoadMapSelector);
  const dispatch = useAppDispatch();

  const statusItem = editableRoadMap.type?.roadmapStatus
    ?.flatMap((s) => s.roadmapStatusItems)
    .find((i) => i.idRoadmapStatusItem === statusItemId);

  const user = useAppSelector(userSelector);

  function updateEditableStatus(newEditableStatus: RoadmapStatusItem) {
    const roadmapStatus = (editableRoadMap.type?.roadmapStatus ?? [])?.map((status) => ({
      ...status,
      roadmapStatusItems: status.roadmapStatusItems.map((item) =>
        item.idRoadmapStatusItem === statusItem?.idRoadmapStatusItem ? newEditableStatus : item,
      ),
    }));
    dispatch(
      setEditableRoadmap({
        ...editableRoadMap,
        type: editableRoadMap.type ? { ...editableRoadMap.type, roadmapStatus } : null,
      }),
    );
  }

  const addComment = () => {
    if (!newComment) return;

    if (statusItem && user) {
      const newEditableStatus = {
        ...statusItem,
        comments: [
          ...(statusItem.comments ?? []),
          {
            comment: newComment,
            idComment: ((statusItem.comments?.length ?? 0) + 1) * -1,
            idUser: user.idUser,
            timestamp: new Date().toISOString(),
            user,
          },
        ],
        edited: true,
      };
      updateEditableStatus(newEditableStatus);
      setNewComment('');
    }
  };

  const onEditComment = (value: string, idComment: number) => {
    if (statusItem) {
      const newEditableStatus = {
        ...statusItem,
        comments: statusItem.comments?.map((comment) =>
          comment.idComment === idComment
            ? {
                ...comment,
                comment: value,
                edited: idComment > 0, // if idComment is negative, it's a new comment
              }
            : comment,
        ),
        edited: true,
      };
      updateEditableStatus(newEditableStatus);
    }
  };

  const onDeleteComment = (idComment: number) => {
    if (statusItem) {
      const newEditableStatus = {
        ...statusItem,
        comments: statusItem.comments?.filter((comment) => comment.idComment !== idComment) ?? [],
        edited: true,
      };
      updateEditableStatus(newEditableStatus);
    }
  };
  return (
    <OverlayPanel
      ref={op}
      showCloseIcon
      dismissable
      id="overlay_panel"
      style={{ width: '450px' }}
      className="overlaypanel-demo"
    >
      <div className={styles.overlayContainer}>
        <ul>
          {statusItem?.comments?.map((comment) => (
            <MessageCard
              key={comment.idComment}
              comment={comment}
              isOwnedByUser={comment.idUser === user?.idUser}
              onEdit={onEditComment}
              onDelete={onDeleteComment}
            />
          ))}
        </ul>
        <InputTextarea
          disabled={!(canEditRoadmap || isQaSpecialist(user, editableRoadMap.product))}
          rows={2}
          maxLength={300}
          value={newComment}
          onChange={(e) => setNewComment(e.target.value)}
          autoResize
        />
        <div className={styles.overlayButton}>
          <Button
            disabled={!(canEditRoadmap || isQaSpecialist(user, editableRoadMap.product))}
            disabledTooltip={
              <div>
                <p>Adding comment requires one the following permissions:</p>
                <ul>
                  <li>
                    <b>Admin</b>
                  </li>
                  <li>
                    <b>Technical Product Owner</b>
                  </li>
                  <li>
                    <b>Head of Technology</b>
                  </li>
                  <li>
                    <b>QA specialist</b>
                  </li>
                </ul>
              </div>
            }
            label="Add Comment"
            onClick={addComment}
          />
        </div>
      </div>
    </OverlayPanel>
  );
};

export default CommentSection;
