export function convertMinutesToTimeFormat(input: number): string {
  let hours = 0;
  let minutes = input;
  if (minutes > 60) {
    const extraHours = Math.floor(minutes / 60);
    hours += extraHours;
    minutes -= extraHours * 60;
  }

  // Calculate the total hours and minutes
  const totalHours = hours + Math.floor(minutes / 60);
  const totalMinutes = minutes % 60;

  // Format the output string
  let output = '';
  if (totalHours > 0) {
    output += `${totalHours}h`;
  }
  if (totalMinutes > 0) {
    output += ` ${totalMinutes}m`;
  }

  return output.trim();
}

function convertStringTimeFormat(input: string): string {
  let hours = 0;
  let minutes = 0;
  if (input.includes('h')) {
    const [hour, minute] = input.split('h');
    hours = Number.parseFloat(hour.replace(',', '.').replace(/[^\d.,]/g, ''));
    minutes = Number.parseInt(minute.replace(/\D/g, '') || '0', 10);
  } else {
    minutes = Number.parseInt(input.replace(/\D/g, ''), 10);
  }

  minutes += hours * 60;

  return convertMinutesToTimeFormat(minutes);
}

export function convertTimeFormat(input: string): string {
  if (input.includes('h') || input.includes('m')) {
    return convertStringTimeFormat(input);
  }
  const number = parseFloat(input.replace(',', '.'));

  if (Number.isNaN(number)) {
    return '';
  }

  const hours = Math.floor(number);
  const minutes = Math.round((number - hours) * 60);

  if (hours > 0 && minutes > 0) {
    return `${hours}h ${minutes}m`;
  }
  if (hours > 0) {
    return `${hours}h`;
  }
  if (minutes > 0) {
    return `${minutes}m`;
  }

  return '';
}

export function getMinutesFromTimeFormat(input: string): number {
  let hours = 0;
  let minutes = 0;
  if (input.includes('h')) {
    const [hour, minute] = input.split('h');
    hours = Number.parseInt(hour.replace(/\D/g, ''), 10);
    minutes = Number.parseInt(minute.replace(/\D/g, '') || '0', 10);
  } else {
    minutes = Number.parseInt(input.replace(/\D/g, ''), 10);
  }

  return minutes + hours * 60;
}
