import { editableProductSelector, setEditableProduct } from 'features/products/reducers/solutionSlice';
import { StreamVideo } from 'features/products/types';
import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';
import { Toolbar } from 'primereact/toolbar';
import { useState } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { toast } from 'react-toastify';
import { useAppDispatch, useAppSelector } from 'store';

import { AddStreamVideosForm } from '../AddStreamVideosForm';
import StreamVideoList from '../StreamVideoList/StreamVideoList';
import styles from './RelatedProductStreamVideos.module.scss';

type RelatedProductStreamVideosProps = {
  videos: StreamVideo[];
};

const initialValue = {
  title: '',
  description: '',
  hyperlink: '',
  timestamp: null,
};

const RelatedProductStreamVideos = ({ videos }: RelatedProductStreamVideosProps) => {
  const editableProduct = useAppSelector(editableProductSelector);
  const dispatch = useAppDispatch();
  const [addDialogVisible, setAddDialogVisible] = useState(false);
  const [isNew, setIsNew] = useState(false);

  const { control, handleSubmit, formState, reset } = useForm<StreamVideo>({
    defaultValues: initialValue,
  });

  const addItem = (value: StreamVideo) => {
    if (editableProduct) {
      dispatch(
        setEditableProduct({ ...editableProduct, streamVideos: [...(editableProduct.streamVideos ?? []), value] }),
      );
    }
  };

  const removeItem = (value: StreamVideo) => {
    if (editableProduct) {
      dispatch(
        setEditableProduct({
          ...editableProduct,
          streamVideos: editableProduct.streamVideos.filter((item) => item.idProductStream !== value.idProductStream),
        }),
      );
    }
  };

  const updateItem = (value: StreamVideo) => {
    if (editableProduct) {
      dispatch(
        setEditableProduct({
          ...editableProduct,
          streamVideos: editableProduct.streamVideos.map((streamVideo) =>
            streamVideo.idProductStream === value.idProductStream ? value : streamVideo,
          ),
        }),
      );
    }
  };

  const handleAddNewVideo = () => {
    reset({
      ...initialValue,
    });
    setIsNew(true);
    setAddDialogVisible(true);
  };

  const handleEditVideo = (value: StreamVideo) => {
    reset(value);
    setIsNew(false);
    setAddDialogVisible(true);
  };

  const onSubmit: SubmitHandler<StreamVideo> = async (payload) => {
    if (videos.some((video) => video.title === payload.title && video.idProductStream !== payload.idProductStream)) {
      toast.error('A stream video with the same title already exists');
      return;
    }
    if (payload.idProductStream != null) {
      updateItem(payload);
    } else {
      addItem({ ...payload, idProductStream: -(Math.max(0, ...videos.map((v) => Math.abs(v.idProductStream))) + 1) });
    }
    reset(initialValue);
    setAddDialogVisible(false);
  };

  const leftToolbarTemplate = () => (
    <Button
      label="Add"
      icon="pi pi-plus"
      className="p-button-success mr-2 p-mb-2"
      aria-haspopup
      aria-controls="overlay_panel"
      onClick={handleAddNewVideo}
    />
  );

  const renderHeader = () => (
    <div className={styles.headerContainer}>
      <span>{isNew ? 'Add' : 'Edit'} Stream Videos</span>
    </div>
  );

  const itemDialogFooter = (
    <div className={styles.bottomButton}>
      <Button
        label="Close"
        icon="pi pi-times"
        className="p-button-outlined"
        onClick={() => setAddDialogVisible(false)}
      />
      <Button
        label={isNew ? 'Add' : 'Save'}
        icon={`pi ${isNew ? 'pi-plus' : 'pi-check'}`}
        onClick={handleSubmit(onSubmit)}
      />
    </div>
  );

  return (
    <>
      <Toolbar className="p-mb-4 p-toolbar" left={leftToolbarTemplate} />
      <StreamVideoList streamVideos={videos} readonly={false} onRemove={removeItem} onEdit={handleEditVideo} />
      {addDialogVisible && (
        <Dialog
          visible={addDialogVisible}
          className={styles.dialog}
          header={renderHeader}
          modal
          footer={itemDialogFooter}
          onHide={() => setAddDialogVisible(false)}
        >
          <AddStreamVideosForm control={control} errors={formState.errors} />
        </Dialog>
      )}
    </>
  );
};

export default RelatedProductStreamVideos;
