import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { MaturityModelOption, MaturityOptionDetail } from 'domain/maturityModel/types';
import type { RootState } from 'store/rootReducer';

interface ModelOptionState {
  editableModelOption: MaturityModelOption | null;
}

const initialState: ModelOptionState = {
  editableModelOption: null,
};

export const maturityModelOptionSlice = createSlice({
  name: 'maturity-model/options',
  initialState,
  reducers: {
    setMaturityModelOption: (state, action: PayloadAction<MaturityModelOption | null>) => {
      state.editableModelOption = action.payload;
    },
    updateMaturityModelOptionDetails: (state, action: PayloadAction<MaturityOptionDetail[]>) => {
      if (state.editableModelOption) {
        state.editableModelOption.details = action.payload;
      }
    },
  },
});

export const { setMaturityModelOption, updateMaturityModelOptionDetails } = maturityModelOptionSlice.actions;

export const maturityModelOptionSelector = (state: RootState) => state.maturityModelOption.editableModelOption;

export default maturityModelOptionSlice.reducer;
