import { AxiosError } from 'axios';
import { PlantBasicDTO } from 'domain/country/types';
import { useQuery } from 'react-query';

import { getPlantStatuses, getPlants, getPlantsBasic, getPlantsWithProducts } from '../api';
import { Plant, PlantDTO, PlantStatusDTO } from '../types';

export const USE_PLANTS_KEY = 'usePlants';
export const USE_PLANTS_BASIC_KEY = 'usePlantsBasic';
export const USE_PLANTS_WITH_PRODUCTS_KEY = 'usePlantsWithProducts';
export const USE_PLANT_STATUSES_KEY = 'usePlantStatuses';

export function usePlants(enabled = true) {
  return useQuery<PlantDTO[], AxiosError>(USE_PLANTS_KEY, getPlants, {
    staleTime: 1000 * 60 * 5,
    enabled,
  });
}

export function usePlantsBasic(enabled = true) {
  return useQuery<PlantBasicDTO[], AxiosError>(USE_PLANTS_BASIC_KEY, getPlantsBasic, {
    staleTime: 1000 * 60 * 5,
    enabled,
  });
}

export function usePlantsWithProducts() {
  return useQuery<Plant[], AxiosError>(USE_PLANTS_WITH_PRODUCTS_KEY, getPlantsWithProducts, {
    staleTime: 1000 * 60 * 5,
  });
}

export function usePlantStatuses() {
  return useQuery<PlantStatusDTO[], AxiosError>(USE_PLANT_STATUSES_KEY, getPlantStatuses, {
    staleTime: 1000 * 60 * 60,
  });
}
