import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { PortfolioWithProductsDTO } from 'domain/productPortfolio/types';
import { UserBrief } from 'domain/user/types';
import { TreeSelectSelectionKeysType } from 'primereact/treeselect';
import type { RootState } from 'store/rootReducer';

import { GroupBy, ProductStage, ViewMode } from '../types';

export interface MaturityFilters {
  selectedIndustries: TreeSelectSelectionKeysType | null;
  selectedPortfolios: PortfolioWithProductsDTO[];
  selectedProductOwners: UserBrief[];
  selectedProductManagers: UserBrief[];
  selectedTechManagers: UserBrief[];
  searchText: string;
  groupBy: GroupBy;
  productStage: ProductStage[];
  saleableSolutions: boolean;
  inRelease: boolean;
}

interface SliceState extends MaturityFilters {
  viewMode: ViewMode;
}

const initialState: SliceState = {
  selectedIndustries: null,
  searchText: '',
  selectedPortfolios: [],
  selectedProductOwners: [],
  selectedProductManagers: [],
  selectedTechManagers: [],
  viewMode: 'Summary',
  productStage: [],
  groupBy: 'Grouping by Average',
  saleableSolutions: false,
  inRelease: false,
};

export const maturityFilterSlice = createSlice({
  name: 'maturity-model/filters',
  initialState,
  reducers: {
    setSelectedIndustries: (state, action: PayloadAction<TreeSelectSelectionKeysType | null>) => {
      state.selectedIndustries = action.payload;
    },
    setSelectedPortfolios: (state, action: PayloadAction<PortfolioWithProductsDTO[]>) => {
      state.selectedPortfolios = action.payload;
    },
    setSelectedProductOwners: (state, action: PayloadAction<UserBrief[]>) => {
      state.selectedProductOwners = action.payload;
    },
    setSelectedProductManagers: (state, action: PayloadAction<UserBrief[]>) => {
      state.selectedProductManagers = action.payload;
    },
    setSelectedTechManagers: (state, action: PayloadAction<UserBrief[]>) => {
      state.selectedTechManagers = action.payload;
    },
    setSearchText: (state, action: PayloadAction<string>) => {
      state.searchText = action.payload;
    },
    setViewMode: (state, action: PayloadAction<ViewMode>) => {
      state.viewMode = action.payload;
    },
    setGroupBy: (state, action: PayloadAction<GroupBy>) => {
      state.groupBy = action.payload;
    },
    setProductStage: (state, action: PayloadAction<ProductStage[]>) => {
      state.productStage = action.payload;
    },
    setSaleableSolutions: (state, action: PayloadAction<boolean>) => {
      state.saleableSolutions = action.payload;
    },
    setInRelease: (state, action: PayloadAction<boolean>) => {
      state.inRelease = action.payload;
    },
  },
});

export const {
  setSelectedIndustries,
  setSearchText,
  setSelectedPortfolios,
  setSelectedProductOwners,
  setSelectedProductManagers,
  setSelectedTechManagers,
  setViewMode,
  setGroupBy,
  setProductStage,
  setSaleableSolutions,
  setInRelease,
} = maturityFilterSlice.actions;

export const selectedPortfoliosSelector = (state: RootState) => state.maturityModelFilters.selectedPortfolios;
export const selectedIndustriesSelector = (state: RootState) => state.maturityModelFilters.selectedIndustries;
export const selectedProductOwnersSelector = (state: RootState) => state.maturityModelFilters.selectedProductOwners;
export const selectedProductManagersSelector = (state: RootState) => state.maturityModelFilters.selectedProductManagers;
export const selectedTechManagersSelector = (state: RootState) => state.maturityModelFilters.selectedTechManagers;
export const searchTextSelector = (state: RootState) => state.maturityModelFilters.searchText;
export const viewModeSelector = (state: RootState) => state.maturityModelFilters.viewMode;
export const groupBySelector = (state: RootState) => state.maturityModelFilters.groupBy;
export const productStageSelector = (state: RootState) => state.maturityModelFilters.productStage;
export const saleableSolutionsSelector = (state: RootState) => state.maturityModelFilters.saleableSolutions;
export const inReleaseSelector = (state: RootState) => state.maturityModelFilters.inRelease;
export const maturityFiltersSelector = ({
  maturityModelFilters: {
    selectedIndustries,
    groupBy,
    productStage,
    saleableSolutions,
    inRelease,
    searchText,
    selectedPortfolios,
    selectedProductManagers,
    selectedProductOwners,
    selectedTechManagers,
  },
}: RootState): MaturityFilters => ({
  selectedIndustries,
  groupBy,
  productStage,
  saleableSolutions,
  inRelease,
  searchText,
  selectedPortfolios,
  selectedProductManagers,
  selectedProductOwners,
  selectedTechManagers,
});

export default maturityFilterSlice.reducer;
