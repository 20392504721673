import { PayloadAction, createAsyncThunk, createSlice, isAnyOf } from '@reduxjs/toolkit';
import axios from 'axios';
import storage from 'services/storage';
import type { RootState } from 'store/rootReducer';

import { adLogin, checkIsInternal } from '../api';
import { LoginRequest, Role, User } from '../types';

interface AuthState {
  token: string | null;
  user: User | null;
  isLoading: boolean;
  isInternal: boolean;
  error: unknown;
}

let token = storage.getItem<string>('token');

const user = storage.getItem<User>('user');

const initialState: AuthState = {
  ...{ token: token || null },
  user: user || null,
  isLoading: false,
  error: null,
  isInternal: false,
};

export const loginAd = createAsyncThunk('auth/login-ad', async ({ accessToken }: LoginRequest, thunkAPI) => {
  try {
    const { data } = await adLogin(accessToken);
    storage.setItem('token', data.token);
    storage.setItem('user', data);
    return data;
  } catch (error) {
    if (axios.isAxiosError(error)) {
      if (error.response?.status === 401) {
        return thunkAPI.rejectWithValue(401);
      }
      return thunkAPI.rejectWithValue(error.response?.data?.Message ?? error.response?.statusText);
    }
    throw error;
  }
});

export const validateInternal = createAsyncThunk('auth/validate-internal', async () => {
  try {
    const { data } = await checkIsInternal();
    return data;
  } catch {
    return false;
  }
});

const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    clearState: (state) => {
      state.isLoading = false;
      state.error = null;
    },
    setUser: (state, action: PayloadAction<User>) => {
      state.user = action.payload;
    },
    logout: (state) => {
      storage.clearStorage();
      token = null;
      state.token = null;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(validateInternal.fulfilled, (state, action) => {
      state.isLoading = false;
      state.isInternal = action.payload;
    });
    builder.addCase(loginAd.fulfilled, (state, action) => {
      state.isLoading = false;
      state.token = action.payload.token;
      state.user = action.payload;
    });
    builder.addMatcher(isAnyOf(loginAd.rejected, validateInternal.rejected), (state, action) => {
      state.isLoading = false;
      state.error = action.payload;
    });
    builder.addMatcher(isAnyOf(loginAd.pending, validateInternal.pending), (state) => {
      state.isLoading = true;
      state.error = null;
    });
  },
});

export const { clearState, setUser, logout } = authSlice.actions;

export const userSelector = (state: RootState) => state.auth.user;
export const isAdminSelector = (state: RootState): boolean =>
  state.auth.user?.role === Role.Admin || (state.auth.user?.roles?.some((role) => role === Role.Admin) ?? false);
export const isLoggedInSelector = (state: RootState) => !!state.auth.token;
export const isLoadingSelector = (state: RootState) => state.auth.isLoading;
export const isInternalSelector = (state: RootState) => state.auth.isInternal;
export const authErrorSelector = (state: RootState) => state.auth.error;

export default authSlice.reducer;
