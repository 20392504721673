import { HTMLAttributes, ReactNode } from 'react';

import styles from './StatusBadge.module.scss';

export type StatusBadgeColors =
  | 'blue'
  | 'lightBlue'
  | 'red'
  | 'lightRed'
  | 'lightGreen'
  | 'green'
  | 'lightyellow'
  | 'yellow'
  | 'grey';

interface StatusBadgeProps extends HTMLAttributes<HTMLSpanElement> {
  color: StatusBadgeColors;
  fontSize?: number;
  children: ReactNode;
  breakLine?: boolean;
}

const StatusBadge = ({ color, fontSize = 12, children, breakLine = false, ...rest }: StatusBadgeProps) => {
  return (
    <span
      {...rest}
      style={{ fontSize, whiteSpace: breakLine ? 'normal' : 'nowrap' }}
      className={`${styles.badge} ${styles[color]}`}
    >
      {children}
    </span>
  );
};

StatusBadge.defaultProps = {
  fontSize: 12,
  breakLine: false,
};

export default StatusBadge;
