/* eslint-disable max-lines */
import { AxiosError } from 'axios';
import { Button } from 'components/common';
import { createFollower, createRoadMap, deleteFollower, getRoadmapListById, updateRoadMap } from 'domain/roadmap/API';
import { ROADMAP_FUNDING_KEY, useRoadmapFollowers, useRoadmaps } from 'domain/roadmap/hooks';
import { ROADMAP_TEMPO_REPORT_BY_USER_KEY } from 'domain/roadmap/hooks/useReport';
import { RoadMap, RoadMapUpdate, RoadmapFollower, RoadmapList, RoadmapStatus } from 'domain/roadmap/types';
import { initialValue } from 'features/admin/utils/initialValues';
import { isInternalSelector, isLoggedInSelector, userSelector } from 'features/auth/reducer/auth';
import HistoryTable from 'features/roadmap/components/HistoryTable/HistoryTable';
import OKRsTable from 'features/roadmap/components/OKRsTable/OKRsTable';
import PlanningTab, { PlanningTabRef } from 'features/roadmap/components/PlanningTab/PlanningTab';
import TempoTable from 'features/roadmap/components/TempoTable/TempoTable';
import { editableRoadMapSelector, followingSelector, setEditableRoadmap } from 'features/roadmap/reducers/roadmap';
import { Chip } from 'primereact/chip';
import { Dialog } from 'primereact/dialog';
import { OverlayPanel } from 'primereact/overlaypanel';
import { TabPanel, TabView } from 'primereact/tabview';
import { Dispatch, SetStateAction, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useMutation, useQueryClient } from 'react-query';
import { toast } from 'react-toastify';
import { useAppDispatch, useAppSelector } from 'store';
import { ApiResponseString } from 'types';
import { toLocaleDateTimeString } from 'utils/datetime';

import FundingSourceTable from '../FundingSourceTable/FundingSourceTable';
import RoadMapDetailForm from '../RoadMapDetailForm/RoadMapDetailForm';
import AddFollowerDialog from '../RoadMapDetailForm/components/AddFollowerDialog';
import styles from './RoadMapEditDialog.module.scss';

const onMutateError = (error: AxiosError<{ data: ApiResponseString }>) => {
  toast.error(`An error occurred while saving data! ${error?.response?.data?.data?.message ?? error.message}`, {
    position: 'top-right',
    autoClose: false,
  });
};

type RoadMapEditDialogProps = {
  visible: boolean;
  viewOnly?: boolean;
  setVisible: Dispatch<SetStateAction<boolean>>;
  onSaveCallback?: () => void;
};

const filterRoadmapStatus = (roadmapStatus: RoadmapStatus[]) =>
  roadmapStatus.filter((r) => r.edited || r.roadmapStatusItems.some((s) => s.edited));

const RoadMapEditDialog = ({
  visible,
  setVisible,
  viewOnly = true,
  onSaveCallback = () => null,
}: RoadMapEditDialogProps) => {
  const editableRoadMap = useAppSelector(editableRoadMapSelector);
  const dispatch = useAppDispatch();
  const { control, handleSubmit, watch, setValue, setFocus, setError } = useForm<RoadMapUpdate>({
    defaultValues: editableRoadMap,
  });
  const { t } = useTranslation();
  const [activeIndex, setActiveIndex] = useState(0);
  const { mutateAsync: mutateDeleteFollower } = useMutation(deleteFollower);
  const { data: followers, refetch } = useRoadmapFollowers(editableRoadMap.idRoadmap);
  const [follower, setFollower] = useState<RoadmapFollower | null>();
  const queryClient = useQueryClient();
  const user = useAppSelector(userSelector);
  const isLoggedIn = useAppSelector(isLoggedInSelector);
  const isInternal = useAppSelector(isInternalSelector);
  const { data: roadmaps } = useRoadmaps(isInternal);
  const planningTabRef = useRef<PlanningTabRef | null>(null);
  const op = useRef<OverlayPanel>(null);

  const { mutateAsync: mutateCreateFollower } = useMutation(createFollower);

  const { idRoadmap } = useMemo(() => editableRoadMap, [editableRoadMap]);
  const isCustomerCare = useMemo(
    () => editableRoadMap.type?.customerCare ?? false,
    [editableRoadMap.type?.customerCare],
  );

  const followingIds = useAppSelector(followingSelector);
  const isFollowing = followingIds.some((id) => id === idRoadmap);

  const onMutateSuccess = () => {
    toast.dismiss();
    setVisible(false);
    dispatch(setEditableRoadmap(initialValue));
    toast.success('Data saved successfully');
    queryClient.invalidateQueries([ROADMAP_FUNDING_KEY]);
    queryClient.invalidateQueries([ROADMAP_TEMPO_REPORT_BY_USER_KEY]);
    queryClient.invalidateQueries(['roadmap-tempo']);
    onSaveCallback();
  };

  const onUpdateMutate = {
    onSuccess: (roadmap: RoadMapUpdate) => {
      if (roadmap.idRoadmap) {
        getRoadmapListById(roadmap.idRoadmap).then((dbRoadmap) => {
          queryClient.setQueryData(['roadmaps', true], (oldData: RoadmapList[] | undefined) => {
            return oldData?.map((r) => (r.idRoadmap === roadmap.idRoadmap ? dbRoadmap : r)) ?? [];
          });
        });
      } else {
        queryClient.invalidateQueries(['roadmaps', true]);
      }
      onMutateSuccess();
    },
    onError: onMutateError,
  };

  const onCreateMutate = {
    onSuccess: (roadmap: RoadMap) => {
      if (roadmap.idRoadmap) {
        getRoadmapListById(roadmap.idRoadmap).then((dbRoadmap) => {
          queryClient.setQueryData(['roadmaps', true], (oldData: RoadmapList[] | undefined) => {
            return oldData ? [...oldData, dbRoadmap] : [];
          });
        });
      } else {
        queryClient.invalidateQueries(['roadmaps', true]);
      }
      onMutateSuccess();
    },
    onError: onMutateError,
  };

  const { mutate: mutateUpdate, isLoading: isLoadingUpdate } = useMutation(updateRoadMap, onUpdateMutate);

  const { mutate: mutateCreate, isLoading: isLoadingCreate } = useMutation(createRoadMap, onCreateMutate);

  const isSubmitting = isLoadingUpdate || isLoadingCreate;

  useEffect(() => {
    setFollower(followers?.find((f) => f.email === user?.email) ?? null);
  }, [followers, user?.email]);

  useEffect(() => {
    setValue('participants', editableRoadMap.participants);
  }, [editableRoadMap.participants, setValue]);

  const validateData = useCallback(
    (data: RoadMapUpdate) => {
      if (!data.item) {
        setActiveIndex(0);
        setTimeout(() => {
          setFocus('item');
          setError('item', {
            message: 'Required',
          });
        }, 500);
        throw new Error('Item is required');
      }
      if (!data.product) {
        setActiveIndex(0);
        setTimeout(() => {
          setFocus('product');
          setError('product', {
            message: 'Required',
          });
        }, 500);
        throw new Error('Product is required');
      }
      if (!data.description) {
        setActiveIndex(0);
        setTimeout(() => {
          setFocus('description');
          setError('description', {
            message: 'Required',
          });
        }, 500);
        throw new Error('Description is required');
      }

      if (
        roadmaps?.some(
          (r) =>
            r.item === data.item && r.idRoadmap !== data.idRoadmap && r.product.idProduct === data.product?.idProduct,
        )
      ) {
        setActiveIndex(0);
        setFocus('item');
        setError('item', {
          message: 'Roadmap item name already exists',
        });
        throw new Error('Roadmap item name already exists');
      }

      if (editableRoadMap.fundingSources.length > 0) {
        const totalPercentage = editableRoadMap.fundingSources.reduce((a, b) => a + b.percentage, 0);
        if (totalPercentage !== 100) {
          setActiveIndex(4);
          throw new Error(`Funding Source Percentage must be equal to 100, current value: ${totalPercentage}`);
        }
      }
    },
    [editableRoadMap.fundingSources, roadmaps, setError, setFocus],
  );

  const onSubmit: SubmitHandler<RoadMapUpdate> = (data) => {
    try {
      validateData(data);
    } catch (e) {
      toast.error(`${e}`);
      return;
    }

    const item: RoadMapUpdate = {
      ...data,
      idProduct: data.product?.idProduct ?? 0,
      type: data.type
        ? { ...data.type, roadmapStatus: filterRoadmapStatus(editableRoadMap.type?.roadmapStatus ?? []) }
        : null,
      status: editableRoadMap.status,
      okrs: editableRoadMap.okrs,
      fundingSources: editableRoadMap.fundingSources,
    };
    if (item.idRoadmap) {
      mutateUpdate(item);
    } else {
      mutateCreate(item);
    }
  };

  const handleFollow = useCallback(
    async (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
      if (!isLoggedIn) {
        op.current?.toggle(e, null);
      } else {
        const item: RoadmapFollower = {
          name: user?.name ?? '',
          email: user?.email ?? '',
          idRoadmapFollower: 0,
          idRoadmap,
        };
        await mutateCreateFollower({ idRoadmap, follower: item });
      }
      refetch();
    },
    [idRoadmap, isLoggedIn, mutateCreateFollower, refetch, user?.email, user?.name],
  );

  const handleUnfollow = useCallback(async () => {
    if (follower) {
      await mutateDeleteFollower(follower.idRoadmapFollower);
      refetch();
    }
  }, [follower, mutateDeleteFollower, refetch]);

  const itemDialogFooter = (
    <div className={styles.bottomButton}>
      {viewOnly ? (
        <Button label="Close" icon="pi pi-times" onClick={() => setVisible(false)} />
      ) : (
        <>
          <Button label="Cancel" icon="pi pi-times" className="p-button-outlined" onClick={() => setVisible(false)} />
          <Button label="Save" loading={isSubmitting} icon="pi pi-check" onClick={handleSubmit(onSubmit)} />
        </>
      )}
    </div>
  );

  const handleShare = useCallback(async () => {
    await navigator.clipboard.writeText(`${window.location.origin}/roadmap?id=${editableRoadMap.idRoadmap}`);
    toast.success('Copied to clipboard!');
  }, [editableRoadMap.idRoadmap]);

  const renderHeader = useMemo(
    () => (
      <div className={styles.headerContainer}>
        {editableRoadMap.idRoadmap > 0 ? (
          <>
            <div>
              <span>{editableRoadMap.item}</span>
              <small style={{ marginLeft: '0.5rem', fontSize: 9 }}>
                Created {editableRoadMap.createdBy && `by ${editableRoadMap.createdBy}`} on{' '}
                {editableRoadMap.createdTimestamp && toLocaleDateTimeString(editableRoadMap.createdTimestamp)}
              </small>
            </div>
            <div>
              <Button label="Share" className="p-button-outlined mr-2" onClick={handleShare} />

              {!follower && (
                <Button
                  label="Follow"
                  loading={isSubmitting}
                  onClick={handleFollow}
                  tooltip="By following this item you will be notified on your email about important changes such as status and version"
                />
              )}
              {follower && isLoggedIn && (
                <Button
                  label="Unfollow"
                  className="p-button-outlined"
                  loading={isSubmitting}
                  onClick={handleUnfollow}
                  tooltip="You will stop receiving notification about this item on your email"
                />
              )}
              {!isLoggedIn && isFollowing && <Chip label="Following" />}
            </div>
          </>
        ) : (
          <span>New Roadmap Item</span>
        )}
      </div>
    ),
    [
      editableRoadMap.createdBy,
      editableRoadMap.createdTimestamp,
      editableRoadMap.idRoadmap,
      editableRoadMap.item,
      follower,
      handleFollow,
      handleShare,
      handleUnfollow,
      isFollowing,
      isLoggedIn,
      isSubmitting,
    ],
  );

  return (
    <Dialog
      visible={visible}
      className={styles.dialog}
      header={renderHeader}
      blockScroll
      modal
      footer={itemDialogFooter}
      onHide={() => setVisible(false)}
    >
      <TabView activeIndex={activeIndex} onTabChange={(e) => setActiveIndex(e.index)}>
        <TabPanel header="Overview">
          <RoadMapDetailForm viewOnly={viewOnly} control={control} watch={watch} setValue={setValue} />
        </TabPanel>
        <TabPanel className="planning-tab" header={<span id="planning-tab">Planning</span>}>
          <PlanningTab ref={planningTabRef} statuses={editableRoadMap.type?.roadmapStatus ?? []} />
        </TabPanel>
        {!isCustomerCare && (
          <TabPanel header={t('products.business-impact')}>
            <OKRsTable okrs={editableRoadMap.okrs ?? []} />
          </TabPanel>
        )}
        <TabPanel header="Funding Source">
          <FundingSourceTable
            year={
              editableRoadMap.version
                ? new Date(editableRoadMap.version?.releaseDate).getFullYear()
                : new Date().getFullYear()
            }
            platform={editableRoadMap.version?.release.platform?.idPlatform ?? 0}
          />
        </TabPanel>
        {isLoggedIn && (
          <TabPanel header="History">
            <HistoryTable roadmapId={editableRoadMap.idRoadmap} />
          </TabPanel>
        )}
        {isLoggedIn && (
          <TabPanel header="Time">
            <TempoTable roadmapId={editableRoadMap.idRoadmap} />
          </TabPanel>
        )}
      </TabView>
      <AddFollowerDialog op={op} idRoadmap={editableRoadMap.idRoadmap} />
    </Dialog>
  );
};

RoadMapEditDialog.defaultProps = {
  onSaveCallback: () => null,
  viewOnly: true,
};

export default RoadMapEditDialog;
