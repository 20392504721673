import { StatusBadge } from 'components/common';
import { approveReleasePm, approveReleasePo } from 'domain/product/api';
import { USE_DETAILED_PRODUCT_KEY } from 'domain/product/hook';
import { userSelector } from 'features/auth/reducer/auth';
import { Product, RelatedIndustry } from 'features/products/types';
import { Column } from 'primereact/column';
import { DataTable } from 'primereact/datatable';
import { ToggleButton } from 'primereact/togglebutton';
import { useCallback, useState } from 'react';
import { useQueryClient } from 'react-query';
import { toast } from 'react-toastify';
import { useAppSelector } from 'store';

import styles from './ManagersSection.module.scss';

interface ManagersSectionProps {
  product: Product;
}

const managerTemplate = (rowData: RelatedIndustry, subject: string) => (
  <>
    <span>{rowData.manager?.name}</span>
    {rowData.manager?.email && (
      <>
        <span> - </span>
        <a
          href={`mailto:${rowData.manager?.email}?subject=${encodeURIComponent(subject)}`}
          className={styles.emailLink}
          target="_blank"
          rel="noreferrer"
        >
          {rowData.manager?.email}
        </a>
      </>
    )}
  </>
);

const ManagersSection = ({ product }: ManagersSectionProps) => {
  const [poApproved, setPoApproved] = useState(product.poApproved);
  const [pmApproved, setPmApproved] = useState(product.pmApproved);
  const user = useAppSelector(userSelector);
  const managers = product.industries.filter((i) => !!i.manager);
  const { productOwner, productManager, technologyManager } = product;
  const queryClient = useQueryClient();

  const handleReleasePo = useCallback(
    async (idProduct: number, status: boolean) => {
      try {
        await approveReleasePo(idProduct, status);
        setPoApproved(status);
        if (status) {
          toast.success('Release approved!');
        } else {
          toast.warning('Release back to pending!');
        }
        queryClient.invalidateQueries(USE_DETAILED_PRODUCT_KEY);
      } catch {
        toast.error('Failed to approve release');
      }
    },
    [queryClient],
  );

  const handleReleasePm = useCallback(
    async (idProduct: number, status: boolean) => {
      try {
        await approveReleasePm(idProduct, status);
        setPmApproved(status);
        if (status) {
          toast.success('Release approved!');
        } else {
          toast.warning('Release back to pending!');
        }
        queryClient.invalidateQueries(USE_DETAILED_PRODUCT_KEY);
      } catch {
        toast.error('Failed to approve release');
      }
    },
    [queryClient],
  );

  if (productOwner || productManager || technologyManager) {
    const subject = `Automation Solution Finder - ${product.product}`;
    return (
      <div className={styles.container}>
        <h4 className={styles.title}>Product Management</h4>
        {productOwner && (
          <div className={styles.listContent}>
            <p className={styles.title}>
              <b>Technical Product Owner</b>: <span>{productOwner.name}</span>
              {productOwner.email && (
                <>
                  <span> - </span>
                  <a
                    href={`mailto:${productOwner.email}?subject=${encodeURIComponent(subject)}`}
                    className={styles.emailLink}
                    target="_blank"
                    rel="noreferrer"
                  >
                    {productOwner.email}
                  </a>
                </>
              )}
            </p>

            <div className="ml-2">
              {user?.idUser === productOwner.idUser ? (
                <ToggleButton
                  className="p-button-sm"
                  checked={poApproved}
                  onChange={(e) => handleReleasePo(product.idProduct, e.value)}
                  onLabel="Release Approved"
                  offLabel="Pending Release"
                  onIcon="pi pi-check"
                  offIcon="pi pi-clock"
                  tooltip={
                    poApproved
                      ? 'This item has been approved for release'
                      : 'This item is pending your approval for release'
                  }
                />
              ) : (
                <StatusBadge color={poApproved ? 'green' : 'yellow'}>
                  {poApproved ? 'released' : 'pending release'}
                </StatusBadge>
              )}
            </div>
          </div>
        )}
        {technologyManager && (
          <div className={styles.listContent}>
            <p className={styles.title}>
              <b>Head of Technology</b>: <span>{technologyManager.name}</span>
              {technologyManager.email && (
                <>
                  <span> - </span>
                  <a
                    href={`mailto:${technologyManager.email}?subject=${encodeURIComponent(subject)}`}
                    className={styles.emailLink}
                    target="_blank"
                    rel="noreferrer"
                  >
                    {technologyManager.email}
                  </a>
                </>
              )}
            </p>
          </div>
        )}
        {productManager && (
          <div className={styles.listContent}>
            <p className={styles.title}>
              <b>Global Product Manager</b>: <span>{productManager.name}</span>
              {productManager.email && (
                <>
                  <span> - </span>
                  <a
                    href={`mailto:${productManager.email}?subject=${encodeURIComponent(subject)}`}
                    className={styles.emailLink}
                    target="_blank"
                    rel="noreferrer"
                  >
                    {productManager.email}
                  </a>
                </>
              )}
            </p>
            <div className="ml-2">
              {user?.idUser === productManager.idUser ? (
                <ToggleButton
                  className="p-button-sm"
                  checked={pmApproved}
                  onChange={(e) => handleReleasePm(product.idProduct, e.value)}
                  onLabel="Release Approved"
                  offLabel="Pending Release"
                  onIcon="pi pi-check"
                  offIcon="pi pi-clock"
                  tooltip={
                    pmApproved
                      ? 'This item has been approved for release'
                      : 'This item is pending your approval for release'
                  }
                />
              ) : (
                <StatusBadge color={pmApproved ? 'green' : 'yellow'}>
                  {pmApproved ? 'released' : 'pending release'}
                </StatusBadge>
              )}
            </div>
          </div>
        )}

        {managers.length > 0 && (
          <DataTable value={managers} stripedRows showGridlines size="small" dataKey="idIndustry">
            <Column field="industry" header="Industry" style={{ width: '15rem' }} />
            <Column header="Manager" field="manager.name" body={(rowData) => managerTemplate(rowData, subject)} />
          </DataTable>
        )}
      </div>
    );
  }
  return null;
};

export default ManagersSection;
