import { Sidebar as PrimeSidebar, SidebarProps } from 'primereact/sidebar';
import { ReactNode } from 'react';

const Sidebar = (
  props: JSX.IntrinsicAttributes &
    JSX.IntrinsicClassAttributes<PrimeSidebar> &
    Readonly<SidebarProps> &
    Readonly<{ children?: ReactNode }>,
) => <PrimeSidebar {...props} />;

export default Sidebar;
