import { AddItemDialog } from 'components/common';
import { useNews } from 'features/admin/hooks';
import { editableProductSelector, setEditableProduct } from 'features/products/reducers/solutionSlice';
import { News, NewsUpdate } from 'features/products/types';
import { Button } from 'primereact/button';
import { Column } from 'primereact/column';
import { Dialog } from 'primereact/dialog';
import { Toolbar } from 'primereact/toolbar';
import { useState } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useAppDispatch, useAppSelector } from 'store';
import { toDateString } from 'utils/datetime';

import { AddNewsForm } from '../AddNewsForm';
import { ProductNewsTable } from '../ProductNewsTable';
import styles from './RelatedProductNews.module.scss';

type RelatedProductNewsProps = {
  news: NewsUpdate[];
};

const initialValue = {
  title: '',
  description: '',
  contentURL: '',
  imageURL: '',
  timestamp: null,
};

const imageTemplate = (rowData: News) =>
  rowData.imageUrl ? <img className={styles.image} src={rowData.imageUrl} alt={rowData.title} /> : 'None';

const contentTemplate = (rowData: News) => (
  <a href={rowData.contentUrl} target="_blank" rel="noreferrer">
    {rowData.title}
  </a>
);

const dateTemplate = (rowData: News) => (rowData.timestamp ? toDateString(rowData.timestamp, '/') : null);

const RelatedProductNews = ({ news: newsList }: RelatedProductNewsProps) => {
  const editableProduct = useAppSelector(editableProductSelector);
  const dispatch = useAppDispatch();
  const [addNewDialogVisible, setAddNewDialogVisible] = useState(false);
  const [addDialogVisible, setAddDialogVisible] = useState(false);
  const { data, isLoading } = useNews();
  const { t } = useTranslation();

  const { control, handleSubmit, formState, reset } = useForm<News>({
    defaultValues: initialValue,
  });

  const addItem = async (value: NewsUpdate) => {
    if (editableProduct) {
      dispatch(setEditableProduct({ ...editableProduct, news: [...(editableProduct.news ?? []), value] }));
    }
  };

  const leftToolbarTemplate = () => (
    <>
      <Button
        label="Add Item"
        icon="pi pi-plus"
        className="p-button-success mr-2 p-mb-2"
        aria-haspopup
        aria-controls="overlay_panel"
        onClick={() => setAddNewDialogVisible(true)}
      />
      <Button
        label="Add Existing"
        icon="pi pi-plus"
        className="p-button-outlined p-button-success mr-2 p-mb-2"
        aria-haspopup
        aria-controls="overlay_panel"
        onClick={() => setAddDialogVisible(true)}
      />
    </>
  );

  const renderHeader = () => (
    <div className={styles.headerContainer}>
      <span>Add Item</span>
    </div>
  );

  const onSubmit: SubmitHandler<News> = async (payload) => {
    addItem({
      ...payload,
      ...(payload.timestamp ? { timestamp: new Date(payload.timestamp).toISOString() } : {}),
      edited: true,
    });
    reset(initialValue);
    setAddNewDialogVisible(false);
  };

  const onAddAsync = async (values: News[]) => {
    if (editableProduct) {
      const news: NewsUpdate[] = [];
      values.forEach((v) => {
        const item = data?.find((n) => n.idNews === v.idNews);
        if (item) {
          news.push({ ...item, edited: true });
        }
      });
      dispatch(setEditableProduct({ ...editableProduct, news: [...(editableProduct.news ?? []), ...news] }));
      setAddDialogVisible(false);
    }
  };

  const itemDialogFooter = (
    <div className={styles.bottomButton}>
      <Button
        label="Close"
        icon="pi pi-times"
        className="p-button-outlined"
        onClick={() => setAddNewDialogVisible(false)}
      />
      <Button label={t('general.add')} icon="pi pi-plus" onClick={handleSubmit(onSubmit)} />
    </div>
  );

  return (
    <>
      <Toolbar className="p-mb-4 p-toolbar" left={leftToolbarTemplate} />
      <ProductNewsTable news={newsList} readonly={false} />
      {addNewDialogVisible && (
        <Dialog
          visible={addNewDialogVisible}
          className={styles.dialog}
          header={renderHeader}
          modal
          footer={itemDialogFooter}
          onHide={() => setAddNewDialogVisible(false)}
        >
          <AddNewsForm control={control} errors={formState.errors} />
        </Dialog>
      )}
      <AddItemDialog
        values={data?.filter((item) => !newsList.some((b) => b.idNews === item.idNews)) ?? []}
        selectionMode="multiple"
        isLoading={isLoading}
        visible={addDialogVisible}
        setVisible={setAddDialogVisible}
        dataKey="idNews"
        onAddAsync={onAddAsync}
      >
        <Column field="title" header="Title" body={contentTemplate} sortable />
        <Column field="description" header="Description" />
        <Column field="imageUrl" header="Image" body={imageTemplate} />
        <Column field="timestamp" header="Timestamp" sortable body={dateTemplate} />
      </AddItemDialog>
    </>
  );
};

export default RelatedProductNews;
