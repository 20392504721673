import { StatusBadge } from 'components/common';
import { MaturityModel, MaturityModelGroup } from 'domain/maturityModel/types';
import { useMaturityModel } from 'domain/product/hook';
import { getHeader, getStatusColor } from 'features/admin/utils';
import { isLoggedInSelector } from 'features/auth/reducer/auth';
import { Column } from 'primereact/column';
import { DataTable, DataTableExpandedRows, DataTableValueArray } from 'primereact/datatable';
import { useMemo, useState } from 'react';
import { useAppSelector } from 'store';

import MaturityGroupItemTable from './MaturityGroupItemTable';
import MaturityHistory from './MaturityHistory';
import styles from './MaturitySection.module.scss';

interface MaturitySectionProps {
  idProduct: number;
  idMaturityModel: number | null;
}

const getTotalMaturity = (maturity: MaturityModel) => {
  const items = maturity.groups.flatMap((g) => g.items);
  return Math.round(items.reduce((a, i) => a + (i.selectedOption?.maturityLevel ?? 0), 0) / items.length);
};

const getAverageMaturity = (row: MaturityModelGroup) => {
  return (
    row.items.reduce((a, i) => {
      if (!i.selectedOption) return a + 0;
      const maxPossibleMaturity = Math.max(...i.options.map((opt) => opt.maturityLevel));
      if (maxPossibleMaturity < 4 && i.selectedOption.maturityLevel === maxPossibleMaturity) {
        return a + Math.max(...row.items.map((item) => item.selectedOption?.maturityLevel ?? 0));
      }
      return a + i.selectedOption.maturityLevel;
    }, 0) / row.items.length
  );
};

const rowExpansionTemplate = (row: MaturityModelGroup) => <MaturityGroupItemTable row={row} />;

const MaturitySection = ({ idProduct, idMaturityModel }: MaturitySectionProps) => {
  const isLoggedIn = useAppSelector(isLoggedInSelector);
  const { data: maturity, isLoading } = useMaturityModel(idProduct, idMaturityModel, isLoggedIn);
  const [expandedRows, setExpandedRows] = useState<DataTableValueArray | DataTableExpandedRows | undefined>([]);

  const total = useMemo(() => (maturity ? getTotalMaturity(maturity) : 0), [maturity]);

  const groupedMaturity = useMemo(
    () => [...(maturity?.groups ?? [])].sort((a, b) => a.order - b.order),
    [maturity?.groups],
  );

  const renderHeaderRow = (row: MaturityModelGroup) => {
    const averageMaturity = Math.round(getAverageMaturity(row));
    return (
      <span>
        {row.name} <StatusBadge color={getStatusColor(averageMaturity)}>{getHeader(averageMaturity)}</StatusBadge>
      </span>
    );
  };

  if (!maturity)
    return (
      <div className={styles.container}>
        <div className={styles.section}>
          <h4>Maturity</h4>
          This product doesn&apos;t have a maturity defined!
        </div>
      </div>
    );

  return (
    <div className={styles.section}>
      <h4>
        Maturity{' '}
        <StatusBadge fontSize={15} color={getStatusColor(total)}>
          {getHeader(total)}
        </StatusBadge>
      </h4>
      <MaturityHistory idProduct={idProduct} />
      <DataTable
        className={styles.datatable}
        loading={isLoading}
        value={groupedMaturity}
        expandedRows={expandedRows}
        onRowToggle={(e) => setExpandedRows(e.data)}
        rowExpansionTemplate={rowExpansionTemplate}
        showHeaders={false}
      >
        <Column expander style={{ width: '3em' }} />
        <Column field="name" body={(rowData: MaturityModelGroup) => renderHeaderRow(rowData)} />
      </DataTable>
    </div>
  );
};

export default MaturitySection;
