import editIcon from 'assets/images/icons/Edit.png';
import { AddItemDialog, Button, CustomDataTable } from 'components/common';
import { useAutonomyFunctions } from 'domain/autonomylevels/hooks';
import { AutonomyFunctionDTO } from 'domain/autonomylevels/types';
import { getImpact } from 'features/admin/utils';
import { editableProductSelector, setEditableProduct } from 'features/products/reducers/solutionSlice';
import { EditableProductAutonomyFunction, ProductAutonomyFunction, impactOptions } from 'features/products/types';
import { Column, ColumnEditorOptions, ColumnEvent } from 'primereact/column';
import { confirmPopup } from 'primereact/confirmpopup';
import { Dropdown } from 'primereact/dropdown';
import { Toolbar } from 'primereact/toolbar';
import { MouseEvent, useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useAppDispatch, useAppSelector } from 'store';

type AutonomyLevelTabProps = {
  autonomyFunctions: ProductAutonomyFunction[];
};

const impactTemplate = (rowData: EditableProductAutonomyFunction) => getImpact(rowData.impact);

const AutonomyLevelTab = ({ autonomyFunctions }: AutonomyLevelTabProps) => {
  const editableProduct = useAppSelector(editableProductSelector);
  const [addDialogVisible, setAddDialogVisible] = useState(false);
  const { data, isLoading } = useAutonomyFunctions();
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  const addItems = async (values: AutonomyFunctionDTO[]) => {
    if (editableProduct) {
      dispatch(
        setEditableProduct({
          ...editableProduct,
          autonomyFunctions: [
            ...(editableProduct.autonomyFunctions ?? []),
            ...values.map((v, index) => ({
              ...v,
              impact: 0,
              edited: true,
              idProductAutonomyFunction: -((editableProduct.autonomyFunctions?.length ?? 0) + (index + 1)),
            })),
          ],
        }),
      );
    }
  };

  const removeItems = async (persona: EditableProductAutonomyFunction) => {
    if (editableProduct) {
      setEditableProduct({
        ...editableProduct,
        autonomyFunctions: editableProduct.autonomyFunctions.filter(
          (x) => x.idProductAutonomyFunction !== persona.idProductAutonomyFunction,
        ),
      });
    }
  };

  const onCellEditComplete = (e: ColumnEvent) => {
    if (!editableProduct) return;
    const { newRowData, rowIndex } = e;
    (newRowData as EditableProductAutonomyFunction).edited = true;
    const functions = [...autonomyFunctions] as EditableProductAutonomyFunction[];
    functions[rowIndex] = newRowData;
    setEditableProduct({ ...editableProduct, autonomyFunctions: functions });
  };

  const editAutonomyImpact = useCallback((idAutonomyFunction: number, value: number) => {
    if (!editableProduct) return;
    const newAutonomy = [...editableProduct.autonomyFunctions];
    const index = newAutonomy.findIndex((autonomy) => autonomy.idAutonomyFunction === idAutonomyFunction);
    newAutonomy[index] = { ...newAutonomy[index], impact: value };
    dispatch(
      setEditableProduct({
        ...editableProduct,
        autonomyFunctions: newAutonomy,
      }),
    );
  }, []);

  const impactEditor = (options: ColumnEditorOptions) => (
    <Dropdown
      value={options.rowData.impact}
      options={impactOptions}
      optionLabel="impact"
      optionValue="id"
      dataKey="id"
      onChange={(e) => editAutonomyImpact((options.rowData as ProductAutonomyFunction).idAutonomyFunction, e.value)}
      style={{ width: '100%' }}
      placeholder="Impact"
    />
  );

  const removeElement = (e: MouseEvent<HTMLButtonElement>, element: EditableProductAutonomyFunction) => {
    confirmPopup({
      target: e.currentTarget,
      message: t('general.remove-item'),
      icon: 'pi pi-info-circle',
      acceptClassName: 'p-button-danger',
      accept: () => removeItems(element),
    });
  };

  const leftToolbarTemplate = () => (
    <Button
      label="Add"
      icon="pi pi-plus"
      className="p-button-success mr-2 p-mb-2"
      aria-haspopup
      aria-controls="overlay_panel"
      onClick={() => setAddDialogVisible(true)}
    />
  );

  const actionBodyTemplate = (rowData: EditableProductAutonomyFunction) => (
    <div className="actions">
      <Button
        icon="pi pi-trash"
        tooltip="Delete"
        className="p-button-rounded p-button-danger"
        onClick={(e: MouseEvent<HTMLButtonElement>) => removeElement(e, rowData)}
      />
    </div>
  );

  return (
    <>
      <Toolbar className="p-mb-4 p-toolbar" start={leftToolbarTemplate} />
      <CustomDataTable value={autonomyFunctions} dataKey="idAutonomyFunction" editMode="cell" rows={10}>
        <Column field="autonomyLevel.name" header="Autonomy Level" sortable />
        <Column field="description" header="Autonomy Function" sortable />
        <Column
          field="impact"
          header="Impact"
          sortable
          editor={impactEditor}
          bodyStyle={{ cursor: `url(${editIcon}),cell` }}
          body={impactTemplate}
          onCellEditComplete={onCellEditComplete}
        />
        <Column headerStyle={{ width: '10%', minWidth: '8rem' }} body={actionBodyTemplate} />
      </CustomDataTable>
      <AddItemDialog
        values={
          data?.filter((item) => !autonomyFunctions.some((b) => b.idAutonomyFunction === item.idAutonomyFunction)) ?? []
        }
        selectionMode="multiple"
        isLoading={isLoading}
        visible={addDialogVisible}
        setVisible={setAddDialogVisible}
        dataKey="idAutonomyFunction"
        onAddAsync={addItems}
      >
        <Column field="autonomyLevel.name" header="Autonomy Level" sortable />
        <Column field="description" header="Autonomy Function" sortable />
      </AddItemDialog>
    </>
  );
};

export default AutonomyLevelTab;
