import { CustomDataTable } from 'components/common';
import { ProductRelatedPlant } from 'domain/plant/types';
import { useProductPlants } from 'domain/product/hook';
import { Column } from 'primereact/column';
import { Tag } from 'primereact/tag';

type PlantsDataTableProps = {
  productId: number;
};

const plantTemplate = (dataRow: ProductRelatedPlant) => (
  <span>
    {dataRow.plant} {dataRow.confidential && <Tag severity="warning" value="Confidential" />}
  </span>
);

const PlantsDataTable = ({ productId }: PlantsDataTableProps) => {
  const { data: plants, isLoading } = useProductPlants(productId);
  return (
    <CustomDataTable loading={isLoading} value={plants ?? []} dataKey="idPlant" rows={10}>
      <Column field="plant" header="Plant" sortable body={plantTemplate} />
      <Column field="industry.industry" header="Industry" sortable />
      <Column field="country.name" header="Country" sortable />
    </CustomDataTable>
  );
};

export default PlantsDataTable;
