import { useContext } from 'react';

import { MediaQueryContext } from '../contexts/MediaQueryContext';

export function useMediaQuery() {
  const context = useContext(MediaQueryContext);
  if (!context) {
    throw new Error('useMediaQuery must be used within an MediaQueryContextProvider');
  }
  return context;
}
