import { ProductNewsTable } from 'features/admin/components';
import { isInternalSelector } from 'features/auth/reducer/auth';
// eslint-disable-next-line import/no-cycle
import { ProductCard } from 'features/products/components/ProductCard';
import { Benefit, Product, ProductBrief, ProductVideo } from 'features/products/types';
import parse from 'html-react-parser';
import i18next from 'i18next';
import { Tooltip } from 'primereact/tooltip';
import { Fragment } from 'react';
import { useTranslation } from 'react-i18next';
import { useAppSelector } from 'store';
import { toDateString } from 'utils/datetime';
import { capitalizeFirstLetter } from 'utils/string';

import styles from './Overview.module.scss';
import { ManagersSection } from './components';

interface OverviewProps {
  product: Product;
}

const renderRelatedSolutions = (relatedProducts: ProductBrief[]) => (
  <>
    <h4 className={`${styles.title} capitalize`}>{i18next.t<string>('products.related-solutions')}</h4>
    <div className={styles.productList}>
      {relatedProducts.map((related) => (
        <ProductCard key={related.idProduct} product={related} layout="small" />
      ))}
    </div>
  </>
);

const renderRelatedAddons = (relatedAddons: ProductBrief[]) => (
  <>
    <h4 className={`${styles.title} capitalize`}>{i18next.t<string>('products.add-ons')}</h4>
    <div className={styles.productList}>
      {relatedAddons.map((related) => (
        <ProductCard key={related.idProduct} product={related} layout="small" />
      ))}
    </div>
  </>
);

const groupBenefits = (benefits: Benefit[]) => {
  const groups = [] as { group: string; values: Benefit[] }[];
  benefits.forEach((benefit) => {
    const index = groups.findIndex((g) => g.group.toUpperCase() === benefit.group.toUpperCase());

    if (index >= 0) {
      const valueIndex = groups[index].values.findIndex(
        (g) => g.benefit.toUpperCase() === benefit.benefit.toUpperCase(),
      );
      if (valueIndex === -1) {
        groups[index].values.push(benefit);
      }
    } else {
      groups.push({ group: benefit.group, values: [benefit] });
    }
  });

  return groups
    .map(({ group, values }) => ({
      group,
      values: [...values].sort((a, b) => (a.benefit < b.benefit ? -1 : 1)),
    }))
    .sort((a, b) => (a.group < b.group ? -1 : 1));
};

const sortVideos = (a: ProductVideo, b: ProductVideo) => {
  if (!a.timestamp && !b.timestamp) {
    if (a.title < b.title) return -1;
    if (a.title > b.title) return 1;
  }
  if (!a.timestamp) return 1;
  if (!b.timestamp) return -1;
  if (a.timestamp > b.timestamp) return -1;
  if (a.timestamp < b.timestamp) return 1;
  if (a.title < b.title) return -1;
  if (a.title > b.title) return 1;
  return 0;
};

const Overview = ({ product }: OverviewProps) => {
  const isInternal = useAppSelector(isInternalSelector);
  const { t } = useTranslation();

  return (
    <div className={styles.mainContainer}>
      <p className={styles.description}>{product.description}</p>
      {product.descriptionForProposals && (
        <>
          <div>{parse(product.descriptionForProposals ?? '')}</div>
          <br />
        </>
      )}
      <hr />
      {product.videos?.length > 0 && (
        <>
          <h4 className={styles.title}>{capitalizeFirstLetter(t('products.videos'))}</h4>
          <div className={styles.container}>
            {[...product.videos].sort(sortVideos).map((video) => (
              <Fragment key={video.idProductVideo}>
                <p>
                  <b>{video.title}</b>
                  <p className={styles.videoDate}>{video.timestamp && toDateString(video.timestamp, '-')}</p>
                </p>
                <div className={styles.videoContainer}>
                  <iframe src={video.hyperlink.replace('watch?v=', 'embed/')} allowFullScreen title={video.title} />
                </div>
              </Fragment>
            ))}
          </div>
        </>
      )}

      {product.news?.length > 0 && (
        <>
          <h4 className={styles.title}>Discover More</h4>
          <ProductNewsTable news={product.news} readonly />
        </>
      )}

      {isInternal && (
        <div className={styles.container}>
          <ManagersSection product={product} />
        </div>
      )}

      {isInternal && product.idEam != null && (
        <div className={styles.container}>
          <p className={styles.title}>
            <a
              className={styles.emailLink}
              href={`https://alm.andritz.com/jira/secure/insight/assets/EAM-${product.idEam}`}
              target="_blank"
              rel="noreferrer"
            >
              <b>{t('products.link-cmdb')}</b>
            </a>
          </p>
        </div>
      )}

      {product.userPersonas?.length > 0 && (
        <>
          <h4 className={styles.title}>{capitalizeFirstLetter(t('products.end-user'))}</h4>
          <div className={styles.container}>
            {product.userPersonas?.map((persona) => (
              <Fragment key={persona.idUserPersona}>
                <span>• {persona.userPersona}</span>
                <br />
              </Fragment>
            ))}
          </div>
        </>
      )}
      {product.benefits?.length > 0 && (
        <>
          <h4 className={`${styles.title} capitalize`}>{t('products.benefits')}</h4>
          {groupBenefits(product.benefits).map(({ group, values }) => (
            <div key={group} className={styles.container}>
              <span>
                <b>{group}</b>
              </span>
              <div className={styles.container}>
                {values.map((benefit) => (
                  <Fragment key={benefit.idBenefit}>
                    <Tooltip
                      target={`[id='${benefit.idBenefit}']`}
                      mouseTrack
                      mouseTrackLeft={10}
                      content={benefit.tooltip}
                      className={styles.tooltip}
                    />
                    <span id={`${benefit.idBenefit}`}>• {benefit.benefit}</span>
                    <br />
                  </Fragment>
                ))}
              </div>
            </div>
          ))}
        </>
      )}
      {product.lifeCycles?.length > 0 && (
        <>
          <h4 className={`${styles.title} capitalize`}>{t('products.plant-lifecycle-management')}</h4>
          <div className={styles.container}>
            {product.lifeCycles?.map((lifeCycle) => (
              <Fragment key={lifeCycle.idLifeCycle}>
                <span>• {lifeCycle.lifeCycle}</span>
                <br />
              </Fragment>
            ))}
          </div>
        </>
      )}
      {product.trainings?.length > 0 && (
        <>
          <h4 className={`${styles.title} capitalize`}>{t('products.trainings')}</h4>
          <div className={styles.container}>
            {[...product.trainings]
              ?.sort((a, b) => a.courseOrder - b.courseOrder)
              .map((training) => (
                <Fragment key={training.idTraining}>
                  <p>
                    <b>
                      {training.courseOrder}){' '}
                      {training.link ? (
                        <a className={styles.emailLink} href={training.link} target="_blank" rel="noreferrer">
                          {training.courseName}
                        </a>
                      ) : (
                        training.courseName
                      )}
                    </b>
                    {!!training.description && `: ${training.description}`}
                  </p>
                </Fragment>
              ))}
          </div>
        </>
      )}

      <h4 className={`${styles.title} capitalize`}>{t('general.group')}</h4>
      <div className={styles.container}>
        <span>{product.group?.productGroup}</span>
      </div>

      {product.industries?.length > 0 && (
        <>
          <h4 className={`${styles.title} capitalize`}>{t('products.industries')}</h4>
          <div className={styles.container}>
            {product.industries?.map((industry) => (
              <Fragment key={industry.idIndustry}>
                <span>• {industry.industry}</span>
                {industry.industryArea ? <span>: {industry.industryArea.industryArea}</span> : null}
                <br />
              </Fragment>
            ))}
          </div>
        </>
      )}

      {isInternal && product.countries?.length > 0 && (
        <>
          <h4 className={`${styles.title} capitalize`}>{t('products.availableCountries')}</h4>
          <div className={styles.container}>
            {product.countries?.map((country) => (
              <Fragment key={country.idCountry}>
                <span>• {country.name}</span>
                <br />
              </Fragment>
            ))}
          </div>
        </>
      )}

      {product.relatedAddons?.length ? renderRelatedAddons(product.relatedAddons) : null}
      {product.relatedProducts?.length ? renderRelatedSolutions(product.relatedProducts) : null}
    </div>
  );
};

export default Overview;
