import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { FundingSourceBudgetDTO, FundingSourceDTO } from 'domain/fundingSource/types';
import type { RootState } from 'store/rootReducer';

interface ModelState {
  editableFundingSource: FundingSourceDTO | null;
}

const initialState: ModelState = {
  editableFundingSource: null,
};

export const fundingSourceSlice = createSlice({
  name: 'funding-source',
  initialState,
  reducers: {
    setFundingSource: (state, action: PayloadAction<FundingSourceDTO | null>) => {
      state.editableFundingSource = action.payload;
    },
    updateFundingSourceBudgets: (state, action: PayloadAction<FundingSourceBudgetDTO[]>) => {
      if (state.editableFundingSource) {
        state.editableFundingSource.budgets = action.payload;
      }
    },
  },
});

export const { setFundingSource, updateFundingSourceBudgets } = fundingSourceSlice.actions;

export const fundingSourceSelector = (state: RootState) => state.fundingSource.editableFundingSource;

export default fundingSourceSlice.reducer;
