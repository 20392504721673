import { useQuery } from 'react-query';

import { getMaturityModelOptions, getMaturityModels } from '../api';
import { MaturityModel, MaturityModelOption } from '../types';

export const MATURITY_MODEL_OPTIONS_ID = 'maturity-model-options';
export const MATURITY_MODELS_ID = 'maturity-models';

export function useMaturityModelOptions() {
  return useQuery<MaturityModelOption[], Error>(MATURITY_MODEL_OPTIONS_ID, getMaturityModelOptions, {
    staleTime: 1000 * 60 * 5,
  });
}

export function useMaturityModels() {
  return useQuery<MaturityModel[], Error>(MATURITY_MODELS_ID, getMaturityModels, {
    staleTime: 1000 * 60 * 5,
  });
}
