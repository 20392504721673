import { Loader } from '@progress/kendo-react-indicators';

import styles from './BusyContext.module.scss';

type Types = 'pulsing' | 'infinite-spinner' | 'converging-spinner';

interface BusyContextProps {
  isLoading: boolean;
  type?: Types;
}

const BusyContext = ({ isLoading, type = 'converging-spinner' }: BusyContextProps) => {
  if (isLoading) return <Loader className={styles.loader} size="large" type={type} />;
  return null;
};

export default BusyContext;

BusyContext.defaultProps = {
  type: 'converging-spinner',
};
