import { useProductHistory } from 'domain/product/hook';
import { ProductMaturityHistoryGroup } from 'domain/product/types';
import { Accordion, AccordionTab } from 'primereact/accordion';
import { Column } from 'primereact/column';
import { DataTable, DataTableExpandedRows, DataTableValueArray } from 'primereact/datatable';
import { useState } from 'react';

import MaturityHistoryItem from './MaturityHistoryItem';

interface MaturityHistoryProps {
  idProduct: number;
}

const groupTemplate = (rowData: ProductMaturityHistoryGroup) => `${rowData.group} [${rowData.items.length}]`;

const rowExpansionTemplate = (rowData: ProductMaturityHistoryGroup) => <MaturityHistoryItem items={rowData.items} />;

const MaturityHistory = ({ idProduct }: MaturityHistoryProps) => {
  const { data, isLoading } = useProductHistory(idProduct);
  const [expandedRows, setExpandedRows] = useState<DataTableValueArray | DataTableExpandedRows | undefined>([]);
  return (
    <Accordion style={{ marginBottom: '1rem' }}>
      <AccordionTab header="History">
        <DataTable
          value={data ?? []}
          dataKey="group"
          loading={isLoading}
          expandedRows={expandedRows}
          onRowToggle={(e) => setExpandedRows(e.data)}
          rowExpansionTemplate={rowExpansionTemplate}
          showHeaders={false}
        >
          <Column expander style={{ width: '3em' }} />
          <Column field="group" body={groupTemplate} />
        </DataTable>
      </AccordionTab>
    </Accordion>
  );
};

export default MaturityHistory;
