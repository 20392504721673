import { createSlice } from '@reduxjs/toolkit';

interface SettingsState {
  showSolutionStatus: boolean;
}

const initialState: SettingsState = {
  showSolutionStatus: false,
};

const settingsSlice = createSlice({
  name: 'settings',
  initialState,
  reducers: {},
});

export default settingsSlice.reducer;
