import { RoadMapUpdate } from 'domain/roadmap/types';
import { Control } from 'react-hook-form';

import GenericClickableField from './GenericClickableField';

interface QualityCertificateFieldProps {
  viewOnly: boolean;
  control: Control<RoadMapUpdate, unknown>;
}

const QualityCertificateField = ({ viewOnly, control }: QualityCertificateFieldProps) => (
  <GenericClickableField
    control={control}
    viewOnly={viewOnly}
    title="Quality Certificate"
    name="qualityCertificate"
    maxLength={255}
  />
);

export default QualityCertificateField;
