import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { UpdateQualityAssuranceCertificate } from 'domain/qualityAssurance/types';
import type { RootState } from 'store/rootReducer';

interface CertificateState {
  editableCertificate: UpdateQualityAssuranceCertificate | null;
}

const initialState: CertificateState = {
  editableCertificate: null,
};

export const certificateSlice = createSlice({
  name: 'quality-assurance/certificate',
  initialState,
  reducers: {
    setCertificate: (state, action: PayloadAction<UpdateQualityAssuranceCertificate | null>) => {
      state.editableCertificate = action.payload;
    },
  },
});

export const { setCertificate } = certificateSlice.actions;

export const certificateSelector = (state: RootState) => state.certificate.editableCertificate;

export default certificateSlice.reducer;
