/* eslint-disable max-lines */
import classNames from 'classnames';
import { Editor } from 'components/KendoReact';
import { Button } from 'components/common';
import { useMaturityModels } from 'domain/maturityModel/hooks';
import { MaturityModel } from 'domain/maturityModel/types';
import { ProductImage } from 'domain/productImages/types';
import { useRoadmapPlatforms } from 'domain/roadmap/hooks';
import { useUsers } from 'domain/user/hooks';
import { useProductGroups } from 'features/admin/hooks';
import { isAdminSelector, userSelector } from 'features/auth/reducer/auth';
import { editableProductSelector, setEditableProduct } from 'features/products/reducers/solutionSlice';
import { EditableProduct, SolutionType } from 'features/products/types';
import { BlockUI } from 'primereact/blockui';
import { confirmDialog } from 'primereact/confirmdialog';
import { Dropdown } from 'primereact/dropdown';
import { InputNumber } from 'primereact/inputnumber';
import { InputSwitch } from 'primereact/inputswitch';
import { InputText } from 'primereact/inputtext';
import { InputTextarea } from 'primereact/inputtextarea';
import { SelectButton } from 'primereact/selectbutton';
import { Tooltip } from 'primereact/tooltip';
import { useMemo, useState } from 'react';
import { Control, Controller } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useAppDispatch, useAppSelector } from 'store';
import { isProductManager, isProductOwner, isTechnologyManager } from 'utils/permissions';

import styles from './ProductDetailForm.module.scss';
import ImageSelectorDialog from './components/ImageSelectorDialog/ImageSelectorDialog';

const solutionTypes: SolutionType[] = [
  { name: 'Product', value: 0 },
  { name: 'Component', value: 1 },
];

const itemTemplate = (option: unknown) => (
  <span className="p-button-label p-c">{option ? 'Saleable' : 'Not Saleable'}</span>
);

interface ProductDetailFormProps {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  control: Control<EditableProduct, any>;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  errors: any;
}

const ProductDetailForm = ({ control, errors }: ProductDetailFormProps) => {
  const { t } = useTranslation();
  const editableProduct = useAppSelector(editableProductSelector);
  const dispatch = useAppDispatch();
  const isAdmin = useAppSelector(isAdminSelector);
  const user = useAppSelector(userSelector);

  const canChangeProductDetails = useMemo(
    () =>
      isAdmin ||
      isProductManager(user, editableProduct) ||
      isProductOwner(user, editableProduct) ||
      isTechnologyManager(user, editableProduct),
    [editableProduct, isAdmin, user],
  );

  const { data: groups, isLoading: isLoadingGroups } = useProductGroups();
  const { data: platforms, isLoading: isLoadingPlatforms } = useRoadmapPlatforms();
  const { data: users, isLoading: isLoadingUsers } = useUsers();
  const { data: maturityModels, isLoading: isLoadingMaturityModels } = useMaturityModels();

  const [showImageSelector, setShowImageSelector] = useState(false);

  const handleOpenDialog = () => {
    setShowImageSelector(true);
  };

  const setImageIcon = (image: ProductImage) => {
    if (editableProduct) {
      dispatch(
        setEditableProduct({
          ...editableProduct,
          image: image.image1,
          image2: image.image2 ?? '',
        }),
      );
    }
    setShowImageSelector(false);
  };

  const isLoading = isLoadingGroups || isLoadingPlatforms || isLoadingMaturityModels || isLoadingUsers;

  const confirmChangeMaturityModel = (onChange: () => void, maturityModel: MaturityModel | null) => {
    confirmDialog({
      message: 'Changing the maturity model will reset all maturity items. Are you sure you want to proceed?',
      header: 'Change Maturity Model',
      icon: 'pi pi-exclamation-triangle',
      accept: async () => {
        onChange();
        if (editableProduct) {
          dispatch(
            setEditableProduct({
              ...editableProduct,
              idMaturityModel: maturityModel?.idMaturityModel ?? null,
            }),
          );
        }
      },
    });
  };

  return (
    <BlockUI
      fullScreen
      blocked={isLoading}
      template={<i className="pi pi-spin pi-spinner" style={{ fontSize: '3rem' }} />}
    >
      <div className={`${styles.form} p-jc-center`}>
        <form className="p-fluid">
          <div className={styles.container}>
            <div className={styles.productAndPlatform}>
              <div className={styles.field}>
                <span className={classNames({ 'p-error': errors.product })}>
                  <span className="red">*</span> Product
                </span>
                <Controller
                  name="product"
                  control={control}
                  rules={{ required: true }}
                  render={({ field }) => (
                    <InputText {...field} className={classNames({ 'p-invalid': errors.product })} maxLength={100} />
                  )}
                />
                {errors.product && <small className="p-error">{t('form.required')}</small>}
              </div>

              <div className={styles.field}>
                <span>Solution Type</span>
                <Controller
                  name="idType"
                  rules={{ required: true }}
                  control={control}
                  render={({ field: { value, onChange } }) => (
                    <SelectButton
                      value={value}
                      onChange={(e) => onChange(e.value)}
                      optionLabel="name"
                      dataKey="value"
                      options={solutionTypes}
                      className={`${styles.selectButton} ${classNames({ 'p-invalid': errors.idType })}`}
                      unselectable={false}
                    />
                  )}
                />
              </div>

              <div className={styles.field}>
                <Controller
                  name="saleable"
                  control={control}
                  render={({ field: { value, onChange } }) => (
                    <SelectButton
                      value={value}
                      onChange={(e) => onChange(e.value)}
                      options={[true, false]}
                      itemTemplate={itemTemplate}
                      className={`${styles.selectButton} ${classNames({ 'p-invalid': errors.idType })}`}
                      unselectable={false}
                    />
                  )}
                />
              </div>
            </div>

            <div className={styles.productImage}>
              <img
                src={`${editableProduct?.image2 || editableProduct?.image}`}
                alt={editableProduct?.image2}
                className={styles.icon}
              />
              <Button
                label="Change Icon"
                icon="pi pi-pencil"
                className={styles.editIconButton}
                onClick={handleOpenDialog}
              />
            </div>
          </div>

          <div className={styles.field}>
            <span>Platform</span>
            <Controller
              name="platform"
              control={control}
              render={({ field: { value, onChange } }) => (
                <Dropdown
                  value={value}
                  onChange={(e) => onChange(e.value ?? null)}
                  optionLabel="name"
                  dataKey="idPlatform"
                  filter
                  showClear
                  filterBy="name"
                  options={platforms}
                />
              )}
            />
          </div>

          <div className={styles.field}>
            <span>Nick Name</span>
            <Controller
              name="nickName"
              control={control}
              render={({ field }) => <InputText {...field} maxLength={50} />}
            />
          </div>

          <Tooltip disabled={canChangeProductDetails} mouseTrack target="[id='enabled']">
            <div>
              <p>Editing requires the following permissions</p>
              <ul>
                <li>Admin</li>
              </ul>
            </div>
          </Tooltip>

          <div>
            <div id="enabled" className={styles.switch}>
              <div className="field-checkbox">
                <Controller
                  name="enabled"
                  control={control}
                  render={({ field: { value, ...rest } }) => (
                    <InputSwitch {...rest} disabled={!canChangeProductDetails} checked={value} />
                  )}
                />
                <label htmlFor="enabled">Enabled</label>
              </div>
            </div>
          </div>

          <Tooltip disabled={canChangeProductDetails} mouseTrack target="[id='externalAccess']">
            <div>
              <p>Editing requires the following permissions</p>
              <ul>
                <li>Admin</li>
              </ul>
            </div>
          </Tooltip>

          <div>
            <div id="externalAccess" className={styles.switch}>
              <div className="field-checkbox">
                <Controller
                  name="externalAccess"
                  control={control}
                  render={({ field: { value, ...rest } }) => (
                    <InputSwitch {...rest} disabled={!canChangeProductDetails} checked={value} />
                  )}
                />
                <label htmlFor="externalAccess">External Access</label>
              </div>
            </div>
          </div>

          <div className={styles.field}>
            <span>Documentation</span>
            <Controller
              name="documentation"
              control={control}
              render={({ field }) => <InputText {...field} maxLength={250} />}
            />
          </div>

          <div className={styles.field}>
            <span>Yammer Link</span>
            <Controller
              name="linkYammer"
              control={control}
              render={({ field }) => <InputText {...field} maxLength={100} />}
            />
          </div>

          <div className={styles.field}>
            <span>EAM Id</span>
            <Controller
              name="idEam"
              control={control}
              render={({ field: { value, onChange } }) => (
                <InputNumber value={value} onChange={(e) => onChange(e.value)} useGrouping={false} />
              )}
            />
          </div>

          <div className={styles.field}>
            <span className={classNames({ 'p-error': errors.group })}>
              <span className="red">*</span> Product Family
            </span>
            <Controller
              name="group"
              control={control}
              rules={{ required: true }}
              render={({ field }) => (
                <Dropdown
                  {...field}
                  optionLabel="productGroup"
                  dataKey="idProductGroup"
                  filter
                  filterBy="productGroup"
                  options={groups}
                  className={classNames({ 'p-invalid': errors.group })}
                />
              )}
            />
            {errors.group && <small className="p-error">{t('form.required')}</small>}
          </div>

          <Tooltip disabled={canChangeProductDetails} mouseTrack target="[id='productOwner']">
            <div>
              <p>Editing Technical Product Owner requires the following permissions</p>
              <ul>
                <li>Admin</li>
              </ul>
            </div>
          </Tooltip>

          <div id="productOwner" className={styles.field}>
            <span className={classNames({ 'p-error': errors.productOwner })}>
              <span className="red">*</span> Technical Product Owner
            </span>
            <Controller
              name="productOwner"
              control={control}
              rules={{ required: true }}
              render={({ field: { value, onChange } }) => (
                <Dropdown
                  className={classNames({ 'p-invalid': errors.productOwner })}
                  disabled={!canChangeProductDetails}
                  value={value}
                  onChange={(e) => onChange(e.value ?? null)}
                  optionLabel="name"
                  dataKey="idUser"
                  filter
                  filterBy="name"
                  options={users}
                />
              )}
            />
            {errors.productOwner && <small className="p-error">{t('form.required')}</small>}
          </div>

          <Tooltip disabled={canChangeProductDetails} mouseTrack target="[id='technologyManager']">
            <div>
              <p>Editing Head of Technology requires the following permissions</p>
              <ul>
                <li>Admin</li>
              </ul>
            </div>
          </Tooltip>

          <div id="technologyManager" className={styles.field}>
            <span>Head of Technology</span>
            <Controller
              name="technologyManager"
              control={control}
              render={({ field: { value, onChange } }) => (
                <Dropdown
                  disabled={!canChangeProductDetails}
                  value={value}
                  onChange={(e) => onChange(e.value ?? null)}
                  optionLabel="name"
                  dataKey="idUser"
                  filter
                  showClear
                  filterBy="name"
                  options={users}
                />
              )}
            />
          </div>

          <Tooltip disabled={canChangeProductDetails} mouseTrack target="[id='productManager']">
            <div>
              <p>Editing Global Product Manager requires the following permissions</p>
              <ul>
                <li>Admin</li>
              </ul>
            </div>
          </Tooltip>

          <div id="productManager" className={styles.field}>
            <span>Global Product Manager</span>
            <Controller
              name="productManager"
              control={control}
              render={({ field: { value, onChange } }) => (
                <Dropdown
                  disabled={!canChangeProductDetails}
                  value={value}
                  onChange={(e) => onChange(e.value ?? null)}
                  optionLabel="name"
                  dataKey="idUser"
                  filter
                  showClear
                  filterBy="name"
                  options={users}
                />
              )}
            />
          </div>

          {/* <div className={styles.field}>
            <span className={classNames({ 'p-error': errors.status })}>
              <span className="red">*</span> Product Status
            </span>
            <Controller
              name="status"
              control={control}
              rules={{ required: true }}
              render={({ field }) => (
                <Dropdown
                  {...field}
                  optionLabel="productStatus"
                  dataKey="idProductStatus"
                  filter
                  filterBy="productStatus"
                  options={statuses}
                  className={classNames({ 'p-invalid': errors.status })}
                />
              )}
            />
            {errors.status && <small className="p-error">{t('form.required')}</small>}
          </div> */}

          <div className={styles.field}>
            <span>Maturity Model</span>
            <Controller
              name="maturityModel"
              control={control}
              render={({ field: { value, onChange } }) => (
                <Dropdown
                  disabled={!canChangeProductDetails}
                  value={value}
                  onChange={(e) => {
                    if (editableProduct?.idMaturityModel) {
                      confirmChangeMaturityModel(() => onChange(e.value ?? null), e.value as MaturityModel | null);
                    } else if (editableProduct) {
                      onChange(e.value ?? null);
                      setEditableProduct({
                        ...editableProduct,
                        idMaturityModel: e.value?.idMaturityModel ?? null,
                      });
                    }
                  }}
                  optionLabel="name"
                  dataKey="idMaturityModel"
                  filter
                  showClear
                  filterBy="name"
                  options={maturityModels}
                />
              )}
            />
          </div>

          <div className={styles.field}>
            <span>Description</span>
            <Controller
              name="description"
              control={control}
              render={({ field }) => <InputTextarea rows={4} {...field} maxLength={600} />}
            />
          </div>

          <div className={styles.field}>
            <span className={classNames({ 'p-error': errors.description })}>Description for Proposal</span>
            <Controller
              name="descriptionForProposals"
              control={control}
              render={({ field: { value, onChange } }) => (
                <Editor
                  onChange={(event) => onChange(event.html)}
                  value={value ?? ''}
                  contentStyle={{ height: '630px' }}
                />
              )}
            />
          </div>
        </form>
        <ImageSelectorDialog visible={showImageSelector} setVisible={setShowImageSelector} selectImage={setImageIcon} />
      </div>
    </BlockUI>
  );
};

export default ProductDetailForm;
