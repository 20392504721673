import { UserPersona } from 'features/products/types';
import { useQuery } from 'react-query';
import api from 'services/api';

const getPersonas = async () => {
  const { data } = await api.get('userpersonas');
  return data as UserPersona[];
};

export function usePersonas() {
  return useQuery<UserPersona[], Error>('personas', getPersonas, {
    staleTime: 1000 * 60 * 15,
  });
}
