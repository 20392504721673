export interface LoginRequest {
  accessToken: string;
}

export interface RegistrationRequest {
  name: string;
  username: string;
  password: string;
  email: string;
}

export type AuthUser = {
  idUser: number;
  name: string;
  username: string;
  email: string;
  role: string;
  permissions: string[];
  roles: Role[] | undefined;
};

export interface User extends AuthUser {
  token: string;
}

export type LoginResponse = {
  data: User;
};

export enum Role {
  Admin = 'Admin',
  Api = 'Api',
  CustomerCare = 'Customer Care',
  Developer = 'Developer',
  ProductOwner = 'ProductOwner',
  ProductManager = 'ProductManager',
  HeadTechnology = 'HeadTechnology',
  Marketing = 'Marketing',
  User = 'User',
}
