import styles from './Stepper.module.scss';

const Stepper = ({ number, activeIndex }: { number: number; activeIndex: number }) => (
  <section className={styles.section}>
    <div className={styles.stepList}>
      {[...Array(number)].map((_, x) => (
        // eslint-disable-next-line react/no-array-index-key
        <div key={x} className={styles.step}>
          <div className={`${styles.number} ${x <= activeIndex ? styles.active : ''} `}>
            {x + 1 < number && <div className={`${styles.tail} ${x < activeIndex ? styles.stepped : ''}`} />}
            <span>{x + 1}</span>
          </div>
        </div>
      ))}
    </div>
  </section>
);

export default Stepper;
