import { editableProductSelector, setEditableProduct } from 'features/products/reducers/solutionSlice';
import { ProductDocumentLinkDTO } from 'features/products/types';
import { Button } from 'primereact/button';
import { Column } from 'primereact/column';
import { confirmPopup } from 'primereact/confirmpopup';
import { DataTable } from 'primereact/datatable';
import { Toolbar } from 'primereact/toolbar';
import { MouseEvent, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useAppDispatch, useAppSelector } from 'store';

import AddDocumentDialog from './AddDocumentDialog';

type ProductDocumentLinkTabProps = {
  documents: ProductDocumentLinkDTO[];
};

const initialValue: ProductDocumentLinkDTO = {
  idProductDocumentLink: 0,
  idProductDocumentType: 0,
  documentLink: '',
  documentName: '',
};

const ProductDocumentLinkTab = ({ documents }: ProductDocumentLinkTabProps) => {
  const { t } = useTranslation();
  const editableProduct = useAppSelector(editableProductSelector);
  const dispatch = useAppDispatch();
  const [editableDocument, setEditableDocument] = useState<ProductDocumentLinkDTO>(initialValue);
  const [addDialogVisible, setAddDialogVisible] = useState(false);

  const handleAddNewDocument = () => {
    setEditableDocument(initialValue);
    setAddDialogVisible(true);
  };

  const handleEditDocument = (value: ProductDocumentLinkDTO) => {
    setEditableDocument(value);
    setAddDialogVisible(true);
  };

  const removeDocument = (value: ProductDocumentLinkDTO) => {
    if (editableProduct) {
      dispatch(
        setEditableProduct({
          ...editableProduct,
          documents: editableProduct.documents.filter(
            (item) => item.idProductDocumentLink !== value.idProductDocumentLink,
          ),
        }),
      );
    }
  };
  const leftToolbarTemplate = () => (
    <Button
      label="Add"
      icon="pi pi-plus"
      className="p-button-success mr-2 p-mb-2"
      aria-haspopup
      aria-controls="overlay_panel"
      onClick={handleAddNewDocument}
    />
  );

  const removeItem = (e: MouseEvent<HTMLButtonElement>, value: ProductDocumentLinkDTO) => {
    confirmPopup({
      target: e.currentTarget,
      message: t('general.remove-item'),
      icon: 'pi pi-info-circle',
      acceptClassName: 'p-button-danger',
      accept: () => removeDocument(value),
    });
  };

  const actionBodyTemplate = (rowData: ProductDocumentLinkDTO) => (
    <div className="actions">
      <Button
        icon="pi pi-pencil"
        tooltip="Edit"
        className="p-button-rounded p-button-success mr-2"
        onClick={() => handleEditDocument(rowData)}
      />
      <Button
        icon="pi pi-trash"
        tooltip="Delete"
        className="p-button-rounded p-button-danger"
        onClick={(e: MouseEvent<HTMLButtonElement>) => removeItem(e, rowData)}
      />
    </div>
  );

  return (
    <>
      <Toolbar className="p-mb-4 p-toolbar" left={leftToolbarTemplate} />
      <DataTable
        value={documents}
        dataKey="id"
        paginator
        editMode="row"
        rows={10}
        rowsPerPageOptions={[5, 10, 25]}
        className="datatable-responsive"
        paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
        currentPageReportTemplate="Showing {first} to {last} of {totalRecords} items"
        emptyMessage="No items found."
      >
        <Column field="documentName" header="Name" sortable />
        <Column field="documentLink" header="Link" sortable />
        <Column field="documentType.name" header="Type" sortable />
        <Column headerStyle={{ width: '10%', minWidth: '8rem' }} body={actionBodyTemplate} />
      </DataTable>
      {addDialogVisible && (
        <AddDocumentDialog
          isVisible={addDialogVisible}
          onClose={() => setAddDialogVisible(false)}
          document={editableDocument}
        />
      )}
    </>
  );
};

export default ProductDocumentLinkTab;
