import { Column } from 'primereact/column';
import { Dialog } from 'primereact/dialog';
import { Dispatch, ReactNode, SetStateAction, useState } from 'react';

import Button from '../Buttons/Button/Button';
import CustomDataTable from '../Data/CustomDataTable/CustomDataTable';
import styles from './AddItemDialog.module.scss';

type AddItemDialogProps<T extends Record<string, any>> = {
  visible: boolean;
  setVisible: Dispatch<SetStateAction<boolean>>;
  values: Array<T>;
  isLoading: boolean;
  dataKey: keyof T & string;
  selectionMode: 'single' | 'multiple';
  children: ReactNode;
  onAddAsync: (selected: T[]) => Promise<void> | void;
};

const AddItemDialog = <T extends Record<string, any>>({
  visible,
  setVisible,
  dataKey,
  values,
  isLoading,
  children,
  selectionMode,
  onAddAsync,
}: AddItemDialogProps<T>) => {
  const [selected, setSelected] = useState<Array<T>>([]);

  const handleAdd = async () => {
    await onAddAsync(selected);
    setVisible(false);
    setSelected([]);
  };

  const itemDialogFooter = (
    <div className={styles.bottomButton}>
      <Button label="Close" icon="pi pi-times" className="p-button-outlined" onClick={() => setVisible(false)} />
      <Button label="Add" disabled={!selected.length} icon="pi pi-plus" onClick={handleAdd} />
    </div>
  );

  const renderHeader = () => (
    <div className={styles.headerContainer}>
      <span>Add items</span>
    </div>
  );

  return (
    <Dialog
      visible={visible}
      className={styles.dialog}
      header={renderHeader}
      modal
      footer={itemDialogFooter}
      onHide={() => setVisible(false)}
    >
      <CustomDataTable
        value={values}
        dataKey={dataKey as string}
        loading={isLoading}
        selectionMode={selectionMode}
        onSelectionChange={(e) => setSelected(e.value as T[])}
        selection={selected}
        rows={10}
      >
        <Column selectionMode={selectionMode} headerStyle={{ width: '3em' }} />
        {children as JSX.Element}
      </CustomDataTable>
    </Dialog>
  );
};

export default AddItemDialog;
