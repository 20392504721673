import { RadioButton as PrimeRadioButton, RadioButtonProps } from 'primereact/radiobutton';
import { ReactNode } from 'react';

const RadioButton = (
  props: JSX.IntrinsicAttributes &
    JSX.IntrinsicClassAttributes<PrimeRadioButton> &
    Readonly<RadioButtonProps> &
    Readonly<{ children?: ReactNode }>,
) => <PrimeRadioButton {...props} />;

export default RadioButton;
