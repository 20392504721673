import { useRoadmapHistory } from 'domain/roadmap/hooks';
import { History } from 'domain/roadmap/types';
import { Column } from 'primereact/column';
import { DataTable } from 'primereact/datatable';
import { useEffect } from 'react';
import { toLocaleDateTimeString } from 'utils/datetime';

import { historyFields } from './historyFields';

type HistoryTableProps = {
  roadmapId: number;
  field?: string | null;
};

const dateTemplate = (rowData: History) => (rowData.timestamp ? toLocaleDateTimeString(rowData.timestamp) : null);

const fieldTemplate = (rowData: History) => historyFields[rowData.field];

const HistoryTable = ({ roadmapId, field = null }: HistoryTableProps) => {
  const { data: history, isLoading, refetch } = useRoadmapHistory(roadmapId, field);

  useEffect(() => {
    refetch();
  }, [refetch]);

  return (
    <DataTable
      value={history}
      loading={isLoading}
      dataKey="idRoadmapHistory"
      paginator
      responsiveLayout="scroll"
      rows={10}
      rowsPerPageOptions={[5, 10, 25]}
      paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
      currentPageReportTemplate="Showing {first} to {last} of {totalRecords} items"
      emptyMessage="No items found."
    >
      <Column field="user.name" header="User" sortable />
      <Column field="timestamp" header="Date" sortable body={dateTemplate} />
      <Column field="field" header="Field" sortable body={fieldTemplate} />
      <Column field="originalValue" header="Previous Value" />
      <Column field="newValue" header="New Value" />
    </DataTable>
  );
};

export default HistoryTable;

HistoryTable.defaultProps = {
  field: null,
};
