import { Button } from 'components/common';
import { useDetailedProduct } from 'domain/product/hook';
import { isInternalSelector, isLoggedInSelector } from 'features/auth/reducer/auth';
import { SuccessToast } from 'features/cart/components';
import { addToCart, cartProductsSelector } from 'features/cart/reducers/cart';
import { useMediaQuery, useToast } from 'hooks';
import { BlockUI } from 'primereact/blockui';
import { Dialog } from 'primereact/dialog';
import { TabPanel, TabView } from 'primereact/tabview';
import { memo, useCallback, useMemo, useState } from 'react';
import { use100vh } from 'react-div-100vh';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import { BASE_API_URL, REPO_URL } from 'services/api';
import pdf from 'services/pdf';
import { useAppDispatch, useAppSelector } from 'store';
import { capitalizeFirstLetter } from 'utils/string';

import styles from './ProductDetailDialog.module.scss';
import DocumentsTab from './components/DocumentsTab/DocumentsTab';
// eslint-disable-next-line import/no-cycle
import { Overview } from './components/Overview';
import { MaturitySection } from './components/Overview/components';
import { PlantsDataTable } from './components/PlantsDataTable';
import { ProcedureDataTable } from './components/ProcedureDataTable';
import RoadmapTab from './components/RoadmapTab/RoadmapTab';
import StreamVideoTab from './components/StreamVideoTab/StreamVideoTab';

enum Tabs {
  OVERVIEW = 0,
  BROCHURE = 1,
  BUSINESS_MODEL = 2,
  ROADMAP = 3,
  PLANTS = 4,
  VIDEOS = 5,
  NEWS_FEED = 6,
  PROCEDURES = 7,
  DOCUMENTS = 8,
  MATURITY = 9,
}

type ProductDetailDialogProps = {
  idProduct: number;
  visible: boolean;
  onClose: () => void;
};

const renderNewsFeed = (url: string) => (
  <iframe
    name="embed-feed"
    title="Yammer"
    src={url}
    style={{ border: '0px', overflow: 'hidden', width: '100%', height: '100%', minHeight: '400px' }}
  />
);

const renderIframe = (url: string, idProduct: number) => (
  <div style={{ width: '100%', height: '100%' }}>
    <iframe
      title={`${idProduct}`}
      id={`${idProduct}`}
      name={`${idProduct}`}
      sandbox="allow-same-origin"
      src={url}
      style={{ width: '100%', height: '100%' }}
      allowFullScreen
    />
  </div>
);

const ProductDetailDialog = ({ idProduct, visible, onClose }: ProductDetailDialogProps) => {
  const { data: detailedProduct, isLoading: isLoadingProduct } = useDetailedProduct(idProduct);
  const isLoggedIn = useAppSelector(isLoggedInSelector);
  const [activeIndex, setActiveIndex] = useState(0);
  const [isLoadingPDF, setIsLoadingPDF] = useState(false);
  const isInternal = useAppSelector(isInternalSelector);
  const { showSuccess } = useToast();
  const { isMobile } = useMediaQuery();
  const { t } = useTranslation();
  const cartProducts = useAppSelector(cartProductsSelector);
  const dispatch = useAppDispatch();

  const height = use100vh();

  const disabled = cartProducts.some((p) => p.idProduct === idProduct);

  const renderButtonLabel = () => (disabled ? t('cart.added') : t('cart.add-to-cart'));

  const getHeight = useMemo(() => {
    if (!height) return '90vh';
    return isMobile ? height : height * 0.9;
  }, [height, isMobile]);

  const handleAddToCart = () => {
    if (!detailedProduct) return;
    dispatch(
      addToCart({
        group: detailedProduct.group.productGroup,
        idProduct: detailedProduct.idProduct,
        product: detailedProduct.product,
        image2: detailedProduct.image2,
      }),
    );
    showSuccess({
      title: capitalizeFirstLetter(t('general.success')),
      detail: `${detailedProduct?.product} ${t('products.added-to-cart')}`,
      content: <SuccessToast title={detailedProduct.product} />,
      lifeTime: 5000,
    });
  };

  const handleCopyURL = useCallback(() => {
    navigator.clipboard.writeText(`${window.location.origin}?id=${idProduct}`);
    toast.success('Copied to clipboard!');
  }, [idProduct]);

  const renderTabsContent = useMemo(() => {
    return (
      detailedProduct && (
        <TabView
          activeIndex={activeIndex}
          onTabChange={(e) => {
            setActiveIndex(e.index);
          }}
        >
          <TabPanel key={Tabs.OVERVIEW} headerClassName="capitalize" header={t('general.overview')}>
            <Overview product={detailedProduct} />
          </TabPanel>
          {detailedProduct.documentation && (
            <TabPanel key={Tabs.BROCHURE} headerClassName="capitalize" header={t('products.brochure')}>
              {renderIframe(`${REPO_URL}/${detailedProduct.documentation}`, idProduct)}
            </TabPanel>
          )}
          {isLoggedIn && detailedProduct.businessModels && (
            <TabPanel key={Tabs.BUSINESS_MODEL} header="Business Models">
              {renderIframe(`${REPO_URL}/${detailedProduct?.businessModels}`, idProduct)}
            </TabPanel>
          )}
          {isInternal && (
            <TabPanel key={Tabs.ROADMAP} headerClassName="capitalize" header={t('roadmap.roadmap')}>
              <RoadmapTab product={detailedProduct} />
            </TabPanel>
          )}
          {isInternal && isLoggedIn && (
            <TabPanel key={Tabs.PLANTS} headerClassName="capitalize" header={t('products.plants')}>
              <PlantsDataTable productId={idProduct} />
            </TabPanel>
          )}
          {detailedProduct.streamVideos?.length > 0 && (
            <TabPanel key={Tabs.VIDEOS} header="Videos">
              <StreamVideoTab streamVideos={detailedProduct.streamVideos} />
            </TabPanel>
          )}
          {detailedProduct.linkYammer && (
            <TabPanel key={Tabs.NEWS_FEED} header="News Feed">
              {renderNewsFeed(detailedProduct.linkYammer)}
            </TabPanel>
          )}
          {isLoggedIn && detailedProduct.procedures?.length > 0 && (
            <TabPanel key={Tabs.PROCEDURES} header="Procedures">
              <ProcedureDataTable procedures={detailedProduct.procedures} />
            </TabPanel>
          )}
          {isLoggedIn && detailedProduct.documents?.length > 0 && (
            <TabPanel key={Tabs.DOCUMENTS} header="Documents">
              <DocumentsTab documents={detailedProduct.documents} />
            </TabPanel>
          )}
          {isInternal && isLoggedIn && (
            <TabPanel key={Tabs.MATURITY} headerClassName="capitalize" header="Maturity">
              <MaturitySection idProduct={idProduct} idMaturityModel={detailedProduct.idMaturityModel} />
            </TabPanel>
          )}
        </TabView>
      )
    );
  }, [activeIndex, detailedProduct, isInternal, isLoggedIn, idProduct, t]);

  const renderHeader = () => (
    <div className={styles.headerContainer}>
      <span>{detailedProduct?.product}</span>
      <div>
        {isLoggedIn && (
          <Button className={`${styles.addToCartButton} uppercase mr-2`} onClick={handleCopyURL} label="Copy URL" />
        )}
        <Button
          disabled={disabled}
          className={`${styles.addToCartButton} uppercase`}
          onClick={handleAddToCart}
          label={renderButtonLabel()}
        />
      </div>
    </div>
  );

  const exportPDF = async () => {
    try {
      setIsLoadingPDF(true);
      if (activeIndex === Tabs.BROCHURE && detailedProduct) {
        await pdf.downloadPDFAsync(
          `${BASE_API_URL}/pdf/from-repository/${detailedProduct.documentation}`,
          detailedProduct?.product,
        );
      } else if (activeIndex === Tabs.BUSINESS_MODEL && detailedProduct) {
        await pdf.downloadPDFAsync(
          `${BASE_API_URL}/pdf/from-repository/${detailedProduct.businessModels}`,
          detailedProduct?.product,
        );
      }
      toast.success(t('general.pdf-downloaded'));
    } finally {
      setIsLoadingPDF(false);
    }
  };

  const renderFooter = () => (
    <div className={styles.bottomButtons}>
      {(activeIndex === Tabs.BROCHURE || activeIndex === Tabs.BUSINESS_MODEL) && (
        <Button label="Export PDF" icon="pi pi-file-pdf" onClick={exportPDF} />
      )}
      <Button label="Close" icon="pi pi-check" onClick={onClose} autoFocus />
    </div>
  );

  return (
    <Dialog
      className={styles.dialog}
      style={{ minHeight: getHeight }}
      header={renderHeader}
      maximizable={!isMobile}
      visible={visible}
      blockScroll
      footer={renderFooter}
      onHide={onClose}
    >
      <BlockUI
        fullScreen
        blocked={isLoadingProduct || isLoadingPDF}
        template={<i className="pi pi-spin pi-spinner" style={{ fontSize: '3rem' }} />}
      >
        {renderTabsContent}
      </BlockUI>
    </Dialog>
  );
};

export default memo(ProductDetailDialog);
