import { useMsal } from '@azure/msal-react';
import { BusyContext } from 'components/common';
import { validateInternal } from 'features/auth/reducer/auth';
import 'primeflex/primeflex.min.css';
import 'primeicons/primeicons.css';
import 'primereact/resources/primereact.min.css';
import { Suspense, useEffect } from 'react';
import { QueryClient, QueryClientProvider } from 'react-query';
import { ReactQueryDevtools } from 'react-query/devtools';
import { BrowserRouter as Router } from 'react-router-dom';
import { useAppDispatch } from 'store';

import { AppProvider } from './contexts';
import { AppRoutes } from './routes';

const queryClient = new QueryClient();

const App = () => {
  const dispatch = useAppDispatch();
  const { inProgress } = useMsal();

  useEffect(() => {
    dispatch(validateInternal());
  }, [dispatch]);

  return (
    <Suspense fallback="loading">
      <QueryClientProvider client={queryClient}>
        <Router>
          <AppProvider>
            <AppRoutes />
          </AppProvider>
        </Router>
        <ReactQueryDevtools initialIsOpen={false} />
      </QueryClientProvider>
      <BusyContext isLoading={inProgress !== 'none'} />
    </Suspense>
  );
};

export default App;
