/**
 * @param {string} string - andritz
 * @returns {string} capitalized string = Andritz
 */

export function capitalizeFirstLetter(string: string): string {
  return string[0].toUpperCase() + string.slice(1) ?? '';
}

export function truncate(str: string, n: number, escape = true) {
  if (escape) return str.length > n ? `${str.slice(0, n - 1)}&hellip;` : str;
  return str.length > n ? `${str.slice(0, n - 1)}...` : str;
}
