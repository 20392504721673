import { StatusBadge } from 'components/common';
import { useProductsBrief } from 'features/admin/hooks';
import { ProductBrief } from 'features/products/types';
import { Column } from 'primereact/column';

import CrudTable from '../CrudTable/CrudTable';

type RelatedProductsTableProps = {
  products: ProductBrief[];
  onAddItems?: ((values: ProductBrief[]) => Promise<void>) | null;
  onRemoveItems?: ((value: ProductBrief) => Promise<void>) | null;
};

const typeBodyTemplate = ({ idType }: ProductBrief) =>
  idType === 0 ? <StatusBadge color="green">Product</StatusBadge> : <StatusBadge color="yellow">Component</StatusBadge>;

const RelatedProductsTable = ({ products, onAddItems = null, onRemoveItems = null }: RelatedProductsTableProps) => {
  const { data, isLoading } = useProductsBrief();

  return (
    <CrudTable
      currentValues={products}
      isLoading={isLoading}
      totalValues={data?.filter((item) => !products.some((b) => b.idProduct === item.idProduct)) ?? []}
      dataKey="idProduct"
      onAddAsync={onAddItems}
      onDeleteAsync={onRemoveItems}
    >
      <Column field="product" header="Product" sortable />
      <Column field="nickName" header="NickName" sortable />
      <Column field="idType" header="Type" sortable body={(rowData: ProductBrief) => typeBodyTemplate(rowData)} />
    </CrudTable>
  );
};

RelatedProductsTable.defaultProps = {
  onAddItems: null,
  onRemoveItems: null,
};

export default RelatedProductsTable;
