import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { IndustryBasicDTO } from 'domain/industry/types';
import { PlantStatusDTO } from 'domain/plant/types';
import { ProductGroup } from 'domain/productGroup/types';
import { PortfolioWithProductsDTO } from 'domain/productPortfolio/types';
import { ProductBasic } from 'features/products/types';
import type { RootState } from 'store/rootReducer';

interface SliceState {
  selectedIndustries: IndustryBasicDTO[];
  selectedPortfolios: PortfolioWithProductsDTO[];
  selectedProductGroups: ProductGroup[];
  selectedProducts: ProductBasic[];
  selectedStatus: PlantStatusDTO[];
  searchText: string;
}

const initialState: SliceState = {
  selectedIndustries: [],
  searchText: '',
  selectedPortfolios: [],
  selectedProductGroups: [],
  selectedProducts: [],
  selectedStatus: [],
};

export const countryMapFilterSlice = createSlice({
  name: 'country-map/filters',
  initialState,
  reducers: {
    setSelectedIndustries: (state, action: PayloadAction<IndustryBasicDTO[]>) => {
      state.selectedIndustries = action.payload;
    },
    setSelectedPortfolios: (state, action: PayloadAction<PortfolioWithProductsDTO[]>) => {
      state.selectedPortfolios = action.payload;
    },
    setSelectedProductGroups: (state, action: PayloadAction<ProductGroup[]>) => {
      state.selectedProductGroups = action.payload;
    },
    setSelectedProducts: (state, action: PayloadAction<ProductBasic[]>) => {
      state.selectedProducts = action.payload;
    },
    setSelectedStatus: (state, action: PayloadAction<PlantStatusDTO[]>) => {
      state.selectedStatus = action.payload;
    },
    setSearchText: (state, action: PayloadAction<string>) => {
      state.searchText = action.payload;
    },
  },
});

export const {
  setSelectedIndustries,
  setSearchText,
  setSelectedPortfolios,
  setSelectedProductGroups,
  setSelectedProducts,
  setSelectedStatus,
} = countryMapFilterSlice.actions;

export const selectedPortfoliosSelector = (state: RootState) => state.countryMapFilter.selectedPortfolios;
export const selectedIndustriesSelector = (state: RootState) => state.countryMapFilter.selectedIndustries;
export const selectedProductGroupsSelector = (state: RootState) => state.countryMapFilter.selectedProductGroups;
export const selectedProductsSelector = (state: RootState) => state.countryMapFilter.selectedProducts;
export const selectedStatusSelector = (state: RootState) => state.countryMapFilter.selectedStatus;
export const searchTextSelector = (state: RootState) => state.countryMapFilter.searchText;

export default countryMapFilterSlice.reducer;
