import classNames from 'classnames';
import { PlantBasicDTO } from 'domain/country/types';
import { usePlantsBasic } from 'domain/plant/hooks';
import { RoadMapUpdate } from 'domain/roadmap/types';
import { userSelector } from 'features/auth/reducer/auth';
import { editableRoadMapSelector } from 'features/roadmap/reducers/roadmap';
import { MultiSelect } from 'primereact/multiselect';
import { Control, Controller } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useAppSelector } from 'store';

import styles from '../RoadMapDetailForm.module.scss';

interface CustomersFieldProps {
  viewOnly: boolean;
  control: Control<RoadMapUpdate, unknown>;
  required: boolean;
}

const renderCustomers = (customers: PlantBasicDTO[]) =>
  customers.map((customer) => <div key={customer.idPlant}>{customer.plant}</div>);

const CustomersField = ({ viewOnly, control, required }: CustomersFieldProps) => {
  const { t } = useTranslation();
  const editableRoadMap = useAppSelector(editableRoadMapSelector);
  const user = useAppSelector(userSelector);
  const { data: plants } = usePlantsBasic(!!user);

  return (
    <Controller
      name="customers"
      control={control}
      rules={{ required }}
      render={({ field: { value, onChange }, fieldState: { error } }) => (
        <div className={styles.field}>
          <span className={classNames({ 'p-error': error && required, required })}>Customers</span>
          {viewOnly ? (
            <p className={styles.readonlyField}>{renderCustomers(editableRoadMap.customers ?? [])}</p>
          ) : (
            <MultiSelect
              value={value}
              className={classNames({ 'p-invalid': error })}
              onChange={(e) => onChange(e.value ?? [])}
              optionLabel="plant"
              dataKey="idPlant"
              filter
              filterBy="plant"
              display="chip"
              options={plants ?? []}
            />
          )}
          {error && <small className="p-error">{t('form.required')}</small>}
        </div>
      )}
    />
  );
};

export default CustomersField;
