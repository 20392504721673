import { AxiosError } from 'axios';
import { MaturityByProductGroup, MaturityModel, ProductMaturity } from 'domain/maturityModel/types';
import { ProductRelatedPlant } from 'domain/plant/types';
import { getProductDetailById, getProductPlants } from 'features/admin/api';
import { Product, ProductDocumentTypeDTO, ProductRoadmap } from 'features/products/types';
import { useQuery } from 'react-query';

import {
  getMaturityHistory,
  getMaturityModel,
  getMaturityReportByGroup,
  getProductDocumentType,
  getProductMaturity,
  getProductMaturityModels,
  getProductRoadMaps,
} from '../api';
import { ProductMaturityHistoryGroup } from '../types';

export const USE_MATURITY_MODEL_KEY = 'use-maturity-model-key';
export const USE_PRODUCT_MATURITY_KEY = 'product-maturity';
export const USE_PRODUCT_MATURITY_MODEL_KEY = 'use-product-maturity-model-key';
export const USE_PRODUCT_MATURITY_REPORT = 'product-maturity-report';
export const USE_PRODUCT_ROADMAP_KEY = 'use-product-roadmap-key';
export const USE_PRODUCT_DOCUMENT_TYPE = 'use-product-document-type-key';
export const USE_DETAILED_PRODUCT_KEY = 'use-details-product-key';
export const USE_PRODUCT_PLANTS_KEY = 'use-product-plants-key';
export const USE_PRODUCT_HISTORY_KEY = 'use-product-history-key';

export function useMaturityModel(productId: number, maturityModelId: number | null, enabled = true) {
  return useQuery<MaturityModel, AxiosError>(
    [USE_MATURITY_MODEL_KEY, productId, maturityModelId],
    () => getMaturityModel(productId, maturityModelId ?? 0),
    {
      staleTime: 1000 * 60 * 5,
      enabled: enabled && maturityModelId !== null,
    },
  );
}

export function useProductMaturity(maturityModelId: number, enabled = true) {
  return useQuery<MaturityModel, AxiosError>(
    [USE_PRODUCT_MATURITY_MODEL_KEY, maturityModelId],
    () => getProductMaturity(maturityModelId),
    {
      staleTime: 1000 * 60 * 15,
      enabled,
    },
  );
}

export const useProductMaturityByMaturityGroup = (maturityId: number) =>
  useQuery<MaturityByProductGroup, AxiosError>(
    [USE_PRODUCT_MATURITY_REPORT, 'group', maturityId],
    () => getMaturityReportByGroup(maturityId),
    {
      staleTime: 1000 * 60 * 15,
    },
  );

export function useProductMaturityModels() {
  return useQuery<ProductMaturity[], AxiosError>([USE_PRODUCT_MATURITY_MODEL_KEY], getProductMaturityModels, {
    staleTime: 1000 * 60 * 5,
  });
}

export function useProductRoadmap(productId: number) {
  return useQuery<ProductRoadmap[], AxiosError>(
    [USE_PRODUCT_ROADMAP_KEY, productId],
    () => getProductRoadMaps(productId),
    {
      staleTime: 1000 * 60 * 5,
    },
  );
}

export function useProductDocumentType() {
  return useQuery<ProductDocumentTypeDTO[], AxiosError>([USE_PRODUCT_DOCUMENT_TYPE], getProductDocumentType, {
    staleTime: 1000 * 60 * 15,
  });
}

export const useDetailedProduct = (id: number, enabled = true) =>
  useQuery<Product, AxiosError>([USE_DETAILED_PRODUCT_KEY, id], async () => getProductDetailById(id), {
    staleTime: 1000 * 60 * 15,
    enabled,
  });

export const useProductPlants = (id: number) =>
  useQuery<ProductRelatedPlant[], AxiosError>([USE_PRODUCT_PLANTS_KEY, id], async () => getProductPlants(id), {
    staleTime: 1000 * 60 * 15,
  });

export const useProductHistory = (id: number) =>
  useQuery<ProductMaturityHistoryGroup[], AxiosError>(
    [USE_PRODUCT_HISTORY_KEY, id],
    async () => getMaturityHistory(id),
    {
      staleTime: 1000 * 60 * 5,
    },
  );
