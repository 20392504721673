import { RoadMapUpdate } from 'domain/roadmap/types';
import { editableRoadMapSelector } from 'features/roadmap/reducers/roadmap';
import { InputText } from 'primereact/inputtext';
import { Control, Controller } from 'react-hook-form';
import { useAppSelector } from 'store';

import styles from '../RoadMapDetailForm.module.scss';
import { renderClickableField } from './templates';

interface GenericClickableFieldProps {
  viewOnly: boolean;
  control: Control<RoadMapUpdate, unknown>;
  title: string;
  name: keyof RoadMapUpdate;
  maxLength: number;
}

const GenericClickableField = ({ viewOnly, control, title, name, maxLength }: GenericClickableFieldProps) => {
  const editableRoadMap = useAppSelector(editableRoadMapSelector);
  return (
    <Controller
      name={name}
      control={control}
      render={({ field: { value, ...rest } }) => (
        <div className={styles.field}>
          <span>{title}</span>
          {viewOnly ? (
            renderClickableField(editableRoadMap.specification)
          ) : (
            <InputText value={value as string | undefined} {...rest} maxLength={maxLength} />
          )}
        </div>
      )}
    />
  );
};

export default GenericClickableField;
