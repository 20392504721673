import { Platform } from 'domain/platform/types';
import { toast } from 'react-toastify';
import api from 'services/api';

import { FundingSourceDTO, FundingSourceGroupDTO } from '../types';

const BASE_URL = 'fundingSources';

export const getPlatforms = async () => {
  const { data } = await api.get(`${BASE_URL}/platforms`);
  return data as Platform[];
};

export const getFundingSourcesByYear = async (year: number, platform?: number) => {
  const { data } = await api.get(`${BASE_URL}/year/${year}`, {
    params: { platform },
  });
  return (data as FundingSourceDTO[]).sort((a, b) => {
    if (!a.alias && !b.alias) {
      return a.name < b.name ? -1 : 1;
    }
    if (a.alias > b.alias) {
      return 1;
    }
    if (a.alias < b.alias) {
      return -1;
    }
    return 0;
  });
};

export const getFundingSourcesGroupByYear = async (year: number, platform?: number) => {
  const { data } = await api.get(`${BASE_URL}/year/${year}/group`, {
    params: { platform },
  });
  return data as FundingSourceGroupDTO[];
};

export const getFundingSources = async () => {
  const { data } = await api.get(`${BASE_URL}`);
  return data as FundingSourceDTO[];
};

export const getFundingSource = async (id: number) => {
  try {
    const { data } = await api.get(`${BASE_URL}/${id}`);
    return data as FundingSourceDTO;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  } catch (e: any) {
    toast.error(`Failed to get funding source: ${e.message}`);
    throw e;
  }
};

export const updateFundingSource = async (data: FundingSourceDTO) => {
  try {
    await api.put(BASE_URL, data);
    toast.success('Funding source updated successfully');
    return data;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  } catch (e: any) {
    toast.error(`Failed to update funding source: ${e.message}`);
    throw e;
  }
};

export const createFundingSource = async (data: FundingSourceDTO) => {
  try {
    await api.post(BASE_URL, data);
    toast.success('Funding Source created successfully');
    return data;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  } catch (e: any) {
    toast.error(`Failed to create funding source: ${e.message}`);
    throw e;
  }
};

export const deleteFundingSource = async (id: number) => {
  try {
    await api.delete(`${BASE_URL}/${id}`);
    toast.success('Funding Source deleted successfully');
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  } catch (e: any) {
    throw Error(`Failed to delete funding source: ${e.response?.data?.Message ?? e.message}`);
  }
};
