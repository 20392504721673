import classNames from 'classnames';
import { RoadMapUpdate } from 'domain/roadmap/types';
import { editableRoadMapSelector } from 'features/roadmap/reducers/roadmap';
import { InputTextarea } from 'primereact/inputtextarea';
import { Control, Controller } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useAppSelector } from 'store';

import styles from '../RoadMapDetailForm.module.scss';
import { renderClickableField } from './templates';

interface DescriptionFieldProps {
  viewOnly: boolean;
  control: Control<RoadMapUpdate, unknown>;
}

const DescriptionField = ({ viewOnly, control }: DescriptionFieldProps) => {
  const { t } = useTranslation();
  const editableRoadMap = useAppSelector(editableRoadMapSelector);
  return (
    <Controller
      name="description"
      control={control}
      rules={{ required: true }}
      render={({ field, fieldState: { error } }) => (
        <div className={styles.field}>
          <span className={classNames({ 'p-error': error, required: !viewOnly })}>Description</span>
          {viewOnly ? (
            renderClickableField(editableRoadMap.description)
          ) : (
            <InputTextarea {...field} rows={6} className={classNames({ 'p-invalid': error })} maxLength={1000} />
          )}
          {error && <small className="p-error">{t('form.required')}</small>}
        </div>
      )}
    />
  );
};

export default DescriptionField;
