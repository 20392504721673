import { SelectButton as PrimeSelectButton, SelectButtonProps } from 'primereact/selectbutton';
import { ReactNode } from 'react';

import styles from './Button.module.scss';

const SelectButton = ({
  className,
  ...props
}: JSX.IntrinsicAttributes &
  JSX.IntrinsicClassAttributes<PrimeSelectButton> &
  Readonly<SelectButtonProps> &
  Readonly<{ children?: ReactNode }>) => <PrimeSelectButton className={`${styles.button} ${className}`} {...props} />;

export default SelectButton;
