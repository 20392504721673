import { useContext } from 'react';

import { WizardContext } from '../contexts/WizardContext';

export function useWizard() {
  const context = useContext(WizardContext);
  if (!context) {
    throw new Error('useWizard must be used within an WizardContextProvider');
  }
  return context;
}
