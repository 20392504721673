import DefaultIcon from 'assets/images/Andritz.png';
import { Button } from 'components/common';
import { SuccessToast } from 'features/cart/components';
import { addToCart, cartProductsSelector } from 'features/cart/reducers/cart';
import { ProductBrief, ProductSimple } from 'features/products/types';
import { useToast } from 'hooks';
import { MouseEvent, memo, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useAppDispatch, useAppSelector } from 'store';
import { capitalizeFirstLetter } from 'utils/string';

import { CardSkeleton } from '../CardSkeleton';
// eslint-disable-next-line import/no-cycle
import { ProductDetailDialog } from '../ProductDetailDialog';
import styles from './ProductCard.module.scss';

type CardProps = {
  product: ProductSimple | ProductBrief;
  layout: 'small' | 'large';
};

const ProductCard = ({ product, layout }: CardProps) => {
  const [visible, setVisible] = useState(false);
  const [isMounted, setIsMounted] = useState(false);
  const { t } = useTranslation();
  const cartProducts = useAppSelector(cartProductsSelector);
  const { showSuccess } = useToast();
  const dispatch = useAppDispatch();

  useEffect(() => {
    setIsMounted(true);
  }, []);

  const onClick = () => setVisible(true);
  const disabled = cartProducts.some((p) => p.idProduct === product.idProduct);

  const handleAddToCart = (e: MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation(); // avoid opening modal
    dispatch(
      addToCart({
        idProduct: product.idProduct,
        image2: product.image2,
        product: product.product,
        group: 'group' in product ? product.group.productGroup : '',
      }),
    );
    showSuccess({
      title: capitalizeFirstLetter(t('general.success')),
      detail: `${product.product} ${t('products.added-to-cart')}`,
      content: <SuccessToast title={product.product} />,
      lifeTime: 5000,
    });
  };

  const renderButtonLabel = () => (disabled ? t('cart.added') : t('cart.add-to-cart'));

  const renderCard = () => (
    <>
      {layout === 'small' ? (
        <div
          tabIndex={product.idProduct}
          role="button"
          className={`${styles.smallProductCard} ${styles.blue}`}
          onKeyPress={() => onclick}
          onClick={onClick}
        >
          <div className={styles.cardHeader}>
            <img
              src={`${product.image || DefaultIcon}`}
              alt={product.product}
              onError={({ currentTarget }) => {
                currentTarget.onerror = null;
                currentTarget.src = DefaultIcon;
              }}
            />
          </div>
          <div className={styles.cardBody}>
            <span>
              <b>{product.product}</b>
            </span>
          </div>
        </div>
      ) : (
        <div
          tabIndex={product.idProduct}
          role="button"
          className={styles.largeProductCard}
          onKeyPress={() => onclick}
          onClick={onClick}
        >
          <div className={styles.cardHeader}>
            <img
              src={`${product.image || DefaultIcon}`}
              alt={product.product}
              onError={({ currentTarget }) => {
                currentTarget.onerror = null;
                currentTarget.src = DefaultIcon;
              }}
            />
          </div>
          <div className={styles.cardBody}>
            <h4>{product.product}</h4>
            <p>{product.description}</p>
          </div>
          <div className={styles.buttonContainer}>
            <Button
              disabled={disabled}
              className={`${styles.addToCartButton} uppercase`}
              onClick={handleAddToCart}
              label={renderButtonLabel()}
            />
          </div>
        </div>
      )}
      {visible && (
        <ProductDetailDialog idProduct={product.idProduct} visible={visible} onClose={() => setVisible(false)} />
      )}
    </>
  );

  return isMounted ? renderCard() : <CardSkeleton />;
};

export default memo(ProductCard);
