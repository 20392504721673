import { RoadMapUpdate, RoadMapVersion } from 'domain/roadmap/types';
import { isAdminSelector, userSelector } from 'features/auth/reducer/auth';
import { ProductBrief } from 'features/products/types';
import HistoryTable from 'features/roadmap/components/HistoryTable/HistoryTable';
import { editableRoadMapSelector } from 'features/roadmap/reducers/roadmap';
import { Button } from 'primereact/button';
import { Dropdown } from 'primereact/dropdown';
import { OverlayPanel } from 'primereact/overlaypanel';
import { MouseEvent, useCallback, useMemo, useRef } from 'react';
import { Control, Controller } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useAppSelector } from 'store';
import { isProductOwner, isTechnologyManager } from 'utils/permissions';

import styles from '../RoadMapDetailForm.module.scss';
import { versionTemplate } from './templates';

interface VersionFieldProps {
  viewOnly: boolean;
  isCustomerCare: boolean;
  control: Control<RoadMapUpdate, unknown>;
  product: ProductBrief | null;
  roadmapVersions: RoadMapVersion[];
  updateStatusesByVersion: (typeId: number, version: RoadMapVersion) => Promise<void>;
}

const VersionField = ({
  viewOnly,
  isCustomerCare,
  control,
  product,
  roadmapVersions,
  updateStatusesByVersion,
}: VersionFieldProps) => {
  const { t } = useTranslation();
  const editableRoadMap = useAppSelector(editableRoadMapSelector);
  const historyOverlayRef = useRef<OverlayPanel>(null);

  const isAdmin = useAppSelector(isAdminSelector);
  const user = useAppSelector(userSelector);

  const handleOpenVersionHistory = useCallback((e: MouseEvent<HTMLButtonElement>) => {
    historyOverlayRef.current?.toggle(e);
  }, []);

  const canChangeVersion =
    isAdmin || isCustomerCare || isTechnologyManager(user, product) || isProductOwner(user, product);

  const renderTip = useMemo(() => {
    if (viewOnly || (product != null && roadmapVersions.length > 0)) return undefined;
    if (!product)
      return (
        <div>
          <small className="p-warning">Select a product first</small>
        </div>
      );
    return (
      <div>
        <small className="p-warning">{`No versions saved for the product ${product?.product}`}</small>
      </div>
    );
  }, [product, roadmapVersions.length, viewOnly]);

  const versionLabel = useMemo(
    () => (
      <label htmlFor="version" className={styles.versionContainer}>
        <span style={{ marginRight: '0.2rem' }}>{isCustomerCare ? 'Fix Version' : 'Version'}</span>
        {editableRoadMap.versionChangesCount > 0 && (
          <div className={styles.versionContainer}>
            <small>{`- ${t('roadmap.changed-count-times', {
              count: editableRoadMap.versionChangesCount,
            })}`}</small>
            <Button type="button" rounded text icon="pi pi-eye" size="small" onClick={handleOpenVersionHistory} />
          </div>
        )}
      </label>
    ),
    [editableRoadMap.versionChangesCount, handleOpenVersionHistory, isCustomerCare, t],
  );

  return (
    <>
      <Controller
        name="version"
        control={control}
        render={({ field: { value, onChange } }) => (
          <div className={styles.field}>
            {versionLabel} {renderTip}
            {viewOnly || !canChangeVersion ? (
              <p className={styles.readonlyField}>
                {editableRoadMap.version && versionTemplate(editableRoadMap.version)}
              </p>
            ) : (
              <Dropdown
                disabled={product === null || !roadmapVersions.length}
                value={value}
                optionLabel="name"
                dataKey="idPlatformVersion"
                onChange={async (e) => {
                  onChange(e.value ?? null);
                  if (e.value != null && editableRoadMap.type != null) {
                    await updateStatusesByVersion(editableRoadMap.type.idRoadmapType, e.value);
                  }
                }}
                filter
                showClear
                filterBy="name"
                itemTemplate={versionTemplate}
                valueTemplate={versionTemplate}
                options={roadmapVersions}
              />
            )}
          </div>
        )}
      />
      <OverlayPanel ref={historyOverlayRef} showCloseIcon>
        <HistoryTable roadmapId={editableRoadMap.idRoadmap} field="IdRoadmapVersion" />
      </OverlayPanel>
    </>
  );
};

export default VersionField;
