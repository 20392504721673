import { useQuery } from 'react-query';

import {
  getBasicRoadmap,
  getFollowers,
  getResources,
  getRoadMapFunding,
  getRoadMapReleases,
  getRoadMapVersions,
  getRoadMapVersionsList,
  getRoadMaps,
  getRoadmapCategory,
  getRoadmapPlatforms,
  getRoadmapStatuses,
  getRoadmapTempo,
  getRoadmapsHistory,
  getRoadmapsStatuses,
  getRoadmapsTypes,
  getStatusResources,
} from '../API';
import type {
  BasicRoadmap,
  GetRoadmapTempoDTO,
  History,
  PlatformRelease,
  RoadMapType,
  RoadMapVersion,
  RoadmapCategory,
  RoadmapFollower,
  RoadmapFundingSourceList,
  RoadmapList,
  RoadmapPlatform,
  RoadmapResource,
  RoadmapStatus,
  RoadmapStatusSimpleDTO,
  RoadmapStatusWithResourceDTO,
} from '../types';

export const ROADMAP_FOLLOWERS_KEY = 'roadmap-followers';
export const ROADMAP_KEY = 'roadmaps';
export const ROADMAP_BASIC_KEY = 'roadmaps-basic';
export const ROADMAP_FUNDING_KEY = 'roadmaps-funding';
export const ROADMAP_RESOURCE_KEY = 'roadmap-resource';
export const ROADMAP_VERSION_KEY = 'roadmap-version';
export const ROADMAP_VERSION_LIST_KEY = 'roadmap-version-list';
export const ROADMAP_STATUS_RESOURCE_KEY = 'roadmap-status-resource';

export function useRoadmapFollowers(roadmapId: number, enabled = true) {
  return useQuery<RoadmapFollower[], Error>([ROADMAP_FOLLOWERS_KEY, roadmapId], () => getFollowers(roadmapId), {
    staleTime: 1000 * 60 * 5,
    enabled,
  });
}

export function useResources(year?: number, enabled = true) {
  return useQuery<RoadmapResource[], Error>([ROADMAP_RESOURCE_KEY, year], () => getResources(year), {
    staleTime: 1000 * 60 * 15,
    enabled,
  });
}

export function useBasicRoadmaps(enabled = true) {
  return useQuery<BasicRoadmap[], Error>([ROADMAP_BASIC_KEY], getBasicRoadmap, {
    staleTime: 1000 * 60 * 15,
    enabled,
  });
}

export function useRoadmapResources(idRoadmap: number, enabled = true) {
  return useQuery<RoadmapStatusWithResourceDTO[], Error>(
    [ROADMAP_STATUS_RESOURCE_KEY, idRoadmap],
    () => getStatusResources(idRoadmap),
    {
      staleTime: 1000 * 60 * 5,
      enabled,
    },
  );
}

export function useRoadmaps(isInternal: boolean) {
  return useQuery<RoadmapList[], Error>([ROADMAP_KEY, isInternal], getRoadMaps, {
    enabled: isInternal,
    staleTime: 1000 * 60 * 30,
  });
}

export function useRoadmapFunding(year: number, enabled = true) {
  return useQuery<RoadmapFundingSourceList[], Error>([ROADMAP_FUNDING_KEY, year], () => getRoadMapFunding(year), {
    enabled,
    staleTime: 1000 * 60 * 30,
  });
}

export function useRoadmapsVersionsList() {
  return useQuery<RoadMapVersion[], Error>(ROADMAP_VERSION_LIST_KEY, getRoadMapVersionsList, {
    staleTime: 1000 * 60 * 15,
  });
}

export function useRoadmapsVersions(enabled = true) {
  return useQuery<RoadMapVersion[], Error>(ROADMAP_VERSION_KEY, getRoadMapVersions, {
    staleTime: 1000 * 60 * 15,
    enabled,
  });
}

export function useRoadmapsType() {
  return useQuery<RoadMapType[], Error>('roadmaps-type', getRoadmapsTypes, {
    staleTime: 1000 * 60 * 15,
  });
}

export function useRoadmapsStatuses() {
  return useQuery<RoadmapStatus[], Error>('roadmaps-statuses', getRoadmapsStatuses, {
    staleTime: 1000 * 60 * 5,
  });
}

export function useRoadmapsReleases() {
  return useQuery<PlatformRelease[], Error>('roadmaps-releases', getRoadMapReleases, {
    staleTime: 1000 * 60 * 15,
  });
}

export function useRoadmapPlatforms() {
  return useQuery<RoadmapPlatform[], Error>('roadmap-platforms', getRoadmapPlatforms, {
    staleTime: 1000 * 60 * 15,
  });
}

export function useRoadmapHistory(roadmapId: number, field: string | null = null) {
  return useQuery<History[], Error>(
    ['roadmap-history', roadmapId, field],
    async () => getRoadmapsHistory(roadmapId, field),
    {
      staleTime: 1000 * 60 * 5,
    },
  );
}

export function useRoadmapStatuses(roadmapId: number, enabled = true) {
  return useQuery<RoadmapStatusSimpleDTO[], Error>(
    ['roadmap-statuses', roadmapId],
    async () => getRoadmapStatuses(roadmapId),
    {
      staleTime: 1000 * 60 * 15,
      enabled,
    },
  );
}

export function useRoadmapTempo(roadmapId: number, roadmapStatusId: number | null = null) {
  return useQuery<GetRoadmapTempoDTO[], Error>(
    ['roadmap-tempo', roadmapId, roadmapStatusId],
    async () => getRoadmapTempo(roadmapId, roadmapStatusId),
    {
      staleTime: 1000 * 60 * 5,
    },
  );
}

export function useRoadmapCategorization() {
  return useQuery<RoadmapCategory[], Error>(['roadmap-category'], getRoadmapCategory, {
    staleTime: 1000 * 60 * 30,
  });
}
