import { StreamVideo } from 'features/products/types';
import { DataView } from 'primereact/dataview';
import { toDateString } from 'utils/datetime';

import styles from './StreamVideoTab.module.scss';

interface StreamVideoTabProps {
  streamVideos: StreamVideo[];
}

const videoTemplate = (video: StreamVideo) => (
  <div className={styles.videoTemplateContainer}>
    <p className={styles.videoTitle}>{video.title}</p>
    <p className={styles.videoDate}>{video.timestamp && toDateString(video.timestamp, '-')}</p>
    <p className={styles.videoDescription}>{video.description}</p>
    <div key={video.idProductStream} className={styles.videoContainer}>
      <iframe
        width="640"
        height="360"
        src={video.hyperlink}
        style={{ border: 'none' }}
        allowFullScreen
        title={video.title}
      />
    </div>
  </div>
);

const sortVideos = (a: StreamVideo, b: StreamVideo) => {
  if (!a.timestamp && !b.timestamp) {
    if (a.title < b.title) return -1;
    if (a.title > b.title) return 1;
  }
  if (!a.timestamp) return 1;
  if (!b.timestamp) return -1;
  if (a.timestamp > b.timestamp) return -1;
  if (a.timestamp < b.timestamp) return 1;
  if (a.title < b.title) return -1;
  if (a.title > b.title) return 1;
  return 0;
};

const StreamVideoTab = ({ streamVideos }: StreamVideoTabProps) => {
  return (
    <div className={styles.videoListContainer}>
      <DataView
        value={[...streamVideos].sort(sortVideos)}
        layout="list"
        itemTemplate={videoTemplate}
        paginator
        rows={4}
      />
    </div>
  );
};

export default StreamVideoTab;
