import { Tooltip as PrimeTooltip, TooltipProps } from 'primereact/tooltip';
import { ReactNode } from 'react';

const Tooltip = (
  props: JSX.IntrinsicAttributes &
    JSX.IntrinsicClassAttributes<PrimeTooltip> &
    Readonly<TooltipProps> &
    Readonly<{ children?: ReactNode }>,
) => <PrimeTooltip {...props} />;

export default Tooltip;
