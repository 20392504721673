import { toast } from 'react-toastify';
import api from 'services/api';

import { AdUser, RoleDTO, UserBrief, UserWithRolesDTO } from '../types';

const BASE_URL = 'users';

export const getAdUser = async (value: string) => {
  const { data } = await api.get<AdUser>(`${BASE_URL}/ad/${value}`);
  return data;
};

export const getRoles = async () => {
  const { data } = await api.get<RoleDTO[]>(`${BASE_URL}/roles`);
  return data;
};

export const getUser = async (id: number) => {
  try {
    const { data } = await api.get<UserWithRolesDTO>(`${BASE_URL}/${id}`);
    return data;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  } catch (e: any) {
    toast.error(`Failed to get User: ${e.message}`);
    throw e;
  }
};

export const getUsersWithRoles = async () => {
  const { data } = await api.get<UserWithRolesDTO[]>(`${BASE_URL}/with-roles`);
  return data;
};

export const getUsers = async (hasCostGroup = false) => {
  const { data } = await api.get<UserBrief[]>(BASE_URL, {
    params: { hasCostGroup },
  });
  return data;
};

export const getProductOwners = async () => {
  const { data } = await api.get(`${BASE_URL}/product-owners`);
  return data as UserBrief[];
};

export const getProductManagers = async () => {
  const { data } = await api.get(`${BASE_URL}/product-managers`);
  return data as UserBrief[];
};

export const getTechnologyManagers = async () => {
  const { data } = await api.get(`${BASE_URL}/technology-managers`);
  return data as UserBrief[];
};

export const getIndustryManagers = async () => {
  const { data } = await api.get(`${BASE_URL}/industry-managers`);
  return data as UserBrief[];
};

export const getRoadmapOwners = async () => {
  const { data } = await api.get(`${BASE_URL}/roadmap-owners`);
  return data as UserBrief[];
};

export const updateUser = async (data: UserBrief) => {
  try {
    await api.put(BASE_URL, data);
    toast.success('User updated successfully');
    return data;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  } catch (e: any) {
    toast.error(`Failed to update User: ${e.message}`);
    throw e;
  }
};

export const createUser = async (data: UserBrief) => {
  try {
    await api.post(BASE_URL, data);
    toast.success('User created successfully');
    return data;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  } catch (e: any) {
    toast.error(`Failed to create User: ${e.message}`);
    throw e;
  }
};

export const deleteUser = async (id: number) => {
  try {
    await api.delete(`${BASE_URL}/${id}`);
    toast.success('User deleted successfully');
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  } catch (e: any) {
    throw Error(`Failed to delete User: ${e.response?.data?.Message ?? e.message}`);
  }
};
