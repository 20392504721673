import { RoadMapUpdate } from 'domain/roadmap/types';
import { Control } from 'react-hook-form';

import GenericClickableField from './GenericClickableField';

interface DevelopmentTasksFieldProps {
  viewOnly: boolean;
  control: Control<RoadMapUpdate, unknown>;
}

const DevelopmentTasksField = ({ viewOnly, control }: DevelopmentTasksFieldProps) => (
  <GenericClickableField
    control={control}
    viewOnly={viewOnly}
    title="Development Tasks"
    name="developmentTasks"
    maxLength={255}
  />
);

export default DevelopmentTasksField;
