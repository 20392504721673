import { MediaQueryContextProvider } from './MediaQueryContext/MediaQueryContext';
import { ToastContextProvider } from './ToastContext/ToastContext';
import { WizardContextProvider } from './WizardContext/WizardContext';

interface AppProviderProps {
  children: React.ReactNode;
}
export const AppProvider = ({ children }: AppProviderProps) => (
  <ToastContextProvider>
    <MediaQueryContextProvider>
      <WizardContextProvider>{children}</WizardContextProvider>
    </MediaQueryContextProvider>
  </ToastContextProvider>
);

export default AppProvider;
