import { getRoadmapsTypeStatusesByRoadmapId } from 'domain/roadmap/API';
import { useRoadmapsType } from 'domain/roadmap/hooks';
import { RoadMapType, RoadMapUpdate, RoadMapVersion } from 'domain/roadmap/types';
import { editableRoadMapSelector, setEditableRoadmap } from 'features/roadmap/reducers/roadmap';
import { confirmDialog } from 'primereact/confirmdialog';
import { Dropdown } from 'primereact/dropdown';
import { useCallback } from 'react';
import { Control, Controller, UseFormSetValue } from 'react-hook-form';
import { useAppDispatch, useAppSelector } from 'store';

import styles from '../RoadMapDetailForm.module.scss';

interface RoadmapTypeFieldProps {
  viewOnly: boolean;
  control: Control<RoadMapUpdate, unknown>;
  updateStatusesByVersion: (typeId: number, version: RoadMapVersion) => Promise<void>;
  setValue: UseFormSetValue<RoadMapUpdate>;
}

const RoadmapTypeField = ({ viewOnly, control, updateStatusesByVersion, setValue }: RoadmapTypeFieldProps) => {
  const editableRoadMap = useAppSelector(editableRoadMapSelector);
  const { data: types } = useRoadmapsType();
  const dispatch = useAppDispatch();

  const updateStatuses = useCallback(
    async (type: RoadMapType) => {
      const roadmapStatus = await getRoadmapsTypeStatusesByRoadmapId(editableRoadMap.idRoadmap, type.idRoadmapType);
      dispatch(
        setEditableRoadmap({
          ...editableRoadMap,
          status: null,
          type: { ...type, roadmapStatus },
        }),
      );
    },
    [dispatch, editableRoadMap],
  );

  const confirmChangeType = useCallback((accept: () => Promise<void>) => {
    confirmDialog({
      message: 'Changing the type will reset all quality assurance items and COSTS. Are you sure you want to proceed?',
      header: 'Change Roadmap Type',
      icon: 'pi pi-exclamation-triangle',
      accept: () => {
        accept();
      },
    });
  }, []);

  return (
    <Controller
      name="type"
      control={control}
      render={({ field: { value, onChange } }) => (
        <div className={styles.field}>
          <label htmlFor="type">Type</label>
          {viewOnly ? (
            <p className={styles.readonlyField}>{editableRoadMap.type?.name}</p>
          ) : (
            <Dropdown
              value={value}
              onChange={(e) =>
                confirmChangeType(async () => {
                  const type = e.value as RoadMapType;
                  onChange(type);
                  setValue('customers', []);
                  setValue('affectedVersions', []);
                  setValue('fixVersions', []);
                  setValue('participants', []);
                  await updateStatuses(type);
                  if (type != null && editableRoadMap.version != null) {
                    await updateStatusesByVersion(type.idRoadmapType, editableRoadMap.version);
                  }
                })
              }
              optionLabel="name"
              dataKey="idRoadmapType"
              options={types ?? []}
            />
          )}
        </div>
      )}
    />
  );
};

export default RoadmapTypeField;
