import api from 'services/api';

import {
  AddQualityAssuranceCertificate,
  QualityAssuranceCertificate,
  QualityAssuranceCertificateBrief,
  UpdateQualityAssuranceCertificate,
} from '../types';

export const createCertificate = async (procedure: AddQualityAssuranceCertificate): Promise<void> => {
  await api.post('qualityAssuranceCertificates', procedure);
};

export const getCertificates = async (): Promise<QualityAssuranceCertificateBrief[]> => {
  const { data } = await api.get('qualityAssuranceCertificates');
  return data as QualityAssuranceCertificateBrief[];
};

export const getCertificate = async (id: number): Promise<QualityAssuranceCertificate> => {
  const { data } = await api.get(`qualityAssuranceCertificates/${id}`);
  return data as QualityAssuranceCertificate;
};

export const updateCertificate = async (certificate: UpdateQualityAssuranceCertificate): Promise<void> => {
  await api.put(`qualityAssuranceCertificates/${certificate.idQualityAssuranceCertificate}`, certificate);
};

export const deleteCertificate = async (id: number): Promise<void> => api.delete(`qualityAssuranceCertificates/${id}`);
