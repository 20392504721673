import { RoadMapUpdate } from 'domain/roadmap/types';
import { SelectButton } from 'primereact/selectbutton';
import { Control, Controller } from 'react-hook-form';

import styles from '../RoadMapDetailForm.module.scss';

interface BugTypeFieldProps {
  viewOnly: boolean;
  control: Control<RoadMapUpdate, unknown>;
}

interface BugType {
  name: 'Internal' | 'Customer';
  value: 0 | 1;
}

const bugTypes: BugType[] = [
  { name: 'Internal', value: 0 },
  { name: 'Customer', value: 1 },
];

const BugTypeField = ({ viewOnly, control }: BugTypeFieldProps) => {
  return (
    <Controller
      name="bugType"
      control={control}
      render={({ field: { value, onChange } }) => (
        <div className={styles.field}>
          <span>Bug Type</span>
          <SelectButton
            readOnly={viewOnly}
            value={value}
            onChange={(e) => onChange(e.value)}
            optionLabel="name"
            dataKey="value"
            optionValue="value"
            options={bugTypes}
            unselectable={false}
          />
        </div>
      )}
    />
  );
};

export default BugTypeField;
