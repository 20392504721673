import { RoadMapVersion } from 'domain/roadmap/types';
import Linkify from 'react-linkify';
import { toDateString } from 'utils/datetime';

import styles from '../RoadMapDetailForm.module.scss';

export const versionTemplate = (option: RoadMapVersion | undefined) =>
  option ? `${option.name} (${toDateString(option.releaseDate)})` : null;

export const renderClickableField = (content: string) => (
  <p className={styles.readonlyField}>
    <Linkify
      componentDecorator={(decoratedHref, decoratedText, key) => (
        <a target="blank" href={decoratedHref} key={key}>
          {decoratedText}
        </a>
      )}
    >
      {content}
    </Linkify>
  </p>
);
