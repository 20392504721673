import { IndustryArea } from 'features/products/types';
import { useQuery } from 'react-query';
import api from 'services/api';

const getIndustryAreas = async () => {
  const { data } = await api.get('industryareas');
  return data as IndustryArea[];
};

export function useIndustryAreas() {
  return useQuery<IndustryArea[], Error>('industry-areas', getIndustryAreas, {
    staleTime: 1000 * 60 * 15,
  });
}
