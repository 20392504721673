import { Country } from 'features/products/types';
import api from 'services/api';

export const getCountries = async (withProducts = false) => {
  const { data } = await api.get(`countries?withProducts=${withProducts}`);
  return data as Country[];
};

export const updateCountry = async (data: Country) => {
  await api.put(`countries/${data.idCountry}`, data);
  return data;
};

export const createCountry = async (data: Country) => {
  await api.post('countries', data);
  return data;
};
