import { Button } from 'components/common';
import { addFilter, clearFilters, filtersValueSelector } from 'features/products/reducers/solutionSlice';
import { useMediaQuery, useWizard } from 'hooks';
import { Dialog } from 'primereact/dialog';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { use100vh } from 'react-div-100vh';
import { useTranslation } from 'react-i18next';
import { useAppDispatch, useAppSelector } from 'store';

import { Step } from '../Step';
import { Stepper } from '../Stepper';
import styles from './HelpDialog.module.scss';
import { getSteps } from './utils';

type HelpDialogProps = {
  visible: boolean;
  handleClose: () => void;
};

const HelpDialog = ({ visible, handleClose }: HelpDialogProps) => {
  const { t } = useTranslation();
  const [activeIndex, setActiveIndex] = useState(0);
  const { steps, setSteps } = useWizard();
  const filtersValue = useAppSelector(filtersValueSelector);
  const { isMobile } = useMediaQuery();
  const height = use100vh();
  const dispatch = useAppDispatch();

  const getHeight = () => {
    if (!height) return '90vh';
    return isMobile ? height : height * 0.9;
  };

  useEffect(() => {
    if (filtersValue) {
      const stepList = getSteps(filtersValue, t);
      setSteps(stepList);
    }
  }, [filtersValue, setSteps, t]);

  useEffect(() => {
    if (visible) {
      setActiveIndex(0);
      dispatch(clearFilters());
    }
  }, [dispatch, visible]);

  const clearValues = useCallback(() => {
    const stepArr = steps.map((x) => ({ ...x, values: [] }));
    setSteps(stepArr);
  }, [setSteps, steps]);

  const filterProducts = useCallback(() => {
    steps.forEach((step) => {
      step.values.forEach((val) => {
        if (val.isParent) {
          dispatch(addFilter({ id: val.id, name: val.value, group: val.group }));
          val.values?.forEach((v) => {
            dispatch(addFilter({ id: v.id, name: v.value, group: v.group }));
          });
        } else {
          dispatch(addFilter({ id: val.id, name: val.value, group: val.group }));
        }
      });
    });
    clearValues();
    handleClose();
  }, [clearValues, dispatch, handleClose, steps]);

  const handleGoBack = useCallback(() => setActiveIndex((i) => i - 1), []);
  const handleNext = useCallback(() => setActiveIndex((i) => i + 1), []);

  const renderFooter = useMemo(
    () => (
      <div className={styles.footer}>
        {activeIndex > 0 && <Button label="Back" icon="pi pi-arrow-left" onClick={handleGoBack} autoFocus />}
        {activeIndex < steps.length - 1 && (
          <Button
            label="Next"
            disabled={!steps[activeIndex].values?.length}
            icon="pi pi-arrow-right"
            iconPos="right"
            onClick={handleNext}
            autoFocus
          />
        )}
        {activeIndex === steps.length - 1 && (
          <Button
            label="Done"
            disabled={!steps[activeIndex].values?.length}
            icon="pi pi-check"
            iconPos="right"
            onClick={filterProducts}
            autoFocus
          />
        )}
      </div>
    ),
    [activeIndex, filterProducts, handleGoBack, handleNext, steps],
  );

  return (
    <Dialog
      header={t('wizard.help-wizard')}
      style={{ minHeight: getHeight() }}
      maximizable={!isMobile}
      visible={visible}
      className={styles.dialog}
      footer={renderFooter}
      onHide={handleClose}
    >
      <div className={styles.container}>
        <Stepper number={steps.length} activeIndex={activeIndex} />
        <Step index={activeIndex} />
      </div>
    </Dialog>
  );
};

export default HelpDialog;
