import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { ProductPortfolio, ProductPortfolioSection } from 'domain/productPortfolio/types';
import type { RootState } from 'store/rootReducer';

interface ModelState {
  editablePortfolio: ProductPortfolio | null;
}

const initialState: ModelState = {
  editablePortfolio: null,
};

export const productPortfolioSlice = createSlice({
  name: 'product-portfolio',
  initialState,
  reducers: {
    setProductPortfolio: (state, action: PayloadAction<ProductPortfolio | null>) => {
      state.editablePortfolio = action.payload;
    },
    updateProductPortfolioSections: (state, action: PayloadAction<ProductPortfolioSection[]>) => {
      if (state.editablePortfolio) {
        state.editablePortfolio.sections = action.payload;
      }
    },
  },
});

export const { setProductPortfolio, updateProductPortfolioSections } = productPortfolioSlice.actions;

export const productPortfolioSelector = (state: RootState) => state.productPortfolio.editablePortfolio;

export default productPortfolioSlice.reducer;
