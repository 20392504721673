import { useUsers } from 'domain/user/hooks';
import { UserBrief } from 'domain/user/types';
import { editableProductSelector, setEditableProduct } from 'features/products/reducers/solutionSlice';
import { Column } from 'primereact/column';
import { useAppDispatch, useAppSelector } from 'store';

import CrudTable from '../CrudTable/CrudTable';

type RelatedQASpecialistsProps = {
  specialists: UserBrief[];
};

const RelatedQASpecialists = ({ specialists }: RelatedQASpecialistsProps) => {
  const editableProduct = useAppSelector(editableProductSelector);
  const dispatch = useAppDispatch();
  const { data, isLoading } = useUsers();

  const addItems = async (values: UserBrief[]) => {
    if (editableProduct) {
      dispatch(
        setEditableProduct({
          ...editableProduct,
          qaSpecialists: [...(editableProduct.qaSpecialists ?? []), ...values],
        }),
      );
    }
  };

  const removeItems = async (value: UserBrief) => {
    if (editableProduct) {
      dispatch(
        setEditableProduct({
          ...editableProduct,
          qaSpecialists: editableProduct.qaSpecialists.filter((x) => x.idUser !== value.idUser),
        }),
      );
    }
  };

  return (
    <CrudTable
      currentValues={specialists}
      isLoading={isLoading}
      totalValues={data?.filter((item) => !specialists.some((b) => b.idUser === item.idUser)) ?? []}
      dataKey="idUser"
      onAddAsync={addItems}
      onDeleteAsync={removeItems}
    >
      <Column field="name" header="User" sortable />
    </CrudTable>
  );
};

export default RelatedQASpecialists;
