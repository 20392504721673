import { Group } from 'features/products/types';
import { useQuery } from 'react-query';
import api from 'services/api';

const getGroups = async () => {
  const { data } = await api.get('productGroups');
  return data as Group[];
};

export function useProductGroups() {
  return useQuery<Group[], Error>('product-groups', getGroups, {
    staleTime: 1000 * 60 * 5,
  });
}
