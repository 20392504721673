import { getProductsSimple } from 'features/admin/api';
import { ProductSimple, ShowType } from 'features/products/types';
import { useQuery } from 'react-query';

export function useProductsSimple(showType: ShowType[]) {
  const showProducts = showType.some((t) => t === 'Show Solutions');
  const showAddons = showType.some((t) => t === 'Show Add-ons');

  return useQuery<ProductSimple[], Error>(
    ['products-simple', showProducts, showAddons],
    () => getProductsSimple(showProducts, showAddons),
    {
      staleTime: 1000 * 60 * 15,
    },
  );
}
