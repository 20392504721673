import { Button, SelectButton } from 'components/common';
import { addFilter, clearFilters, filtersValueSelector } from 'features/products/reducers/solutionSlice';
import { FilterGroup, SelectOption, StepValue } from 'features/products/types';
import { useMediaQuery } from 'hooks';
import { Dialog } from 'primereact/dialog';
import { SelectButtonProps } from 'primereact/selectbutton';
import { useEffect, useState } from 'react';
import { use100vh } from 'react-div-100vh';
import storage from 'services/storage';
import { useAppDispatch, useAppSelector } from 'store';

import { Card } from '../HelpWizard/Card';
import styles from './InitialWizard.module.scss';

const buttonTemplate = (option: SelectButtonProps) => (
  <Card key={option.value.value} title={option.value.value} tooltip={option.tooltip ?? ''} />
);

const renderButtons = (options: SelectOption[], values: StepValue[], addValues: (value: StepValue[]) => void) => {
  return (
    <SelectButton
      className={styles.selectButton}
      value={values}
      options={options?.sort((a, b) => (a.value.value < b.value.value ? -1 : 1))}
      onChange={(e) => addValues(e.value as StepValue[])}
      itemTemplate={buttonTemplate}
      multiple
    />
  );
};

const InitialWizard = () => {
  const filtersValue = useAppSelector(filtersValueSelector);
  const { isMobile } = useMediaQuery();
  const [values, setValues] = useState<StepValue[]>([]);
  const [options, setOptions] = useState<SelectOption[]>([]);
  const [wasVisited, setWasVisited] = useState(() => {
    const storedValue = storage.getItem<boolean>('wasVisited');
    return storedValue !== null;
  });
  const height = use100vh();
  const dispatch = useAppDispatch();

  const localStorageSet = () => {
    storage.setItem('wasVisited', true);
    setWasVisited(true);
  };

  const getHeight = () => {
    if (!height) return '60vh';
    return isMobile ? height : height * 0.7;
  };

  useEffect(() => {
    const industry = filtersValue.find((f) => f.group === FilterGroup.INDUSTRY);
    if (industry) {
      setOptions(
        industry.children?.map(({ id: parentId, label, values: childValue }) => ({
          id: parentId,
          label,
          value:
            childValue.length > 0
              ? {
                  id: parentId,
                  group: FilterGroup.INDUSTRY,
                  value: label,
                  isParent: true,
                  values: childValue.map(({ id, value }) => ({
                    id,
                    group: FilterGroup.INDUSTRY_AREA,
                    value,
                    isParent: false,
                  })),
                }
              : { id: parentId, group: FilterGroup.INDUSTRY, value: label, isParent: false },
        })) ?? [],
      );
    }
  }, [filtersValue]);

  useEffect(() => {
    if (!wasVisited) {
      dispatch(clearFilters());
      setValues([]);
    }
  }, [dispatch, wasVisited]);

  const filterProducts = () => {
    values.forEach((value) => {
      if (value.isParent) {
        dispatch(addFilter({ id: value.id, name: value.value, group: value.group }));
        value.values?.forEach((v) => {
          dispatch(addFilter({ id: v.id, name: v.value, group: v.group }));
        });
      } else {
        dispatch(addFilter({ id: value.id, name: value.value, group: value.group }));
      }
    });
    localStorageSet();
  };

  const renderFooter = () => (
    <div className={styles.footer}>
      <Button
        label="Done"
        icon="pi pi-check"
        iconPos="right"
        onClick={filterProducts}
        autoFocus
        disabled={values.length === 0}
      />
    </div>
  );

  return (
    <Dialog
      style={{ minHeight: getHeight() }}
      maximizable={!isMobile}
      visible={!wasVisited}
      className={styles.dialog}
      footer={renderFooter}
      onHide={() => localStorageSet()}
    >
      <section className={styles.container}>
        <h2>What is your industry?</h2>
        <p>
          We have specific solutions for industries and we can find them more easily for you. Multiple industries can be
          chosen.
        </p>
        <div className={styles.cards}>{renderButtons(options, values, setValues)}</div>
      </section>
    </Dialog>
  );
};

export default InitialWizard;
