import { ProductRelatedPlant } from 'domain/plant/types';
import {
  ExportableSolution,
  Product,
  ProductBasic,
  ProductBrief,
  ProductList,
  ProductSimple,
  ProductUpdate,
} from 'features/products/types';
import { toast } from 'react-toastify';
import api from 'services/api';

export const updateProduct = async (product: ProductUpdate) => {
  const { data } = await api.put('products', product);
  return data as ProductBasic;
};

export const createProduct = async (product: ProductUpdate) => {
  const { data } = await api.post('products', product);
  return data as ProductBasic;
};

export const getProducts = async () => {
  const { data } = await api.get('products');
  toast.dismiss();
  return data as Product[];
};

export const getProductById = async (id: number, filterRelatedProducts = true) => {
  const { data } = await api.get(`products/${id}?filterRelatedProducts=${filterRelatedProducts}`);
  return data as Product;
};

export const getProductDetailById = async (id: number) => {
  const { data } = await api.get(`products/${id}/detail`);
  return data as Product;
};

export const getProductSimpleById = async (id: number) => {
  const { data } = await api.get(`products/${id}/simple`);
  return data as ProductSimple;
};

export const getProductListById = async (id: number) => {
  const { data } = await api.get(`products/${id}/list`);
  return data as ProductList;
};

export const getProductsSimple = async (showProducts: boolean, showAddons: boolean) => {
  const { data } = await api.get('products/simple', { params: { showProducts, showAddons } });
  toast.dismiss();
  return data as ProductSimple[];
};

export const getProductsBrief = async () => {
  const { data } = await api.get('products/brief');
  toast.dismiss();
  return data as ProductBrief[];
};

export const getProductsBasic = async () => {
  const { data } = await api.get('products/basic');
  toast.dismiss();
  return data as ProductBasic[];
};

export const getProductsList = async () => {
  const { data } = await api.get('products/list');
  toast.dismiss();
  return data as ProductList[];
};

export const deleteProduct = async (id: number) => {
  await api.delete(`products/${id}`);
};

export const addProductBenefit = async (id: number, ids: number[]) => {
  const data = { ids };
  await api.put(`products/${id}/benefit`, data);
};

export const getProductPlants = async (id: number) => {
  const { data } = await api.get(`products/${id}/plants`);
  return data as ProductRelatedPlant[];
};

export const getExportableProducts = async (all = false) => {
  const { data } = await api.get('products/exportable', { params: { all } });
  return data as ExportableSolution[];
};
