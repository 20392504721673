import { logError } from 'domain/error/api';
import { ErrorData } from 'domain/error/types';
import { isLoadingSelector } from 'features/auth/reducer/auth';
import { Button } from 'primereact/button';
import { ProgressSpinner } from 'primereact/progressspinner';
import { ErrorBoundary, FallbackProps } from 'react-error-boundary';
import { Outlet } from 'react-router-dom';
import { useAppSelector } from 'store';
import { debounce } from 'utils/debounce';

import Footer from '../Footer/Footer';
import TopBar from '../TopBar/TopBar';

function fallbackRender({ error, resetErrorBoundary }: FallbackProps) {
  const handleRefresh = () => {
    resetErrorBoundary();
  };

  return (
    <div style={{ margin: '1rem' }}>
      <h2 style={{ color: 'rgb(232, 59, 70)', fontSize: '2em' }}>An error occurred</h2>
      <div
        style={{
          backgroundColor: 'rgba(206, 17, 38, 0.5)',
          color: 'rgb(252, 207, 207)',
          padding: '1rem 1rem 1.5rem',
        }}
      >
        <pre style={{ color: 'white' }}>{error.message}</pre>
      </div>
      <p>
        The error has been reported to our support team. We will take a look at it and resolve the issue as soon as
        possible.
      </p>
      <Button onClick={handleRefresh}>Refresh</Button>
    </div>
  );
}

const onError = (error: Error, info: { componentStack: string }) => {
  const errorData: ErrorData = {
    error: error.toString(),
    componentStack: info.componentStack,
    userAgent: window.navigator.userAgent,
    screen: window.location.pathname,
  };
  debounce(() => {
    logError(errorData);
  }, 300)();
};

const MainLayout = () => {
  const isLoading = useAppSelector(isLoadingSelector);

  if (isLoading) {
    return <ProgressSpinner />;
  }

  return (
    <div className="main-layout layout-wrapper">
      <TopBar />
      <ErrorBoundary fallbackRender={fallbackRender} onError={onError}>
        <div className="main-content">
          <Outlet />
        </div>
        <Footer />
      </ErrorBoundary>
    </div>
  );
};

export default MainLayout;
