import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { MaturityModel, MaturityModelGroup } from 'domain/maturityModel/types';
import type { RootState } from 'store/rootReducer';

interface ModelState {
  editableModel: MaturityModel | null;
}

const initialState: ModelState = {
  editableModel: null,
};

export const maturityModelSlice = createSlice({
  name: 'maturity-model',
  initialState,
  reducers: {
    setMaturityModel: (state, action: PayloadAction<MaturityModel | null>) => {
      state.editableModel = action.payload;
    },
    updateMaturityModelGroups: (state, action: PayloadAction<MaturityModelGroup[]>) => {
      if (state.editableModel) {
        state.editableModel.groups = action.payload;
      }
    },
  },
});

export const { setMaturityModel, updateMaturityModelGroups } = maturityModelSlice.actions;

export const maturityModelSelector = (state: RootState) => state.maturityModel.editableModel;

export default maturityModelSlice.reducer;
