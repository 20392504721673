import { ScrollTop as PrimeScrollTop, ScrollTopProps } from 'primereact/scrolltop';
import { ReactNode } from 'react';

const ScrollTop = (
  props: JSX.IntrinsicAttributes &
    JSX.IntrinsicClassAttributes<PrimeScrollTop> &
    Readonly<ScrollTopProps> &
    Readonly<{ children?: ReactNode }>,
) => <PrimeScrollTop {...props} />;

export default ScrollTop;
