import { AxiosError } from 'axios';
import { useQuery } from 'react-query';

import { getIndustries } from '../API';
import type { Industry } from '../types';

export const USE_INDUSTRY_KEY = 'useIndustries';

export function useIndustries() {
  return useQuery<Industry[], AxiosError>(USE_INDUSTRY_KEY, getIndustries, {
    staleTime: 1000 * 60 * 5,
  });
}
