/* eslint-disable jsx-a11y/label-has-associated-control */
import classNames from 'classnames';
import { useCountryRegions } from 'features/admin/hooks';
import { Country } from 'features/products/types';
import { BlockUI } from 'primereact/blockui';
import { Dropdown } from 'primereact/dropdown';
import { InputText } from 'primereact/inputtext';
import { Control, Controller } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import styles from './CountryDetailForm.module.scss';

interface CountryDetailFormProps {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  control: Control<Country, any>;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  errors: any;
}

const CountryDetailForm = ({ control, errors }: CountryDetailFormProps) => {
  const { t } = useTranslation();

  const { data: regions, isLoading } = useCountryRegions();

  return (
    <BlockUI
      fullScreen
      blocked={isLoading}
      template={<i className="pi pi-spin pi-spinner" style={{ fontSize: '3rem' }} />}
    >
      <div className={`${styles.form} p-jc-center`}>
        <form className="p-fluid">
          <div className={styles.field}>
            <label htmlFor="plant" className={`capitalize ${classNames({ 'p-error': errors.plant })}`}>
              <span className="red">*</span> Name
            </label>
            <Controller
              name="name"
              control={control}
              rules={{ required: true }}
              render={({ field }) => <InputText {...field} className={classNames({ 'p-invalid': errors.plant })} />}
            />
            {errors.name && <small className="p-error">{t('form.required')}</small>}
          </div>

          <div className={styles.field}>
            <label htmlFor="region">
              <span className="red">*</span> Region
            </label>
            <Controller
              name="region"
              control={control}
              rules={{ required: true }}
              render={({ field }) => (
                <Dropdown
                  {...field}
                  optionLabel="name"
                  inputId="idCountryRegion"
                  id="idCountryRegion"
                  filter
                  filterBy="name"
                  options={regions}
                />
              )}
            />
            {errors.region && <small className="p-error">{t('form.required')}</small>}
          </div>
        </form>
      </div>
    </BlockUI>
  );
};

export default CountryDetailForm;
