import { useQuery } from 'react-query';

import {
  getIndustryManagers,
  getProductManagers,
  getProductOwners,
  getRoadmapOwners,
  getRoles,
  getTechnologyManagers,
  getUsers,
  getUsersWithRoles,
} from '../API/user';
import { RoleDTO, UserBrief, UserWithRolesDTO } from '../types/user.interfaces';

export const USE_USER_KEY = 'useUsers';
export const USE_USER_WITH_ROLES_KEY = 'useUsersWithRoles';
export const USE_ROLES_KEY = 'useRoles';
export const USE_PRODUCT_OWNER_KEY = 'useProductOwners';
export const USE_PRODUCT_MANAGER_KEY = 'useProductManagers';
export const USE_TECHNOLOGY_MANAGER_KEY = 'useTechnologyManagers';
export const USE_INDUSTRY_MANAGER_KEY = 'useIndustryManagers';
export const USE_ROADMAP_OWNERS_KEY = 'useRoadmapOwners';

export function useUsers(
  { hasCostGroup, enabled }: { hasCostGroup?: boolean; enabled?: boolean } = {
    hasCostGroup: false,
    enabled: true,
  },
) {
  return useQuery<UserBrief[], Error>([USE_USER_KEY, hasCostGroup], () => getUsers(hasCostGroup), {
    staleTime: 1000 * 60 * 15,
    enabled,
  });
}

export function useUsersWithRoles() {
  return useQuery<UserWithRolesDTO[], Error>(USE_USER_WITH_ROLES_KEY, getUsersWithRoles, {
    staleTime: 1000 * 60 * 5,
  });
}

export function useProductOwners() {
  return useQuery<UserBrief[], Error>(USE_PRODUCT_OWNER_KEY, getProductOwners, {
    staleTime: 1000 * 60 * 5,
  });
}

export function useProductManagers() {
  return useQuery<UserBrief[], Error>(USE_PRODUCT_MANAGER_KEY, getProductManagers, {
    staleTime: 1000 * 60 * 5,
  });
}

export function useTechnologyManagers() {
  return useQuery<UserBrief[], Error>(USE_TECHNOLOGY_MANAGER_KEY, getTechnologyManagers, {
    staleTime: 1000 * 60 * 5,
  });
}

export function useIndustryManagers() {
  return useQuery<UserBrief[], Error>(USE_INDUSTRY_MANAGER_KEY, getIndustryManagers, {
    staleTime: 1000 * 60 * 5,
  });
}

export function useRoadmapOwners() {
  return useQuery<UserBrief[], Error>(USE_ROADMAP_OWNERS_KEY, getRoadmapOwners, {
    staleTime: 1000 * 60 * 5,
  });
}

export function useRoles() {
  return useQuery<RoleDTO[], Error>(USE_ROLES_KEY, getRoles, {
    staleTime: 1000 * 60 * 30,
  });
}
