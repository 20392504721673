import { HomePage } from 'features/main/pages';
import type { RouteObject } from 'react-router-dom';
import { lazyImport } from 'utils/lazyImport';

const { ProductPage } = lazyImport(() => import('../pages'), 'ProductPage');
const { Benefit } = lazyImport(() => import('../pages'), 'Benefit');
const { ProductLifecycle } = lazyImport(() => import('../pages'), 'ProductLifecycle');
const { RoadMapPage } = lazyImport(() => import('../pages'), 'RoadMapPage');
const { CountryPage } = lazyImport(() => import('../pages'), 'CountryPage');

export const adminRoutes: RouteObject[] = [
  { index: true, element: <HomePage /> },
  { path: 'products', element: <ProductPage /> },
  { path: 'benefits', element: <Benefit /> },
  { path: 'roadmap', element: <RoadMapPage /> },
  { path: 'product-lifecycle', element: <ProductLifecycle /> },
  { path: 'countries', element: <CountryPage /> },
];
