import { MainLayout } from 'features/main/components';
import { NotFoundPage } from 'features/main/pages';
import { ProductsPage } from 'features/products/pages';
import type { RouteObject } from 'react-router-dom';
import { lazyImport } from 'utils/lazyImport';

const { CartRoutes } = lazyImport(() => import('features/cart/routes'), 'CartRoutes');

const { RoadMapRoutes } = lazyImport(() => import('features/roadmap/routes'), 'RoadMapRoutes');
const { MarketingDetail } = lazyImport(() => import('features/products/components'), 'MarketingDetail');

export const publicRoutes: RouteObject[] = [
  {
    path: '',
    element: <MainLayout />,
    children: [
      { index: true, element: <ProductsPage /> },
      { path: '*', element: <NotFoundPage /> },
      { path: '/solutions/cart/*', element: <CartRoutes /> },
      {
        path: '/roadmap/*',
        element: <RoadMapRoutes />,
      },
    ],
  },
  { path: '/marketing/:id', element: <MarketingDetail /> },
];
