import { useAccount, useMsal } from '@azure/msal-react';
import { Popup } from '@progress/kendo-react-popup';
import logo from 'assets/andritz-logo-icon.svg';
import claimLogo from 'assets/claim-logo-icon.svg';
import { BusyContext } from 'components/common';
import {
  authErrorSelector,
  clearState,
  isInternalSelector,
  isLoggedInSelector,
  loginAd,
} from 'features/auth/reducer/auth';
import { useMediaQuery } from 'hooks';
import { Button } from 'primereact/button';
import { Menu } from 'primereact/menu';
import { MenuItem } from 'primereact/menuitem';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';
import { useAppDispatch, useAppSelector } from 'store';
import { env } from 'utils/env';

import styles from './TopBar.module.scss';
import UserMenuPopup from './UserMenuPopup';

const itemTemplate = (item: MenuItem) => (
  <Link to={item.url as string} className={styles.itemLink}>
    {item.label}
  </Link>
);

const TopBar = () => {
  const { instance, accounts } = useMsal();
  const account = useAccount(accounts[0] || {});
  const menu = useRef<Menu>(null);
  const isValidating = useRef(false);
  const isLoggedIn = useAppSelector(isLoggedInSelector);
  const error = useAppSelector(authErrorSelector);
  const userAnchor = useRef<HTMLDivElement | null>(null);
  const isInternal = useAppSelector(isInternalSelector);
  const [showUser, setShowUser] = useState(false);
  const { isMobile } = useMediaQuery();
  const dispatch = useAppDispatch();

  const isDevelopment = process.env.NODE_ENV === 'development';
  const isTest = env.REACT_APP_IS_TEST;

  const onClickMenu = useCallback(() => {
    setShowUser((prev) => !prev);
  }, []);

  const handleCloseUserDialog = useCallback(() => {
    setShowUser(false);
  }, []);

  const handleLoginClick = useCallback(() => {
    dispatch(clearState());
    instance.loginPopup({
      redirectUri: window.location.origin,
      scopes: ['profile', 'user.read'],
    });
  }, [dispatch, instance]);

  useEffect(() => {
    if (!error && !isValidating.current && !isLoggedIn && account) {
      isValidating.current = true;
      instance
        .acquireTokenSilent({
          scopes: ['profile', 'user.read'],
          account,
        })
        .then(async (tokenResponse) => {
          if (tokenResponse) {
            await dispatch(loginAd({ accessToken: tokenResponse.accessToken })).unwrap();
            toast.dismiss();
          }
        })
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        .catch((e: any) => {
          toast.error(typeof e === 'object' ? 'Error while trying to login' : `${e}`, {
            toastId: 'login-error',
            autoClose: false,
          });
          setTimeout(async () => {
            try {
              await instance.logoutPopup();
            } catch {
              await instance.logoutRedirect();
            }
          }, 3000);
        })
        .finally(() => {
          isValidating.current = false;
        });
    }
  }, [account, dispatch, error, instance, isLoggedIn]);

  const items: MenuItem[] = useMemo(
    () => [
      {
        label: 'Manage',
        items: [
          {
            label: 'TIME ALLOCATION',
            url: '/manage/time-allocation',
            template: itemTemplate,
          },
          {
            label: 'PRODUCT MATURITY',
            url: '/admin/maturity-model',
            template: itemTemplate,
          },
          {
            label: 'INSTALLED BASE',
            url: '/admin/country/maps',
            template: itemTemplate,
          },
        ],
      },
    ],
    [],
  );

  return (
    <header role="banner" className={`layout-topbar ${styles.layoutTopbar}`}>
      {isTest && <div className={styles.testRibbon}>TEST ENV</div>}
      {isDevelopment && <div className={styles.testRibbon}>DEV ENV</div>}
      <div className={styles.container}>
        <div className={styles.logoWrapper}>
          <a href="https://www.andritz.com/group-en" tabIndex={0}>
            <img alt="ANDRITZ logo" src={logo} />
          </a>
          <span>
            <img alt="ANDRITZ Site Claim logo" src={claimLogo} />
          </span>
        </div>
      </div>
      <div className={`${styles.navbar} ${isTest ? styles.test : ''}`}>
        <div className={styles.containerNavBar}>
          <Link to="/" style={{ marginRight: isMobile ? '2rem' : '3rem' }}>
            <span className={styles.link}>{isMobile ? 'ASF' : 'AUTOMATION SOLUTION FINDER'}</span>
          </Link>
          {isInternal && (
            <>
              <Link to="/roadmap" style={{ marginRight: isMobile ? '2rem' : '3rem' }}>
                <span className={styles.link}>ROADMAPS</span>
              </Link>
              {isLoggedIn &&
                (isMobile ? (
                  <Button
                    icon="pi pi-bars"
                    className="mr-2"
                    style={{ color: '#fff' }}
                    onClick={(event) => menu.current?.toggle(event)}
                    aria-haspopup
                    link
                  />
                ) : (
                  <>
                    <Link id="time-allocation-link" to="/manage/time-allocation" style={{ marginRight: '3rem' }}>
                      <span className={styles.link}>TIME ALLOCATION</span>
                    </Link>
                    <Link to="/admin/maturity-model" style={{ marginRight: '3rem' }}>
                      <span className={styles.link}>PRODUCT MATURITY</span>
                    </Link>
                    <Link to="/admin/country/maps">
                      <span className={styles.link}>INSTALLED BASE</span>
                    </Link>
                  </>
                ))}
            </>
          )}
        </div>
        {isInternal && (
          <div className={styles.rightContainer}>
            {isLoggedIn ? (
              <>
                <div className={styles.user} tabIndex={0} role="button" onKeyDown={onClickMenu} onClick={onClickMenu}>
                  <div className={styles.avatar} ref={userAnchor}>
                    <img src="/images/icons/userWhite.png" alt="user" />
                  </div>
                </div>
                <Popup anchor={userAnchor.current} show={showUser} popupClass={styles.popup}>
                  <UserMenuPopup handleClose={handleCloseUserDialog} />
                </Popup>
              </>
            ) : (
              <Button text onClick={handleLoginClick}>
                <span className={styles.link}>LOGIN</span>
              </Button>
            )}
          </div>
        )}
      </div>
      <BusyContext isLoading={isValidating.current} />
      <Menu model={items} popup ref={menu} />
    </header>
  );
};

export default TopBar;
