import classNames from 'classnames';
import { Button, InputText } from 'components/common';
import { createFollower } from 'domain/roadmap/API';
import { ROADMAP_FOLLOWERS_KEY, useRoadmapFollowers } from 'domain/roadmap/hooks';
import { RoadmapFollower } from 'domain/roadmap/types';
import { userSelector } from 'features/auth/reducer/auth';
import { addFollowingIds } from 'features/roadmap/reducers/roadmap';
import { OverlayPanel } from 'primereact/overlaypanel';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useMutation, useQueryClient } from 'react-query';
import { toast } from 'react-toastify';
import { useAppDispatch, useAppSelector } from 'store';

import styles from './AddFollowerDialog.module.scss';

interface AddFollowerDialogProps {
  op: React.RefObject<OverlayPanel>;
  idRoadmap: number;
}

const AddFollowerDialog = ({ op, idRoadmap }: AddFollowerDialogProps) => {
  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm<RoadmapFollower>();
  const { t } = useTranslation();
  const { mutateAsync: mutateCreateAsync } = useMutation(createFollower);
  const queryClient = useQueryClient();
  const user = useAppSelector(userSelector);
  const { data: roadmapFollowers } = useRoadmapFollowers(idRoadmap, !!user);
  const dispatch = useAppDispatch();

  const isValidEmail = (email: string) => {
    return /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email);
  };

  const onSubmit: SubmitHandler<RoadmapFollower> = async (data) => {
    try {
      if (roadmapFollowers?.some((rf) => rf.email === data.email && rf.idRoadmap !== data.idRoadmap)) {
        toast.error('Follower email already exists');
        return;
      }
      if (!isValidEmail(data.email)) {
        toast.error('Please type a valid email');
        return;
      }
      const item: RoadmapFollower = {
        ...data,
        idRoadmap,
      };
      await mutateCreateAsync({ idRoadmap, follower: item });
      dispatch(addFollowingIds(idRoadmap));
      queryClient.invalidateQueries(ROADMAP_FOLLOWERS_KEY);
    } catch {
      toast.error('Error adding follower');
      return;
    }
    op.current?.hide();
  };

  return (
    <OverlayPanel
      ref={op}
      showCloseIcon
      dismissable
      id="overlay_panel"
      style={{ width: '450px' }}
      className="overlaypanel-demo"
    >
      <form className="p-fluid" onSubmit={handleSubmit(onSubmit)}>
        <div className={styles.field}>
          <label htmlFor="Name" className={`capitalize ${classNames({ 'p-error': errors.name })}`}>
            <span className="red">*</span> Name
          </label>
          <div className="p-inputgroup">
            <Controller
              name="name"
              control={control}
              rules={{ required: true }}
              render={({ field }) => (
                <InputText {...field} data-testid="name" className={classNames({ 'p-invalid': errors.name })} />
              )}
            />
          </div>{' '}
          {errors.email && <small className="p-error">{t('form.required')}</small>}
        </div>
        <div className={styles.field}>
          <label htmlFor="Email" className={`capitalize ${classNames({ 'p-error': errors.email })}`}>
            <span className="red">*</span> Email
          </label>
          <div className="p-inputgroup">
            <Controller
              name="email"
              control={control}
              rules={{ required: true }}
              render={({ field }) => (
                <InputText {...field} data-testid="email" className={classNames({ 'p-invalid': errors.name })} />
              )}
            />
          </div>{' '}
          {errors.email && <small className="p-error">{t('form.required')}</small>}
        </div>
        <div className={styles.overlayButton}>
          <Button style={{ width: '6rem', marginTop: '1rem' }} type="submit" label="Done" />
        </div>
      </form>
    </OverlayPanel>
  );
};

export default AddFollowerDialog;
