import { RoadMapUpdate } from 'domain/roadmap/types';
import { useUsers } from 'domain/user/hooks';
import { userSelector } from 'features/auth/reducer/auth';
import { editableRoadMapSelector } from 'features/roadmap/reducers/roadmap';
import { Dropdown } from 'primereact/dropdown';
import { Control, Controller } from 'react-hook-form';
import { useAppSelector } from 'store';

import styles from '../RoadMapDetailForm.module.scss';

interface ReporterFieldProps {
  viewOnly: boolean;
  control: Control<RoadMapUpdate, unknown>;
}

const ReporterField = ({ viewOnly, control }: ReporterFieldProps) => {
  const editableRoadMap = useAppSelector(editableRoadMapSelector);
  const user = useAppSelector(userSelector);
  const { data: users } = useUsers({ enabled: !!user });
  return (
    <Controller
      name="reporter"
      control={control}
      render={({ field: { value, onChange } }) => (
        <div className={styles.field}>
          <span>Reporter</span>
          {viewOnly ? (
            <p className={styles.readonlyField}>{editableRoadMap.reporter?.name}</p>
          ) : (
            <Dropdown
              value={value}
              onChange={(e) => onChange(e.value ?? null)}
              optionLabel="name"
              dataKey="idUser"
              filter
              filterBy="name"
              options={users}
            />
          )}
        </div>
      )}
    />
  );
};

export default ReporterField;
