import { FilterGroup, Filters, StepProps } from 'features/products/types';
import { TFunction } from 'react-i18next';

export const getSteps = (filters: Filters[], t: TFunction<'translation', undefined>): StepProps[] => {
  const steps: StepProps[] = [];
  const personas = filters.find((f) => f.group === FilterGroup.USER_PERSONA);
  if (personas) {
    steps.push({
      title: t('wizard.persona-title'),
      content: t('wizard.persona-content'),
      group: FilterGroup.USER_PERSONA,
      options: personas.values.map(({ id, value }) => ({
        label: value,
        value: { id, group: FilterGroup.USER_PERSONA, value, isParent: false },
      })),
      values: [],
    });
  }

  const industry = filters.find((f) => f.group === FilterGroup.INDUSTRY);
  if (industry) {
    steps.push({
      title: t('wizard.industry-title'),
      content: t('wizard.industry-content'),
      group: FilterGroup.INDUSTRY,
      options: industry.children?.map(({ id: parentId, label, values }) => ({
        id: parentId,
        label,
        value:
          values.length > 0
            ? {
                id: parentId,
                group: FilterGroup.INDUSTRY,
                value: label,
                isParent: true,
                values: values.map(({ id, value }) => ({
                  id,
                  group: FilterGroup.INDUSTRY_AREA,
                  value,
                  isParent: false,
                })),
              }
            : { id: parentId, group: FilterGroup.INDUSTRY, value: label, isParent: false },
      })),
      values: [],
    });
  }

  const benefits = filters.find((f) => f.group === FilterGroup.BENEFIT_GROUP);
  if (benefits) {
    steps.push({
      title: t('wizard.problem-title'),
      content: t('wizard.benefit-content'),
      group: FilterGroup.BENEFIT_GROUP,
      groups: benefits.children?.map(({ label, values, id: parentId }) => ({
        id: parentId,
        group: label,
        options: values.map(({ id, value, tooltip }) => ({
          id,
          label: value,
          value: { id, group: FilterGroup.BENEFIT, value, isParent: false },
          tooltip,
        })),
      })),
      values: [],
    });
  }

  return [...steps];
};
