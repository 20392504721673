import { Button } from 'components/common';
import { Column } from 'primereact/column';
import { DataTable, DataTableProps } from 'primereact/datatable';
import { Dialog } from 'primereact/dialog';
import { InputText } from 'primereact/inputtext';
import { Toolbar } from 'primereact/toolbar';
import { Tooltip } from 'primereact/tooltip';
import { ReactNode, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';

import { HandleAdd, HandleDelete, HandleEdit } from './CrudDataTable.interface';
import styles from './CrudDataTable.module.scss';

interface CrudDataTableProps<T extends Record<string, any>> extends DataTableProps<Array<T>> {
  children: ReactNode;
  value: Array<T>;
  title?: string;
  rows?: number;
  onRefresh?: (() => void) | null;
  handleDelete?: HandleDelete<T> | null;
  handleEdit?: HandleEdit<T> | null;
  handleAdd?: HandleAdd<T> | null;
}

const CrudDataTable = <T extends Record<string, any>>({
  children,
  value,
  title = '',
  rows = 10,
  onRefresh = null,
  handleDelete = null,
  handleEdit = null,
  handleAdd = null,
  ...rest
}: CrudDataTableProps<T>) => {
  const [globalFilter, setGlobalFilter] = useState(null);
  const [item, setItem] = useState<T | null>(null);
  const [showDeleteItemDialog, setShowDeleteItemDialog] = useState(false);
  const [showEditDialog, setShowEditDialog] = useState(false);
  const [showAddDialog, setShowAddDialog] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const { t } = useTranslation();

  const header = (
    <div className="table-header">
      {title}
      <InputText
        className={styles.searchInput}
        type="search"
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        onInput={(e: any) => setGlobalFilter(e.target.value)}
        placeholder="Search..."
      />
      {!!onRefresh && <Button icon="pi pi-refresh" onClick={onRefresh} />}
    </div>
  );

  const openNew = () => setShowAddDialog(true);

  const leftToolbarTemplate = () =>
    handleAdd && (
      <>
        <Tooltip
          disabled={handleAdd.canExecute ? handleAdd.canExecute() : true}
          mouseTrack
          target=".create-button-wrapper"
          content="Not enough permission to create items"
        />
        <div className="create-button-wrapper">
          <Button
            label="New"
            icon="pi pi-plus"
            disabled={handleAdd.canExecute ? !handleAdd.canExecute() : false}
            className="create-button p-button-success mr-2 p-mb-2"
            onClick={openNew}
          />
        </div>
      </>
    );

  const confirmDeleteItem = (itemToDelete: T) => {
    setItem(itemToDelete);
    setShowDeleteItemDialog(true);
  };

  const deleteButtonTemplate = (rowData: T) => (
    <Button
      className="p-button-rounded p-button-warning"
      disabled={handleDelete?.canExecute ? !handleDelete.canExecute(rowData) : false}
      disabledTooltip="Not enough permission to delete this item"
      icon="pi pi-trash"
      tooltip="Delete"
      onClick={() => confirmDeleteItem(rowData)}
    />
  );

  const handleOnEdit = (itemToEdit: T) => {
    setItem(itemToEdit);
    setShowEditDialog(true);
  };

  const editButtonTemplate = (rowData: T) => (
    <Button
      className="p-button-rounded p-button-success mr-2"
      disabled={handleEdit?.canExecute ? !handleEdit.canExecute(rowData) : false}
      disabledTooltip="Not enough permission to edit this item"
      icon="pi pi-pencil"
      tooltip="Edit"
      onClick={() => handleOnEdit(rowData)}
    />
  );

  const closeDeleteDialog = () => {
    if (handleDelete?.onClose) {
      handleDelete.onClose();
    }
    setShowDeleteItemDialog(false);
  };

  const closeEditDialog = () => {
    if (handleEdit?.onClose) {
      handleEdit.onClose();
    }
    setShowEditDialog(false);
  };

  const closeAddDialog = () => {
    if (handleAdd?.onClose) {
      handleAdd.onClose();
    }
    setShowAddDialog(false);
  };

  const handleDeleteButton = async () => {
    try {
      setIsLoading(true);
      if (handleDelete) {
        await handleDelete.onDelete(item as T);
      }
      closeDeleteDialog();
    } catch (error) {
      toast.error(`${error}`);
    } finally {
      setIsLoading(false);
    }
  };

  const handleEditButton = async () => {
    try {
      setIsLoading(true);
      if (handleEdit) {
        await handleEdit.onEdit(item as T);
      }
      closeEditDialog();
    } catch (error) {
      toast.error(`${error}`);
    } finally {
      setIsLoading(false);
    }
  };

  const handleAddButton = async () => {
    try {
      setIsLoading(true);
      if (handleAdd) {
        await handleAdd.onAdd(item as T);
      }
      closeAddDialog();
    } catch (error) {
      toast.error(`${error}`);
    } finally {
      setIsLoading(false);
    }
  };

  const renderHeader = () =>
    item &&
    handleEdit && (
      <div className={styles.headerContainer}>
        <span>{handleEdit.itemName ? `Editing ${item[handleEdit.itemName]}` : handleEdit.title?.(item)}</span>
      </div>
    );

  const renderAddHeader = () => (
    <div className={styles.headerContainer}>
      <span>{handleAdd?.title ?? 'Create'}</span>
    </div>
  );

  const editItemDialogFooter = (
    <div className={styles.bottomButton}>
      <Button label="Cancel" icon="pi pi-times" className="p-button-outlined" onClick={closeEditDialog} />
      <Button label="Save" loading={handleEdit?.isLoading ?? isLoading} icon="pi pi-check" onClick={handleEditButton} />
    </div>
  );

  const addItemDialogFooter = (
    <div className={styles.bottomButton}>
      <Button label="Cancel" icon="pi pi-times" className="p-button-outlined" onClick={closeAddDialog} />
      <Button label="Save" loading={handleAdd?.isLoading ?? isLoading} icon="pi pi-check" onClick={handleAddButton} />
    </div>
  );

  const deleteItemDialogFooter = (
    <div className={styles.bottomButton}>
      <Button label="Cancel" icon="pi pi-times" className="p-button-outlined" onClick={closeDeleteDialog} />
      <Button
        label="Delete"
        className="p-button-danger"
        loading={handleDelete?.isLoading ?? isLoading}
        icon="pi pi-check"
        onClick={handleDeleteButton}
      />
    </div>
  );

  return (
    <>
      {handleAdd && <Toolbar className="p-mb-4 p-toolbar" left={leftToolbarTemplate} />}
      <DataTable
        {...rest}
        value={value}
        header={header}
        paginator
        rows={rows}
        responsiveLayout="scroll"
        globalFilter={globalFilter}
        rowsPerPageOptions={[Math.ceil(rows / 2), rows, rows * 2]}
        paginatorTemplate={`FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport 
  RowsPerPageDropdown`}
        currentPageReportTemplate="Showing {first} to {last} of {totalRecords} items"
        emptyMessage={t('items-found', { count: 0 })}
      >
        {handleEdit && (
          <Column
            headerStyle={{ width: '3rem' }}
            bodyStyle={{ padding: 0, textAlign: 'center' }}
            style={{ padding: 0 }}
            body={editButtonTemplate}
          />
        )}
        {handleDelete && (
          <Column
            headerStyle={{ width: '3rem' }}
            bodyStyle={{ padding: 0, textAlign: 'center' }}
            style={{ padding: 0 }}
            body={deleteButtonTemplate}
          />
        )}
        {children}
      </DataTable>
      {showDeleteItemDialog && handleDelete && item && (
        <Dialog
          visible={showDeleteItemDialog}
          style={{ width: '450px' }}
          header={<span className="capitalize">{t('general.confirm-delete')}</span>}
          modal
          footer={deleteItemDialogFooter}
          onHide={closeDeleteDialog}
        >
          <div className="confirmation-content">
            <i className="pi pi-exclamation-triangle p-mr-3" style={{ fontSize: '2rem' }} />
            {handleDelete.itemName ? (
              <span>{t('general.delete-confirm', { item: item[handleDelete.itemName] })}</span>
            ) : (
              handleDelete.title?.(item)
            )}
          </div>
        </Dialog>
      )}
      {showEditDialog && handleEdit && item && (
        <Dialog
          visible={showEditDialog}
          className={handleEdit.dialogClassName ?? styles.dialog}
          header={renderHeader}
          modal
          blockScroll
          footer={editItemDialogFooter}
          onHide={closeEditDialog}
        >
          {handleEdit.renderForm(item)}
        </Dialog>
      )}
      {showAddDialog && handleAdd && (
        <Dialog
          visible={showAddDialog}
          className={handleAdd.dialogClassName ?? styles.dialog}
          header={renderAddHeader}
          modal
          blockScroll
          footer={addItemDialogFooter}
          onHide={closeAddDialog}
        >
          {handleAdd.renderForm()}
        </Dialog>
      )}
    </>
  );
};

export default CrudDataTable;

CrudDataTable.defaultProps = {
  rows: 10,
  onRefresh: null,
  handleDelete: null,
  handleEdit: null,
  handleAdd: null,
  title: '',
};
