import editIcon from 'assets/images/icons/Edit.png';
import { AddItemDialog, Button } from 'components/common';
import { useOKRs } from 'features/admin/hooks';
import { getImpact } from 'features/admin/utils';
import { userSelector } from 'features/auth/reducer/auth';
import { OKR, OKRAdd } from 'features/products/types';
import { editableRoadMapSelector, setEditableRoadmap } from 'features/roadmap/reducers/roadmap';
import { Column, ColumnEditorOptions } from 'primereact/column';
import { confirmPopup } from 'primereact/confirmpopup';
import { DataTable } from 'primereact/datatable';
import { Dropdown } from 'primereact/dropdown';
import { Toolbar } from 'primereact/toolbar';
import { MouseEvent, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useAppDispatch, useAppSelector } from 'store';
import { canEditRoadmapDetails } from 'utils/permissions';

import OkrCard from './OkrCard';

type OKRsTableProps = {
  okrs: OKRAdd[];
};

const impact = [
  {
    id: 0,
    impact: 'Low',
  },
  {
    id: 1,
    impact: 'Medium',
  },
  {
    id: 2,
    impact: 'High',
  },
];

const itemImpactBodyTemplate = (rowData: OKR) => getImpact(rowData.impact);

const OKRsTable = ({ okrs }: OKRsTableProps) => {
  const [benefitsArray, setBenefitsArray] = useState<OKR[]>([]);
  const [addDialogVisible, setAddDialogVisible] = useState(false);
  const { data, isLoading } = useOKRs();
  const editableRoadMap = useAppSelector(editableRoadMapSelector);
  const dispatch = useAppDispatch();
  const user = useAppSelector(userSelector);
  const { t } = useTranslation();

  const canEditRoadmap = !editableRoadMap.idRoadmap || canEditRoadmapDetails(user, editableRoadMap);

  useEffect(() => {
    if (data) {
      const values = okrs.map((x) => {
        const index = data.findIndex((okr) => okr.id === x.id);
        return { ...data[index], impact: x.impact.id };
      });
      setBenefitsArray(values as OKR[]);
    }
  }, [okrs, data]);

  const addOKR = (values: OKR[]) => {
    dispatch(
      setEditableRoadmap({
        ...editableRoadMap,
        okrs: [...(editableRoadMap.okrs ?? []), ...values.map((x) => ({ id: x.id, impact: { id: 0, impact: 'Low' } }))],
      }),
    );
  };

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const editOKR = (id: number, value: any) => {
    const newOkrs = [...editableRoadMap.okrs];
    const index = newOkrs.findIndex((benefit) => benefit.id === id);
    newOkrs[index] = { ...newOkrs[index], impact: { id: value, impact: getImpact(value) } };
    dispatch(
      setEditableRoadmap({
        ...editableRoadMap,
        okrs: newOkrs,
      }),
    );
  };

  const removeOKR = (value: OKR) => {
    dispatch(setEditableRoadmap({ ...editableRoadMap, okrs: editableRoadMap.okrs.filter((x) => x.id !== value.id) }));
  };

  const impactEditor = (options: ColumnEditorOptions) => {
    return (
      <Dropdown
        value={options.rowData.impact}
        options={impact}
        optionLabel="impact"
        optionValue="id"
        onChange={(e) => editOKR(options.rowData.id, e.value)}
        style={{ width: '100%' }}
        placeholder="Impact"
      />
    );
  };

  const removeItem = (e: MouseEvent<HTMLButtonElement>, value: OKR) => {
    confirmPopup({
      target: e.currentTarget,
      message: t('general.remove-item'),
      icon: 'pi pi-info-circle',
      acceptClassName: 'p-button-danger',
      accept: () => removeOKR(value),
    });
  };

  const actionBodyTemplate = (rowData: OKR) => (
    <div className="actions">
      <Button
        disabled={!canEditRoadmap}
        disabledTooltip={
          <div>
            <p>Deleting requires one the following permissions:</p>
            <ul>
              <li>
                <b>Admin</b>
              </li>
              <li>
                <b>Technical Product Owner</b>
              </li>
              <li>
                <b>Head of Technology</b>
              </li>
            </ul>
          </div>
        }
        icon="pi pi-trash"
        tooltip="Delete"
        className="p-button-rounded p-button-danger"
        onClick={(e: MouseEvent<HTMLButtonElement>) => removeItem(e, rowData)}
      />
    </div>
  );

  const leftToolbarTemplate = () => (
    <Button
      disabled={!canEditRoadmap}
      disabledTooltip={
        <div>
          <p>Adding requires one the following permissions:</p>
          <ul>
            <li>
              <b>Admin</b>
            </li>
            <li>
              <b>Technical Product Owner</b>
            </li>
            <li>
              <b>Head of Technology</b>
            </li>
          </ul>
        </div>
      }
      label="Add"
      icon="pi pi-plus"
      className="p-button-success mr-2 p-mb-2"
      aria-haspopup
      aria-controls="overlay_panel"
      onClick={() => setAddDialogVisible(true)}
    />
  );

  return (
    <>
      <OkrCard />
      <Toolbar className="p-mb-4 p-toolbar" left={leftToolbarTemplate} />
      <DataTable
        value={benefitsArray}
        dataKey="id"
        paginator
        editMode={canEditRoadmap ? 'cell' : undefined}
        responsiveLayout="scroll"
        rows={10}
        rowsPerPageOptions={[5, 10, 25]}
        className="datatable-responsive"
        paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
        currentPageReportTemplate="Showing {first} to {last} of {totalRecords} items"
        emptyMessage="No items found."
      >
        <Column field="name" header="Name" sortable />
        <Column
          bodyStyle={{ cursor: canEditRoadmap ? `url(${editIcon}),cell` : 'default' }}
          field="impact"
          header="Impact"
          sortable
          editor={canEditRoadmap ? impactEditor : undefined}
          body={itemImpactBodyTemplate}
        />
        <Column headerStyle={{ width: '10%', minWidth: '8rem' }} body={actionBodyTemplate} />
      </DataTable>
      <AddItemDialog
        values={data as OKR[]}
        selectionMode="multiple"
        isLoading={isLoading}
        visible={addDialogVisible}
        setVisible={setAddDialogVisible}
        dataKey="id"
        onAddAsync={addOKR}
      >
        <Column field="name" header="Name" sortable />
      </AddItemDialog>
    </>
  );
};

export default OKRsTable;
