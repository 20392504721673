import { useLifeCycles } from 'features/admin/hooks';
import { editableProductSelector, setEditableProduct } from 'features/products/reducers/solutionSlice';
import { LifeCycle } from 'features/products/types';
import { Column } from 'primereact/column';
import { useAppDispatch, useAppSelector } from 'store';

import CrudTable from '../CrudTable/CrudTable';

type RelatedLifeCycleTableProps = {
  lifeCycles: LifeCycle[];
};

const RelatedLifeCycleTable = ({ lifeCycles }: RelatedLifeCycleTableProps) => {
  const editableProduct = useAppSelector(editableProductSelector);
  const dispatch = useAppDispatch();
  const { data, isLoading } = useLifeCycles();

  const addItems = async (values: LifeCycle[]) => {
    if (editableProduct) {
      dispatch(
        setEditableProduct({ ...editableProduct, lifeCycles: [...(editableProduct.lifeCycles ?? []), ...values] }),
      );
    }
  };

  const removeItems = async (value: LifeCycle) => {
    if (editableProduct) {
      dispatch(
        setEditableProduct({
          ...editableProduct,
          lifeCycles: editableProduct.lifeCycles.filter((x) => x.idLifeCycle !== value.idLifeCycle),
        }),
      );
    }
  };

  return (
    <CrudTable
      currentValues={lifeCycles}
      isLoading={isLoading}
      totalValues={data?.filter((item) => !lifeCycles.some((b) => b.idLifeCycle === item.idLifeCycle)) ?? []}
      dataKey="idLifeCycle"
      onAddAsync={addItems}
      onDeleteAsync={removeItems}
    >
      <Column field="lifeCycleGroup.lifeCycleGroup" header="Life Cycle Group" sortable />
      <Column field="lifeCycle" header="Life Cycle" sortable />
    </CrudTable>
  );
};

export default RelatedLifeCycleTable;
