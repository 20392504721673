/* eslint-disable jsx-a11y/label-has-associated-control */
import classNames from 'classnames';
import { Training } from 'features/products/types';
import { Dropdown } from 'primereact/dropdown';
import { InputNumber } from 'primereact/inputnumber';
import { InputText } from 'primereact/inputtext';
import { InputTextarea } from 'primereact/inputtextarea';
import { Control, Controller } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import styles from './AddTrainingForm.module.scss';

interface AddTrainingFormProps {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  control: Control<Training, any>;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  errors: any;
}

const AddTrainingForm = ({ control, errors }: AddTrainingFormProps) => {
  const { t } = useTranslation();

  return (
    <div className={`${styles.form} p-jc-center`}>
      <form className="p-fluid">
        <div className={styles.field}>
          <div className="p-field">
            <label htmlFor="courseName" className={`capitalize ${classNames({ 'p-error': errors.courseName })}`}>
              <span className="red">*</span> Course Name
            </label>
            <Controller
              name="courseName"
              control={control}
              rules={{ required: true }}
              render={({ field }) => (
                <InputText {...field} className={classNames({ 'p-invalid': errors.courseName })} maxLength={250} />
              )}
            />
          </div>
          {errors.courseName && <small className="p-error">{t('form.required')}</small>}
        </div>

        <div className={styles.field}>
          <div className="p-field">
            <label htmlFor="courseOrder">Course Order</label>
            <Controller
              name="courseOrder"
              control={control}
              render={({ field: { value, onChange } }) => (
                <InputNumber value={value} onChange={(e) => onChange(e.value)} showButtons min={1} mode="decimal" />
              )}
            />
          </div>
        </div>

        <div className={styles.field}>
          <div className="p-field">
            <label htmlFor="duration">Duration</label>
            <Controller
              name="duration"
              control={control}
              render={({ field: { value, onChange } }) => (
                <InputNumber value={value} onChange={(e) => onChange(e.value)} showButtons min={1} mode="decimal" />
              )}
            />
          </div>
        </div>

        <div className={styles.field}>
          <div className="p-field">
            <label htmlFor="description">Description</label>
            <Controller
              name="description"
              control={control}
              render={({ field }) => <InputText {...field} maxLength={500} />}
            />
          </div>
        </div>

        <div className={styles.field}>
          <label htmlFor="courseLevel">Course Level</label>
          <Controller
            name="courseLevel"
            control={control}
            render={({ field }) => <Dropdown {...field} options={['Beginner', 'Intermediate', 'Advanced']} />}
          />
        </div>

        <div className={styles.field}>
          <label htmlFor="courseType">Course Type</label>
          <Controller
            name="courseType"
            control={control}
            render={({ field }) => <Dropdown {...field} options={['Self-Paced', 'Instructor-Paced']} />}
          />
        </div>

        <div className={styles.field}>
          <div className="p-field">
            <label htmlFor="availability">Availability</label>
            <Controller
              name="availability"
              control={control}
              render={({ field }) => <InputText {...field} maxLength={100} />}
            />
          </div>
        </div>

        <div className={styles.field}>
          <div className="p-field">
            <label htmlFor="contentUpdate">Content Update</label>
            <Controller
              name="contentUpdate"
              control={control}
              render={({ field }) => <InputText {...field} maxLength={100} />}
            />
          </div>
        </div>

        <div className={styles.field}>
          <div className="p-field">
            <label htmlFor="link">Link</label>
            <Controller
              name="link"
              control={control}
              render={({ field }) => <InputText {...field} maxLength={250} />}
            />
          </div>
        </div>

        <div className={styles.field}>
          <div className="p-field">
            <label htmlFor="knowledgePrerequisites">Knowledge Prerequisites</label>
            <Controller
              name="knowledgePrerequisites"
              control={control}
              render={({ field }) => <InputTextarea rows={3} {...field} maxLength={1000} />}
            />
          </div>
        </div>
      </form>
    </div>
  );
};

export default AddTrainingForm;
