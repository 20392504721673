import { useProductsList } from 'features/admin/hooks';
import { ProductBasic } from 'features/products/types';
import { Column } from 'primereact/column';

import CrudTable from '../CrudTable/CrudTable';

type RelatedProductTableProps = {
  products: ProductBasic[];
  onAdd: (values: ProductBasic[]) => void;
  onDelete: (value: ProductBasic) => void;
};

const RelatedProductTable = ({ products, onAdd, onDelete }: RelatedProductTableProps) => {
  const { data, isLoading } = useProductsList();

  const addItems = async (values: ProductBasic[]) => {
    if (data) {
      const newValues = values.map((value) =>
        data.find((product) => product.idProduct === value.idProduct),
      ) as ProductBasic[];
      onAdd(newValues);
    }
  };

  const deleteItem = (value: ProductBasic) => {
    if (data) {
      const product = data.find((p) => p.idProduct === value.idProduct);
      if (product) onDelete(product);
    }
  };

  return (
    <CrudTable
      currentValues={products}
      isLoading={isLoading}
      totalValues={data?.filter((item) => !products.some((b) => b.idProduct === item.idProduct)) ?? []}
      dataKey="idProduct"
      onAddAsync={addItems}
      onDeleteAsync={async (id) => deleteItem(id)}
    >
      <Column field="product" header="Product" sortable />
    </CrudTable>
  );
};

export default RelatedProductTable;
