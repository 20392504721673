import { ComponentType, lazy } from 'react';

// a function to retry loading a chunk to avoid chunk load error for out of date code
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function lazyRetry<T extends ComponentType<any>>(
  componentImport: Parameters<typeof lazy<T>>[0],
  chunkIdentifier: string,
): Promise<{ default: T }> {
  const sessionStorageKey = `retry-lazy-refreshed-${chunkIdentifier}`;

  return new Promise((resolve, reject) => {
    // check if the window has already been refreshed due to this chunk
    const hasRefreshed = JSON.parse(window.sessionStorage.getItem(sessionStorageKey) || 'false');

    // try to import the component
    componentImport()
      .then((component) => {
        // success so reset the refresh state
        window.sessionStorage.setItem(sessionStorageKey, 'false');
        resolve(component);
      })
      .catch((error) => {
        if (!hasRefreshed) {
          // not been refreshed yet
          window.sessionStorage.setItem(sessionStorageKey, 'true');
          // refresh the page
          window.location.reload();
        }

        // Default error behaviour as already tried refresh
        reject(error);
      });
  });
}

// named imports for React.lazy: https://github.com/facebook/react/issues/14603#issuecomment-726551598
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function lazyImport<T extends ComponentType<any>, I extends { [K2 in K]: T }, K extends keyof I>(
  factory: () => Promise<I>,
  name: K,
): I {
  return Object.create({
    [name]: lazy(() => lazyRetry(() => factory().then((module) => ({ default: module[name] })), String(name))),
  });
}

// Usage
// const { Home } = lazyImport(() => import("./Home"), "Home");
