import { Button, CustomDataTable } from 'components/common';
import { getProcedureHtml } from 'domain/qualityAssurance/api';
import { QualityAssuranceProcedure } from 'domain/qualityAssurance/types';
import { BlockUI } from 'primereact/blockui';
import { Column } from 'primereact/column';
import { Dialog } from 'primereact/dialog';
import { useEffect, useRef, useState } from 'react';
import { toast } from 'react-toastify';

import styles from './ProcedureDataTable.module.scss';

type ProcedureDataTableProps = {
  procedures: QualityAssuranceProcedure[];
};

const ProcedureDataTable = ({ procedures }: ProcedureDataTableProps) => {
  const [selectedProcedure, setSelectedProcedure] = useState<QualityAssuranceProcedure | undefined>(undefined);
  const [isLoading, setIsLoading] = useState(false);
  const [showDetailDialog, setShowDetailDialog] = useState(false);
  const iframeRef = useRef<HTMLIFrameElement>(null);

  useEffect(() => {
    if (selectedProcedure) {
      setIsLoading(true);
      getProcedureHtml(selectedProcedure.idQualityAssuranceProcedure)
        .then((html) => {
          if (iframeRef.current?.contentDocument) {
            iframeRef.current.contentDocument.body.innerHTML = html;
          }
        })
        .catch((err) => {
          toast.error(`Error downloading procedure: ${err.message}`);
        })
        .finally(() => {
          setIsLoading(false);
        });
    }
  }, [selectedProcedure]);

  const openDetail = (rowData: QualityAssuranceProcedure) => {
    setSelectedProcedure(rowData);
    setShowDetailDialog(true);
  };

  const closeDetail = () => {
    setSelectedProcedure(undefined);
    setShowDetailDialog(false);
  };

  const renderFooter = () => (
    <Button label="Close" icon="pi pi-times" className="p-button-outlined" onClick={closeDetail} />
  );

  const detailButtonTemplate = (rowData: QualityAssuranceProcedure) => (
    <Button
      className="p-button-rounded p-button-success mr-2"
      icon="pi pi-window-maximize"
      tooltip="Details"
      onClick={() => openDetail(rowData)}
    />
  );

  return (
    <>
      <CustomDataTable value={procedures} dataKey="idQualityAssuranceProcedure" rows={10}>
        <Column
          headerStyle={{ width: '4rem' }}
          bodyStyle={{ padding: 0, textAlign: 'center' }}
          style={{ padding: 0 }}
          body={detailButtonTemplate}
        />
        <Column field="name" header="Name" sortable />
      </CustomDataTable>
      {selectedProcedure && showDetailDialog && (
        <Dialog
          visible={showDetailDialog}
          className={styles.dialog}
          modal
          maximizable
          header={selectedProcedure.name}
          blockScroll
          footer={renderFooter}
          onHide={closeDetail}
        >
          <BlockUI
            fullScreen
            blocked={isLoading}
            template={<i className="pi pi-spin pi-spinner" style={{ fontSize: '3rem' }} />}
          >
            <div style={{ width: '100%', height: '100%' }}>
              <iframe
                ref={iframeRef}
                title={`${selectedProcedure.name}`}
                id={`${selectedProcedure.idQualityAssuranceProcedure}`}
                name={`${selectedProcedure.name}`}
                sandbox="allow-same-origin allow-scripts allow-popups allow-forms"
                style={{ width: '100%', height: '100%' }}
                frameBorder="no"
                allowFullScreen
                scrolling="yes"
              />
            </div>
          </BlockUI>
        </Dialog>
      )}
    </>
  );
};

export default ProcedureDataTable;
