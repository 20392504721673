import { StatusBadge } from 'components/common';
import { RoadMap, RoadmapList } from 'domain/roadmap/types';
import { getImpact, getImpactColor } from 'features/admin/utils';
import { ProductRoadmap } from 'features/products/types';
import { Tooltip } from 'primereact/tooltip';

import styles from './styles.module.scss';

export const OKRsBodyTemplate = ({ okrs, idRoadmap }: RoadMap | ProductRoadmap) => (
  <div>
    {[...okrs]
      .sort((a, b) => b.impact - a.impact)
      .map(({ id, alias, impact, description, name }) => (
        <div key={id} className={styles.badgeWrapper}>
          <Tooltip target={`[id='${id}-${idRoadmap}']`}>
            <div>
              <div>
                <span className={styles.tooltipImpact}>({getImpact(impact)})</span>
                <span className={styles.tooltipHeader}>{name}</span>
              </div>
              <div>
                <span className={styles.tooltipDescription}>{description}</span>
              </div>
            </div>
          </Tooltip>
          <div id={`${id}-${idRoadmap}`}>
            <StatusBadge color={getImpactColor(impact)}>{alias ?? name}</StatusBadge>
          </div>
        </div>
      ))}
  </div>
);

export const fundingSourceTemplate = <T extends RoadmapList>({ idRoadmap, fundingSources }: T) => (
  <div>
    {[...fundingSources]
      .sort((a, b) => (b.name > a.name ? 1 : -1))
      .map(({ idFundingSource, name, alias, percentage }) => (
        <div key={idFundingSource} style={{ margin: '0.3rem 0' }}>
          <Tooltip target={`[id='${idFundingSource}-${idRoadmap}']`} position="top">
            <div>
              <div>
                <span>{name}</span>
              </div>
            </div>
          </Tooltip>
          <div id={`${idFundingSource}-${idRoadmap}`}>
            <StatusBadge breakLine color="green">{`${alias ?? name} (${percentage}%)`}</StatusBadge>
          </div>
        </div>
      ))}
  </div>
);
