import editIcon from 'assets/images/icons/Edit.png';
import { AddItemDialog, Button } from 'components/common';
import { inputNumberEditor } from 'components/common/CrudDataTable';
import { useResources } from 'domain/roadmap/hooks';
import { RoadMapUpdate, RoadmapResource, RoadmapStatusWithResourceDTO, StatusResource } from 'domain/roadmap/types';
import { userSelector } from 'features/auth/reducer/auth';
import { editableRoadMapSelector, setEditableRoadmap } from 'features/roadmap/reducers/roadmap';
import { Button as PrimeButton } from 'primereact/button';
import { Column, ColumnEditorOptions, ColumnEvent } from 'primereact/column';
import { confirmDialog } from 'primereact/confirmdialog';
import { confirmPopup } from 'primereact/confirmpopup';
import { DataTable } from 'primereact/datatable';
import { MouseEvent, useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useAppDispatch, useAppSelector } from 'store';
import { canEditRoadmapDetails } from 'utils/permissions';

import { getUpdateWorkingDays } from './utils';

type CostTableProps = {
  row: RoadmapStatusWithResourceDTO;
  year: number;
  checkInconsistency: boolean;
  setCheckInconsistency: (value: boolean) => void;
};

const CostTable = ({ row, year, checkInconsistency, setCheckInconsistency }: CostTableProps) => {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const [addDialogVisible, setAddDialogVisible] = useState(false);
  const { data, isLoading } = useResources(year);
  const editableRoadMap = useAppSelector(editableRoadMapSelector);
  const dispatch = useAppDispatch();
  const user = useAppSelector(userSelector);
  const { t } = useTranslation();

  const canEditRoadmap = canEditRoadmapDetails(user, editableRoadMap);

  const updateWorkingDays = (updatedRoadmap: RoadMapUpdate, idRoadmapStatus: number, value: number) => {
    const updatedWorkingDays = getUpdateWorkingDays(updatedRoadmap, idRoadmapStatus, value);
    if (updatedWorkingDays) {
      dispatch(setEditableRoadmap(updatedWorkingDays));
    }
  };

  const updateRoadmapResources = useCallback(
    (updatedResources: StatusResource[]) => {
      const updatedRoadmap: RoadMapUpdate = {
        ...editableRoadMap,
        type: editableRoadMap.type
          ? {
              ...editableRoadMap.type,
              roadmapStatus:
                editableRoadMap.type.roadmapStatus?.map((r) =>
                  r.idRoadmapStatus === row.idRoadmapStatus
                    ? {
                        ...r,
                        resources: updatedResources,
                        edited: true,
                      }
                    : r,
                ) ?? null,
            }
          : null,
      };
      dispatch(setEditableRoadmap(updatedRoadmap));
      return updatedRoadmap;
    },
    [dispatch, editableRoadMap, row.idRoadmapStatus],
  );

  const onCellEditComplete = (e: ColumnEvent) => {
    const { newRowData, rowIndex, field } = e;
    const updatedResources = [...(row.resources ?? [])];
    updatedResources[rowIndex] = newRowData;
    const updatedRoadmap = updateRoadmapResources(updatedResources);

    if (field === 'hours' && checkInconsistency) {
      const status = editableRoadMap.type?.roadmapStatus?.find((s) => s.idRoadmapStatus === row.idRoadmapStatus);
      if (status) {
        const hours = updatedResources.reduce((a, r) => a + r.hours, 0);
        const days = hours / 8;
        if (status.workingDays < days) {
          confirmDialog({
            message: (
              <div>
                The assigned hours <strong>({hours} hours)</strong> are grater than the defined days{' '}
                <strong>({status.workingDays} days)</strong> for the current stage. <br /> The recommended days are{' '}
                <span style={{ fontWeight: 'bold', color: 'red' }}>{Math.ceil(days)} days</span>. Do you want to update
                the current stage for {Math.ceil(days)} days?
              </div>
            ),
            header: 'Inconsistent Time',
            icon: 'pi pi-exclamation-triangle',
            accept: () => updateWorkingDays(updatedRoadmap, status.idRoadmapStatus, Math.ceil(days)),
            footer: (opt) => (
              <div>
                <PrimeButton
                  onClick={() => {
                    setCheckInconsistency(false);
                    opt.reject();
                  }}
                  text
                  severity="secondary"
                  tooltip="You won't be prompted this dialog for any other stages of this roadmap item while you are on this tab."
                >
                  Don&apos;t ask for this roadmap item
                </PrimeButton>
                <PrimeButton onClick={() => opt.reject()} text>
                  No
                </PrimeButton>
                <PrimeButton onClick={() => opt.accept()}>Yes</PrimeButton>
              </div>
            ),
          });
        }
      }
    }
  };

  const hoursEditor = (options: ColumnEditorOptions) =>
    inputNumberEditor({ options, showButtons: true, maxFractionDigits: 2, min: 0 });

  const onDelete = (value: RoadmapResource) => {
    const updatedResources = [...(row.resources ?? [])].filter(
      (rs) => rs.idRoadmapResource !== value.idRoadmapResource,
    );
    updateRoadmapResources(updatedResources);
  };

  const removeItem = (e: MouseEvent<HTMLButtonElement>, value: RoadmapResource) => {
    confirmPopup({
      target: e.currentTarget,
      message: t('general.remove-item'),
      icon: 'pi pi-info-circle',
      acceptClassName: 'p-button-danger',
      accept: () => onDelete(value),
    });
  };

  const actionBodyTemplate = (rowData: RoadmapResource) => (
    <div className="actions">
      <Button
        disabled={!canEditRoadmap}
        disabledTooltip={
          <div>
            <p>Deleting requires one the following permissions:</p>
            <ul>
              <li>
                <b>Admin</b>
              </li>
              <li>
                <b>Technical Product Owner</b>
              </li>
              <li>
                <b>Head of Technology</b>
              </li>
            </ul>
          </div>
        }
        icon="pi pi-trash"
        tooltip="Delete"
        className="p-button-rounded p-button-danger"
        onClick={(e: MouseEvent<HTMLButtonElement>) => removeItem(e, rowData)}
      />
    </div>
  );

  const header = (
    <div className="table-header-container">
      {canEditRoadmap && (
        <Button
          icon="pi pi-plus"
          label="Add Item"
          className="p-button-success mr-2"
          onClick={() => setAddDialogVisible(true)}
        />
      )}
    </div>
  );

  const onAdd = (selected: RoadmapResource[]) => {
    const resources: StatusResource[] = selected.map((f) => ({
      idRoadmap: 0,
      idRoadmapResource: f.idRoadmapResource,
      hours: 0,
      resource: f.resource,
      alias: f.alias,
      roadmapResourceYear: f.roadmapResourceYear,
    }));
    const updatedResources = [...(row.resources ?? []), ...resources];
    updateRoadmapResources(updatedResources);
  };

  return (
    <>
      <DataTable
        value={row.resources}
        header={header}
        editMode={canEditRoadmap ? 'cell' : undefined}
        responsiveLayout="scroll"
        dataKey="idRoadmapResource"
      >
        <Column field="resource" header="Resource" />
        <Column
          field="hours"
          header="Hours"
          bodyStyle={{ cursor: canEditRoadmap ? `url(${editIcon}),cell` : 'default' }}
          editor={canEditRoadmap ? hoursEditor : undefined}
          onCellEditComplete={onCellEditComplete}
        />
        {canEditRoadmap && <Column style={{ width: '5em', textAlign: 'center' }} body={actionBodyTemplate} />}
      </DataTable>
      <AddItemDialog
        values={data?.filter((d) => !row.resources?.some((f) => f.idRoadmapResource === d.idRoadmapResource)) ?? []}
        selectionMode="multiple"
        isLoading={isLoading}
        visible={addDialogVisible}
        setVisible={setAddDialogVisible}
        dataKey="idRoadmapResource"
        onAddAsync={onAdd}
      >
        <Column field="resource" header="Resource" sortable />
      </AddItemDialog>
    </>
  );
};

export default CostTable;
