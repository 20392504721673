import classNames from 'classnames';
import { RoadMapUpdate } from 'domain/roadmap/types';
import { editableRoadMapSelector } from 'features/roadmap/reducers/roadmap';
import { InputText } from 'primereact/inputtext';
import { Control, Controller } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useAppSelector } from 'store';

import styles from '../RoadMapDetailForm.module.scss';

interface ItemFieldProps {
  viewOnly: boolean;
  control: Control<RoadMapUpdate, unknown>;
}

const ItemField = ({ viewOnly, control }: ItemFieldProps) => {
  const { t } = useTranslation();
  const editableRoadMap = useAppSelector(editableRoadMapSelector);
  return (
    <Controller
      name="item"
      control={control}
      rules={{ required: t('form.required') }}
      render={({ field, fieldState: { error } }) => (
        <div className={styles.field}>
          <span className={classNames({ 'p-error': error, required: !viewOnly })}>Item</span>
          {viewOnly ? (
            <p className={styles.readonlyField}>{editableRoadMap.item}</p>
          ) : (
            <>
              <InputText {...field} className={classNames({ 'p-invalid': error })} maxLength={200} />
              {error && <small className="p-error">{error.message}</small>}
            </>
          )}
        </div>
      )}
    />
  );
};

export default ItemField;
