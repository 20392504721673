import { BadgeProps, Badge as PrimeBadge } from 'primereact/badge';
import { ReactNode } from 'react';

const Badge = (
  props: JSX.IntrinsicAttributes &
    JSX.IntrinsicClassAttributes<PrimeBadge> &
    Readonly<BadgeProps> &
    Readonly<{ children?: ReactNode }>,
) => <PrimeBadge {...props} />;

export default Badge;
