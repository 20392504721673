import { Benefit } from 'features/products/types';
import api from 'services/api';

export const updateBenefit = async (data: Benefit) => {
  await api.put('benefits', data);
  return data;
};

export const getBenefits = async () => {
  const { data } = await api.get('benefits');
  return data as Benefit[];
};

export const deleteBenefit = async (id: number) => {
  const { data } = await api.delete(`benefits/${id}`);
  return data as Benefit[];
};
