import editIcon from 'assets/images/icons/Edit.png';
import { AddItemDialog, Button } from 'components/common';
import { useBenefits } from 'features/admin/hooks';
import { getImpact, sortBenefit } from 'features/admin/utils';
import { editableProductSelector, setEditableProduct } from 'features/products/reducers/solutionSlice';
import { Benefit, impactOptions } from 'features/products/types';
import { Column, ColumnEditorOptions, ColumnEvent } from 'primereact/column';
import { confirmPopup } from 'primereact/confirmpopup';
import { DataTable } from 'primereact/datatable';
import { Dropdown } from 'primereact/dropdown';
import { Toolbar } from 'primereact/toolbar';
import { MouseEvent, useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useAppDispatch, useAppSelector } from 'store';

const itemImpactBodyTemplate = (rowData: Benefit) => getImpact(rowData.impact);

const BenefitsTable = () => {
  const editableProduct = useAppSelector(editableProductSelector);
  const [benefitsArray, setBenefitsArray] = useState<Benefit[]>([]);
  const [addDialogVisible, setAddDialogVisible] = useState(false);
  const { data, isLoading } = useBenefits();
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  const removeBenefit = useCallback(
    (value: Benefit) => {
      if (!editableProduct) return;
      dispatch(
        setEditableProduct({
          ...editableProduct,
          benefits: editableProduct.benefits.filter((x) => x.idBenefit !== value.idBenefit),
        }),
      );
    },
    [dispatch, editableProduct],
  );

  const editBenefitImpact = useCallback(
    (id: number, value: number) => {
      if (!editableProduct) return;
      const newBenefits = [...editableProduct.benefits];
      const index = newBenefits.findIndex((benefit) => benefit.idBenefit === id);
      newBenefits[index] = { ...newBenefits[index], impact: { id: value, impact: getImpact(value) } };
      dispatch(
        setEditableProduct({
          ...editableProduct,
          benefits: newBenefits,
        }),
      );
    },
    [dispatch, editableProduct],
  );

  const addBenefit = useCallback(
    (values: Benefit[]) => {
      if (!editableProduct) return;
      dispatch(
        setEditableProduct({
          ...editableProduct,
          benefits: [
            ...(editableProduct.benefits ?? []),
            ...values.map((x) => ({ idBenefit: x.idBenefit, impact: { id: 0, impact: 'Low' } })),
          ],
        }),
      );
    },
    [dispatch, editableProduct],
  );

  useEffect(() => {
    if (data) {
      const values = editableProduct?.benefits.map((x) => {
        const index = data.findIndex((benefit) => benefit.idBenefit === x.idBenefit);
        return { ...data[index], impact: x.impact.id };
      });
      setBenefitsArray(values as Benefit[]);
    }
  }, [data, editableProduct?.benefits]);

  const onCellEditComplete = useCallback(
    (e: ColumnEvent) => {
      const { newRowData } = e as { newRowData: Benefit };
      editBenefitImpact(newRowData.idBenefit, newRowData.impact);
    },
    [editBenefitImpact],
  );

  const impactEditor = (props: ColumnEditorOptions) => {
    return (
      <Dropdown
        value={props.rowData.impact}
        options={impactOptions}
        optionLabel="impact"
        optionValue="id"
        onChange={(e) => editBenefitImpact(props.rowData.idBenefit, e.value)}
        style={{ width: '100%' }}
        placeholder="Impact"
      />
    );
  };

  const removeProduct = (e: MouseEvent<HTMLButtonElement>, value: Benefit) => {
    confirmPopup({
      target: e.currentTarget,
      message: t('general.remove-item'),
      icon: 'pi pi-info-circle',
      acceptClassName: 'p-button-danger',
      accept: () => removeBenefit(value),
    });
  };

  const actionBodyTemplate = (rowData: Benefit) => (
    <div className="actions">
      <Button
        icon="pi pi-trash"
        tooltip="Delete"
        className="p-button-rounded p-button-danger"
        onClick={(e: MouseEvent<HTMLButtonElement>) => removeProduct(e, rowData)}
      />
    </div>
  );

  const leftToolbarTemplate = () => (
    <Button
      label="Add"
      icon="pi pi-plus"
      className="p-button-success mr-2 p-mb-2"
      aria-haspopup
      aria-controls="overlay_panel"
      onClick={() => setAddDialogVisible(true)}
    />
  );

  return (
    <>
      <Toolbar className="p-mb-4 p-toolbar" left={leftToolbarTemplate} />
      <DataTable
        value={benefitsArray}
        dataKey="idBenefit"
        paginator
        editMode="cell"
        rows={10}
        rowsPerPageOptions={[5, 10, 25]}
        paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport 
RowsPerPageDropdown"
        currentPageReportTemplate="Showing {first} to {last} of {totalRecords} items"
        emptyMessage="No items found."
      >
        <Column field="group" header="Group" sortable />
        <Column field="benefit" header="Benefit" sortable />
        <Column
          field="impact"
          header="Impact"
          sortable
          bodyStyle={{ cursor: `url(${editIcon}),cell` }}
          editor={impactEditor}
          body={itemImpactBodyTemplate}
          onCellEditComplete={onCellEditComplete}
        />
        <Column headerStyle={{ width: '10%', minWidth: '8rem' }} body={actionBodyTemplate} />
      </DataTable>
      <AddItemDialog
        values={
          data?.filter((item) => !benefitsArray.some((b) => b.idBenefit === item.idBenefit)).sort(sortBenefit) ?? []
        }
        selectionMode="multiple"
        isLoading={isLoading}
        visible={addDialogVisible}
        setVisible={setAddDialogVisible}
        dataKey="idBenefit"
        onAddAsync={addBenefit}
      >
        <Column field="group" header="Group" sortable />
        <Column field="benefit" header="Benefit" sortable />
      </AddItemDialog>
    </>
  );
};

export default BenefitsTable;
