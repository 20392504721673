import editIcon from 'assets/images/icons/Edit.png';
import { Button, StatusBadge } from 'components/common';
import { ItemTrackingStatus, RoadmapStatus, RoadmapStatusItem } from 'domain/roadmap/types';
import { isAdminSelector, isLoggedInSelector, userSelector } from 'features/auth/reducer/auth';
import { editableRoadMapSelector, setEditableRoadmap } from 'features/roadmap/reducers/roadmap';
import { Column, ColumnEditorOptions } from 'primereact/column';
import { DataTable } from 'primereact/datatable';
import { Dropdown } from 'primereact/dropdown';
import { OverlayPanel } from 'primereact/overlaypanel';
import { ToggleButton } from 'primereact/togglebutton';
import { Tooltip } from 'primereact/tooltip';
import { useRef, useState } from 'react';
import { useAppDispatch, useAppSelector } from 'store';
import { canEditRoadmapDetails, isQaSpecialist, isRoadmapCreator, isRoadmapOwner } from 'utils/permissions';

import { getRoadmapStatus } from '../utils';
import CommentSection from './CommentSection';

interface PlanningTableProps {
  roadmapStatus: RoadmapStatus;
}

const getStatusColor = (status: ItemTrackingStatus): 'blue' | 'yellow' | 'green' | 'red' | 'grey' => {
  switch (status) {
    case 'Not Started':
      return 'blue';
    case 'In Progress':
      return 'yellow';
    case 'Completed':
      return 'green';
    case 'Delayed':
      return 'red';
    case 'Not Applicable':
      return 'grey';
    default:
      return 'grey';
  }
};

const renderQaTags = (isVerified: boolean) =>
  isVerified ? <StatusBadge color="green">Approved</StatusBadge> : <StatusBadge color="grey">Pending</StatusBadge>;

const itemNameTemplate = (item: RoadmapStatusItem) => (
  <>
    <span>{item.name}</span>
    {item.documentation && (
      <strong>
        <i title={item.documentation} className="pi pi-info-circle" style={{ marginLeft: '0.5rem' }} />
      </strong>
    )}
  </>
);

const PlanningTable = ({ roadmapStatus }: PlanningTableProps) => {
  const op = useRef<OverlayPanel>(null);
  const editableRoadMap = useAppSelector(editableRoadMapSelector);
  const isLoggedIn = useAppSelector(isLoggedInSelector);
  const dispatch = useAppDispatch();

  const [editableStatus, setEditableStatus] = useState<RoadmapStatusItem | null>(null);

  const user = useAppSelector(userSelector);
  const isAdmin = useAppSelector(isAdminSelector);

  const canEditRoadmap =
    !editableRoadMap.idRoadmap ||
    canEditRoadmapDetails(user, editableRoadMap) ||
    isRoadmapOwner(user, editableRoadMap) ||
    isRoadmapCreator(user, editableRoadMap);

  const handleEditComment = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>, row: RoadmapStatusItem) => {
    op.current?.toggle(e, null);
    setEditableStatus(row);
  };

  const commentBodyTemplate = (rowData: RoadmapStatusItem) => (
    <Button
      className={`p-button-rounded ${rowData.comments?.length ? 'p-button-success' : 'p-button-secondary'}`}
      icon="pi pi-comment"
      onClick={(e) => handleEditComment(e, rowData)}
    />
  );

  const editQA = (idRoadmapStatusItem: number, value: boolean) => {
    const roadmapStatuses = editableRoadMap.type?.roadmapStatus?.map((status) => ({
      ...status,
      roadmapStatusItems: status.roadmapStatusItems.map((item) =>
        item.idRoadmapStatusItem === idRoadmapStatusItem ? { ...item, qualityAssurance: value, edited: true } : item,
      ),
    }));
    if (roadmapStatuses) {
      dispatch(
        setEditableRoadmap({
          ...editableRoadMap,
          type: editableRoadMap.type ? { ...editableRoadMap.type, roadmapStatus: roadmapStatuses } : null,
        }),
      );
    }
  };

  const editStatus = (idRoadmapStatusItem: number, value: ItemTrackingStatus) => {
    const roadmapStatuses: RoadmapStatus[] =
      editableRoadMap.type?.roadmapStatus?.map((status) => ({
        ...status,
        roadmapStatusItems: status.roadmapStatusItems.map((item) =>
          item.idRoadmapStatusItem === idRoadmapStatusItem ? { ...item, status: value, edited: true } : item,
        ),
      })) ?? [];

    dispatch(
      setEditableRoadmap({
        ...editableRoadMap,
        status: getRoadmapStatus(roadmapStatuses),
        type: editableRoadMap.type ? { ...editableRoadMap.type, roadmapStatus: roadmapStatuses } : null,
      }),
    );
  };

  const statusEditor = (options: ColumnEditorOptions) => {
    return (
      <Dropdown
        value={options.rowData.status}
        options={['Not Started', 'In Progress', 'Completed', 'Delayed', 'Not Applicable']}
        onChange={(e) => editStatus(options.rowData.idRoadmapStatusItem, e.value)}
        style={{ width: '100%' }}
        placeholder="status"
      />
    );
  };

  const actionBodyTemplate = (rowData: RoadmapStatusItem) => {
    const getToggleButtonStyle = () => (rowData.qualityAssurance ? { backgroundColor: '#47a23f' } : {});

    return isAdmin || isQaSpecialist(user, editableRoadMap.product) ? (
      <ToggleButton
        style={getToggleButtonStyle()}
        onLabel="Approved"
        offLabel="Pending"
        onIcon="pi pi-check"
        offIcon="pi pi-times"
        checked={rowData.qualityAssurance}
        onChange={(e) => editQA(rowData.idRoadmapStatusItem, e.value)}
      />
    ) : (
      <>
        <Tooltip mouseTrack target={`[id='qa-tags-${rowData.idRoadmapStatusItem}']`}>
          Editing requires the following role: QA Specialist
        </Tooltip>
        <div id={`qa-tags-${rowData.idRoadmapStatusItem}`}>{renderQaTags(rowData.qualityAssurance)}</div>
      </>
    );
  };

  const statusBodyTemplate = (rowData: RoadmapStatusItem) => (
    <>
      <Tooltip
        disabled={canEditRoadmap || isQaSpecialist(user, editableRoadMap.product)}
        mouseTrack
        target={`[id='qa-status-${rowData.idRoadmapStatusItem}']`}
      >
        <div>
          <p>Editing requires one the following permissions:</p>
          <ul>
            <li>
              <b>Admin</b>
            </li>
            <li>
              <b>Technical Product Owner</b>
            </li>
            <li>
              <b>Roadmap owner</b>
            </li>
            <li>
              <b>Global Product manager</b>
            </li>
            <li>
              <b>Head of Technology</b>
            </li>
          </ul>
        </div>
      </Tooltip>
      <div id={`qa-status-${rowData.idRoadmapStatusItem}`}>
        <StatusBadge color={getStatusColor(rowData.status)}>{rowData.status}</StatusBadge>
      </div>
    </>
  );

  return (
    <>
      <DataTable
        value={roadmapStatus.roadmapStatusItems}
        dataKey="idRoadmapStatusItem"
        editMode={canEditRoadmap ? 'cell' : undefined}
        responsiveLayout="scroll"
      >
        <Column field="itemOrder" header="#" headerStyle={{ width: '2%', minWidth: '3rem' }} />
        <Column field="name" header="Name" body={itemNameTemplate} />
        <Column field="teamMembers" header="Team Members" />
        <Column
          headerStyle={{ width: '18%', minWidth: '8rem' }}
          bodyStyle={{ cursor: canEditRoadmap ? `url(${editIcon}),cell` : 'default' }}
          field="status"
          header="Status"
          editor={canEditRoadmap ? statusEditor : undefined}
          body={statusBodyTemplate}
        />
        {isLoggedIn && (
          <Column headerStyle={{ width: '8%', minWidth: '8rem' }} header="Comment" body={commentBodyTemplate} />
        )}
        <Column headerStyle={{ width: '12%', minWidth: '8rem' }} header="QA" body={actionBodyTemplate} />
      </DataTable>
      <CommentSection op={op} statusItemId={editableStatus?.idRoadmapStatusItem} />
    </>
  );
};

export default PlanningTable;
