import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import type { RootState } from 'store/rootReducer';
import { getLastAndFirstDayOfMonth } from 'utils/datetime';

export type ShowByTempo = 'By User' | 'By Item';

interface TempoState {
  showByTempo: ShowByTempo;
  users: number[];
  startDate: number | null;
  endDate: number | null;
}

const initialState: TempoState = {
  showByTempo: 'By User',
  users: [],
  startDate: null,
  endDate: null,
};

export const tempoSlice = createSlice({
  name: 'roadmap/tempo',
  initialState,
  reducers: {
    setShowByTempo: (state, action: PayloadAction<ShowByTempo>) => {
      state.showByTempo = action.payload;
    },
    setUsers: (state, action: PayloadAction<number[]>) => {
      state.users = action.payload;
    },
    setStartEndRange: (state, action: PayloadAction<Date | null>) => {
      if (action.payload) {
        const { firstDay, lastDay } = getLastAndFirstDayOfMonth(action.payload);
        state.startDate = firstDay.getTime();
        state.endDate = lastDay.getTime();
      } else {
        state.startDate = null;
        state.endDate = null;
      }
    },
  },
});

export const { setShowByTempo, setUsers, setStartEndRange } = tempoSlice.actions;

export const showByTempoSelector = (state: RootState) => state.roadmapTempo.showByTempo ?? 'By User';
export const usersSelector = (state: RootState) => state.roadmapTempo.users;
export const startEndRangeSelector = ({ roadmapTempo }: RootState) => ({
  start: roadmapTempo.startDate,
  end: roadmapTempo.endDate,
});

export default tempoSlice.reducer;
