import editIcon from 'assets/images/icons/Edit.png';
import { AddItemDialog, Button } from 'components/common';
import { inputNumberEditor } from 'components/common/CrudDataTable';
import { useFundingSourceByYear } from 'domain/fundingSource/hooks';
import { FundingSourceDTO } from 'domain/fundingSource/types';
import { RoadmapFundingSource } from 'domain/roadmap/types';
import { userSelector } from 'features/auth/reducer/auth';
import { editableRoadMapSelector, setEditableRoadmap } from 'features/roadmap/reducers/roadmap';
import { Column, ColumnEditorOptions, ColumnEvent } from 'primereact/column';
import { confirmPopup } from 'primereact/confirmpopup';
import { DataTable } from 'primereact/datatable';
import { Toolbar } from 'primereact/toolbar';
import { MouseEvent, useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useAppDispatch, useAppSelector } from 'store';
import { roundDecimal } from 'utils/number';
import { canEditRoadmapDetails } from 'utils/permissions';

type FundingSourceTableProps = {
  year: number;
  platform: number;
};

const nameTemplate = (rowData: FundingSourceDTO) => `${rowData.alias} - ${rowData.name}`;

const FundingSourceTable = ({ year, platform }: FundingSourceTableProps) => {
  const [addDialogVisible, setAddDialogVisible] = useState(false);
  const { data, isLoading } = useFundingSourceByYear(year, platform);
  const editableRoadMap = useAppSelector(editableRoadMapSelector);
  const dispatch = useAppDispatch();
  const user = useAppSelector(userSelector);
  const { t } = useTranslation();

  const canEditRoadmap = canEditRoadmapDetails(user, editableRoadMap);

  const percentageEditor = (options: ColumnEditorOptions) =>
    inputNumberEditor({ options, showButtons: true, suffix: '%', max: 100, min: 0 });

  const onCellEditComplete = useCallback(
    (e: ColumnEvent) => {
      const { newRowData, rowIndex } = e;
      const updatedSources = [...editableRoadMap.fundingSources];
      updatedSources[rowIndex] = newRowData;
      dispatch(setEditableRoadmap({ ...editableRoadMap, fundingSources: updatedSources }));
    },
    [dispatch, editableRoadMap],
  );

  const onDelete = useCallback(
    (value: RoadmapFundingSource) => {
      dispatch(
        setEditableRoadmap({
          ...editableRoadMap,
          fundingSources: editableRoadMap.fundingSources.filter((f) => f.idFundingSource !== value.idFundingSource),
        }),
      );
    },
    [dispatch, editableRoadMap],
  );

  const removeItem = useCallback(
    (e: MouseEvent<HTMLButtonElement>, value: RoadmapFundingSource) => {
      confirmPopup({
        target: e.currentTarget,
        message: t('general.remove-item'),
        icon: 'pi pi-info-circle',
        acceptClassName: 'p-button-danger',
        accept: () => onDelete(value),
      });
    },
    [onDelete, t],
  );

  const actionBodyTemplate = useCallback(
    (rowData: RoadmapFundingSource) => (
      <div className="actions">
        <Button
          disabled={!canEditRoadmap}
          disabledTooltip={
            <div>
              <p>Deleting requires one the following permissions:</p>
              <ul>
                <li>
                  <b>Admin</b>
                </li>
                <li>
                  <b>Technical Product Owner</b>
                </li>
                <li>
                  <b>Head of Technology</b>
                </li>
              </ul>
            </div>
          }
          icon="pi pi-trash"
          tooltip="Delete"
          className="p-button-rounded p-button-danger"
          onClick={(e: MouseEvent<HTMLButtonElement>) => removeItem(e, rowData)}
        />
      </div>
    ),
    [canEditRoadmap, removeItem],
  );

  const handleOpenDialog = useCallback(() => setAddDialogVisible(true), []);

  const leftToolbarTemplate = useMemo(
    () => (
      <Button
        disabled={!canEditRoadmap}
        disabledTooltip={
          <div>
            <p>Adding requires one the following permissions:</p>
            <ul>
              <li>
                <b>Admin</b>
              </li>
              <li>
                <b>Technical Product Owner</b>
              </li>
              <li>
                <b>Head of Technology</b>
              </li>
            </ul>
          </div>
        }
        label="Add"
        icon="pi pi-plus"
        className="p-button-success mr-2 p-mb-2"
        aria-haspopup
        aria-controls="overlay_panel"
        onClick={handleOpenDialog}
      />
    ),
    [canEditRoadmap, handleOpenDialog],
  );

  const onAdd = useCallback(
    (selected: FundingSourceDTO[]) => {
      const fundingSources: RoadmapFundingSource[] = selected.map((f) => ({
        idFundingSource: f.idFundingSource,
        idRoadmapFundingSource: (editableRoadMap.fundingSources.length + 1) * -1,
        percentage: editableRoadMap.fundingSources.length
          ? 0
          : roundDecimal(100 / (selected.length + editableRoadMap.fundingSources.length), 2),
        fundingSource: f,
      }));
      dispatch(
        setEditableRoadmap({
          ...editableRoadMap,
          fundingSources: [...editableRoadMap.fundingSources, ...fundingSources],
        }),
      );
    },
    [dispatch, editableRoadMap],
  );

  return (
    <>
      <Toolbar className="p-mb-4 p-toolbar" left={leftToolbarTemplate} />
      <DataTable
        value={editableRoadMap.fundingSources}
        dataKey="id"
        paginator
        editMode={canEditRoadmap ? 'cell' : undefined}
        rows={10}
        rowsPerPageOptions={[5, 10, 25]}
        responsiveLayout="scroll"
        paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
        currentPageReportTemplate="Showing {first} to {last} of {totalRecords} items"
        emptyMessage="No items found."
      >
        <Column field="fundingSource.name" header="Name" sortable />
        <Column
          field="percentage"
          header="Percentage"
          sortable
          editor={canEditRoadmap ? percentageEditor : undefined}
          bodyStyle={{ cursor: canEditRoadmap ? `url(${editIcon}),cell` : 'default' }}
          onCellEditComplete={onCellEditComplete}
        />
        <Column headerStyle={{ width: '10%', minWidth: '8rem' }} body={actionBodyTemplate} />
      </DataTable>
      <AddItemDialog
        values={
          data?.filter((d) => !editableRoadMap.fundingSources.some((f) => f.idFundingSource === d.idFundingSource)) ??
          []
        }
        selectionMode="multiple"
        isLoading={isLoading}
        visible={addDialogVisible}
        setVisible={setAddDialogVisible}
        dataKey="idFundingSource"
        onAddAsync={onAdd}
      >
        <Column field="name" header="Name" sortable body={nameTemplate} />
      </AddItemDialog>
    </>
  );
};

export default FundingSourceTable;
