import { Button } from 'primereact/button';
import { DataTable, DataTableProps } from 'primereact/datatable';
import { ReactNode, useState } from 'react';

import InputText from '../../Form/InputText/InputText';

interface CustomDataTableProps<TValue extends Record<string, any>> extends DataTableProps<Array<TValue>> {
  children: ReactNode;
  title?: string;
  rows?: number;
  onRefresh?: (() => void) | null;
}

const CustomDataTable = <TValue extends Record<string, any>>({
  children,
  title = '',
  rows = 10,
  onRefresh = null,
  ...rest
}: CustomDataTableProps<TValue>) => {
  const [globalFilter, setGlobalFilter] = useState(null);

  const header = (
    <div className="table-header">
      {title}
      <InputText
        type="search"
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        onInput={(e: any) => setGlobalFilter(e.target.value)}
        placeholder="Search..."
      />
      {!!onRefresh && <Button icon="pi pi-refresh" />}
    </div>
  );

  return (
    <DataTable
      {...rest}
      header={header}
      paginator
      rows={rows}
      globalFilter={globalFilter}
      responsiveLayout="scroll"
      rowsPerPageOptions={[Math.ceil(rows / 2), rows, rows * 2]}
      paginatorTemplate={`FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport 
  RowsPerPageDropdown`}
      currentPageReportTemplate="Showing {first} to {last} of {totalRecords} items"
      emptyMessage="No items found."
    >
      {children}
    </DataTable>
  );
};

export default CustomDataTable;

CustomDataTable.defaultProps = {
  rows: 10,
  onRefresh: null,
  title: '',
};
