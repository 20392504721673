import { Tooltip } from 'primereact/tooltip';
import { v4 as uuidv4 } from 'uuid';

import styles from './Card.module.scss';

const Card = ({ title, tooltip }: { title: string; tooltip: string }) => {
  const id = uuidv4().split('-')[0];
  return (
    <>
      <Tooltip className={styles.tooltip} mouseTrack mouseTrackLeft={10} content={tooltip} target={`[id='${id}']`} />
      <div id={id} className={styles.card}>
        <h5>{title}</h5>
      </div>
    </>
  );
};

export default Card;
