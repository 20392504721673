import { MaturityByProductGroup, MaturityModel, ProductMaturity } from 'domain/maturityModel/types';
import { ProductDocumentTypeDTO, ProductRoadmap } from 'features/products/types';
import { toast } from 'react-toastify';
import api from 'services/api';

import { ProductMaturityHistoryGroup } from '../types';

const BASE_URL = 'products';

export const getMaturityModel = async (productId: number, maturityModelId: number) => {
  try {
    const { data } = await api.get(`${BASE_URL}/${productId}/maturity/${maturityModelId}`);
    return data as MaturityModel;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  } catch (e: any) {
    toast.error(`Failed to get maturity model: ${e.response?.data?.Message ?? e.message} `);
    throw e;
  }
};

export const getProductMaturityModels = async () => {
  try {
    const { data } = await api.get(`${BASE_URL}/maturity-models`);
    return data as ProductMaturity[];
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  } catch (e: any) {
    toast.error(`Failed to get maturity model: ${e.response?.data?.Message ?? e.message} `);
    throw e;
  }
};

export const getMaturityReportByGroup = async (maturityId: number) => {
  try {
    const { data } = await api.get<MaturityByProductGroup>(`${BASE_URL}/maturity-models/${maturityId}/report/group`);
    return data;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  } catch (e: any) {
    toast.error(`Failed to get maturity model: ${e.response?.data?.Message ?? e.message} `);
    throw e;
  }
};

export const getProductMaturity = async (maturityId: number) => {
  try {
    const { data } = await api.get<MaturityModel>(`${BASE_URL}/maturity/${maturityId}`);
    return data;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  } catch (e: any) {
    toast.error(`Failed to get maturity model: ${e.response?.data?.Message ?? e.message} `);
    throw e;
  }
};

export const getProductRoadMaps = async (id: number) => {
  const { data } = await api.get(`${BASE_URL}/${id}/roadmaps`);
  return data as ProductRoadmap[];
};

export const getProductDocumentType = async () => {
  const { data } = await api.get(`${BASE_URL}/document-type`);
  return data as ProductDocumentTypeDTO[];
};

export const approveReleasePo = async (id: number, status: boolean) => {
  await api.patch(`${BASE_URL}/${id}/release/po`, { status });
};

export const approveReleasePm = async (id: number, status: boolean) => {
  await api.patch(`${BASE_URL}/${id}/release/pm`, { status });
};

export const getMaturityHistory = async (id: number) => {
  const { data } = await api.get<ProductMaturityHistoryGroup[]>(`${BASE_URL}/${id}/maturity/history`);
  return data;
};
