import api from 'services/api';

import { QualityAssuranceProcedure, QualityAssuranceProcedureWithProductsId } from '../types';

export const getProcedures = async (): Promise<QualityAssuranceProcedure[]> => {
  const { data } = await api.get('qualityAssuranceProcedures');
  return data as QualityAssuranceProcedure[];
};

export const getProceduresWithProductsId = async (): Promise<QualityAssuranceProcedureWithProductsId[]> => {
  const { data } = await api.get('qualityAssuranceProcedures/productsId');
  return data as QualityAssuranceProcedureWithProductsId[];
};

export const getProcedure = async (id: number): Promise<QualityAssuranceProcedure> => {
  const { data } = await api.get(`qualityAssuranceProcedures/${id}`);
  return data as QualityAssuranceProcedure;
};

export const getProcedureHtml = async (id: number): Promise<string> => {
  const { data } = await api.get(`qualityAssuranceProcedures/${id}/html`);
  return data as string;
};

export const updateProcedure = async (procedure: QualityAssuranceProcedure): Promise<QualityAssuranceProcedure> => {
  await api.put(`qualityAssuranceProcedures/${procedure.idQualityAssuranceProcedure}`, procedure);
  return procedure;
};

export const createProcedure = async (procedure: QualityAssuranceProcedure): Promise<QualityAssuranceProcedure> => {
  const { data } = await api.post('qualityAssuranceProcedures', procedure);
  return data as QualityAssuranceProcedure;
};

export const deleteProcedure = async (id: number): Promise<void> => api.delete(`qualityAssuranceProcedures/${id}`);
