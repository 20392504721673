/* eslint-disable jsx-a11y/label-has-associated-control */
import classNames from 'classnames';
import { News } from 'features/products/types';
import { Calendar } from 'primereact/calendar';
import { InputText } from 'primereact/inputtext';
import { InputTextarea } from 'primereact/inputtextarea';
import { Control, Controller } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import styles from './AddNewsForm.module.scss';

interface AddNewsFormProps {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  control: Control<News, any>;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  errors: any;
}

const AddNewsForm = ({ control, errors }: AddNewsFormProps) => {
  const { t } = useTranslation();

  return (
    <div className={`${styles.form} p-jc-center`}>
      <form className="p-fluid">
        <div className={styles.field}>
          <label htmlFor="title" className={`capitalize ${classNames({ 'p-error': errors.title })}`}>
            <span className="red">*</span> Title
          </label>
          <Controller
            name="title"
            control={control}
            rules={{ required: true }}
            render={({ field }) => (
              <InputTextarea
                {...field}
                rows={2}
                className={classNames({ 'p-invalid': errors.title })}
                autoResize
                maxLength={300}
              />
            )}
          />
          {errors.title && <small className="p-error">{t('form.required')}</small>}
        </div>

        <div className={styles.field}>
          <label htmlFor="description" className={`capitalize ${classNames({ 'p-error': errors.description })}`}>
            <span className="red">*</span> Description
          </label>
          <Controller
            name="description"
            control={control}
            rules={{ required: true }}
            render={({ field }) => (
              <InputTextarea
                {...field}
                rows={4}
                className={classNames({ 'p-invalid': errors.description })}
                autoResize
                maxLength={700}
              />
            )}
          />
          {errors.description && <small className="p-error">{t('form.required')}</small>}
        </div>

        <div className={styles.field}>
          <div className="p-field">
            <label htmlFor="contentUrl" className={`capitalize ${classNames({ 'p-error': errors.contentUrl })}`}>
              <span className="red">*</span> Content URL
            </label>
            <Controller
              name="contentUrl"
              control={control}
              rules={{ required: true }}
              render={({ field }) => (
                <InputText {...field} className={classNames({ 'p-invalid': errors.contentUrl })} maxLength={200} />
              )}
            />
          </div>
          {errors.contentUrl && <small className="p-error">{t('form.required')}</small>}
        </div>

        <div className={styles.field}>
          <div className="p-field">
            <label htmlFor="imageUrl">Image URL</label>
            <Controller
              name="imageUrl"
              control={control}
              render={({ field }) => <InputText {...field} maxLength={200} />}
            />
          </div>
        </div>

        <div className={styles.field}>
          <div className="p-field">
            <label htmlFor="timestamp">Timestamp</label>
            <Controller
              name="timestamp"
              control={control}
              render={({ field: { value, onChange } }) => (
                <Calendar
                  id="mask"
                  value={value ? new Date(value) : undefined}
                  onChange={(e) => onChange(e.value)}
                  mask="9999-99-99"
                  dateFormat="yy-mm-dd"
                  showIcon
                />
              )}
            />
          </div>
        </div>
      </form>
    </div>
  );
};

export default AddNewsForm;
