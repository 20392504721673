export const getStatus = (status: number | null) => {
  switch (status) {
    case -1:
      return 'Not defined';
    case 0:
      return 'Inactive';
    case 1:
      return 'In use';
    case 2:
      return 'Sold but not yet implemented';
    default:
      return '-';
  }
};
