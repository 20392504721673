import { AddItemDialog, CustomDataTable } from 'components/common';
import { Button } from 'primereact/button';
import { Column } from 'primereact/column';
import { confirmPopup } from 'primereact/confirmpopup';
import { DataTableProps } from 'primereact/datatable';
import { Toolbar } from 'primereact/toolbar';
import { MouseEvent, ReactNode, useState } from 'react';
import { useTranslation } from 'react-i18next';

interface CrudTableProps<TValue extends Record<string, any>> extends DataTableProps<Array<TValue>> {
  currentValues: Array<TValue>;
  totalValues: Array<TValue>;
  children: ReactNode;
  isLoading: boolean;
  dataKey: keyof TValue & string;
  onAddAsync: ((values: TValue[]) => Promise<void>) | null;
  onDeleteAsync: ((id: TValue) => Promise<void>) | null;
}

const CrudTable = <TValue extends Record<string, any>>({
  currentValues,
  totalValues,
  children,
  isLoading,
  onAddAsync,
  onDeleteAsync,
  dataKey,
  ...rest
}: CrudTableProps<TValue>) => {
  const [addDialogVisible, setAddDialogVisible] = useState(false);
  const { t } = useTranslation();

  const removeElement = (e: MouseEvent<HTMLButtonElement>, element: TValue) => {
    confirmPopup({
      target: e.currentTarget,
      message: t('general.remove-item'),
      icon: 'pi pi-info-circle',
      acceptClassName: 'p-button-danger',
      accept: () => onDeleteAsync && onDeleteAsync(element),
    });
  };

  const leftToolbarTemplate = () => (
    <Button
      label="Add"
      icon="pi pi-plus"
      className="p-button-success mr-2 p-mb-2"
      aria-haspopup
      aria-controls="overlay_panel"
      onClick={() => setAddDialogVisible(true)}
    />
  );

  const actionBodyTemplate = (rowData: TValue) => (
    <div className="actions">
      <Button
        icon="pi pi-trash"
        tooltip="Delete"
        className="p-button-rounded p-button-danger"
        onClick={(e: MouseEvent<HTMLButtonElement>) => removeElement(e, rowData)}
      />
    </div>
  );

  return (
    <>
      {onAddAsync && <Toolbar className="p-mb-4 p-toolbar" left={leftToolbarTemplate} />}
      <CustomDataTable {...rest} value={currentValues} dataKey={dataKey} rows={10}>
        {onDeleteAsync && <Column headerStyle={{ width: '10%', minWidth: '8rem' }} body={actionBodyTemplate} />}
        {children}
      </CustomDataTable>
      {onAddAsync && (
        <AddItemDialog
          values={totalValues}
          selectionMode="multiple"
          isLoading={isLoading}
          visible={addDialogVisible}
          setVisible={setAddDialogVisible}
          dataKey={dataKey}
          onAddAsync={onAddAsync}
        >
          {children}
        </AddItemDialog>
      )}
    </>
  );
};

export default CrudTable;
