import { useQuery } from 'react-query';

import { getAutonomyLevel, getAutonomyLevelFunctions } from '../api';
import { AutonomyFunctionDTO, AutonomyLevelDTO } from '../types/autonomylevels.interface';

export const AUTONOMY_LEVEL_KEY = 'autonomy-level-key';
export const AUTONOMY_FUNCTION_KEY = 'autonomy-function-key';

export function useAutonomyLevels() {
  return useQuery<AutonomyLevelDTO[], Error>(AUTONOMY_LEVEL_KEY, getAutonomyLevel, {
    staleTime: 1000 * 60 * 5,
  });
}

export function useAutonomyFunctions() {
  return useQuery<AutonomyFunctionDTO[], Error>(AUTONOMY_FUNCTION_KEY, getAutonomyLevelFunctions, {
    staleTime: 1000 * 60 * 5,
  });
}
