import { deleteFollower } from 'domain/roadmap/API';
import { ROADMAP_FOLLOWERS_KEY, useRoadmapFollowers } from 'domain/roadmap/hooks';
import { userSelector } from 'features/auth/reducer/auth';
import { editableRoadMapSelector } from 'features/roadmap/reducers/roadmap';
import { Button } from 'primereact/button';
import { OverlayPanel } from 'primereact/overlaypanel';
import { ScrollPanel } from 'primereact/scrollpanel';
import { MouseEvent, useCallback, useRef } from 'react';
import { useQueryClient } from 'react-query';
import { useAppSelector } from 'store';

import styles from '../RoadMapDetailForm.module.scss';
import AddFollowerDialog from './AddFollowerDialog';

const FollowerPanel = () => {
  const user = useAppSelector(userSelector);
  const editableRoadMap = useAppSelector(editableRoadMapSelector);
  const { data: followers } = useRoadmapFollowers(editableRoadMap.idRoadmap, !!user);
  const op = useRef<OverlayPanel>(null);
  const queryClient = useQueryClient();

  const handleAddFollower = useCallback((e: MouseEvent<HTMLButtonElement>) => {
    op.current?.toggle(e, null);
  }, []);

  const handleDeleteFollowers = useCallback(
    async (id: number) => {
      await deleteFollower(id);
      queryClient.invalidateQueries(ROADMAP_FOLLOWERS_KEY);
    },
    [queryClient],
  );

  return (
    <>
      <div className={styles.followers}>
        <ScrollPanel style={{ width: '100%', height: '180px' }} className="custom">
          <Button
            label="Add Follower +"
            type="button"
            className={`${styles.addFollowerButton} p-button-link`}
            onClick={handleAddFollower}
            tooltip="When you add a follower, this user will be notified about important changes such as status and version"
          />
          <p className={styles.followersTitle}>Followers:</p>
          <ul className={styles.followersList}>
            {followers?.map((f) => (
              <li key={f.idRoadmapFollower}>
                <span>{f.name}</span>
                <Button
                  style={{ padding: '0.1rem' }}
                  title="delete"
                  label="x"
                  className="p-button-text p-button-danger"
                  onClick={() => handleDeleteFollowers(f.idRoadmapFollower)}
                />
              </li>
            ))}
          </ul>
        </ScrollPanel>
      </div>
      <AddFollowerDialog op={op} idRoadmap={editableRoadMap.idRoadmap} />
    </>
  );
};

export default FollowerPanel;
