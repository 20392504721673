import { AxiosError } from 'axios';
import { Button } from 'components/common';
import {
  USE_MATURITY_MODEL_KEY,
  USE_PRODUCT_MATURITY_MODEL_KEY,
  USE_PRODUCT_MATURITY_REPORT,
} from 'domain/product/hook';
import { UserBrief } from 'domain/user/types';
import { createProduct, getProductListById, updateProduct } from 'features/admin/api';
import { setEditableProduct } from 'features/products/reducers/solutionSlice';
import { EditableProduct, ProductBasic, ProductBrief, ProductList, ProductUpdate } from 'features/products/types';
import { Dialog } from 'primereact/dialog';
import { TabPanel, TabView } from 'primereact/tabview';
import { Dispatch, SetStateAction, useEffect } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { useMutation, useQueryClient } from 'react-query';
import { toast } from 'react-toastify';
import { useAppDispatch } from 'store';

import AutonomyLevelTab from '../AutonomyLevelTab/AutonomyLevelTab';
import BenefitsTable from '../BenefitsTable/BenefitsTable';
import { MaturityModelTab } from '../MaturityModelTab';
import PersonasTable from '../PersonasTable/PersonasTable';
import ProductDetailForm from '../ProductDetailForm/ProductDetailForm';
import ProductDocumentLinkTab from '../ProductDocumentLinkTab/ProductDocumentLinkTab';
import RelatedIndustriesTable from '../RelatedIndustriesTable/RelatedIndustriesTable';
import RelatedLifeCycleTable from '../RelatedLifeCycleTable/RelatedLifeCycleTable';
import RelatedPlantTable from '../RelatedPlantTable/RelatedPlantTable';
import RelatedProductNews from '../RelatedProductNews/RelatedProductNews';
import RelatedProductStreamVideos from '../RelatedProductStreamVideos/RelatedProductStreamVideos';
import RelatedProductVideos from '../RelatedProductVideos/RelatedProductVideos';
import RelatedProductsTable from '../RelatedProductsTable/RelatedProductsTable';
import RelatedQASpecialists from '../RelatedQASpecialists/RelatedQASpecialists';
import RelatedTrainingTable from '../RelatedTrainingTable/RelatedTrainingTable';
import styles from './ProductEditDialog.module.scss';

const onMutateError = (error: AxiosError) => {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  toast.error(`An error occurred while saving data! ${(error.response as any)?.data?.Message ?? error?.message}.`, {
    position: 'top-right',
    autoClose: false,
  });
};

type ProductEditDialogProps = {
  visible: boolean;
  setVisible: Dispatch<SetStateAction<boolean>>;
  editableProduct: EditableProduct;
};

const ProductEditDialog = ({ visible, setVisible, editableProduct }: ProductEditDialogProps) => {
  const { control, handleSubmit, formState, reset } = useForm<EditableProduct>({
    defaultValues: editableProduct,
  });
  const queryClient = useQueryClient();
  const dispatch = useAppDispatch();

  useEffect(() => {
    reset();
  }, [reset, visible]);

  const onMutateSuccess = () => {
    setVisible(false);
    dispatch(setEditableProduct(null));
    toast.success('Data saved successfully');
    queryClient.invalidateQueries('products');
    queryClient.invalidateQueries('products-simple');
    queryClient.invalidateQueries('products-brief');
    queryClient.invalidateQueries([USE_MATURITY_MODEL_KEY]);
    queryClient.invalidateQueries([USE_PRODUCT_MATURITY_MODEL_KEY]);
    queryClient.invalidateQueries([USE_PRODUCT_MATURITY_REPORT]);
  };

  const onUpdateMutate = {
    onSuccess: (product: ProductBasic) => {
      if (product.idProduct) {
        getProductListById(product.idProduct).then((dbProduct) => {
          queryClient.setQueryData('products-list', (oldData: ProductList[] | undefined) => {
            return oldData?.map((r) => (r.idProduct === product.idProduct ? dbProduct : r)) ?? [];
          });
        });
      } else {
        queryClient.invalidateQueries('products-list');
      }
      onMutateSuccess();
    },
    onError: onMutateError,
  };

  const onCreateMutate = {
    onSuccess: (product: ProductBasic) => {
      if (product.idProduct) {
        getProductListById(product.idProduct).then((dbProduct) => {
          queryClient.setQueryData('products-list', (oldData: ProductList[] | undefined) => {
            return oldData ? [...oldData, dbProduct] : [];
          });
        });
      } else {
        queryClient.invalidateQueries('products-list');
      }
      onMutateSuccess();
    },
    onError: onMutateError,
  };

  const { mutate: mutateUpdate, isLoading: isLoadingUpdate } = useMutation(updateProduct, onUpdateMutate);

  const { mutate: mutateCreate, isLoading: isLoadingCreate } = useMutation(createProduct, onCreateMutate);

  const isSubmitting = isLoadingUpdate || isLoadingCreate;

  const onSubmit: SubmitHandler<EditableProduct> = (data) => {
    const item = {
      ...data,
      idProductStatus: data.status?.idProductStatus,
      idProductGroup: data.group?.idProductGroup,
      idMaturityModel: data.maturityModel?.idMaturityModel ?? null,
      idProductOwner: data.productOwner?.idUser ?? null,
      idTechnologyManager: data.technologyManager?.idUser ?? null,
      idProductManager: data.productManager?.idUser ?? null,
      idPlatform: data.platform?.idPlatform ?? null,
      benefits: editableProduct.benefits,
      userPersonas: editableProduct.userPersonas.map((u) => u.idUserPersona),
      industries: editableProduct.industries,
      relatedProducts: editableProduct.relatedProducts.map((r) => r.idProduct),
      relatedAddons: editableProduct.relatedAddons.map((r) => r.idProduct),
      lifeCycles: editableProduct.lifeCycles.map((l) => l.idLifeCycle),
      relatedPlants: editableProduct.relatedPlants,
      trainings: editableProduct.trainings,
      news: editableProduct.news,
      videos: editableProduct.videos,
      streamVideos: editableProduct.streamVideos,
      qaSpecialists: editableProduct.qaSpecialists.map((q) => q.idUser),
      includedProducts: editableProduct.includedProducts.map((i) => i.idProduct),
      image: editableProduct.image,
      image2: editableProduct.image2,
      maturityModel: editableProduct.maturityModel,
      documents: editableProduct.documents,
      autonomyFunctions: editableProduct.autonomyFunctions,
    } as ProductUpdate;
    if (item.idProduct) {
      mutateUpdate(item);
    } else {
      mutateCreate(item);
    }
  };

  const onInvalid = () => {
    const firstError = Object.keys(formState.errors).reduce(
      (field, a) => (!formState.errors[field as keyof EditableProduct] ? a : field),
      '',
    );

    if (firstError) {
      toast.error(`Missing required field: ${firstError}`);
    }
  };

  const itemDialogFooter = (
    <div className={styles.bottomButton}>
      <Button label="Cancel" icon="pi pi-times" className="p-button-outlined" onClick={() => setVisible(false)} />
      <Button label="Save" loading={isSubmitting} icon="pi pi-check" onClick={handleSubmit(onSubmit, onInvalid)} />
    </div>
  );

  const renderHeader = () => (
    <div className={styles.headerContainer}>
      <span>{editableProduct.product}</span>
    </div>
  );

  const editIndustry = (id: number, isSpecific: string, manager?: UserBrief) => {
    const newIndustries = [...editableProduct.industries];
    const index = newIndustries.findIndex((industry) => industry.idProductIndustry === id);
    newIndustries[index] = {
      ...newIndustries[index],
      isSpecific: isSpecific === 'Yes',
      idManager: manager?.idUser,
      manager,
    };
    dispatch(
      setEditableProduct({
        ...editableProduct,
        industries: newIndustries,
      }),
    );
  };

  const addRelatedProduct = async (values: ProductBrief[]) => {
    if (editableProduct) {
      dispatch(
        setEditableProduct({
          ...editableProduct,
          relatedProducts: [...(editableProduct.relatedProducts ?? []), ...values],
        }),
      );
    }
  };

  const removeRelatedProduct = async (value: ProductBrief) => {
    if (editableProduct) {
      dispatch(
        setEditableProduct({
          ...editableProduct,
          relatedProducts: editableProduct.relatedProducts.filter((x) => x.idProduct !== value.idProduct),
        }),
      );
    }
  };

  const addRelatedAddon = async (values: ProductBrief[]) => {
    if (editableProduct) {
      dispatch(
        setEditableProduct({
          ...editableProduct,
          relatedAddons: [...(editableProduct.relatedAddons ?? []), ...values],
        }),
      );
    }
  };

  const removeRelatedAddon = async (value: ProductBrief) => {
    if (editableProduct) {
      dispatch(
        setEditableProduct({
          ...editableProduct,
          relatedAddons: editableProduct.relatedAddons.filter((x) => x.idProduct !== value.idProduct),
        }),
      );
    }
  };

  return (
    <Dialog
      visible={visible}
      className={styles.dialog}
      header={renderHeader}
      modal
      blockScroll
      footer={itemDialogFooter}
      onHide={() => setVisible(false)}
    >
      <TabView scrollable>
        <TabPanel header="Overview">
          <ProductDetailForm control={control} errors={formState.errors} />
        </TabPanel>
        <TabPanel header="Maturity">
          <MaturityModelTab idMaturityModel={editableProduct.idMaturityModel} idProduct={editableProduct.idProduct} />
        </TabPanel>
        <TabPanel header="Autonomy">
          <AutonomyLevelTab autonomyFunctions={editableProduct.autonomyFunctions ?? []} />
        </TabPanel>
        <TabPanel header="Benefits">
          <BenefitsTable />
        </TabPanel>
        <TabPanel header="End-users">
          <PersonasTable personas={editableProduct.userPersonas ?? []} />
        </TabPanel>
        <TabPanel header="Industries">
          <RelatedIndustriesTable industries={editableProduct.industries ?? []} onEdit={editIndustry} />
        </TabPanel>
        <TabPanel header="Related Products">
          <RelatedProductsTable
            onAddItems={addRelatedProduct}
            onRemoveItems={removeRelatedProduct}
            products={editableProduct.relatedProducts ?? []}
          />
        </TabPanel>
        <TabPanel header="Related Add-Ons">
          <RelatedProductsTable
            onAddItems={addRelatedAddon}
            onRemoveItems={removeRelatedAddon}
            products={editableProduct.relatedAddons ?? []}
          />
        </TabPanel>
        <TabPanel header="Life Cycles">
          <RelatedLifeCycleTable lifeCycles={editableProduct.lifeCycles ?? []} />
        </TabPanel>
        <TabPanel header="Plants">
          <RelatedPlantTable plants={editableProduct.relatedPlants ?? []} />
        </TabPanel>
        <TabPanel header="Trainings">
          <RelatedTrainingTable trainings={editableProduct.trainings ?? []} />
        </TabPanel>
        <TabPanel header="Discover More">
          <RelatedProductNews news={editableProduct.news ?? []} />
        </TabPanel>
        <TabPanel header="Public Videos">
          <RelatedProductVideos videos={editableProduct.videos ?? []} />
        </TabPanel>
        <TabPanel header="Stream Videos">
          <RelatedProductStreamVideos videos={editableProduct.streamVideos ?? []} />
        </TabPanel>
        <TabPanel header="QA Specialists">
          <RelatedQASpecialists specialists={editableProduct.qaSpecialists ?? []} />
        </TabPanel>
        <TabPanel header="Documents">
          <ProductDocumentLinkTab documents={editableProduct.documents ?? []} />
        </TabPanel>
      </TabView>
    </Dialog>
  );
};

export default ProductEditDialog;
