import { AxiosError } from 'axios';
import { Button, CustomDataTable } from 'components/common';
import { deleteRoadMap, getRoadMapsById, getRoadmapsTypeStatusesByRoadmapId } from 'domain/roadmap/API';
import { RoadMap, RoadmapList, RoadmapStatus } from 'domain/roadmap/types';
import { isLoggedInSelector, userSelector } from 'features/auth/reducer/auth';
import { ProductRoadmapWithProduct } from 'features/products/types';
import { getRoadmapStatus } from 'features/roadmap/components/PlanningTab/utils';
import { setEditableRoadmap } from 'features/roadmap/reducers/roadmap';
import { Column } from 'primereact/column';
import { Dialog } from 'primereact/dialog';
import { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useMutation, useQueryClient } from 'react-query';
import { toast } from 'react-toastify';
import { useAppDispatch, useAppSelector } from 'store';
import { canDeleteRoadmap, canEditRoadmap } from 'utils/permissions';
import { capitalizeFirstLetter } from 'utils/string';

import { getImpact } from '../../utils';
import RoadMapEditDialog from '../RoadMapEditDialog/RoadMapEditDialog';

type RoadmapTableProps = {
  children: JSX.Element | JSX.Element[];
  roadMaps: (ProductRoadmapWithProduct | RoadmapList)[];
  isLoading?: boolean;
  onSaveCallback?: () => void;
  onDeleteCallback?: () => void;
};

const RoadmapTable = ({
  children,
  roadMaps,
  isLoading = false,
  onSaveCallback = () => null,
  onDeleteCallback = () => null,
}: RoadmapTableProps) => {
  const { t } = useTranslation();
  const [deleteItemDialog, setDeleteItemDialog] = useState(false);
  const [itemDialog, setItemDialog] = useState(false);
  const [isFetching, setIsFetching] = useState(false);
  const [item, setItem] = useState<ProductRoadmapWithProduct>({} as ProductRoadmapWithProduct);
  const queryClient = useQueryClient();
  const user = useAppSelector(userSelector);
  const isLoggedIn = useAppSelector(isLoggedInSelector);
  const dispatch = useAppDispatch();

  const getRoadmapsTypeStatusesByRoadmapIdAsync = async (
    roadmapId: number,
    typeId: number,
  ): Promise<RoadmapStatus[]> => {
    try {
      return await getRoadmapsTypeStatusesByRoadmapId(roadmapId, typeId);
    } catch (e) {
      toast.error(`Error while getting roadmaps type statuses ${e}`);
      throw e;
    }
  };

  const { mutate, isLoading: isSubmitting } = useMutation(deleteRoadMap, {
    onSuccess: () => {
      toast.dismiss();
      toast.success(capitalizeFirstLetter(t('roadmap.roadmap-deleted')));
      setDeleteItemDialog(false);
      if (item.idRoadmap) {
        queryClient.setQueryData(['roadmaps', true], (oldData: RoadMap[] | undefined) => {
          return oldData?.filter((r) => r.idRoadmap !== item.idRoadmap) ?? [];
        });
      } else {
        queryClient.invalidateQueries(['roadmaps', true]);
      }
      setItem({} as ProductRoadmapWithProduct);
      onDeleteCallback();
    },
    onError: (err: AxiosError) => {
      toast.error(`An error occurred while saving data! ${err?.message}`);
    },
  });

  const deleteItem = useCallback(async () => {
    mutate(item.idRoadmap);
  }, [item.idRoadmap, mutate]);

  const hideDeleteItemDialog = useCallback(() => {
    setDeleteItemDialog(false);
  }, []);

  const deleteItemDialogFooter = (
    <>
      <Button
        label={capitalizeFirstLetter(t('general.no'))}
        icon="pi pi-times"
        className="p-button-outlined"
        onClick={hideDeleteItemDialog}
      />
      <Button
        label={capitalizeFirstLetter(t('general.yes'))}
        loading={isSubmitting}
        icon="pi pi-check"
        className="p-button-danger"
        onClick={deleteItem}
      />
    </>
  );

  const confirmDeleteItem = (itemToDelete: ProductRoadmapWithProduct) => {
    setItem(itemToDelete);
    setDeleteItemDialog(true);
  };

  const editItem = async (itemToEdit: ProductRoadmapWithProduct) => {
    setIsFetching(true);
    try {
      const roadmap = await getRoadMapsById(itemToEdit.idRoadmap);
      let statuses = [] as RoadmapStatus[];
      if (itemToEdit.type?.idRoadmapType) {
        statuses = await getRoadmapsTypeStatusesByRoadmapIdAsync(itemToEdit.idRoadmap, itemToEdit.type.idRoadmapType);
      }
      dispatch(
        setEditableRoadmap({
          ...roadmap,
          status: getRoadmapStatus(statuses),
          owner: roadmap.owner ?? null,
          description: roadmap.description ?? '',
          detailedDescription: roadmap.detailedDescription ?? '',
          item: roadmap.item ?? '',
          justification: roadmap.justification ?? '',
          qualityCertificate: roadmap.qualityCertificate ?? '',
          developmentTasks: roadmap.developmentTasks ?? '',
          specification: roadmap.specification ?? '',
          milestones: roadmap.milestones ?? [],
          plants: roadmap.plants?.map((plant) => plant.idPlant) ?? [],
          okrs:
            roadmap.okrs?.map((okr) => ({
              id: okr.id,
              impact: { id: okr.impact, impact: getImpact(okr.impact) },
            })) ?? [],
          type: roadmap.type ? { ...roadmap.type, roadmapStatus: statuses } : null,
          version: roadmap.version ?? null,
        }),
      );
      setItemDialog(true);
    } catch (e) {
      toast.error(`Error while getting roadmaps ${e}`);
      throw e;
    } finally {
      setIsFetching(false);
    }
  };

  const actionBodyTemplate = (rowData: ProductRoadmapWithProduct) => (
    <div className="actions">
      <div id={`${rowData.idRoadmap}-edit-button`} style={{ display: 'inline-block' }}>
        <Button
          disabled={!canEditRoadmap(user, rowData)}
          disabledTooltip={
            <div>
              <p>Editing requires one the following permissions:</p>
              <ul>
                <li>
                  <b>Admin</b>
                </li>
                <li>
                  <b>Roadmap owner</b>
                </li>
                <li>
                  <b>Technical Product Owner</b>
                </li>
                <li>
                  <b>Global Product manager</b>
                </li>
                <li>
                  <b>Head of Technology</b>
                </li>
                <li>
                  <b>QA specialist</b>
                </li>
              </ul>
            </div>
          }
          icon="pi pi-pencil"
          tooltip={capitalizeFirstLetter(t('general.edit'))}
          className="p-button-rounded p-button-success mr-2"
          onClick={() => editItem(rowData)}
        />
      </div>
      <div id={`${rowData.idRoadmap}-delete-button`} style={{ display: 'inline-block' }}>
        <Button
          icon="pi pi-trash"
          disabled={!canDeleteRoadmap(user, rowData)}
          disabledTooltip={
            <div>
              <p>Deleting requires one the following permissions:</p>
              <ul>
                <li>
                  <b>Admin</b>
                </li>
                <li>
                  <b>Technical Product Owner</b>
                </li>
                <li>
                  <b>Global Product manager</b>
                </li>
                <li>
                  <b>Head of Technology</b>
                </li>
              </ul>
            </div>
          }
          tooltip={capitalizeFirstLetter(t('general.delete'))}
          className="p-button-rounded p-button-warning"
          onClick={() => confirmDeleteItem(rowData)}
        />
      </div>
    </div>
  );

  return (
    <>
      <CustomDataTable value={roadMaps} dataKey="idRoadmap" rows={15} loading={isLoading || isFetching}>
        {(isLoggedIn && <Column headerStyle={{ width: '8rem' }} body={actionBodyTemplate} />) as JSX.Element}
        {children as JSX.Element}
      </CustomDataTable>

      {itemDialog && (
        <RoadMapEditDialog
          viewOnly={false}
          visible={itemDialog}
          setVisible={setItemDialog}
          onSaveCallback={onSaveCallback}
        />
      )}

      <Dialog
        visible={deleteItemDialog}
        style={{ width: '450px' }}
        header={capitalizeFirstLetter(t('wizard.confirm'))}
        modal
        footer={deleteItemDialogFooter}
        onHide={hideDeleteItemDialog}
      >
        <div className="confirmation-content">
          <i className="pi pi-exclamation-triangle p-mr-3" style={{ fontSize: '2rem' }} />
          {item && (
            <span>
              {capitalizeFirstLetter(t('roadmap.confirm-roadmap-delete'))} <b>{item.item}</b>?
            </span>
          )}
        </div>
      </Dialog>
    </>
  );
};

RoadmapTable.defaultProps = {
  isLoading: false,
  onSaveCallback: () => null,
  onDeleteCallback: () => null,
};

export default RoadmapTable;
