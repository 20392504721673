import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { FundingSourceDTO } from 'domain/fundingSource/types';
import { PortfolioWithProductsDTO } from 'domain/productPortfolio/types';
import { PlatformRelease, RoadMapType, RoadMapVersion, RoadmapPlatform, RoadmapStatus } from 'domain/roadmap/types';
import { UserBrief } from 'domain/user/types';
import { OKR, ProductBasic } from 'features/products/types';
import { TreeSelectSelectionKeysType } from 'primereact/treeselect';
import type { RootState } from 'store/rootReducer';
import { copyMatchingKeyValues } from 'utils/object';

import { ReleaseFilterOption, RoadmapItemStatus, ViewMode } from '../types';

export type ShowBy = 'By Resource' | 'By Week';
export type FilterUserItem = 'Owner' | 'Participant' | 'Follower' | 'Reporter';

export interface RoadmapFilters {
  platform: RoadmapPlatform | null;
  releases: PlatformRelease[];
  version: RoadMapVersion | null;
  types: RoadMapType[];
  bugType: (0 | 1)[];
  statuses: RoadmapStatus[];
  okrs: OKR[];
  productOwners: UserBrief[];
  productManagers: UserBrief[];
  techManagers: UserBrief[];
  industryManagers: UserBrief[];
  owner: UserBrief | null;
  industries: TreeSelectSelectionKeysType | null;
  fundingSources: FundingSourceDTO[];
  releaseOption: ReleaseFilterOption;
  portfolios: PortfolioWithProductsDTO[];
  itemStatuses: RoadmapItemStatus[];
  products: ProductBasic[];
}

interface FilterState {
  selectedPlatform: RoadmapPlatform | null;
  selectedRelease: PlatformRelease[];
  selectedVersion: RoadMapVersion | null;
  selectedType: RoadMapType[];
  selectedBugType: (0 | 1)[];
  selectedStatus: RoadmapStatus[];
  selectedOKRs: OKR[];
  selectedProductOwners: UserBrief[];
  selectedProductManagers: UserBrief[];
  selectedTechManagers: UserBrief[];
  selectedIndustryManagers: UserBrief[];
  selectedOwner: UserBrief | null;
  selectedIndustries: TreeSelectSelectionKeysType | null;
  selectedFundingSources: FundingSourceDTO[];
  selectedReleaseOption: ReleaseFilterOption;
  selectedPortfolios: PortfolioWithProductsDTO[];
  selectedItemStatus: RoadmapItemStatus[];
  selectedProducts: ProductBasic[];
  searchText: string;
  viewMode: ViewMode;
  userItems: FilterUserItem[];
  year: number;
  showBy: ShowBy;
}

const initialState: FilterState = {
  selectedPlatform: null,
  selectedRelease: [],
  selectedVersion: null,
  selectedType: [],
  selectedBugType: [],
  selectedStatus: [],
  selectedOKRs: [],
  selectedProductOwners: [],
  selectedProductManagers: [],
  selectedTechManagers: [],
  selectedIndustryManagers: [],
  selectedOwner: null,
  selectedPortfolios: [],
  selectedProducts: [],
  selectedIndustries: null,
  selectedItemStatus: [],
  selectedFundingSources: [],
  selectedReleaseOption: 'Specific',
  searchText: '',
  viewMode: 'Grid',
  year: new Date().getFullYear(),
  showBy: 'By Resource',
  userItems: [],
};

export const filterSlice = createSlice({
  name: 'roadmap/filter',
  initialState,
  reducers: {
    clearFilter: (state: FilterState) => {
      copyMatchingKeyValues(state, initialState);
      state.selectedItemStatus = [];
    },
    setFilters: (state: FilterState, action: PayloadAction<Partial<RoadmapFilters>>) => {
      const {
        platform = null,
        releases = [],
        version = null,
        types = [],
        statuses = [],
        okrs = [],
        productOwners = [],
        productManagers = [],
        techManagers = [],
        industryManagers = [],
        owner = null,
        industries = null,
        fundingSources = [],
        portfolios = [],
        itemStatuses = [],
        releaseOption = 'Specific',
        products = [],
      } = action.payload;

      state.selectedPlatform = platform;
      state.selectedRelease = releases;
      state.selectedVersion = version;
      state.selectedType = types;
      state.selectedStatus = statuses;
      state.selectedOKRs = okrs;
      state.selectedProductOwners = productOwners;
      state.selectedProductManagers = productManagers;
      state.selectedTechManagers = techManagers;
      state.selectedIndustryManagers = industryManagers;
      state.selectedOwner = owner;
      state.selectedIndustries = industries;
      state.selectedFundingSources = fundingSources;
      state.selectedPortfolios = portfolios;
      state.selectedItemStatus = itemStatuses;
      state.selectedReleaseOption = releaseOption;
      state.selectedProducts = products;
    },
    setSelectedPlatform: (state, action: PayloadAction<RoadmapPlatform | null>) => {
      state.selectedPlatform = action.payload;
    },
    setSelectedRelease: (state, action: PayloadAction<PlatformRelease[]>) => {
      state.selectedRelease = action.payload;
    },
    setSelectedVersion: (state, action: PayloadAction<RoadMapVersion | null>) => {
      state.selectedVersion = action.payload;
    },
    setSelectedBugType: (state, action: PayloadAction<(0 | 1)[]>) => {
      state.selectedBugType = action.payload;
    },
    setSelectedType: (state, action: PayloadAction<RoadMapType[]>) => {
      state.selectedType = action.payload;
    },
    setSelectedStatus: (state, action: PayloadAction<RoadmapStatus[]>) => {
      state.selectedStatus = action.payload;
    },
    setSelectedOKRs: (state, action: PayloadAction<OKR[]>) => {
      state.selectedOKRs = action.payload;
    },
    setSelectedProductOwners: (state, action: PayloadAction<UserBrief[]>) => {
      state.selectedProductOwners = action.payload;
    },
    setSelectedProductManagers: (state, action: PayloadAction<UserBrief[]>) => {
      state.selectedProductManagers = action.payload;
    },
    setSelectedTechManagers: (state, action: PayloadAction<UserBrief[]>) => {
      state.selectedTechManagers = action.payload;
    },
    setSelectedIndustryManagers: (state, action: PayloadAction<UserBrief[]>) => {
      state.selectedIndustryManagers = action.payload;
    },
    setSelectedOwner: (state, action: PayloadAction<UserBrief | null>) => {
      state.selectedOwner = action.payload;
    },
    setSelectedIndustries: (state, action: PayloadAction<TreeSelectSelectionKeysType | null>) => {
      state.selectedIndustries = action.payload;
    },
    setSelectedFundingSources: (state, action: PayloadAction<FundingSourceDTO[]>) => {
      state.selectedFundingSources = action.payload;
    },
    setSelectedReleaseOption: (state, action: PayloadAction<ReleaseFilterOption>) => {
      state.selectedReleaseOption = action.payload;
    },
    setSelectedPortfolios: (state, action: PayloadAction<PortfolioWithProductsDTO[]>) => {
      state.selectedPortfolios = action.payload;
    },
    setSelectedItemStatus: (state, action: PayloadAction<RoadmapItemStatus[]>) => {
      state.selectedItemStatus = action.payload;
    },
    setSearchText: (state, action: PayloadAction<string>) => {
      state.searchText = action.payload;
    },
    setViewMode: (state, action: PayloadAction<ViewMode>) => {
      state.viewMode = action.payload;
    },
    setYear: (state, action: PayloadAction<number>) => {
      state.year = action.payload;
    },
    setShowBy: (state, action: PayloadAction<ShowBy>) => {
      state.showBy = action.payload;
    },
    setSelectedProducts: (state, action: PayloadAction<ProductBasic[]>) => {
      state.selectedProducts = action.payload;
    },
    setFilterUserItems: (state, action: PayloadAction<FilterUserItem[]>) => {
      state.userItems = action.payload;
    },
  },
});

export const {
  setSelectedPlatform,
  setSelectedRelease,
  setSelectedVersion,
  setSelectedType,
  setSelectedBugType,
  setSelectedStatus,
  setSelectedOKRs,
  setSelectedProductOwners,
  setSelectedProductManagers,
  setSelectedTechManagers,
  setSelectedIndustryManagers,
  setSelectedOwner,
  setSelectedIndustries,
  setSelectedFundingSources,
  setSelectedReleaseOption,
  setSelectedItemStatus,
  setSelectedPortfolios,
  setSearchText,
  setViewMode,
  setYear,
  setShowBy,
  clearFilter,
  setFilters,
  setSelectedProducts,
  setFilterUserItems,
} = filterSlice.actions;

export const selectedPlatformSelector = (state: RootState) => state.roadmapFilter.selectedPlatform;
export const selectedReleaseSelector = (state: RootState) => state.roadmapFilter.selectedRelease;
export const selectedVersionSelector = (state: RootState) => state.roadmapFilter.selectedVersion;
export const selectedTypeSelector = (state: RootState) => state.roadmapFilter.selectedType;
export const selectedBugTypeSelector = (state: RootState) => state.roadmapFilter.selectedBugType;
export const selectedStatusSelector = (state: RootState) => state.roadmapFilter.selectedStatus;
export const selectedOKRsSelector = (state: RootState) => state.roadmapFilter.selectedOKRs;
export const selectedProductOwnersSelector = (state: RootState) => state.roadmapFilter.selectedProductOwners;
export const selectedProductManagersSelector = (state: RootState) => state.roadmapFilter.selectedProductManagers;
export const selectedTechManagersSelector = (state: RootState) => state.roadmapFilter.selectedTechManagers;
export const selectedIndustryManagersSelector = (state: RootState) => state.roadmapFilter.selectedIndustryManagers;
export const selectedOwnerSelector = (state: RootState) => state.roadmapFilter.selectedOwner;
export const selectedIndustriesSelector = (state: RootState) => state.roadmapFilter.selectedIndustries;
export const selectedFundingSourcesSelector = (state: RootState) => state.roadmapFilter.selectedFundingSources;
export const selectedReleaseOptionSelector = (state: RootState) => state.roadmapFilter.selectedReleaseOption;
export const searchTextSelector = (state: RootState) => state.roadmapFilter.searchText;
export const viewModeSelector = (state: RootState) => state.roadmapFilter.viewMode;
export const yearSelector = (state: RootState) => state.roadmapFilter.year;
export const showBySelector = (state: RootState) => state.roadmapFilter.showBy;
export const selectedPortfoliosSelector = (state: RootState) => state.roadmapFilter.selectedPortfolios;
export const selectedItemStatusSelector = (state: RootState) => state.roadmapFilter.selectedItemStatus;
export const filterUserItemsSelector = (state: RootState) => state.roadmapFilter.userItems ?? [];
export const selectedProductsSelector = (state: RootState) => state.roadmapFilter.selectedProducts;
export const getFiltersSelector = (state: RootState): RoadmapFilters => ({
  platform: state.roadmapFilter.selectedPlatform,
  releases: state.roadmapFilter.selectedRelease,
  version: state.roadmapFilter.selectedVersion,
  types: state.roadmapFilter.selectedType,
  bugType: state.roadmapFilter.selectedBugType,
  statuses: state.roadmapFilter.selectedStatus,
  okrs: state.roadmapFilter.selectedOKRs,
  productOwners: state.roadmapFilter.selectedProductOwners,
  productManagers: state.roadmapFilter.selectedProductManagers,
  techManagers: state.roadmapFilter.selectedTechManagers,
  industryManagers: state.roadmapFilter.selectedIndustryManagers,
  owner: state.roadmapFilter.selectedOwner,
  industries: state.roadmapFilter.selectedIndustries,
  fundingSources: state.roadmapFilter.selectedFundingSources,
  releaseOption: state.roadmapFilter.selectedReleaseOption,
  portfolios: state.roadmapFilter.selectedPortfolios,
  itemStatuses: state.roadmapFilter.selectedItemStatus,
  products: state.roadmapFilter.selectedProducts,
});

export default filterSlice.reducer;
