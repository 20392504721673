import api from 'services/api';

import { RoadmapCustomerCareReport, RoadmapTempoReportByUser, RoadmapTempoReportRequest } from '../types';

const BASE_URL = 'roadmaps';

export const getRoadmapTempoReportByUser = async (request: RoadmapTempoReportRequest) => {
  const { data } = await api.post<RoadmapTempoReportByUser[]>(`${BASE_URL}/report/tempo`, request);
  return data;
};

export const getRoadmapCustomerCarePendingInstall = async () => {
  const { data } = await api.get<RoadmapCustomerCareReport[]>(`${BASE_URL}/report/customer-care`);
  return data;
};
