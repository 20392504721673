import { editableProductSelector, setEditableProduct } from 'features/products/reducers/solutionSlice';
import { Training } from 'features/products/types';
import { Button } from 'primereact/button';
import { Column } from 'primereact/column';
import { confirmPopup } from 'primereact/confirmpopup';
import { DataTable } from 'primereact/datatable';
import { Dialog } from 'primereact/dialog';
import { Toolbar } from 'primereact/toolbar';
import { MouseEvent, useState } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import { useAppDispatch, useAppSelector } from 'store';
import { randomIntFromInterval } from 'utils/number';

import AddTrainingForm from '../AddTrainingForm/AddTrainingForm';
import styles from './RelatedTrainingTable.module.scss';

type RelatedTrainingTableProps = {
  trainings: Training[];
};

const initialValue = {
  availability: '',
  contentUpdate: '',
  courseType: '',
  courseLevel: '',
  courseName: '',
  description: '',
  duration: 60,
  knowledgePrerequisites: '',
  link: '',
};

const RelatedTrainingTable = ({ trainings }: RelatedTrainingTableProps) => {
  const editableProduct = useAppSelector(editableProductSelector);
  const dispatch = useAppDispatch();
  const [addDialogVisible, setAddDialogVisible] = useState(false);
  const [isNew, setIsNew] = useState(false);
  const { t } = useTranslation();

  const { control, handleSubmit, formState, reset } = useForm<Training>({
    defaultValues: {
      courseOrder: trainings.length + 1,
      ...initialValue,
    },
  });

  const addItems = async (values: Training[]) => {
    if (editableProduct) {
      dispatch(
        setEditableProduct({ ...editableProduct, trainings: [...(editableProduct.trainings ?? []), ...values] }),
      );
    }
  };

  const updateItem = async (value: Training) => {
    if (editableProduct) {
      dispatch(
        setEditableProduct({
          ...editableProduct,
          trainings: editableProduct.trainings.map((training) =>
            training.idTraining === value.idTraining ? value : training,
          ),
        }),
      );
    }
  };

  const removeItems = async (value: Training) => {
    if (editableProduct) {
      dispatch(
        setEditableProduct({
          ...editableProduct,
          trainings: editableProduct.trainings.filter((x) => x.idTraining !== value.idTraining),
        }),
      );
      reset({ ...initialValue, courseOrder: trainings.length });
    }
  };

  const removeTraining = (e: MouseEvent<HTMLButtonElement>, value: Training) => {
    confirmPopup({
      target: e.currentTarget,
      message: t('general.remove-item'),
      icon: 'pi pi-info-circle',
      acceptClassName: 'p-button-danger',
      accept: () => removeItems(value),
    });
  };

  const handleEditTrainingDialog = (training: Training) => {
    reset(training);
    setIsNew(false);
    setAddDialogVisible(true);
  };

  const handleAddNewTrainingDialog = () => {
    reset({
      courseOrder: trainings.length + 1,
      ...initialValue,
    });
    setIsNew(true);
    setAddDialogVisible(true);
  };

  const leftToolbarTemplate = () => (
    <Button
      label="Add"
      icon="pi pi-plus"
      className="p-button-success mr-2 p-mb-2"
      aria-haspopup
      aria-controls="overlay_panel"
      onClick={handleAddNewTrainingDialog}
    />
  );

  const actionBodyTemplate = (training: Training) => (
    <div className="actions">
      <Button
        icon="pi pi-pencil"
        tooltip="Edit"
        className="p-button-rounded p-button-success mr-2"
        onClick={() => handleEditTrainingDialog(training)}
      />
      <Button
        icon="pi pi-trash"
        tooltip="Delete"
        className="p-button-rounded p-button-danger"
        onClick={(e: MouseEvent<HTMLButtonElement>) => removeTraining(e, training)}
      />
    </div>
  );

  const renderHeader = () => (
    <div className={styles.headerContainer}>
      <span>{isNew ? 'Add' : 'Edit'} Training</span>
    </div>
  );

  const onSubmit: SubmitHandler<Training> = async (payload) => {
    if (
      trainings.some(
        (training) => training.courseName === payload.courseName && training.idTraining !== payload.idTraining,
      )
    ) {
      toast.error('A training with the same name already exists');
      return;
    }
    if (!payload.idTraining) {
      addItems([
        {
          ...payload,
          idTraining: randomIntFromInterval(10000, 99999) * -1,
          idProduct: 0,
        },
      ]);
    } else {
      updateItem(payload);
    }
    reset({ ...initialValue, courseOrder: payload.courseOrder + 1 });
    setAddDialogVisible(false);
  };

  const itemDialogFooter = (
    <div className={styles.bottomButton}>
      <Button
        label="Close"
        icon="pi pi-times"
        className="p-button-outlined"
        onClick={() => setAddDialogVisible(false)}
      />
      <Button
        label={isNew ? 'Add' : 'Save'}
        icon={`pi ${isNew ? 'pi-plus' : 'pi-check'}`}
        onClick={handleSubmit(onSubmit)}
      />
    </div>
  );

  return (
    <>
      <Toolbar className="p-mb-4 p-toolbar" start={leftToolbarTemplate} />
      <DataTable
        value={trainings}
        dataKey="idTraining"
        paginator
        rows={10}
        rowsPerPageOptions={[5, 10, 25]}
        className="datatable-responsive"
        paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport 
RowsPerPageDropdown"
        currentPageReportTemplate="Showing {first} to {last} of {totalRecords} items"
        emptyMessage="No items found."
      >
        <Column field="courseName" header="Course Name" sortable />
        <Column field="description" header="Description" sortable />
        <Column field="courseLevel" header="Course Level" sortable />
        <Column field="courseType" header="Course Type" sortable />
        <Column field="courseOrder" header="Course Order" sortable />
        <Column headerStyle={{ width: '10%', minWidth: '8rem' }} body={actionBodyTemplate} />
      </DataTable>
      <Dialog
        visible={addDialogVisible}
        className={styles.dialog}
        header={renderHeader}
        modal
        footer={itemDialogFooter}
        onHide={() => setAddDialogVisible(false)}
      >
        <AddTrainingForm control={control} errors={formState.errors} />
      </Dialog>
    </>
  );
};

export default RelatedTrainingTable;
