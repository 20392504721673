import { StatusBadge } from 'components/common';
import { RoadMapUpdate } from 'domain/roadmap/types';
import { editableRoadMapSelector } from 'features/roadmap/reducers/roadmap';
import { Dropdown } from 'primereact/dropdown';
import { Control, Controller } from 'react-hook-form';
import { useAppSelector } from 'store';

import styles from '../RoadMapDetailForm.module.scss';

interface PriorityFieldProps {
  viewOnly: boolean;
  control: Control<RoadMapUpdate, unknown>;
}

const renderPriority = (priority: number | undefined) => {
  switch (priority) {
    case 0:
      return <StatusBadge color="grey">Low</StatusBadge>;
    case 1:
      return <StatusBadge color="yellow">Medium</StatusBadge>;
    case 2:
      return <StatusBadge color="red">High</StatusBadge>;
    default:
      return <StatusBadge color="grey">Low</StatusBadge>;
  }
};

const PriorityField = ({ viewOnly, control }: PriorityFieldProps) => {
  const editableRoadMap = useAppSelector(editableRoadMapSelector);
  return (
    <div className={styles.field}>
      <span>Priority: </span>
      {viewOnly ? (
        <p className={styles.readonlyField}>{renderPriority(editableRoadMap.priority)}</p>
      ) : (
        <Controller
          name="priority"
          control={control}
          render={({ field: { value, onChange } }) => (
            <Dropdown
              value={value}
              onChange={onChange}
              options={[
                { value: 0, label: 'Low' },
                { value: 1, label: 'Medium' },
                { value: 2, label: 'High' },
              ]}
            />
          )}
        />
      )}
    </div>
  );
};

export default PriorityField;
