import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { RoadMapUpdate } from 'domain/roadmap/types';
import { initialValue } from 'features/admin/utils/initialValues';
import type { RootState } from 'store/rootReducer';

interface RoadmapState {
  following: number[];
  editableRoadMap: RoadMapUpdate;
}

const initialState: RoadmapState = {
  following: [],
  editableRoadMap: initialValue,
};

export const roadmapSlice = createSlice({
  name: 'roadmap',
  initialState,
  reducers: {
    addFollowingIds: (state, action: PayloadAction<number>) => {
      state.following = [...state.following, action.payload];
    },
    setEditableRoadmap: (state, action: PayloadAction<RoadMapUpdate>) => {
      state.editableRoadMap = action.payload;
    },
  },
});

export const { addFollowingIds, setEditableRoadmap } = roadmapSlice.actions;

export const followingSelector = (state: RootState) => state.roadmap.following;
export const editableRoadMapSelector = (state: RootState) => state.roadmap.editableRoadMap;

export default roadmapSlice.reducer;
