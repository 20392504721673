import { Editor } from 'components/KendoReact';
import { RoadMapUpdate } from 'domain/roadmap/types';
import { editableRoadMapSelector } from 'features/roadmap/reducers/roadmap';
import parse from 'html-react-parser';
import { Control, Controller } from 'react-hook-form';
import { useAppSelector } from 'store';

import styles from '../RoadMapDetailForm.module.scss';

interface DetailedDescriptionFieldProps {
  viewOnly: boolean;
  control: Control<RoadMapUpdate, unknown>;
}
const DetailedDescriptionField = ({ viewOnly, control }: DetailedDescriptionFieldProps) => {
  const editableRoadMap = useAppSelector(editableRoadMapSelector);
  return (
    <Controller
      name="detailedDescription"
      control={control}
      render={({ field: { value, onChange } }) => (
        <div className={styles.field}>
          <span>Detailed Description</span>
          {viewOnly ? (
            <div>{parse(editableRoadMap.detailedDescription ?? '')}</div>
          ) : (
            <Editor onChange={(event) => onChange(event.html)} value={value ?? ''} contentStyle={{ height: '630px' }} />
          )}
        </div>
      )}
    />
  );
};

export default DetailedDescriptionField;
