import { MsalProvider } from '@azure/msal-react';
import { ClickToComponent } from 'click-to-react-component';
import { ProgressSpinner } from 'components/common';
import { ConfirmDialog } from 'primereact/confirmdialog';
import { ConfirmPopup } from 'primereact/confirmpopup';
import * as React from 'react';
import { createRoot } from 'react-dom/client';
import { Provider } from 'react-redux';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { PersistGate } from 'redux-persist/integration/react';
import { pca } from 'services/msal-browser';

import App from './App';
import './i18n/config';
import { persistor, store } from './store';
import './styles/global.scss';

const root = createRoot(document.getElementById('root') as HTMLElement);

root.render(
  <React.StrictMode>
    <Provider store={store}>
      <PersistGate loading={<ProgressSpinner />} persistor={persistor}>
        <ToastContainer style={{ marginTop: 65, zIndex: 999999 }} position="top-right" newestOnTop theme="colored" />
        <ClickToComponent />
        <MsalProvider instance={pca}>
          <App />
        </MsalProvider>
        <ConfirmPopup />
        <ConfirmDialog />
      </PersistGate>
    </Provider>
  </React.StrictMode>,
);
