import type { ReleaseViewModel } from 'features/okrs/types';
import { toast } from 'react-toastify';
import api from 'services/api';

import type {
  BasicRoadmap,
  History,
  PlatformRelease,
  RoadMap,
  RoadMapType,
  RoadMapUpdate,
  RoadMapVersion,
  RoadmapCategory,
  RoadmapFollower,
  RoadmapFundingSourceList,
  RoadmapList,
  RoadmapPlatform,
  RoadmapResource,
  RoadmapStatus,
  RoadmapStatusSimpleDTO,
  RoadmapStatusWithResourceDTO,
} from '../types';

const BASE_URL = 'roadmaps';

export const getRoadMaps = async () => {
  const { data } = await api.get<RoadmapList[]>(BASE_URL);
  return data;
};

export const getRoadMapFunding = async (year: number) => {
  const { data } = await api.get(`${BASE_URL}/funding-source/${year}`);
  return data as RoadmapFundingSourceList[];
};

export const getFollowers = async (roadmapId: number) => {
  const { data } = await api.get(`${BASE_URL}/${roadmapId}/followers`);
  return data as RoadmapFollower[];
};

export const createFollower = async ({ idRoadmap, follower }: { idRoadmap: number; follower: RoadmapFollower }) => {
  try {
    const { data } = await api.post(`${BASE_URL}/${idRoadmap}/followers`, follower);
    toast.success('Follower added successfully');
    return data as RoadmapFollower;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  } catch (e: any) {
    toast.error(`Failed to add follower: ${e.message}`);
    throw e;
  }
};

export const deleteFollower = async (id: number) => {
  try {
    await api.delete(`${BASE_URL}/followers/${id}`);
    toast.success('Follower deleted successfully');
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  } catch (e: any) {
    throw Error(`Failed to delete follower: ${e.response?.data?.Message ?? e.message}`);
  }
};

export const getResources = async (year?: number) => {
  const { data } = await api.get(`${BASE_URL}/resources`, {
    params: { year },
  });
  return data as RoadmapResource[];
};

export const getStatusResources = async (roadmapId: number) => {
  const { data } = await api.get(`${BASE_URL}/${roadmapId}/resources`);
  return data as RoadmapStatusWithResourceDTO[];
};

export const getRoadMapsById = async (id: number) => {
  const { data } = await api.get(`${BASE_URL}/${id}`);
  return data as RoadMap;
};

export const getRoadmapListById = async (id: number) => {
  const { data } = await api.get(`${BASE_URL}/${id}/list`);
  return data as RoadmapList;
};

export const updateRoadMap = async (roadMap: RoadMapUpdate) => {
  await api.put(BASE_URL, roadMap);
  return roadMap;
};

export const createRoadMap = async (roadMap: RoadMapUpdate) => {
  const { data } = await api.post(BASE_URL, roadMap);
  return data as RoadMap;
};

export const deleteRoadMap = async (id: number) => {
  await api.delete(`${BASE_URL}/${id}`);
};

export const getRoadmapPlatforms = async () => {
  const { data } = await api.get(`${BASE_URL}/platforms`);
  return data as RoadmapPlatform[];
};

export const getRoadMapVersions = async () => {
  const { data } = await api.get(`${BASE_URL}/versions`);
  return data as RoadMapVersion[];
};

export const getRoadMapVersionsList = async () => {
  const { data } = await api.get(`${BASE_URL}/versions/list`);
  return data as RoadMapVersion[];
};

export const getRoadMapReleases = async () => {
  const { data } = await api.get(`${BASE_URL}/releases/list`);
  return data as PlatformRelease[];
};

export const getGroupedOKRs = async () => {
  const { data } = await api.get(`${BASE_URL}/releases`);
  return data as ReleaseViewModel[];
};

export const getRoadmapsTypes = async () => {
  const { data } = await api.get(`${BASE_URL}/types`);
  return data as RoadMapType[];
};

export const getRoadmapsStatuses = async () => {
  const { data } = await api.get(`${BASE_URL}/statuses`);
  return data as RoadmapStatus[];
};

export const getRoadmapsTypeStatuses = async (id: number) => {
  const { data } = await api.get(`${BASE_URL}/types/${id}/statuses`);
  return data as RoadmapStatus[];
};

export const getRoadmapsTypeStatusesByRoadmapId = async (roadmapId: number, typeId: number) => {
  const { data } = await api.get(`${BASE_URL}/${roadmapId}/types/${typeId}/statuses`);
  return data as RoadmapStatus[];
};

export const getRoadmapsTypeStatusesByVersion = async (typeId: number, versionId: number) => {
  const { data } = await api.get(`${BASE_URL}/types/${typeId}/version/${versionId}`);
  return data as RoadmapStatus[];
};

export const getRoadmapsHistory = async (roadmapId: number, field: string | null = null) => {
  const { data } = await api.get<History[]>(`${BASE_URL}/${roadmapId}/history`, { params: { field } });
  return data;
};

export const getRoadmapStatuses = async (roadmapId: number) => {
  const { data } = await api.get<RoadmapStatusSimpleDTO[]>(`${BASE_URL}/${roadmapId}/statuses`);
  return data;
};

export const checkUserIsParticipant = async (roadmapId: number) => {
  const { data } = await api.get<boolean>(`${BASE_URL}/${roadmapId}/check-participant`);
  return data;
};

export const getRoadmapCategory = async () => {
  const { data } = await api.get<RoadmapCategory[]>(`${BASE_URL}/categorization`);
  return data;
};

export const getBasicRoadmap = async () => {
  const { data } = await api.get<BasicRoadmap[]>(`${BASE_URL}/basic`);
  return data;
};
