import { StatusBadge } from 'components/common';
import { StatusBadgeColors } from 'components/common/StatusBadge/StatusBadge';
import { ProductDocumentLinkDTO } from 'features/products/types';
import { DataView } from 'primereact/dataview';

import styles from './DocumentsTab.module.scss';

interface DocumentsTabProps {
  documents: ProductDocumentLinkDTO[];
}

const getColor = (id: number): StatusBadgeColors => {
  switch (id) {
    case 1:
      return 'blue';
    case 2:
      return 'lightBlue';
    case 3:
      return 'lightRed';
    case 4:
      return 'lightGreen';
    case 5:
      return 'green';
    case 6:
      return 'red';
    case 7:
      return 'lightyellow';
    case 8:
      return 'lightGreen';
    case 9:
      return 'yellow';
    case 10:
      return 'green';
    case 11:
      return 'blue';
    case 12:
      return 'lightBlue';
    default:
      return 'blue';
  }
};

const documentTemplate = (document: ProductDocumentLinkDTO) => (
  <div className={styles.documentContainer}>
    <div className={styles.iconContainer}>
      <i className="pi pi-file" style={{ fontSize: '2rem' }} />
    </div>
    <div>
      <StatusBadge color={getColor(document.documentType?.idProductDocumentType ?? 0)} fontSize={10}>
        {document.documentType?.name}
      </StatusBadge>
      <p>
        <a className={styles.documentName} href={document.documentLink} target="_blank" rel="noreferrer">
          {document.documentName}
        </a>
      </p>
    </div>
  </div>
);

const DocumentsTab = ({ documents }: DocumentsTabProps) => {
  return <DataView value={documents} layout="list" itemTemplate={documentTemplate} paginator rows={20} />;
};

export default DocumentsTab;
