import api from 'services/api';

import { MaturityModelOption } from '../types/maturityModel.interface';

const BASE_URL = 'maturityModelOptions';

export const getMaturityModelOptions = async (): Promise<MaturityModelOption[]> => {
  const { data } = await api.get(BASE_URL);
  return data as MaturityModelOption[];
};

export const getMaturityModelOption = async (id: number): Promise<MaturityModelOption> => {
  const { data } = await api.get(`${BASE_URL}/${id}`);
  return data as MaturityModelOption;
};

export const updateMaturityModelOption = async (data: MaturityModelOption): Promise<MaturityModelOption> => {
  await api.put(`${BASE_URL}/${data.idMaturityOption}`, data);
  return data;
};

export const createMaturityModelOption = async (option: MaturityModelOption): Promise<MaturityModelOption> => {
  const { data } = await api.post(BASE_URL, option);
  return data as MaturityModelOption;
};

export const deleteMaturityModelOption = async (id: number): Promise<void> => api.delete(`${BASE_URL}/${id}`);
