import { BusyContext } from 'components/common';
import { useDetailedProduct } from 'domain/product/hook';
import parse from 'html-react-parser';
import { useMemo } from 'react';
import { useParams } from 'react-router-dom';

import styles from './MarketingDetail.module.scss';

const MarketingDetail = () => {
  const { id } = useParams();
  const { data: detailedProduct, isLoading } = useDetailedProduct(Number(id ?? 0), !!id);

  const descriptionForProposals = useMemo(
    () => detailedProduct?.descriptionForProposals?.replace(/<img\b[^>]*>/g, ''),
    [detailedProduct?.descriptionForProposals],
  );

  return (
    <div className={styles.container}>
      <p className={styles.description}>{detailedProduct?.description}</p>
      {descriptionForProposals && (
        <>
          <div className={styles.content}>{parse(descriptionForProposals)}</div>
          <br />
        </>
      )}
      <BusyContext isLoading={isLoading} />
    </div>
  );
};

export default MarketingDetail;
