import { Tooltip } from 'primereact/tooltip';

import styles from './OkrCard.module.scss';

const impacts = [
  {
    label: 'HIGH',
    color: 'red',
    desc: [
      <span key={1}>Requirements that are </span>,
      <b key={2}>critical</b>,
      <span key={3}> to the current delivery roadmap in order for it to be a success.</span>,
    ],
    tooltip:
      'Requirements labelled as High are critical to the current delivery timebox in order for it to be a success.' +
      ' If even one High have requirement is not included, the project delivery should be considered a failure.',
  },
  {
    label: 'MEDIUM',
    color: 'orange',
    desc: [
      <span key={4}>Requirements that are </span>,
      <b key={5}>important</b>,
      <span key={6}> but not necessary for delivery in the current delivery timebox.</span>,
    ],
    tooltip:
      'Requirements labelled as Medium are important but not necessary for delivery in the current delivery timebox. ' +
      'While Medium requirements can be as important as High, they are often not as time-critical or ' +
      'there may be another way to satisfy the requirement so that it can be held back until a future delivery timebox.',
  },
  {
    label: 'LOW',
    color: 'green',
    desc: [
      <span key={7}>Requirements that are </span>,
      <b key={8}>desirable </b>,
      <span key={9}>
        but not necessary and could improve the user experience or customer satisfaction for a little development cost.
      </span>,
    ],
    tooltip:
      'Requirements labelled as Low are desirable but not necessary and could improve the user experience or customer satisfaction' +
      ' for a little development cost. These will typically be included if time and resources permit.',
  },
];

interface CardProps {
  label: string;
  color: string;
  desc: JSX.Element[];
  tooltip: string;
}

const Card = ({ label, color, desc, tooltip }: CardProps) => (
  <div>
    <Tooltip className={styles.tooltip} target={`[id='${label}']`} content={tooltip} />
    <div id={label} className={styles.card}>
      <div className={styles.cardHeader}>
        <div className={`${styles.cardTitle} ${styles[color]}`}>{label}</div>
      </div>
      <div className={styles.cardDescription}>{desc}</div>
    </div>
  </div>
);

const OkrCard = () => {
  return (
    <div className={styles.cardsContainer}>
      {impacts.map((x) => {
        return <Card key={x.label} label={x.label} color={x.color} desc={x.desc} tooltip={x.tooltip} />;
      })}
    </div>
  );
};

export default OkrCard;
