import { CommentDto } from 'domain/roadmap/types';
import { Avatar } from 'primereact/avatar';
import { Button } from 'primereact/button';
import { confirmPopup } from 'primereact/confirmpopup';
import { InputTextarea } from 'primereact/inputtextarea';
import { MouseEvent, useState } from 'react';
import Linkify from 'react-linkify';
import { toLocaleDateTimeString } from 'utils/datetime';

import styles from './MessageCard.module.scss';

interface MessageCardProps {
  isOwnedByUser: boolean;
  comment: CommentDto;
  onEdit: (value: string, id: number) => void;
  onDelete: (id: number) => void;
}

const MessageCard = ({ isOwnedByUser, comment, onEdit, onDelete }: MessageCardProps) => {
  const [isEditing, setIsEditing] = useState(false);
  const [value, setValue] = useState(comment.comment);

  const handleEdit = () => {
    onEdit(value, comment.idComment);
    setIsEditing(false);
  };

  const handleDelete = (e: MouseEvent<HTMLButtonElement>) => {
    confirmPopup({
      target: e.currentTarget,
      message: 'Are you sure you want to delete it?',
      icon: 'pi pi-exclamation-triangle',
      accept: () => onDelete(comment.idComment),
    });
  };

  return (
    <>
      <li className={styles.message}>
        <div className={`${styles.grid} ${isOwnedByUser ? styles.right : null}`}>
          <div className={styles.colFixed}>
            <Avatar
              icon="pi pi-user"
              className="mr-2"
              style={{ backgroundColor: '#2196F3', color: '#ffffff' }}
              shape="circle"
            />
          </div>
          <div className={styles.col}>
            <div className={styles.header}>
              <div className={styles.name}>{comment.user.name} - </div>
              <div className={styles.timestamp}>{toLocaleDateTimeString(comment.timestamp)}</div>
              {isOwnedByUser && !isEditing && (
                <>
                  <Button
                    label="Edit"
                    onClick={() => setIsEditing(true)}
                    className={`p-button-link ${styles.actionButton}`}
                  />
                  <Button label="Delete" onClick={handleDelete} className={`p-button-link ${styles.deleteButton}`} />
                </>
              )}
              {isEditing && (
                <Button label="Save" className={`p-button-outlined  ${styles.actionButton}`} onClick={handleEdit} />
              )}
            </div>
            <div className={styles.chatMessage}>
              {isEditing ? (
                <InputTextarea
                  className={styles.textArea}
                  rows={2}
                  value={value}
                  onChange={(e) => setValue(e.target.value)}
                  autoResize
                />
              ) : (
                <Linkify
                  componentDecorator={(decoratedHref, decoratedText, key) => (
                    <a target="blank" href={decoratedHref} key={key}>
                      {decoratedText}
                    </a>
                  )}
                >
                  {comment.comment}
                </Linkify>
              )}
            </div>
          </div>
        </div>
      </li>
    </>
  );
};

export default MessageCard;
