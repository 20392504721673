import { editableProductSelector, setEditableProduct } from 'features/products/reducers/solutionSlice';
import { News } from 'features/products/types';
import { Button } from 'primereact/button';
import { confirmPopup } from 'primereact/confirmpopup';
import { DataView } from 'primereact/dataview';
import { MouseEvent } from 'react';
import { useTranslation } from 'react-i18next';
import { useAppDispatch, useAppSelector } from 'store';
import { toDateString } from 'utils/datetime';

import styles from './ProductNewsTable.module.scss';

interface ProductNewsTableProps {
  news: News[];
  readonly?: boolean;
}

const sortNews = (a: News, b: News): 1 | -1 => (a.idNews < b.idNews ? 1 : -1);

const ProductNewsTable = ({ news: newsList, readonly = true }: ProductNewsTableProps) => {
  const editableProduct = useAppSelector(editableProductSelector);
  const { t } = useTranslation();
  const newsListSorted = [...newsList].sort(sortNews);
  const dispatch = useAppDispatch();

  const removeItem = async (id: number) => {
    if (editableProduct) {
      dispatch(
        setEditableProduct({
          ...editableProduct,
          news: editableProduct.news.filter((x) => x.idNews !== id),
        }),
      );
    }
  };

  const removeNews = (e: MouseEvent<HTMLButtonElement>, id: number) => {
    confirmPopup({
      target: e.currentTarget,
      message: t('general.remove-item'),
      icon: 'pi pi-info-circle',
      acceptClassName: 'p-button-danger',
      accept: () => removeItem(id),
    });
  };

  const newsTemplate = (news: News) => (
    <div className={styles.newsCard}>
      <a style={{ width: '100%' }} href={news.contentUrl} target="_blank" rel="noreferrer">
        <div className={styles.newsCardContent}>
          <div className={styles.newsImage}>
            <img
              src={news.imageUrl ?? '/images/default-news.png'}
              alt={news.title}
              onError={({ currentTarget }) => {
                // eslint-disable-next-line no-param-reassign
                currentTarget.src = '/images/default-news.png';
                // eslint-disable-next-line no-param-reassign
                currentTarget.onerror = null;
              }}
            />
          </div>
          <div className={styles.newsContent}>
            <p className={styles.newsTitle}>{news.title}</p>
            <p className={styles.newsDate}>{news.timestamp && toDateString(news.timestamp, '-')}</p>
            <p className={styles.newsDescription}>{news.description}</p>
          </div>
        </div>
      </a>
      {!readonly && (
        <div className={styles.newsActions}>
          {/* <Button onClick={(e) => editNews(e, news.idNews)} icon="pi pi-pencil" className="p-button-rounded" /> */}
          <Button
            onClick={(e) => removeNews(e, news.idNews)}
            icon="pi pi-trash"
            className="p-button-rounded p-button-danger"
          />
        </div>
      )}
    </div>
  );

  return (
    <div className={styles.newsListContainer}>
      <DataView value={newsListSorted} layout="list" itemTemplate={newsTemplate} paginator rows={4} />
    </div>
  );
};

ProductNewsTable.defaultProps = {
  readonly: true,
};

export default ProductNewsTable;
