import editIcon from 'assets/images/icons/Edit.png';
import { StatusBadge } from 'components/common';
import {
  MaturityModelGroup,
  MaturityModelGroupItemsDTO,
  MaturityModelGroupItemsOptions,
} from 'domain/maturityModel/types';
import { useMaturityModel } from 'domain/product/hook';
import { getHeader, getStatusColor } from 'features/admin/utils';
import { isAdminSelector, userSelector } from 'features/auth/reducer/auth';
import { editableProductSelector, setEditableProduct } from 'features/products/reducers/solutionSlice';
import { BlockUI } from 'primereact/blockui';
import { Column, ColumnEditorOptions } from 'primereact/column';
import { DataTable, DataTableExpandedRows } from 'primereact/datatable';
import { Dropdown } from 'primereact/dropdown';
import { Tooltip } from 'primereact/tooltip';
import { useEffect, useMemo, useRef, useState } from 'react';
import { useAppDispatch, useAppSelector } from 'store';
import { isProductManager, isProductOwner, isTechnologyManager } from 'utils/permissions';

import styles from './MaturityModelTab.module.scss';

interface MaturityModelTabProps {
  idProduct: number;
  idMaturityModel: number | null;
}

interface MaturityModelItemViewModel extends MaturityModelGroupItemsDTO {
  group: string;
  idMaturityModelGroup: number;
}

const footerTemplate = () => {
  return <td colSpan={6}> </td>;
};

const flatItems = (data: MaturityModelGroup[]): MaturityModelItemViewModel[] =>
  data
    .reduce((acc, group) => {
      return [
        ...acc,
        ...group.items.map((item) => ({
          ...item,
          group: `${group.order}.${group.name}`,
          idMaturityModelGroup: group.idMaturityModelGroup,
        })),
      ];
    }, [] as MaturityModelItemViewModel[])
    .sort((a, b) => a.order - b.order);

const MaturityModelTab = ({ idProduct, idMaturityModel }: MaturityModelTabProps) => {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const [expandedRows, setExpandedRows] = useState<any[] | DataTableExpandedRows>([]);
  const hasSetValue = useRef(false);
  const editableProduct = useAppSelector(editableProductSelector);
  const { data: maturityModel, isLoading } = useMaturityModel(idProduct, idMaturityModel);
  const user = useAppSelector(userSelector);
  const isAdmin = useAppSelector(isAdminSelector);
  const dispatch = useAppDispatch();
  const canEditProduct =
    isAdmin ||
    isProductOwner(user, editableProduct) ||
    isTechnologyManager(user, editableProduct) ||
    isProductManager(user, editableProduct);

  const maturityModelItemViewModels = useMemo(
    () => flatItems(editableProduct?.maturityModel?.groups ?? []),
    [editableProduct?.maturityModel?.groups],
  );

  useEffect(() => {
    // TODO: valida whether it will catch into an infinite loop
    if (!hasSetValue.current && editableProduct && maturityModel) {
      dispatch(setEditableProduct({ ...editableProduct, maturityModel }));
      hasSetValue.current = true;
    }
  }, [dispatch, editableProduct, maturityModel]);

  if (!idMaturityModel && !isLoading)
    return (
      <div>
        <h5>No Maturity Model has been selected</h5>
      </div>
    );

  const renderMaturity = () => {
    if (!editableProduct?.maturityModel?.groups) return '';
    const minValue = Math.min(
      ...editableProduct.maturityModel.groups.flatMap((g) => g.items.map((i) => i.selectedOption?.maturityLevel ?? 0)),
    );
    return (
      <StatusBadge fontSize={15} color={getStatusColor(minValue)}>
        {getHeader(minValue)}
      </StatusBadge>
    );
  };

  const headerTemplate = (data: MaturityModelItemViewModel) => {
    if (!editableProduct?.maturityModel) return null;
    const items = editableProduct.maturityModel.groups
      .filter((g) => g.idMaturityModelGroup === data.idMaturityModelGroup)
      .flatMap((g) => g.items);
    const minMaturity = Math.min(...items.map((i) => i.selectedOption?.maturityLevel ?? 0));
    return (
      <>
        <strong>{data.group.split('.').pop()}</strong>{' '}
        <StatusBadge color={getStatusColor(minMaturity)}>{getHeader(minMaturity)}</StatusBadge>
      </>
    );
  };

  const editOption = (idMaturityModelGroupItems: number, value: MaturityModelGroupItemsOptions) => {
    if (!editableProduct?.maturityModel) return;
    const groups = editableProduct.maturityModel.groups.map((group) => ({
      ...group,
      items: group.items.map((item) =>
        item.idMaturityModelGroupItems === idMaturityModelGroupItems
          ? { ...item, selectedOption: value, edited: true }
          : item,
      ),
    }));
    dispatch(
      setEditableProduct({
        ...editableProduct,
        maturityModel: { ...editableProduct.maturityModel, groups: groups ?? [] },
      }),
    );
  };

  const statusEditor = (props: ColumnEditorOptions) => {
    const rowData = props.rowData as MaturityModelItemViewModel;
    return (
      <Dropdown
        value={rowData.selectedOption}
        options={[...rowData.options].sort((a, b) => a.maturityLevel - b.maturityLevel)}
        itemTemplate={(option: MaturityModelGroupItemsOptions) => `${option.maturityLevel} - ${option.name}`}
        optionLabel="name"
        dataKey="idMaturityModelGroupItemsOptions"
        onChange={(e) => editOption(rowData.idMaturityModelGroupItems, e.value)}
        style={{ width: '100%' }}
        placeholder="Status"
      />
    );
  };

  const statusBodyTemplate = (rowData: MaturityModelItemViewModel) =>
    rowData.selectedOption ? (
      <>
        <Tooltip disabled={canEditProduct} mouseTrack target={`[id='qa-status-${rowData.idMaturityModelGroupItems}']`}>
          <div>
            <p>Editing requires one the following permissions:</p>
            <ul>
              <li>
                <b>Admin</b>
              </li>
              <li>
                <b>Technical Product Owner</b>
              </li>
              <li>
                <b>Global Product manager</b>
              </li>
              <li>
                <b>Head of Technology</b>
              </li>
            </ul>
          </div>
        </Tooltip>
        <div id={`qa-status-${rowData.idMaturityModelGroupItems}`}>
          <StatusBadge color={getStatusColor(rowData.selectedOption.maturityLevel)}>
            {rowData.selectedOption.name}
          </StatusBadge>
        </div>
      </>
    ) : (
      <StatusBadge color="grey">Not set</StatusBadge>
    );

  return (
    <BlockUI
      fullScreen
      blocked={isLoading}
      template={<i className="pi pi-spin pi-spinner" style={{ fontSize: '3rem' }} />}
    >
      <div className={styles.field}>
        <h4>Maturity {renderMaturity()}</h4>
      </div>
      <DataTable
        value={maturityModelItemViewModels}
        rowGroupMode="subheader"
        groupRowsBy="group"
        sortMode="single"
        sortField="group"
        dataKey="idMaturityModelGroupItems"
        editMode={canEditProduct ? 'cell' : undefined}
        responsiveLayout="scroll"
        sortOrder={1}
        expandableRowGroups
        expandedRows={expandedRows}
        onRowToggle={(e) => setExpandedRows(e.data)}
        rowGroupHeaderTemplate={headerTemplate}
        rowGroupFooterTemplate={footerTemplate}
      >
        <Column
          headerStyle={{ width: '100%' }}
          field="name"
          header
          body={(rowData: MaturityModelItemViewModel) => `${rowData.order} - ${rowData.name}`}
        />
        <Column
          headerStyle={{ width: '60%' }}
          field="status"
          header="Status"
          editor={canEditProduct ? statusEditor : undefined}
          body={statusBodyTemplate}
          bodyStyle={{ cursor: canEditProduct ? `url(${editIcon}),cell` : 'default' }}
        />
      </DataTable>
    </BlockUI>
  );
};

export default MaturityModelTab;
