/* eslint-disable @typescript-eslint/no-unused-vars */
import {
  EditorMountEvent,
  EditorProps,
  EditorTools,
  EditorUtils,
  Editor as KendoEditor,
  ProseMirror,
} from '@progress/kendo-react-editor';
import { useRef } from 'react';

import styles from './Editor.module.scss';

const {
  Bold,
  Italic,
  Underline,
  Strikethrough,
  Subscript,
  Superscript,
  ForeColor,
  BackColor,
  CleanFormatting,
  AlignLeft,
  AlignCenter,
  AlignRight,
  AlignJustify,
  Indent,
  Outdent,
  OrderedList,
  UnorderedList,
  NumberedList,
  BulletedList,
  Undo,
  Redo,
  FontSize,
  FormatBlock,
  Link,
  Unlink,
  InsertImage,
  ViewHtml,
  Print,
  Pdf,
  InsertTable,
  AddRowBefore,
  AddRowAfter,
  AddColumnBefore,
  AddColumnAfter,
  DeleteRow,
  DeleteColumn,
  DeleteTable,
  MergeCells,
  SplitCell,
} = EditorTools;

const { EditorState, EditorView, Plugin, PluginKey } = ProseMirror;
const {
  imageResizing,
  tableResizing,
  pasteCleanup,
  sanitize,
  sanitizeClassAttr,
  sanitizeStyleAttr,
  removeAttribute,
  replaceImageSourcesFromRtf,
} = EditorUtils;

interface Props extends EditorProps {
  value: string;
  disabled?: boolean;
}

const editorStyle = `
    p {
      font-family: "Gilroy-Regular", sans-serif;
    }
`;

const pasteSettings = {
  convertMsLists: true,
  // stripTags: 'span|font'
  attributes: {
    class: sanitizeClassAttr,
    style: sanitizeStyleAttr,
    // keep `width`, `height` and `src` attributes
    width: () => {},
    height: () => {},
    src: () => {},
    // Removes `lang` attribute
    // lang: removeAttribute,

    // removes other (unspecified above) attributes
    '*': removeAttribute,
  },
};

const Editor = ({ disabled = false, ...rest }: Props) => {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const view = useRef<any>(null);

  const onMount = (event: EditorMountEvent) => {
    const { state } = event.viewProps;
    const iframeDocument = event.dom.ownerDocument;
    const style = iframeDocument.createElement('style');
    style.appendChild(iframeDocument.createTextNode(editorStyle));
    iframeDocument.head.appendChild(style);
    const plugins = [
      ...state.plugins,
      ...tableResizing(),
      imageResizing(),
      new Plugin({
        key: new PluginKey('readonly'),
        props: { editable: () => !disabled },
        filterTransaction: (tr) => !disabled || !tr.docChanged,
      }),
    ];
    view.current = new EditorView(
      {
        mount: event.dom,
      },
      {
        ...event.viewProps,
        state: EditorState.create({
          doc: state.doc,
          plugins,
        }),
      },
    );
    return view.current;
  };

  return (
    <KendoEditor
      className={styles.editor}
      {...rest}
      tools={
        disabled
          ? []
          : [
              [Bold, Italic, Underline, Strikethrough],
              [Subscript, Superscript],
              ForeColor,
              BackColor,
              [CleanFormatting],
              [AlignLeft, AlignCenter, AlignRight, AlignJustify],
              [Indent, Outdent],
              [OrderedList, UnorderedList],
              [NumberedList, BulletedList],
              FontSize,
              FormatBlock,
              [Undo, Redo],
              [Link, Unlink, InsertImage, ViewHtml],
              [Pdf, Print],
              [InsertTable],
              [AddRowBefore, AddRowAfter, AddColumnBefore, AddColumnAfter],
              [DeleteRow, DeleteColumn, DeleteTable],
              [MergeCells, SplitCell],
            ]
      }
      onPasteHtml={(event) => {
        let html = pasteCleanup(sanitize(event.pastedHtml), pasteSettings);

        // If the pasted HTML contains images with sources pointing to the local file system,
        // `replaceImageSourcesFromRtf` will extract the sources from the RTF and place them to images 'src' attribute in base64 format.
        if (event.nativeEvent.clipboardData) {
          html = replaceImageSourcesFromRtf(html, event.nativeEvent.clipboardData);
        }
        return html;
      }}
      onMount={onMount}
    />
  );
};

Editor.defaultProps = {
  disabled: false,
};

export default Editor;
