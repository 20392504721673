import { ProgressSpinner } from 'components/common';
import { useProductsSimple } from 'features/admin/hooks';
import { showTypeSelector } from 'features/products/reducers/solutionSlice';
import { ProductSimple } from 'features/products/types';
import { Fragment, memo, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useAppSelector } from 'store';
import { Dictionary } from 'types';

import { ProductCard } from '../ProductCard';
import styles from './CardsList.module.scss';

type CardsListProps = {
  layout: string;
  filteredProducts: ProductSimple[];
};

const groupByProductGroup = (data: ProductSimple[]): Dictionary<string, ProductSimple>[] =>
  data
    .reduce((arr, curr) => {
      const index = arr.findIndex((e) => e.key === curr.group.productGroup);
      if (index >= 0) {
        arr[index].values.push(curr);
      } else {
        arr.push({ key: curr.group.productGroup, values: [curr] });
      }
      return arr;
    }, [] as Dictionary<string, ProductSimple>[])
    .sort((a, b) => (a.key < b.key ? -1 : 1));

const CardsList = ({ layout, filteredProducts }: CardsListProps) => {
  const showType = useAppSelector(showTypeSelector);
  const { data } = useProductsSimple(showType);
  const [isMounted, setIsMounted] = useState(false);
  const { t } = useTranslation();

  const products = useMemo(() => data ?? [], [data]);

  const groupedProducts = useMemo(() => groupByProductGroup(filteredProducts), [filteredProducts]);

  useEffect(() => {
    setIsMounted(true);
  }, []);

  const renderCardList = useMemo(
    () =>
      groupedProducts.map(({ key, values }) => (
        <Fragment key={key}>
          <div className={styles.groupHeaderCard}>
            <h2>{key}</h2>
          </div>
          {values.map((product) => (
            <ProductCard key={product.idProduct} product={product} layout="large" />
          ))}
        </Fragment>
      )),
    [groupedProducts],
  );

  const renderGridList = useMemo(
    () =>
      groupedProducts.map(({ key, values }) => (
        <Fragment key={key}>
          <div className={styles.groupHeaderCard}>
            <h2>{key}</h2>
          </div>
          {values.map((product) => (
            <ProductCard key={product.idProduct} product={product} layout="small" />
          ))}
        </Fragment>
      )),
    [groupedProducts],
  );

  const renderProducts = useMemo(() => {
    if (products.length === 0) return <div className={styles.emptyList}>{t('products.empty-list')}</div>;
    if (groupedProducts.length === 0)
      return <div className={styles.emptyList}>{t('products.no-products-have-been-found')}</div>;
    return layout === 'card' ? renderCardList : renderGridList;
  }, [groupedProducts.length, layout, products.length, renderCardList, renderGridList, t]);

  return isMounted ? <>{renderProducts}</> : <ProgressSpinner className={styles.fullScreenSpinner} />;
};

export default memo(CardsList);
