/**
 * @param {string} dateTimeString - 2021-12-22T00:00:00
 * @returns {string} 2021/12/22
 */
export const toDateString = (dateTimeString: string, delimiter: '-' | '/' | '.' = '/'): string => {
  const dateString = dateTimeString.split('T')[0];
  const dateArray = dateString.split('-');
  const year = dateArray[0];
  const month = dateArray[1];
  const day = dateArray[2];
  return `${year}${delimiter}${month}${delimiter}${day}`;
};

/**
 * @param {string} dateTimeString - 2021-12-22T00:00:00
 * @returns {string} 12/2021
 */
export const toMonthString = (dateTimeString: string, delimiter: '-' | '/' | '.' = '/'): string => {
  const dateString = dateTimeString.split('T')[0];
  const dateArray = dateString.split('-');
  const year = dateArray[0];
  const month = dateArray[1];
  return `${month}${delimiter}${year}`;
};

/**
 * @param {string} dateTimeString - 2022-06-04T02:28:02
 * @returns {string} 6/3/2022, 11:28:02 PM
 */
export const toLocaleDateTimeString = (dateTimeString: string): string => {
  if (!dateTimeString) return '';
  const date = dateTimeString.includes('Z') ? new Date(dateTimeString) : new Date(`${dateTimeString}Z`);
  return date.toLocaleString();
};

export const addDays = (date: Date, days: number): Date => {
  return new Date(date.getTime() + days * 86400000);
};

export const isWeekend = (date: Date) => date.getDay() === 0 || date.getDay() === 6;

export function getWeekends(startDate: Date, endDate: Date): Date[] {
  const weekends: Date[] = [];

  const current = new Date(startDate);

  while (current <= endDate) {
    if (isWeekend(current)) {
      weekends.push(new Date(current));
    }
    current.setDate(current.getDate() + 1);
  }

  return weekends;
}

export function getLastWeek() {
  const today = new Date();
  const lastWeekStart = new Date(today);
  lastWeekStart.setDate(today.getDate() - today.getDay() - 6);
  const lastWeekEnd = new Date(today);
  lastWeekEnd.setDate(today.getDate() - today.getDay());

  return { start: lastWeekStart, end: lastWeekEnd };
}

export function getLastAndFirstDayOfMonth(date: Date): { firstDay: Date; lastDay: Date } {
  const currentYear = new Date().getFullYear();
  const currentMonth = new Date().getMonth();

  const targetYear = date.getFullYear();
  const targetMonth = date.getMonth();

  const firstDay = new Date(targetYear, targetMonth, 1);
  const lastDay = new Date(targetYear, targetMonth + 1, 0);

  if (targetYear === currentYear && targetMonth === currentMonth) {
    const currentDate = new Date();
    if (lastDay.getDate() > currentDate.getDate()) {
      lastDay.setDate(currentDate.getDate());
    }
  }

  return { firstDay, lastDay };
}

export const getDayAndWeekDay = (date: Date) =>
  date
    .toLocaleString('en-US', {
      day: '2-digit',
      weekday: 'short',
    })
    .toUpperCase()
    .split(' ');

export function dateWithoutTimeZone(date: Date) {
  if (date == null) return date;
  const timestamp = date.getTime() - date.getTimezoneOffset() * 60000;
  const correctDate = new Date(timestamp);
  correctDate.setUTCHours(0, 0, 0, 0);
  return correctDate;
}

export const isoDateWithoutTimeZone = (date: Date) => dateWithoutTimeZone(date).toISOString();

export function getLastWeekday() {
  const today = new Date();
  const dayOfWeek = today.getDay();

  if (dayOfWeek === 6) {
    today.setDate(today.getDate() - 1);
  } else if (dayOfWeek === 0) {
    today.setDate(today.getDate() - 2);
  }

  return today;
}
