import { StepProps } from 'features/products/types';
import { Dispatch, ReactNode, SetStateAction, createContext, useMemo, useState } from 'react';

type WizardContextProviderProps = {
  children: ReactNode;
};

type WizardContextType = {
  steps: StepProps[];
  setSteps: Dispatch<SetStateAction<StepProps[]>>;
};

export const WizardContext = createContext({} as WizardContextType);

export const WizardContextProvider = ({ children }: WizardContextProviderProps) => {
  const [steps, setSteps] = useState([] as StepProps[]);

  const values = useMemo(
    () => ({
      steps,
      setSteps,
    }),
    [steps],
  );

  return <WizardContext.Provider value={values}>{children}</WizardContext.Provider>;
};
