import dayjs from 'dayjs';
import dayjsBusinessTime from 'dayjs-business-time';
import { RoadMapUpdate, RoadmapStatus, RoadmapStatusItem } from 'domain/roadmap/types';

dayjs.extend(dayjsBusinessTime);

export function getPercentage(items: RoadmapStatusItem[]) {
  const total = items.length;
  const completed = items.filter((item) => item.status === 'Completed' || item.status === 'Not Applicable').length;
  return Math.round((completed / total) * 100);
}

export const getRoadmapStatus = (statuses: RoadmapStatus[]): RoadmapStatus | null => {
  const values = statuses.map((status) => ({ ...status, percentage: getPercentage(status.roadmapStatusItems) }));
  return values.find((v) => v.percentage !== 100) ?? values.slice(-1)[0];
};

export const getUpdateWorkingDays = (
  editableRoadMap: RoadMapUpdate,
  idRoadmapStatus: number,
  value: number | null,
): RoadMapUpdate | null => {
  if (!editableRoadMap.type?.roadmapStatus || !value) return null;
  const index = editableRoadMap.type.roadmapStatus.findIndex((s) => s.idRoadmapStatus === idRoadmapStatus);
  const newStatuses = [...editableRoadMap.type.roadmapStatus];
  if (index != null) {
    const workingDaysDiff = value - newStatuses[index].workingDays;
    const workingDaysCount = Math.abs(workingDaysDiff);
    const sign = Math.sign(workingDaysDiff);

    const oldStatus = { ...newStatuses[index] };

    oldStatus.workingDays = value;

    if (oldStatus.startDate && oldStatus.endDate) {
      if (oldStatus.isRelease) {
        oldStatus.newStartDate = oldStatus.startDate;

        const newEndDate = dayjs(oldStatus.startDate).addBusinessDays(value).toISOString();

        oldStatus.endDate = newEndDate;
        oldStatus.newEndDate = newEndDate;
        oldStatus.edited = true;
      } else {
        let newStartDate =
          sign > 0
            ? dayjs(oldStatus.startDate).subtractBusinessDays(workingDaysCount).toISOString()
            : dayjs(oldStatus.startDate).addBusinessDays(workingDaysCount).toISOString();
        oldStatus.startDate = newStartDate;
        oldStatus.newStartDate = newStartDate;
        oldStatus.newEndDate = oldStatus.endDate;
        oldStatus.edited = true;

        for (let i = index - 1; i >= 0; i--) {
          const currentStatus = { ...newStatuses[i] };
          newStartDate =
            sign > 0
              ? dayjs(currentStatus.startDate).subtractBusinessDays(workingDaysCount).toISOString()
              : dayjs(currentStatus.startDate).addBusinessDays(workingDaysCount).toISOString();

          currentStatus.startDate = newStartDate;
          currentStatus.newStartDate = newStartDate;
          const newEndDate =
            sign > 0
              ? dayjs(currentStatus.endDate).subtractBusinessDays(workingDaysCount).toISOString()
              : dayjs(currentStatus.endDate).addBusinessDays(workingDaysCount).toISOString();

          currentStatus.endDate = newEndDate;
          currentStatus.newEndDate = newEndDate;
          currentStatus.edited = true;

          newStatuses[i] = currentStatus;
        }
      }
    }

    newStatuses[index] = oldStatus;
  }
  const roadmapStatuses = newStatuses?.map((status) =>
    status.idRoadmapStatus === idRoadmapStatus
      ? {
          ...status,
          workingDays: value,
        }
      : status,
  );

  return {
    ...editableRoadMap,
    type: editableRoadMap.type ? { ...editableRoadMap.type, roadmapStatus: roadmapStatuses } : null,
  };
};
