import axios, { AxiosError } from 'axios';
import { toast } from 'react-toastify';

import { env } from '../utils/env';
import storage from './storage';

export const BASE_URL = env.REACT_APP_BASE_URL || process.env.REACT_APP_BASE_URL;

export const BASE_API_URL = `${BASE_URL}/api`;
export const ORIGIN = window.location.origin.toString();

export const REPO_URL = env.REACT_APP_REPO_URL || process.env.REACT_APP_REPO_URL;

const api = axios.create({
  baseURL: BASE_API_URL,
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json',
    'Access-Control-Allow-Origin': ORIGIN,
  },
});

const errorHandler = async (error: Error | AxiosError) => {
  if (axios.isAxiosError(error)) {
    if (error.response?.status === 500) {
      toast.error(`${error.response.data.message ?? error.response.statusText}`, {
        position: 'top-right',
        toastId: '500error',
        autoClose: false,
      });
    }
    if (!error.response) {
      toast.error(`Network Error! Api is not reachable. Check if service is running on url ${BASE_API_URL}`, {
        position: 'top-right',
        toastId: 'networkError',
        autoClose: false,
      });
    }
  }
  return Promise.reject(error);
};

api.interceptors.response.use(
  (response) => response,
  (error) => errorHandler(error),
);

api.interceptors.request.use((config) => {
  if (config.headers && !config.headers.authorization) {
    const token = storage.getItem<string>('token');
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }
  }
  return config;
});

export default api;
