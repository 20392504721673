import dayjs from 'dayjs';
import dayjsBusinessTime from 'dayjs-business-time';
import { getRoadmapsTypeStatusesByVersion } from 'domain/roadmap/API';
import { useRoadmapsVersions } from 'domain/roadmap/hooks';
import { RoadMapUpdate, RoadMapVersion } from 'domain/roadmap/types';
import { useProductsBrief } from 'features/admin/hooks';
import { isAdminSelector, userSelector } from 'features/auth/reducer/auth';
import { editableRoadMapSelector, setEditableRoadmap } from 'features/roadmap/reducers/roadmap';
import { BlockUI } from 'primereact/blockui';
import { Tooltip } from 'primereact/tooltip';
import { useEffect, useMemo, useRef, useState } from 'react';
import { Control, UseFormSetValue, UseFormWatch } from 'react-hook-form';
import { useAppDispatch, useAppSelector } from 'store';
import { canEditRoadmapDetails, isRoadmapOwner } from 'utils/permissions';

import {
  AffectedVersionsField,
  BugTypeField,
  CategoryField,
  CustomersField,
  DescriptionField,
  DetailedDescriptionField,
  DevelopmentTasksField,
  ItemField,
  JustificationField,
  OwnerField,
  ParticipantsField,
  PriorityField,
  ProductField,
  QualityCertificateField,
  ReporterField,
  RoadmapTypeField,
  SpecificationField,
  StatusField,
  VersionField,
} from './Fields';
import styles from './RoadMapDetailForm.module.scss';
import FollowerPanel from './components/FollowerPanel';

dayjs.extend(dayjsBusinessTime);

interface RoadMapDetailFormProps {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  control: Control<RoadMapUpdate, any>;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  watch: UseFormWatch<RoadMapUpdate>;
  setValue: UseFormSetValue<RoadMapUpdate>;
  viewOnly?: boolean;
}

const RoadMapDetailForm = ({ control, watch, setValue, viewOnly = true }: RoadMapDetailFormProps) => {
  const idRoadmapType = useRef<number | null | undefined>(null);
  const [roadmapVersions, setRoadmapVersions] = useState<RoadMapVersion[]>([]);
  const editableRoadMap = useAppSelector(editableRoadMapSelector);
  const user = useAppSelector(userSelector);
  const isAdmin = useAppSelector(isAdminSelector);

  const dispatch = useAppDispatch();

  idRoadmapType.current = watch('type')?.idRoadmapType;
  const isInternal = watch('bugType') !== 1;

  const { isLoading: isLoadingProduct } = useProductsBrief();
  const { data: versions } = useRoadmapsVersions(!!user);

  const isCustomerCare = useMemo(
    () => editableRoadMap.type?.customerCare ?? false,
    [editableRoadMap.type?.customerCare],
  );

  const canEditRoadmap =
    (isCustomerCare && !!user) ||
    !editableRoadMap.idRoadmap ||
    isRoadmapOwner(user, editableRoadMap) ||
    canEditRoadmapDetails(user, editableRoadMap);

  useEffect(() => {
    const idPlatform = watch('product')?.idPlatform;
    const filteredVersions = versions?.filter((v) => v.release?.idPlatform === idPlatform) ?? [];
    setRoadmapVersions(filteredVersions);
  }, [versions, watch]);

  const updateStatusesByVersion = async (typeId: number, version: RoadMapVersion) => {
    const roadmapStatus = await getRoadmapsTypeStatusesByVersion(typeId, version.idPlatformVersion);
    let lastStartDate = '';
    const statuses = [...(editableRoadMap.type?.roadmapStatus ?? [])]
      .sort((a, b) => b.statusOrder - a.statusOrder)
      .map((s) => {
        const status = roadmapStatus.find((rs) => rs.idRoadmapStatus === s.idRoadmapStatus);
        if (!status) return s;
        if (!lastStartDate) {
          lastStartDate = status.startDate;
        }
        let newEndDate = '';
        if (s.isRelease) {
          lastStartDate = status.startDate;
          newEndDate = dayjs(status.startDate).addBusinessDays(s.workingDays).toISOString();
        } else {
          newEndDate = dayjs(lastStartDate).subtractBusinessDays(1).toISOString();
          lastStartDate = dayjs(newEndDate)
            .subtractBusinessDays(Math.max(s.workingDays - 1, 1))
            .toISOString();
        }

        return {
          ...s,
          startDate: lastStartDate,
          newStartDate: lastStartDate,
          endDate: newEndDate,
          newEndDate,
          edited: true,
        };
      });

    dispatch(
      setEditableRoadmap({
        ...editableRoadMap,
        version,
        type: editableRoadMap.type
          ? { ...editableRoadMap.type, roadmapStatus: [...statuses].sort((a, b) => a.statusOrder - b.statusOrder) }
          : null,
      }),
    );
  };

  return (
    <BlockUI
      fullScreen
      blocked={isLoadingProduct}
      template={<i className="pi pi-spin pi-spinner" style={{ fontSize: '3rem' }} />}
    >
      <div className={`${styles.form} p-jc-center`}>
        <form className="p-fluid">
          {!editableRoadMap.idRoadmap && (
            <div className={styles.field}>
              <span>Type</span>
              <p className={styles.readonlyField}>{editableRoadMap.type?.name}</p>
            </div>
          )}
          <div className={styles.container}>
            <div className={styles.productItemType}>
              <ItemField viewOnly={viewOnly || !canEditRoadmap} control={control} />

              <ProductField
                viewOnly={viewOnly || !canEditRoadmap}
                isCustomerCare={isCustomerCare}
                control={control}
                setValue={setValue}
                setRoadmapVersions={setRoadmapVersions}
              />

              {editableRoadMap.idRoadmap > 0 && (
                <RoadmapTypeField
                  viewOnly={viewOnly || !canEditRoadmap}
                  control={control}
                  updateStatusesByVersion={updateStatusesByVersion}
                  setValue={setValue}
                />
              )}
            </div>

            {isAdmin && editableRoadMap.idRoadmap > 0 && <FollowerPanel />}
          </div>

          {isCustomerCare && <BugTypeField viewOnly={viewOnly || !canEditRoadmap} control={control} />}

          <StatusField viewOnly={viewOnly || !canEditRoadmap} />

          <PriorityField viewOnly={viewOnly || !canEditRoadmap} control={control} />

          {isCustomerCare && <CategoryField viewOnly={viewOnly || !canEditRoadmap} control={control} />}

          {isCustomerCare && (
            <AffectedVersionsField
              viewOnly={viewOnly || !canEditRoadmap}
              control={control}
              product={watch('product')}
              roadmapVersions={roadmapVersions}
              required={!isInternal && roadmapVersions.length > 0}
            />
          )}

          <VersionField
            viewOnly={viewOnly || !canEditRoadmap}
            isCustomerCare={isCustomerCare}
            control={control}
            product={watch('product')}
            roadmapVersions={roadmapVersions ?? []}
            updateStatusesByVersion={updateStatusesByVersion}
          />

          {isCustomerCare && (
            <>
              <CustomersField viewOnly={viewOnly || !canEditRoadmap} control={control} required={!isInternal} />

              <ReporterField viewOnly={viewOnly || !canEditRoadmap} control={control} />
            </>
          )}

          <OwnerField viewOnly={viewOnly || !canEditRoadmap} control={control} />

          <ParticipantsField viewOnly={viewOnly || !canEditRoadmap} control={control} />

          {!isCustomerCare && <SpecificationField viewOnly={viewOnly || !canEditRoadmap} control={control} />}

          <DevelopmentTasksField viewOnly={viewOnly || !canEditRoadmap} control={control} />

          <QualityCertificateField viewOnly={viewOnly || !canEditRoadmap} control={control} />

          <JustificationField viewOnly={viewOnly || !canEditRoadmap} control={control} />

          <DescriptionField viewOnly={viewOnly || !canEditRoadmap} control={control} />

          <DetailedDescriptionField viewOnly={viewOnly || !canEditRoadmap} control={control} />
        </form>
      </div>
      <Tooltip target=".has-tooltip" mouseTrack mouseTrackLeft={10} />
    </BlockUI>
  );
};

RoadMapDetailForm.defaultProps = {
  viewOnly: true,
};

export default RoadMapDetailForm;
