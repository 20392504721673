import { Button, SelectButton } from 'components/common';
import { filtersValueSelector } from 'features/products/reducers/solutionSlice';
import { FilterGroup, StepProps, StepValue } from 'features/products/types';
import { useWizard } from 'hooks';
import i18next from 'i18next';
import { SelectButtonProps } from 'primereact/selectbutton';
import { Fragment } from 'react';
import { useAppSelector } from 'store';

import { Card } from '../Card';
import styles from './Step.module.scss';

const buttonTemplate = (option: SelectButtonProps) => (
  <Card key={option.value.value} title={option.value.value} tooltip={option.tooltip ?? ''} />
);

const renderButtons = (
  step: StepProps,
  handleSelectAll: (id: number, label: string, filterGroup: FilterGroup) => void,
  addValues: (value: StepValue[]) => void,
) => {
  const { groups, options, values, group: filterGroup } = step;
  if (groups?.length) {
    return [...groups]
      .sort((a, b) => (a.group < b.group ? -1 : 1))
      .map(({ group, options: opts, id }) => (
        <Fragment key={group}>
          <div className={styles.groupHeader}>
            <h4>{group}</h4>
            <Button
              onClick={() => handleSelectAll(id, group, filterGroup)}
              label={i18next.t('general.selectAll')}
              className="p-button-outlined"
            />
          </div>
          <SelectButton
            className={styles.selectButton}
            value={values}
            options={[...opts]?.sort((a, b) => (a.value.value < b.value.value ? -1 : 1))}
            onChange={(e) => addValues(e.value as StepValue[])}
            itemTemplate={buttonTemplate}
            multiple
          />
        </Fragment>
      ));
  }
  return (
    <SelectButton
      className={styles.selectButton}
      value={values}
      options={options?.sort((a, b) => (a.value.value < b.value.value ? -1 : 1))}
      onChange={(e) => addValues(e.value as StepValue[])}
      itemTemplate={buttonTemplate}
      multiple
    />
  );
};

const Step = ({ index }: { index: number }) => {
  const { steps, setSteps } = useWizard();
  const filtersValue = useAppSelector(filtersValueSelector);

  const step = steps[index];

  const findParent = (id: number, label: string, filterGroup: FilterGroup) => {
    const filter = filtersValue.find((b) => b.group === filterGroup);
    if (filter?.children?.length) {
      const selectedGroup = filter.children.find((b) => b.label === label);
      if (selectedGroup) {
        return [
          ...selectedGroup.values.map((v) => ({
            id: v.id,
            group: FilterGroup.BENEFIT,
            value: v.value,
            isParent: false,
          })),
          { id, group: filter.group, value: label, isParent: true },
        ].filter((v) => !step.values?.some((s) => s.value === v.value)) as StepValue[];
      }
    }
    return [];
  };

  const handleSelectAll = (id: number, label: string, filterGroup: FilterGroup) => {
    const values = findParent(id, label, filterGroup);
    const stepArr = steps.map((x, i) => (i === index ? { ...x, values: [...x.values, ...values] } : x));
    setSteps(stepArr);
  };

  const addValues = (values: StepValue[]) => {
    const stepArr = steps.map((x, i) => (i === index ? { ...x, values } : x));
    setSteps(stepArr);
  };

  return step ? (
    <section className={styles.container}>
      <h2>{step.title}</h2>
      <p>{step.content}</p>
      <div className={styles.cards}>{renderButtons(step, handleSelectAll, addValues)}</div>
    </section>
  ) : null;
};

export default Step;
