import { usePersonas } from 'features/admin/hooks';
import { editableProductSelector, setEditableProduct } from 'features/products/reducers/solutionSlice';
import { UserPersona } from 'features/products/types';
import { Column } from 'primereact/column';
import { useAppDispatch, useAppSelector } from 'store';

import CrudTable from '../CrudTable/CrudTable';

type PersonasTableProps = {
  personas: UserPersona[];
};

const PersonasTable = ({ personas }: PersonasTableProps) => {
  const editableProduct = useAppSelector(editableProductSelector);
  const dispatch = useAppDispatch();
  const { data, isLoading } = usePersonas();

  const addItems = async (values: UserPersona[]) => {
    if (editableProduct) {
      dispatch(setEditableProduct({ ...editableProduct, userPersonas: [...editableProduct.userPersonas, ...values] }));
    }
  };

  const removeItems = async (persona: UserPersona) => {
    if (editableProduct) {
      dispatch(
        setEditableProduct({
          ...editableProduct,
          userPersonas: editableProduct.userPersonas.filter((x) => x.idUserPersona !== persona.idUserPersona),
        }),
      );
    }
  };

  return (
    <CrudTable
      currentValues={personas}
      isLoading={isLoading}
      totalValues={data?.filter((item) => !personas.some((b) => b.idUserPersona === item.idUserPersona)) ?? []}
      dataKey="idUserPersona"
      onAddAsync={addItems}
      onDeleteAsync={removeItems}
    >
      <Column field="userPersona" header="End-user" sortable />
    </CrudTable>
  );
};

export default PersonasTable;
