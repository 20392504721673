import { LifeCycle } from 'features/products/types';
import { useQuery } from 'react-query';
import api from 'services/api';

const getLifeCycles = async () => {
  const { data } = await api.get('lifeCycles');
  return data as LifeCycle[];
};

export function useLifeCycles() {
  return useQuery<LifeCycle[], Error>('lifeCycles', getLifeCycles, {
    staleTime: 1000 * 60 * 15,
  });
}
