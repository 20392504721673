import { RoadMapUpdate } from 'domain/roadmap/types';
import { Control } from 'react-hook-form';

import GenericClickableField from './GenericClickableField';

interface JustificationFieldProps {
  viewOnly: boolean;
  control: Control<RoadMapUpdate, unknown>;
}

const JustificationField = ({ viewOnly, control }: JustificationFieldProps) => (
  <GenericClickableField
    control={control}
    viewOnly={viewOnly}
    title="Justification"
    name="justification"
    maxLength={250}
  />
);

export default JustificationField;
