import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import type { RootState } from 'store/rootReducer';

import { EditableProduct, Filter, FilterList, Filters, Industries, ShowType } from '../types';

interface SolutionState {
  editableProduct: EditableProduct | null;
  showType: ShowType[];
  industry: Industries;
  searchValue: string;
  filterList: FilterList | null;
  filtersValue: Filters[];
  filters: Filter[];
}

const initialState: SolutionState = {
  editableProduct: null,
  showType: ['Show Solutions'],
  industry: Industries.ALL_SOLUTIONS,
  searchValue: '',
  filterList: null,
  filtersValue: [],
  filters: [],
};

const solutionSlice = createSlice({
  name: 'solutions',
  initialState,
  reducers: {
    setEditableProduct: (state, action: PayloadAction<EditableProduct | null>) => {
      state.editableProduct = action.payload;
    },
    setShowType: (state, action: PayloadAction<ShowType[]>) => {
      state.showType = action.payload;
    },
    setSolutionIndustry: (state, action: PayloadAction<Industries>) => {
      state.industry = action.payload;
    },
    setSearchValue: (state, action: PayloadAction<string>) => {
      state.searchValue = action.payload;
    },
    setFilterList: (state, action: PayloadAction<FilterList>) => {
      state.filterList = action.payload;
    },
    setFilters: (state, action: PayloadAction<Filter[]>) => {
      state.filters = action.payload;
    },
    setFilterValue: (state, action: PayloadAction<Filters[]>) => {
      state.filtersValue = action.payload;
    },
    addFilter: (state, action: PayloadAction<Filter>) => {
      state.filters = [...state.filters, action.payload];
    },
    clearFilters: (state) => {
      state.filters = [];
    },
    removeFilter: (state, action: PayloadAction<Omit<Filter, 'name'>>) => {
      state.filters = state.filters.filter((f) => !(f.id === action.payload.id && f.group === action.payload.group));
    },
    setFilter: (state, action: PayloadAction<Filter>) => {
      const newFilters = state.filters.filter((f) => f.group !== action.payload.group);
      state.filters = [...newFilters, action.payload];
    },
  },
});

export const {
  setEditableProduct,
  setShowType,
  setSolutionIndustry,
  setSearchValue,
  setFilterList,
  setFilters,
  setFilterValue,
  addFilter,
  clearFilters,
  removeFilter,
  setFilter,
} = solutionSlice.actions;

export const editableProductSelector = (state: RootState) => state.solutions.editableProduct;
export const showTypeSelector = (state: RootState) => state.solutions.showType;
export const solutionIndustrySelector = (state: RootState) => state.solutions.industry;
export const searchValueSelector = (state: RootState) => state.solutions.searchValue;
export const filterListSelector = (state: RootState) => state.solutions.filterList;
export const filtersSelector = (state: RootState) => state.solutions.filters;
export const filtersValueSelector = (state: RootState) => state.solutions.filtersValue;

export default solutionSlice.reducer;
