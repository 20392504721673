import { StatusBadge } from 'components/common';
import { StatusBadgeColors } from 'components/common/StatusBadge/StatusBadge';
import { editableRoadMapSelector } from 'features/roadmap/reducers/roadmap';
import { useAppSelector } from 'store';

import styles from '../RoadMapDetailForm.module.scss';

interface StatusFieldProps {
  viewOnly: boolean;
}

const getColor = (value: number | undefined, isRelease: boolean): StatusBadgeColors => {
  if (isRelease) return 'green';
  switch (value) {
    case 1:
      return 'grey';
    case 2:
      return 'lightyellow';
    case 3:
      return 'lightBlue';
    case 4:
      return 'blue';
    case 5:
      return 'lightGreen';
    case 6:
      return 'green';
    default:
      return 'green';
  }
};

const StatusField = ({ viewOnly }: StatusFieldProps) => {
  const { status } = useAppSelector(editableRoadMapSelector);
  return (
    <div
      className={`has-tooltip ${styles.fieldWrapper}`}
      data-pr-tooltip="Status is automatically set according to quality status"
      data-pr-disabled={viewOnly}
    >
      <span>Status: </span>
      {status && (
        <p className={styles.readonlyField}>
          <StatusBadge color={getColor(status.statusOrder, status.isRelease)}>{status.name}</StatusBadge>
        </p>
      )}
    </div>
  );
};

export default StatusField;
