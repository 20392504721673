import { ProgressSpinner as PrimeProgressSpinner, ProgressSpinnerProps } from 'primereact/progressspinner';
import { ReactNode } from 'react';

const ProgressSpinner = (
  props: JSX.IntrinsicAttributes &
    JSX.IntrinsicClassAttributes<PrimeProgressSpinner> &
    Readonly<ProgressSpinnerProps> &
    Readonly<{ children?: ReactNode }>,
) => <PrimeProgressSpinner {...props} />;

export default ProgressSpinner;
